import React, { useContext, useEffect, useRef, useState } from 'react'
import { ChatContext } from './context/ChatContext.js';
import { AuthContext } from './context/AuthContext.js';
import defaultProfilePic from '../assets/default_profile_picture.png';
import { getAuth } from 'firebase/auth';
import { collection, doc, getDoc, setDoc } from 'firebase/firestore';
import { firestore } from '../firebase.js';
import PopupModal from './PopupAbo.tsx';
import { Timestamp } from 'firebase/firestore';

const MessageChat = ({message}) => {

 const {currentUser} = useContext(AuthContext)
 const {data} = useContext(ChatContext)
 const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');

 const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [message]);

  const handleSubscriptionLinkClick = async (senderId) => {
    const auth = getAuth();
    let currentUser = auth.currentUser;
  
    if (!currentUser) {
      console.log('User not logged in');
      return;
    }
  
    if (!senderId) {
      console.log('Sender ID not found');
      return;
    }
  
    const currentUserSubscriptionsRef = collection(firestore, 'users', currentUser.uid, 'mySubscriptions');
    const senderStudentsRef = collection(firestore, 'users', senderId, 'myStudents');
  
    try {
      // Fetch the current user's name
      const currentUserDocRef = doc(firestore, 'users', currentUser.uid);
      const currentUserDoc = await getDoc(currentUserDocRef);
      const currentUserName = currentUserDoc.exists() ? currentUserDoc.data().Name : null;
  
  
      // Fetch the sender's name
      const senderDocRef = doc(firestore, 'users', senderId);
      const senderDoc = await getDoc(senderDocRef);
      const senderName = senderDoc.exists() ? senderDoc.data().Name : null;
  
    
      setPopupMessage(`Félicitations, vous êtes désormais l'élève de ${senderName}`);
      setShowPopup(true);

      const subscriptionTimestamp = Timestamp.now();

  
      // Update current user's subscriptions and sender's students
      await setDoc(doc(currentUserSubscriptionsRef, senderId), { coachId: senderId, Name: senderName,subscribedAt: subscriptionTimestamp });
      await setDoc(doc(senderStudentsRef, currentUser.uid), { userId: currentUser.uid, Name: currentUserName,subscribedAt: subscriptionTimestamp });
  
    } catch (error) {
      console.error("Error in handleSubscriptionLinkClick: ", error);
    }
  

  



  };

  const renderMessageContent = () => {
    if (message.subscriptionLink) {
      // Render the subscription link as a clickable element
      return (
        <p className="subscription-link" onClick={() => handleSubscriptionLinkClick(message.senderId)}>
          Clique ici pour devenir mon élève !
        </p>
      );
    }
    // Render normal message content
    return <p>{message.text}</p>;
  };


const profilePic = message.senderId === currentUser.uid ? 
(currentUser.profilePicUri || defaultProfilePic) : 
(data.user.profilePicUri || defaultProfilePic);

const renderMedia = (message) => {
  if (message.type === 'video') {
    return <video className="media" controls src={message.media} />;
  } else if (message.type === 'image') {
    return <img className="media" src={message.media} alt="" />;
  }
  return null;
};
const handleClosePopup = () => {
  setShowPopup(false);
};


    return (
      <div ref={ref} className={`MessageChat ${message.senderId === currentUser.uid && "owner"}`}>
        <div className="MessageInfo">
        <img src={profilePic} alt="" />
        <span>À l'instant</span>
        </div>
        <div className="MessageContent">
        {renderMessageContent()}
        {renderMedia(message)}


        {showPopup && <PopupModal message={popupMessage} onClose={handleClosePopup} />}
        </div>



      </div>
    );
  };
  
  export default MessageChat;