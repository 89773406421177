import React, { useEffect, useState } from 'react';
import './LandingPageStyles.css';
import { useHistory } from 'react-router-dom';
import { getAnalytics, logEvent } from 'firebase/analytics'; // Import Firebase Analytics functions

const AboutUs = () => {
    const history = useHistory();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
    const analytics = getAnalytics(); // Initialize Firebase Analytics

    useEffect(() => {
        logEvent(analytics, 'about_us_visit');
    }, []);

    const navigateToFAQ = () => {
        history.push('/FAQ');
    };

    const navigateToAboutUs = () => {
        history.push('/AboutUs');
    };

    const navigateToLogin = (buttonLabel) => {
        logEvent(analytics, 'create_account_clicked', { button_label: buttonLabel });
        history.push('/login');
    };

    const navigateToPres = () => {
        history.push('/');
    };

    const openEmail = () => {
        logEvent(analytics, 'send_email_clicked');
        const email = 'contactinfo.repp@gmail.com';
        window.location.href = `mailto:${email}`;
    };

    return (
        <>
            <div className="LandingPageStylesMainContainerOtherPages">
                <header className="LandingPageStylesHeaderContainer">
                    <div onClick={navigateToPres}>
                        <img src={require('../assets/ReReReDesign.png')} alt="Logo" className="LandingPageStylesLogo" />
                    </div>
                    <img src={require('../assets/hamburger_menu.png')} alt="Menu" className="HamburgerMenuIcon" onClick={toggleMenu} />
                    <div className={`MenuOverlay ${isMenuOpen ? 'Active' : ''}`} onClick={toggleMenu}></div>
                    <nav className={`LandingPageStylesNavigationContainer ${isMenuOpen ? 'Active' : ''}`}>
                        <img src={require('../assets/cross_modal.png')} alt="Close" className="CloseMenuIcon" onClick={toggleMenu} />
                        <div
                            className='LandingPageStylesContainerMenuMobileTextButton'
                            onClick={navigateToFAQ}
                            style={{ cursor: 'pointer' }} // Add cursor pointer style to indicate it's clickable
                        >
                            <span className='LandingPageStylesButtonNavigation'>FAQ</span>
                            <img className='LandingPageStylesMenuMobileIcon' src={require('../assets/next_icon_gris.png')} alt="Next" />
                        </div>
                        <div
                            className='LandingPageStylesContainerMenuMobileTextButton'
                            onClick={navigateToAboutUs}
                            style={{ cursor: 'pointer' }}
                        >
                            <span className='LandingPageStylesButtonNavigation'>Qui sommes-nous ?</span>
                            <img className='LandingPageStylesMenuMobileIcon' src={require('../assets/next_icon_gris.png')} alt="Next" />
                        </div>
                        <div
                            className='LandingPageStylesContainerMenuMobileTextButton'
                            onClick={openEmail}
                            style={{ cursor: 'pointer' }}
                        >
                            <span className='LandingPageStylesButtonNavigation'>Nous contacter</span>
                            <img className='LandingPageStylesMenuMobileIcon' src={require('../assets/next_icon_gris.png')} alt="Next" />
                        </div>
                        <button onClick={() => navigateToLogin('Créer mon compte')} className="BlueButton LandingPageStylesAddWidth LandingPageStylesAddMargingTop">Créer mon compte</button>
                        <div className="LandingPageFooterMessage">
                            <span>Fait avec ❤️ par l'équipe Repp</span>
                        </div>
                    </nav>
                </header>
                <div style={{ marginTop: '30px' }}>
                    <section className="LandingPageStylesFourthSectionContainer LandingPageStylesAddMaringBottom">
                        <img src={require('../assets/Pres_About_Us.png')} alt="Exercise" className="LandingPageStylesImageCoach" />
                        <div className="LandingPageStylesTextContainer">
                            <span className='LandingPageStylesTitle'>Deux frères</span>
                            <span className='LandingPageStylesDescription'>Nous sommes deux frères, ingénieurs de formation avec une passion pour le Streetlifting. <br></br><br></br>Pendant des années, nous avons fait l'expérience frustrante de jongler entre des feuilles Excel compliquées et des applications peu adaptées pour suivre nos performances et nos progrès. Fatigués de ces méthodes peu pratiques et inefficaces, nous avons été poussés par l'envie de concevoir quelque chose de différent. <br></br><br></br>Nous visions à créer un outil qui offre un suivi intuitif et complet, qui serve autant les besoins des athlètes que ceux de leurs coachs. <br></br><br></br>C'est dans cet esprit que notre plateforme a été développée : une solution pensée par des sportifs, pour des sportifs, destinée à rendre le coaching plus simple et plus efficace.</span>
                        </div>
                    </section>
                </div>
            </div>
            <footer className="LandingPageStylesFooterContainer">
                <div className="LandingFooter-section">
                    <img src={require('../assets/repp_logo_white.png')} alt="Logo" className="LandingPageStylesLogoFooter" />
                    <span style={{ marginTop: '20px' }}>© 2024 Repp Company.</span>
                </div>
                <div className="LandingFooter-section">
                    <h5>Entreprise</h5>
                    <button onClick={navigateToAboutUs} className='LandingFooter-button'>À propos</button>
                    <button onClick={navigateToFAQ} className='LandingFooter-button'>FAQ</button>
                    <button onClick={openEmail} className='LandingFooter-button'>Nous contacter</button>
                </div>
                <div className="LandingFooter-section">
                    <h5>Plateforme</h5>
                    <button onClick={() => navigateToLogin('Créer mon compte footer')} className='LandingFooter-button'>Créer un compte</button>
                    <button onClick={() => navigateToLogin('Inviter mes élèves footer')} className='LandingFooter-button'>Inviter vos élèves</button>
                </div>
            </footer>
        </>
    );
};

export default AboutUs;