import React, { useState } from 'react';
import Modal from 'react-modal';

const ExcelModal = ({ isVisible, onClose, onFileUpload }) => {

  return (
    <Modal isOpen={isVisible} onRequestClose={onClose} contentLabel="Excel Import/Export Modal">
      <div className="WeekWorkoutStylesModalContainerExcel">
        <img 
          src={require('../assets/back_arrow_black.png')}
          alt="Back" 
          onClick={onClose}
          className="WeekWorkoutStylesModalbackArrowExcel"
        />
        <h2>Importer vos programmes Excel directement dans l'application !</h2>
        <div className="WeekWorkoutStylesModalDLContainerExcel">
          <a 
            href="https://firebasestorage.googleapis.com/v0/b/repp-bf58c.appspot.com/o/TemplateImportationREPPUnique.xlsx?alt=media&token=91f6d6d5-b9fa-437f-bcf0-42f9b515814c" 
            download
            className="downloadButton"
          >
            Télécharger le template
          </a>
        </div>
        <div className="WeekWorkoutStylesModalMessageImportContainerExcel">
          Après avoir rempli vos informations dans le template, vous pouvez importer votre document ci-dessous !
        </div>
        <div className="WeekWorkoutStylesModalIMContainerExcel">
          <input type="file" accept=".xlsx, .xls" onChange={onFileUpload} />
        </div>
      </div>
    </Modal>
  );
};

export default ExcelModal;
