import React, { useEffect, useState } from 'react';
import SideBarChat from '../components/SideBarChat.tsx';
import ChatChat from '../components/ChatChat.tsx';
import '../screens/ChatPageStyles.css';
import BackButton from '../components/BackButton.tsx';



const ChatPage = () => {

      // State to control the visibility of the sidebar
      const [isSidebarVisible, setIsSidebarVisible] = useState(window.innerWidth > 768);

      useEffect(() => {
        const handleResize = () => {
            setIsSidebarVisible(window.innerWidth > 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

      // Function to toggle the visibility of the sidebar
      const toggleSidebar = () => {
        if (window.innerWidth <= 768) {
          setIsSidebarVisible(prev => !prev);
      }
          console.log("Sidebar visibility:", !isSidebarVisible);
      };

    return (
      <div className='home'>
      <div className='ArrowBackMessageContainer' >
      <BackButton to="/home" arrowColor='black' hoverBackgroundColor="F7F8FA"/>
      </div>
       <div className={`containerc ${isSidebarVisible ? 'visible' : 'hidden'}`}>
                <SideBarChat toggleSidebar={toggleSidebar} />
                <ChatChat toggleSidebar={toggleSidebar} />
            </div>
      </div>
    );
  };
  
  export default ChatPage;
