import React, { useEffect, useState } from 'react';
import '../../components/EditProfileModalStyles.css';

const DemoOneTimePaymentEditModal = ({ isVisible, onCancel, onSave, initialData, isStripeProcessing }) => {
    // Initial states for benefits, assuming a max of 4 benefits for simplicity
    const [benefit1, setBenefit1] = useState(initialData.oneTimeBenefit1 || '');
    const [benefit2, setBenefit2] = useState(initialData.oneTimeBenefit2 || '');
    const [benefit3, setBenefit3] = useState(initialData.oneTimeBenefit3 || '');
    const [benefit4, setBenefit4] = useState(initialData.oneTimeBenefit4 || '');
    const [paymentFee, setPaymentFee] = useState(initialData.paymentFee || '');
    const [buttonColor, setButtonColor] = useState(initialData.buttonColor || '#005B96'); // Default color

    useEffect(() => {
        setButtonColor(initialData.buttonColor || '#005B96');
    }, [initialData.buttonColor]); // Depend on initialData.buttonColor to trigger this effect
    
    if (!isVisible) return null;

    const handleSave = async () => {

            const validatedPaymentFee = paymentFee ? parseFloat(paymentFee) : 0; // Use 0 or another default value as appropriate

            const updatedData = {
                paymentFee: validatedPaymentFee,
                oneTimeBenefit1: benefit1,
                oneTimeBenefit2: benefit2,
                oneTimeBenefit3: benefit3,
                oneTimeBenefit4: benefit4,
            };

            try {
                console.log("Simulated save of one-time payment data:", updatedData);
                onSave(updatedData);
            } catch (error) {
                console.error("Error updating One time payment data: ", error);
            }
    };

    const modalClass = `EditProfileModal ${isVisible ? 'visible' : ''}`;

    return (
        <div className={modalClass}>
            <div className='EditProfileModalContentContainer'>
                <div className="EditProfileModalHeader">
                    <button onClick={onCancel}>Annuler</button>
                    <h1>Paiement unique</h1>
                    <button style={{ backgroundColor: buttonColor }} className='BlueButton EditProfileModalAddWidth' onClick={handleSave}>
                        <span className='EditProfileButtonText'>
                            {isStripeProcessing ? (
                                <div className='spinnerContainer' style={{ display: 'inline-block' }}>
                                    <div className="spinnerWaitStripe"></div>
                                </div>
                            ) : (
                                <img className="EditProfileSaveIcon" src={require('../../assets/Sauvegarder_icon.png')} alt="Save" />
                            )}
                        </span>
                    </button>
                </div>
                <div className="EditProfileModalContent">
                    {[benefit1, benefit2, benefit3, benefit4].map((benefit, index) => (
                        <div key={index} className="EditProfileModalInputContainer">
                            <label htmlFor={`benefit-${index}`}>Avantage {index + 1}</label>
                            <input
                                className="input"
                                id={`benefit-${index}`}
                                type="text"
                                value={benefit}
                                onChange={(e) => {
                                    // Update the corresponding benefit based on the index
                                    const setters = [setBenefit1, setBenefit2, setBenefit3, setBenefit4];
                                    const setBenefit = setters[index];
                                    setBenefit(e.target.value);
                                }}
                            />
                        </div>
                    ))}
                    <div className="EditProfileModalInputContainer">
                        <label htmlFor="coachingFee">Prix paiement unique</label>
                        <div style={{ position: 'relative' }}>
                            <input
                                className="input"
                                id="coachingFee"
                                type="number"
                                value={paymentFee}
                                onChange={(e) => setPaymentFee(e.target.value)}
                            />
                            <span style={{
                                position: 'absolute',
                                right: '10px',
                                top: '-11px',
                                bottom: '0',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '0 5px',
                            }}>€</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DemoOneTimePaymentEditModal;