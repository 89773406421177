import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import './PaymentLoginScreenStyles.css';
import '../commonStyles.css';
import '../CategoryPageStyles.css';
import { auth, firestore } from '../../firebase.js';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail, deleteUser } from "firebase/auth";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from 'firebase/functions';
import { signInWithPopup, GoogleAuthProvider, getAdditionalUserInfo } from "firebase/auth";

function PaymentLoginScreen() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [Name, setName] = useState('');
  const [showSignUp, setShowSignUp] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [coachingFee, setCoachingFee] = useState('');
  const [paymentFee, setPaymentFee] = useState('');
  const history = useHistory();

  const allFieldsFilled = email && password;
  const allSignUpFieldsFilled = Name && email && password; // Assuming gender is a required field for sign-up
  const [isStripeProcessing, setIsStripeProcessing] = useState(false);

  interface ConnectAccountResponse {
    accountId: string;
  }

  // Define the structure of the data sent to the cloud function
  interface VerifyPaymentRequest {
    email: string;
  }

  // Define the structure of the response data from the cloud function
  interface VerifyPaymentResponse {
    paymentExists: boolean;
  }

  // Get the Firebase Functions instance
  const functions = getFunctions();

  // Create a reference to the verifyPaymentForEmail cloud function
  const verifyPayment = httpsCallable<VerifyPaymentRequest, VerifyPaymentResponse>(functions, 'verifyPaymentForEmail');

  // Reference your callable functions
  const createStripeConnectAccount = httpsCallable(functions, 'createStripeConnectAccount');

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      // Using getAdditionalUserInfo to retrieve additional user info
      const additionalUserInfo = getAdditionalUserInfo(result);
      const isNewUser = additionalUserInfo?.isNewUser;
      const email = result.user.email; // This line is added to get the email

      if (isNewUser) {
        // Handle new user sign-up
        await handleSSOSignUp(result.user, email);
        setShowSignUp(false);
        console.log('New user signed up through Google SSO');
      } else {
        if (email) { // Check if email is not null or undefined
          const resultPayment = await verifyPayment({ email: email.toLowerCase() });

          if (!resultPayment.data.paymentExists) {
            alert("Please make a payment to access the premium features.");
            setIsStripeProcessing(false);
            return;
          }

          const userDocRef = doc(firestore, "users", result.user.uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists() && userDoc.data().role) {
            // User has a role, proceed to home
            console.log('Existing user with a role signed in through Google SSO');
            history.push('/PaymentProfile');
          } else {
            // No role found, show role selection or appropriate action
            console.log('Existing user without a role, showing category page');
          }
        }
      }
     } catch (error) {
       console.error('Error signing in with Google:', error);
     }
  };

  // Function to handle new user sign-up via SSO (e.g., Google Sign-In)
  async function handleSSOSignUp(user, emailUser) {
    setIsStripeProcessing(true);

    try {
      const result = await verifyPayment({ email: emailUser.toLowerCase() });

      if (!result.data.paymentExists) {
        alert("Please make a payment to access the premium features.");
        await deleteUser(user);
        setIsStripeProcessing(false);
        return;
      }

      const lowercaseName = user.displayName ? user.displayName.toLowerCase() : emailUser.split('@')[0].toLowerCase();
      const role = 'coach';
      const userProfile = {
        uid: user.uid,
        Name: lowercaseName,
        role: role,
        stripeAccountSetupComplete: false,
        title: "Nouveau coach sur la plateforme",
        description: "Coach impliqué et à l'écoute",
        showSubscriptionBlock: true,
        showOneTimePaymentBlock: true, // Assuming default is false, adjust as necessary
      };
      await setDoc(doc(firestore, "users", user.uid), userProfile);
      const defaultBenefits = [
        "Programme personnalisé chaque semaine",
        "Échanges directs avec le coach",
        "Suivi des progrès avec des indicateurs clés",
        "Conseils et analyses techniques détaillés"
      ];
      const defaultBenefitOneTimePayment = [
        "Une séance en présentiel",
        "Entraînement spécifique",
        "Une analyse morphologique",
        "Conseils et analyse pour la suite"
      ];
      const membershipData = {
        benefit1: defaultBenefits[0],
        benefit2: defaultBenefits[1],
        benefit3: defaultBenefits[2],
        benefit4: defaultBenefits[3],
      };
      const oneTimePaymentData = {
        oneTimeBenefit1: defaultBenefitOneTimePayment[0],
        oneTimeBenefit2: defaultBenefitOneTimePayment[1],
        oneTimeBenefit3: defaultBenefitOneTimePayment[2],
        oneTimeBenefit4: defaultBenefitOneTimePayment[3],
      };
      const oneTimePaymentDocRef = doc(firestore, "users", user.uid, "onetimepayment", 'oneTimePaymentInfo');
      await setDoc(oneTimePaymentDocRef, oneTimePaymentData);
      const newMembershipDocRef = doc(firestore, "users", user.uid, "membership", 'membershipInfo');
      await setDoc(newMembershipDocRef, membershipData);
      history.push('/PaymentProfile');
      setIsStripeProcessing(false);
    }
    catch (error) {
      console.error('Error during the signup process:', error);
      // handleFirebaseError is assumed to be defined elsewhere
      handleFirebaseError(error);
      setIsStripeProcessing(false); // Start loading
    }
  }

  async function onLoginPress() {
    try {
      if (!allFieldsFilled) {
        alert('Email and password fields cannot be empty');
        return;
      }
      const result = await verifyPayment({ email: email.toLowerCase() });

      if (!result.data.paymentExists) {
        alert("Please make a payment to access the premium features.");
        setIsStripeProcessing(false);
        return;
      }

      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      console.log('Logged in successfully with user:', userCredential.user);

      history.push('/PaymentProfile');

    } catch (error) {
      alert('Invalid email or password. Please try again.');
      console.error(error);
    }
  }

  async function onForgotPasswordPress() {
    try {
      await sendPasswordResetEmail(auth, email);
      alert('A link to reset your password has been sent to your email.');
      setShowForgotPassword(false); // Hide the reset form after sending the email
    } catch (error) {
      alert('An error occurred while trying to send reset password email.');
      console.error(error);
    }
  }

  function toggleSignUp() {
    setShowSignUp(!showSignUp);
    setShowForgotPassword(false);
  }

  async function handleSignUp() {
    setIsStripeProcessing(true); // Start loading
    if (!allSignUpFieldsFilled) {
      alert('All fields are required');
      return;
    }

    try {
      const result = await verifyPayment({ email: email.toLowerCase() });

      if (!result.data.paymentExists) {
        alert("Please make a payment to access the premium features.");
        setIsStripeProcessing(false);
        return;
      }

      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      const lowercaseName = Name.toLowerCase();

      let coachingFeeTotal;
      if (coachingFee) {
        const serviceFee = parseFloat(coachingFee) * 0.02;
        coachingFeeTotal = parseFloat(coachingFee) + serviceFee;
        coachingFeeTotal = Math.round(coachingFeeTotal * 100) / 100;
      }

      const role = 'coach';

      const userProfile = {
        uid: user.uid,
        Name: lowercaseName,
        role: role,
        stripeAccountSetupComplete: false,
        title: "Nouveau coach sur la plateforme",
        description: "Coach impliqué et à l'écoute",
        showSubscriptionBlock: true,
        showOneTimePaymentBlock: true,
      };

      await setDoc(doc(firestore, "users", user.uid), userProfile);

      // Initialize membership data object to later populate with Stripe and coaching fee information
      const defaultBenefits = [
        "Programme personnalisé chaque semaine",
        "Échanges directs avec le coach",
        "Suivi des progrès avec des indicateurs clés",
        "Conseils et analyses techniques détaillés"
      ];

      const defaultBenefitOneTimePayment = [
        "Une séance en présentiel",
        "Entraînement spécifique",
        "Une analyse morphologique",
        "Conseils et analyse pour la suite"
      ];

      // Call Firebase Function to create Stripe Connect account
      const connectAccountResult = await createStripeConnectAccount({ email: user.email, role: role }); // Using the correctly defined email and role.
      const connectAccountData = connectAccountResult.data as ConnectAccountResponse; // Cast to the correct type
      const accountId = connectAccountData.accountId;

      // Membership data setup
      const membershipData = {
        coachingFee: coachingFee ? parseFloat(coachingFee) : null, // Use null if coachingFee is undefined
        benefit1: defaultBenefits[0],
        benefit2: defaultBenefits[1],
        benefit3: defaultBenefits[2],
        benefit4: defaultBenefits[3],
      };

      // Prepare oneTimePayment data with similar structure
      const oneTimePaymentData = {
        paymentFee: paymentFee, // Assuming this is the payment fee for one-time payment
        oneTimeBenefit1: defaultBenefitOneTimePayment[0],
        oneTimeBenefit2: defaultBenefitOneTimePayment[1],
        oneTimeBenefit3: defaultBenefitOneTimePayment[2],
        oneTimeBenefit4: defaultBenefitOneTimePayment[3],
      };

      // Save oneTimePayment data
      const oneTimePaymentDocRef = doc(firestore, "users", user.uid, "onetimepayment", 'oneTimePaymentInfo');
      await setDoc(oneTimePaymentDocRef, oneTimePaymentData);

      const newMembershipDocRef = doc(firestore, "users", user.uid, "membership", 'membershipInfo');
      await setDoc(newMembershipDocRef, membershipData);

      history.push('/PaymentProfile');
    } catch (error) {
      console.error('Error during the signup process:', error);
      // handleFirebaseError is assumed to be defined elsewhere
      handleFirebaseError(error);
      setIsStripeProcessing(false); // Start loading
    }
  }

  function handleFirebaseError(error) {
    let errorMessage = error.message || 'Something went wrong. Please try again.';
    if (error.code) {
      switch (error.code) {
        case 'auth/email-already-in-use':
          errorMessage = 'The email address is already in use.';
          break;
        case 'auth/invalid-email':
          errorMessage = 'The email address is invalid.';
          break;
        case 'auth/weak-password':
          errorMessage = 'The password is too weak.';
          break;
        case 'auth/too-many-requests':
          errorMessage = 'Too many attempts. Please try again later.';
          break;
        default:
          break;
      }
    }
    alert(errorMessage);
  }

  function toggleForgotPassword() {
    setShowForgotPassword(!showForgotPassword);
    setShowSignUp(false); // Make sure to close sign up if opening forgot password
  }

  return (
    <div>
      <div className={showSignUp ? 'fullPageContainerMobileSignUp' : 'fullPageContainer'}>
        <div className={showSignUp ? 'PaymentLoginSignUpContainer' : 'PaymentLogincontainer'}>
          <div className="logoContainer">
            <img src={require('../../assets/repp_logo_black.png')} className="PaymentLogo" alt="Logo" />
          </div>

          {!showSignUp && !showForgotPassword && (
            // Login Form
            <>
              <div className='PaymentTitleAndDescriptionContainer'>
                <h1 className="title">Connexion à votre profil.</h1>
                <p className="LoginScreenDescription">Vendez partout, en un clic.</p>
              </div>
              {!showSignUp && !showForgotPassword && (
                <div className='LoginStylesSSOContainer'>
                  <button onClick={signInWithGoogle} className="LoginStylesButtonSignInGoogle">
                    <span className=''>Se connecter avec Google</span>
                    <img className="ProgramCreationStylesConfigureIcon" src={require('../../assets/Google_logo.png')} alt="Add" />
                  </button>
                  <div className='LoginStylesOrMessageLine'>
                    <span className='LoginStylesGoogleOrTitle'>Ou</span>
                  </div>
                </div>
              )}
              <div className="fieldContainer">
                <label className="PaymentLabel">Email</label>
                <input
                  className="input"
                  type="email"
                  value={email}
                  placeholder="Entrez votre email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="fieldContainer">
                <label className="PaymentLabel">Mot de passe</label>
                <div className="passwordInputContainer">
                  <input
                    className="input"
                    type="password"
                    placeholder="Entrez votre mot de passe"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {password.length === 0 && (
                    <button
                      onClick={toggleForgotPassword}
                      className="forgotPasswordButton"
                    >
                      Oublié?
                    </button>
                  )}
                </div>
              </div>
              <div className='commonStylesButtonContainer'>
                <button
                  className={`buttontitle ${!allFieldsFilled ? 'buttonDisabled' : ''}`}
                  onClick={onLoginPress}
                  disabled={!allFieldsFilled}
                >
                  <div className='ProgramCreationStylesConfigureButtonContainer'>
                    <span className={`buttonText ${!allFieldsFilled ? 'buttonTextDisabled' : ''}`}>Connexion</span>
                    <img className="ProgramCreationStylesConfigureIcon" src={require('../../assets/login_icon.png')} alt="Add" />
                  </div>
                </button>
              </div>
            </>
          )}
          {showSignUp && (
            // Sign-up Form
            <>
              <div className='PaymentTitleAndDescriptionContainer'>
                <h1 className="title">Créez votre profil.</h1>
                <p className="LoginScreenDescription">Vendez partout, en un clic.</p>
              </div>
              <div className='LoginStylesSSOContainer'>
                <button onClick={signInWithGoogle} className="LoginStylesButtonSignInGoogle">
                  <span className=''>S'inscrire avec Google</span>
                  <img className="LoginStylesGoogleIcon" src={require('../../assets/Google_logo.png')} alt="Add" />
                </button>
                <div className='LoginStylesOrMessageLine'>
                  <span className='LoginStylesGoogleOrTitle'>Ou</span>
                </div>
              </div>
              <div className="fieldContainer">
                <label className="PaymentLabel">Nom</label>
                <input
                  className="input"
                  type="text"
                  placeholder="Entrer votre pseudo"
                  value={Name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              <div className="fieldContainer">
                <label className="PaymentLabel">Email</label>
                <input
                  className="input"
                  type="email"
                  placeholder="Entrez votre email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className="fieldContainer">
                <label className="PaymentLabel">Mot de passe</label>
                <input
                  className="input"
                  type="password"
                  placeholder="Entrez votre mot de passe"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              <div className='commonStylesButtonContainer'>
                <button
                  className={`buttontitle ${!allSignUpFieldsFilled || isStripeProcessing ? 'buttonDisabled' : ''}`}
                  onClick={handleSignUp}
                  disabled={!allSignUpFieldsFilled || isStripeProcessing}
                >
                  <div className='ProgramCreationStylesConfigureButtonContainer'>
                    {isStripeProcessing ? (
                      <span className={`buttonText buttonTextDisabled`}>
                        Un petit instant
                      </span>
                    ) : (
                      <span className={`buttonText ${!allSignUpFieldsFilled ? 'buttonTextDisabled' : ''}`}>
                        Suivant
                      </span>
                    )}
                    {isStripeProcessing ? (
                      <div className='spinnerContainer' style={{ display: 'inline-block', marginLeft: '10px' }}>
                        <div className="spinnerWaitStripe"></div>
                      </div>
                    ) : (
                      <img className="ProgramCreationStylesConfigureIcon" src={require('../../assets/goWorkout_Icon.png')} alt="Add" />
                    )}
                  </div>
                </button>
              </div>
            </>
          )}

          {showForgotPassword && (
            // Forgot Password Form
            <>
              <div className='PaymentTitleAndDescriptionContainer'>
                <h1 className="title">Réinitialiser le mot de passe.</h1>
                <p className="LoginScreenDescription">Un email de réinitialisation de mot de passe vous sera envoyé.</p>
              </div>
              <div className="fieldContainer">
                <label className="PaymentLabel">Email</label>
                <input
                  type="email"
                  className="input"
                  placeholder="Entrez votre email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className='commonStylesButtonContainer'>
                <button
                  className={`buttontitle ${!email ? 'buttonDisabled' : ''}`}
                  onClick={onForgotPasswordPress}
                  disabled={!email}
                >
                  <div className='ProgramCreationStylesConfigureButtonContainer'>
                    <span className={`buttonText ${!email ? 'buttonTextDisabled' : ''}`}>Envoyer le lien</span>
                    <img className="ProgramCreationStylesConfigureIcon" src={require('../../assets/send_icon.png')} alt="Add" />
                  </div>
                </button>
              </div>
            </>
          )}

          {!showSignUp && !showForgotPassword && (
            <button onClick={toggleSignUp} className="toggleSignUpButton">
              Pas encore de compte ? Créez-en un!
            </button>
          )}
          {showSignUp && (
            <button onClick={toggleSignUp} className="toggleSignUpButton">
              Vous avez déjà un compte ? Par ici!
            </button>
          )}
          {showForgotPassword && (
            <button onClick={toggleForgotPassword} className="toggleSignUpButton">
              ← Retour à la page de connexion
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default PaymentLoginScreen;