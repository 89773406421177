import React, { useContext, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import './Footer.css';
import { UserContext } from './UserContext.tsx';

type FooterProps = {
    setActiveIcon: React.Dispatch<React.SetStateAction<'personne' | 'calendrier' | 'stats' | 'maison' | 'invitation' | 'eleves' | 'parametres' | null>>;
    activeIcon: 'personne' | 'calendrier' | 'stats' | 'maison' | 'invitation' | 'eleves' | 'parametres' | null;
};

const Footer: React.FC<FooterProps> = ({ setActiveIcon, activeIcon }) => {
    const location = useLocation();
    const { userRole } = useContext(UserContext);

    const history = useHistory(); // Initialize history object

    // Functions to determine the link based on the user's role
    const getCalendarLink = () => '/ProgramCreation';
    const getStatsLink = () => '/statistiques';
    const getProfileLink = () => '/ProfilePage';
    const getHomeLink = () => '/home';
    const getInviteLink = () => userRole === 'coach' ? '/InviteStudents' : '/WeekWorkout';
    const getElevesLink = () => '/ManageSubscriptions';
    const getParametresLink = () => '/Ressources';

    useEffect(() => {
        if (location.pathname === getCalendarLink() || location.pathname === '/WeekWorkout' || location.pathname === '/Workout') {
            setActiveIcon('calendrier');
        } else if (location.pathname === getStatsLink()) {
            setActiveIcon('stats');
        } else if (location.pathname === getProfileLink()) {
            setActiveIcon('personne');
        } else if (location.pathname === getHomeLink()) {
            setActiveIcon('maison');
        } else if (location.pathname === getInviteLink()) {
            setActiveIcon('invitation');
        } else if (location.pathname === getElevesLink()) {
            setActiveIcon('eleves');
        } else if (location.pathname === getParametresLink()) {
            setActiveIcon('parametres');
        }
    }, [location, setActiveIcon]);

    const handleNavigation = (path: string, iconName: 'personne' | 'calendrier' | 'stats' | 'maison' | 'invitation' | 'eleves' | 'parametres') => {
        history.push(path); // Navigate to the specified path
        setActiveIcon(iconName); // Update the active icon state
    };

    return (
        <div className="Footer">
            <div className="FooterIconContainer" onClick={() => handleNavigation(getHomeLink(), 'maison')}>
                <div className={`NiceEffectContainer ${activeIcon === 'maison' ? 'active' : ''}`}>
                    <img
                        className="FooterIcon"
                        src={activeIcon === 'maison' ? require('../assets/HomeFilled2.png') : require('../assets/HomePlain2.png')}
                        alt="Home"
                    />
                    <span className={activeIcon === 'maison' ? 'FooterActiveText' : 'FooterInactiveText'}>
                        Accueil
                    </span>
                </div>
            </div>

            <div className="FooterIconContainer" onClick={() => handleNavigation(getCalendarLink(), 'calendrier')}>
                <div className={`NiceEffectContainer ${activeIcon === 'calendrier' ? 'active' : ''}`}>
                    <img
                        className="FooterIcon"
                        src={activeIcon === 'calendrier' ? require('../assets/CalendarFilled2.png') : require('../assets/CalendarPlain2.png')}
                        alt="Calendar"
                    />
                    <span className={activeIcon === 'calendrier' ? 'FooterActiveText' : 'FooterInactiveText'}>
                        Planning
                    </span>
                </div>
            </div>

            <div className="FooterIconContainer" onClick={() => handleNavigation(getStatsLink(), 'stats')}>
                <div className={`NiceEffectContainer ${activeIcon === 'stats' ? 'active' : ''}`}>
                    <img
                        className="FooterIcon"
                        src={activeIcon === 'stats' ? require('../assets/StatsFilled2.png') : require('../assets/StatsPlain2.png')}
                        alt="Statistiques"
                    />
                    <span className={activeIcon === 'stats' ? 'FooterActiveText' : 'FooterInactiveText'}>
                        Statistiques
                    </span>
                </div>
            </div>

            <div className="FooterIconContainer" onClick={() => handleNavigation(getProfileLink(), 'personne')}>
                <div className={`NiceEffectContainer ${activeIcon === 'personne' ? 'active' : ''}`}>
                    <img
                        className="FooterIcon"
                        src={activeIcon === 'personne' ? require('../assets/ProfileFilled2.png') : require('../assets/ProfilePlain2.png')}
                        alt="Profile"
                    />
                    <span className={activeIcon === 'personne' ? 'FooterActiveText' : 'FooterInactiveText'}>
                        Profil
                    </span>
                </div>
            </div>

            <div className="FooterIconContainer" onClick={() => handleNavigation(getInviteLink(), 'invitation')}>
                {userRole === 'coach' && (
                    <div className={`NiceEffectContainerInvite ${activeIcon === 'invitation' ? 'active' : ''}`}>
                        <span className={activeIcon === 'invitation' ? 'FooterActiveTextInvite' : 'FooterInactiveTextInvite'}>
                            Inviter vos élèves
                        </span>
                        <img
                            className="FooterIconInvite"
                            src={require('../assets/Invitewhite.png')}
                            alt="Invitation"
                        />
                    </div>
                )}
            </div>

            <div className="FooterIconContainer" onClick={() => handleNavigation(getElevesLink(), 'eleves')}>
                <div className={`NiceEffectContainer ${activeIcon === 'eleves' ? 'active' : ''}`}>
                    <img
                        className="FooterIconEqualSize"
                        src={
                            activeIcon === 'eleves' ?
                                (userRole === 'coach' ? require('../assets/ElevesFilled.png') : require('../assets/Subscription_filled.png')) :
                                (userRole === 'coach' ? require('../assets/ElevesPlain.png') : require('../assets/Subscription_plain.png'))
                        }
                        alt="Eleves"
                    />
                    <span className={activeIcon === 'eleves' ? 'FooterActiveText' : 'FooterInactiveText'}>
                        {userRole === 'coach' ? 'Mes élèves' : 'Abonnement'}
                    </span>
                </div>
            </div>

            <div className="FooterIconContainer" onClick={() => handleNavigation(getParametresLink(), 'parametres')}>
                <div className={`NiceEffectContainer ${activeIcon === 'parametres' ? 'active' : ''}`}>
                    <img
                        className="FooterIconEqualSize"
                        src={activeIcon === 'parametres' ? require('../assets/ParametresFilled.png') : require('../assets/ParametresPlain.png')}
                        alt="Parametres"
                    />
                    <span className={activeIcon === 'parametres' ? 'FooterActiveText' : 'FooterInactiveText'}>
                        Ressources
                    </span>
                </div>
            </div>
        </div>
    );
};

export default Footer;