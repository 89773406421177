import React from 'react';
import './ImageSelectionModal.css'; // Import the CSS file

const ImageSelectionModal = ({ visible, onEdit, onCancel }) => {
  if (!visible) {
    return null;
  }

  return (
    <div className="centeredView" onClick={onCancel}>
      <div className="modalView" onClick={(e) => e.stopPropagation()}>
        <p className="modalTitle">Voulez-vous modifier cette photo ?</p>
        <button className="modalButton" onClick={onEdit}>
          Modifier
        </button>
        <button className="modalButton" onClick={onCancel}>
          Annuler
        </button>
      </div>
    </div>
  );
};

export default ImageSelectionModal;
