import React, { useEffect, useState } from 'react';
import '../LandingPageStyles.css';
import { getAnalytics, logEvent } from 'firebase/analytics'; // Import Firebase Analytics functions
import PaymentNavigationLanding from './PaymentNavigationLanding.tsx';
import LandingPageHeader from './LandingPageHeader.tsx';

const AboutUsPayment = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
    const analytics = getAnalytics(); // Initialize Firebase Analytics
    const { navigateToAboutUsPayment, navigateToCheckout, openEmail, navigateToFAQPayment } = PaymentNavigationLanding();

    useEffect(() => {
        logEvent(analytics, 'payment_about_us_visit');
    }, []);

    return (
        <>
            <div className="LandingPageStylesMainContainerOtherPages">
               <LandingPageHeader />
                <div style={{ marginTop: '30px' }}>
                    <section className="LandingPageStylesFourthSectionContainer LandingPageStylesAddMaringBottom">
                        <img src={require('../../assets/Pres_About_Us.png')} alt="Exercise" className="LandingPageStylesImageCoach" />
                        <div className="LandingPageStylesTextContainer LandingPageStylesAddMarginLeft">
                            <span className='LandingPageStylesTitle'>Deux frères</span>
                            <span className='LandingPageStylesDescription'>Nous sommes deux frères, ingénieurs de formation avec une passion pour le Streetlifting. <br></br><br></br>Suivis depuis des années par des coachs, nous avons remarqué à quel point il était difficile pour eux de pouvoir être payés simplement et sans retard. <br></br><br></br>Nous visions à créer un outil simple qui réponde à leurs besoins, et aux besoins de tous les travailleurs indépendants. <br></br><br></br>C'est dans cet esprit que Repp a été développée : une solution pensée pour les auto-entrepreneurs, destinée à rendre la gestion de leur paiement plus simple et plus efficace.</span>
                        </div>
                    </section>
                </div>
            </div>
            <footer className="LandingPageStylesFooterContainer">
                <div className="LandingFooter-section">
                    <img src={require('../../assets/repp_logo_white.png')} alt="Logo" className="LandingPageStylesLogoFooter" />
                    <span style={{ marginTop: '20px' }}>© 2024 Repp Company.</span>
                </div>
                <div className="LandingFooter-section">
                    <h5>Entreprise</h5>
                    <button onClick={navigateToAboutUsPayment} className='LandingFooter-button'>À propos</button>
                    <button onClick={navigateToFAQPayment} className='LandingFooter-button'>FAQ</button>
                    <button onClick={openEmail} className='LandingFooter-button'>Nous contacter</button>
                </div>
                <div className="LandingFooter-section">
                    <h5>Plateforme</h5>
                    <button onClick={() => navigateToCheckout()} className='LandingFooter-button'>Créer un compte</button>
                </div>
            </footer>
        </>
    );
};

export default AboutUsPayment;