import React, { useState, useContext, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { firestore } from '../firebase.js';
import { UserContext } from '../components/UserContext.tsx';
import { useUserId } from '../types/utils.ts';
import { useSelectedUser } from '../components/SelectedUserContext.tsx';

interface Student {
  id: string;
  name: string;
}

export const StudentSelector = () => {
  const { userRole } = useContext(UserContext);
  const [students, setStudents] = useState<Student[]>([]);
  const userId = useUserId();
  const { selectedUserId, setSelectedUserId } = useSelectedUser();

  useEffect(() => {
    const fetchStudents = async () => {
      if (userId && userRole === 'coach') {
        const studentsCollectionRef = collection(firestore, 'users', userId, 'myStudents');
        const querySnapshot = await getDocs(studentsCollectionRef);
        const fetchedStudents = querySnapshot.docs.map(doc => ({
          id: doc.id,
          name: doc.data().Name, // Adjust the field name if necessary
        }));
        setStudents(fetchedStudents);
      }
    };

    if (userRole === 'coach') {
      fetchStudents();
    }
  }, [userId, userRole]);

  const handleSelectionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedUserId(event.target.value);
  };

  if (userRole !== 'coach') {
    return null;
  }

  return (
    <div className='SelectorContainerPCreation1'>
      <div className='SelectorContainerPCreation2'>
        <select onChange={handleSelectionChange} value={selectedUserId} className="ProgramCreationStylesSelect">
          <option value={userId || ''}>Mon Programme</option>
          {students.map(student => (
            <option key={student.id} value={student.id}>{student.name}</option>
          ))}
        </select>
      </div>
    </div>
  );
};
