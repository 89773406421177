import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom'; // Import useHistory
import Footer from '../components/Footer.tsx';
import './ParametersPageStyles.css';
import { UserContext } from '../components/UserContext.tsx';

const ParametersPage = () => {
    const { userRole } = useContext(UserContext);
    const [activeIcon, setActiveIcon] = useState<'personne' | 'calendrier' | 'stats' | 'maison' | 'invitation' | 'eleves' | 'parametres' | null>(null);
    const history = useHistory(); // Use the useHistory hook to get the history instance

    // Function to navigate to the FAQ page
    const goToFAQ = () => {
        history.push('/FAQ'); // Navigate to /FAQ when the function is called
    };

    const goToAffiliation = () => {
        history.push('/CalculAffiliation'); // Navigate to /FAQ when the function is called
    };

    const openEmail = () => {
        const email = 'contactinfo.repp@gmail.com';
        window.location.href = `mailto:${email}`;
    };

    return (
        <div className="SuprememainContainerStats">
            <div className="leftsideContainerStats">
                <div className="LogoIconContainerStats">
                    <img className="LogoStats" src={require('../assets/ReReReDesign.png')} alt="Logo" />
                </div>
                <Footer setActiveIcon={setActiveIcon} activeIcon={activeIcon} />
            </div>
            <div className="statistics-container">
                <div className='StatsScreenContentContainer'>
                    <div className='FirstLayerStatsScreenContentContainer'>
                        <div className='TitleStatsScreenContentContainer'>
                            Ressources
                        </div>
                    </div>
                    <div className='ParametersPageStylesContainer'>
                        <p className='ParametersPageStylesMainTitle'>Vous avez des questions ?</p>
                        <p className='ParametersStylesDescription'>
                            Nous avons les réponses ! Retrouvez toutes les réponses aux questions que vous vous posez dans notre FAQ
                            dédiée.
                        </p>
                        <p className='ParametersStylesDescription'>
                            Vous pouvez également nous contacter par email pour plus de détails.
                        </p>
                    </div>
                    <div className="ParametersPageStylesContainerButtons">
                        <button className="ProfilePageSearchButton ParametersPageButtonAddMargin" onClick={goToFAQ}>FAQ</button>
                        <button className="ProfilePageSearchButton" onClick={openEmail}>Envoyer un email</button>
                    </div>

                    {
                        userRole === 'coach' && (
                            <>
                                <div className='ParametersPageStylesContainer'>
                                    <p className='ParametersPageStylesMainTitle'>Vous souhaitez gagner un revenu passif ?</p>
                                    <p className='ParametersStylesDescription'>
                                        Le programme d'affiliation est fait pour vous !
                                    </p>
                                    <p className='ParametersStylesDescription'>
                                        Inviter des coachs à rejoindre la plateforme et gagner 5% de leurs revenus, À VIE.
                                    </p>
                                </div>
                                <div className="ParametersPageStylesContainerButtons">
                                    <div className='ParametersPageStylesContainerButtonAndImage'>
                                        <button className="BlueButton ParametersPageStylesAddWidth" onClick={goToAffiliation}>
                                            <div className='ParametersPageStylesContainerButtonAndImage'>
                                                <span>Calculez vos revenus</span>
                                                <img className="ParametersPageStylesEuroIcon" src={require('../assets/euro_icon.png')} alt="Add" />
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </>
                        )
                    }
                </div>
            </div>
        </div>
    );
}

export default ParametersPage;
