import React, { useState, useContext } from 'react';
import '../../components/EditProfileModalStyles.css';

const PaymentEditProfileModal = ({ isVisible, onCancel, onSave, initialData }) => {
  const [name, setName] = useState(initialData.Name);
  const [title, setTitle] = useState(initialData.title);
  const [description, setDescription] = useState(initialData.description);
  const [showSubscriptionBlock, setShowSubscriptionBlock] = useState(initialData.showSubscriptionBlock);
  const [showOneTimePaymentBlock, setShowOneTimePaymentBlock] = useState(initialData.showOneTimePaymentBlock);
  const [buttonColor, setButtonColor] = useState(initialData.buttonColor || '#005B96'); // Default color
  const modalClass = `EditProfileModal ${isVisible ? 'visible' : ''}`;

  const handleSave = () => {
    onSave({
      Name: name.toLowerCase(), // Convert name to lowercase before saving
      title: title, // Pass the updated title
      description: description, // Pass the updated description
      showSubscriptionBlock: showSubscriptionBlock, // Include both toggle states
      showOneTimePaymentBlock: showOneTimePaymentBlock,
      buttonColor: buttonColor, // Include the selected button color
    });
  };

  if (!isVisible) return null;

  return (
    <div className={modalClass}>
      <div className='EditProfileModalContentContainer'>
        <div className="EditProfileModalHeader">
          <button onClick={onCancel}>Annuler</button>
          <h1>Profil</h1>
          <button
            style={{ backgroundColor: buttonColor }}
            className='BlueButton EditProfileModalAddWidth'
            onClick={handleSave}
          >
            <span className='EditProfileButtonText'>
              <img className="EditProfileSaveIcon" src={require('../../assets/Sauvegarder_icon.png')} alt="Menu" />
            </span>
          </button>
        </div>
        <div className="EditProfileModalContent">
          <label htmlFor="name">Nom</label>
          <input className='input' id="name" type="text" value={name} onChange={(e) => setName(e.target.value)} />

          <label htmlFor="title">Titre</label>
          <input className='input' id="title" type="text" value={title} onChange={(e) => setTitle(e.target.value)} />

          <label htmlFor="description">Bio</label>
          <textarea className='input' id="description" value={description} onChange={(e) => setDescription(e.target.value)} />
        </div>
        <div className="EditProfileModalToggleContainer">
          <div className='EditProfileModalToggleLine PaymentEditProfileModalAddMarginBottom'>
            <label htmlFor="oneTimePaymentToggle">Afficher le bloc de paiement ponctuel</label>
            <input
              type="checkbox"
              id="oneTimePaymentToggle"
              className="EditProfileModalToggleSwitch"
              checked={showOneTimePaymentBlock}
              onChange={(e) => setShowOneTimePaymentBlock(e.target.checked)}
            />
          </div>
          <div className='EditProfileModalToggleLine PaymentEditProfileModalAddMarginBottom'>
            <label htmlFor="subscriptionToggle">Afficher le bloc d'abonnement</label>
            <input
              type="checkbox"
              id="subscriptionToggle"
              className="EditProfileModalToggleSwitch"
              checked={showSubscriptionBlock}
              onChange={(e) => setShowSubscriptionBlock(e.target.checked)}
            />
          </div>
          <div className='EditProfileModalToggleLine'>
            <label htmlFor="buttonColor">Couleur de votre plateforme:</label>
            <input
              type="color"
              id="buttonColor"
              value={buttonColor}
              onChange={(e) => setButtonColor(e.target.value)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentEditProfileModal;