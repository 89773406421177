import React, { useEffect, useRef } from 'react';
import lottie, { AnimationItem } from 'lottie-web';
import checkboxAnimation from '../assets/checkbox_animation.json';
import '../screens/StudentScreens/WorkoutStyles.css';

const LottieCheckbox = ({ isChecked }) => {
    const animationContainer = useRef<HTMLDivElement>(null);
    const animationInstance = useRef<AnimationItem | null>(null);

    useEffect(() => {
      if (animationContainer.current) {
          animationInstance.current = lottie.loadAnimation({
              container: animationContainer.current,
              renderer: 'svg',
              loop: false,
              autoplay: false, // Don't autoplay on mount
              animationData: checkboxAnimation,
          });
          animationInstance.current.setSpeed(5); // Adjust the speed as needed

      }
      return () => {
          if (animationInstance.current) {
              animationInstance.current.destroy();
              animationInstance.current = null;
          }
      };
  }, []);

  useEffect(() => {
    if (animationInstance.current) {
        const direction = isChecked ? 1 : -1;
        animationInstance.current.setDirection(direction);
        animationInstance.current.play();
    }
}, [isChecked]);

  return <div ref={animationContainer} className="WorkoutStylesCheckboxContainer" />;
};

export default LottieCheckbox;
