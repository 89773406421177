import React from 'react';

const DateSelectionModal = ({ isVisible, onClose, availableDates, onSelectDate, formatDate,  onPreviousWeek, onNextWeek, isCopyMode }) => {
  if (!isVisible) return null;

  const handleContainerClick = () => {
    onClose();
  };

  const handleContentClick = (e) => {
    e.stopPropagation(); // Prevents the click from bubbling up to the container
  };

  return (
    <div className="modalContainer" onClick={handleContainerClick}>
      <div className="modalContent" onClick={handleContentClick}>
      <div className='modalTitreContainer'>
          {isCopyMode && <button className='modalArrow' onClick={onPreviousWeek}>←</button>} {/* Arrow for previous week */}
          <span>Les jours disponibles</span>
          {isCopyMode && <button className='modalArrow' onClick={onNextWeek}>→</button>} {/* Arrow for next week */}
        </div>
        {availableDates.map((date, index) => {
          const formattedDate = formatDate(date);
          return (
            <button key={index} className="optionButton" onClick={() => onSelectDate(date)}>
              {`${formattedDate.dayName}, ${formattedDate.monthAndDay}`}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default DateSelectionModal;
