import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

type BackButtonProps = {
  to: string;
  selectedDate?: string;
  creatorId?: string;
  arrowColor?: 'black' | 'white'; // New prop for arrow color
  hoverBackgroundColor?: 'F7F8FA' | '252525'; // New prop for hover background color
  circleColor?: string; // New optional prop for circle color
};

const BackButton: React.FC<BackButtonProps> = ({ to, selectedDate, creatorId, arrowColor = 'white',  hoverBackgroundColor = 'f5f5f5', circleColor = 'transparent'}) => {
  const history = useHistory();
  const [isHovered, setIsHovered] = useState(false);

  const handleBack = () => {
    // Build state object conditionally based on provided props
    const state: { selectedDate?: string; creatorId?: string } = {};
    if (selectedDate) state.selectedDate = selectedDate;
    if (creatorId) state.creatorId = creatorId;

    // Navigate with or without state
    history.push({
      pathname: to,
      state: Object.keys(state).length > 0 ? state : undefined
    });
  };

  // Conditional image source based on arrowColor
  const arrowImage = arrowColor === 'black'
    ? require('../assets/back_arrow_black.png')
    : require('../assets/back_arrow_white.png');

    return (
      <div
        onClick={handleBack}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <div style={{
          width: '40px',
          height: '40px',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: isHovered ? `#${hoverBackgroundColor}` : 'transparent',
          border: `1px solid ${isHovered ? 'transparent' : circleColor}`, // Apply circle color as border
        }}>
          <img src={arrowImage} alt="Back" style={{ width: 20, height: 20 }} />
        </div>
      </div>
    );
};

export default BackButton;
