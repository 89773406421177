import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import './CalculatorStyles.css';

function Calculator() {
    const history = useHistory();
    const [numCoaches, setNumCoaches] = useState(10);
    const [studentsPerCoach, setStudentsPerCoach] = useState(7);
    const [pricePerStudent, setPricePerStudent] = useState(100); // Set an initial value that makes sense for your use case
  
    const calculateEarnings = () => {
      const totalStudents = numCoaches * studentsPerCoach;
      const totalEarnings = totalStudents * pricePerStudent * 0.05;
      return totalEarnings; // Returns the earnings rounded to 2 decimal places
    };

    const goToProgramCreation = () => {
        history.push('/');
    };
  
    return (
    <div className='MainContainerEverythingCalculator'>
        <div className='ContainerTopCalculator'>
            <label className='BigTitleCalculator'>Gagnez des revenus passifs À VIE.</label>
            <label className='SmallTitleCalculator'>Parrainer des coachs à rejoindre la plateforme et recevez 5% sur chacun de leurs abonnements, et ce pour toujours !</label>
            <label className='SmallTitleCalculator'>Recevez votre agent de manière hedbomadaire, directement sur votre compte bancaire, sans effort.</label>
        </div>
        <div className='MainContainerCalculator'>
            <div className='ContainerLeftCalculator'>
                <div className="calculator">
                    <span className='TitleCalculator'>Quelle est votre situation ?</span>
                    <div className="input-group">
                    <div className='ContainerInputCalculator'>
                        <label className='SmallDescriptionCalculator'>Nombre de coachs dans votre entourage</label>
                        <label className='NumberCalculator'>{numCoaches}</label>
                    </div>
                        <input className='slider' type="range" min="1" max="30" value={numCoaches} onChange={e => setNumCoaches(Number(e.target.value))} />
                    </div>
                    <div className="input-group">
                    <div className='ContainerInputCalculator'>
                        <label className='SmallDescriptionCalculator'>Nombre moyen d'élèves par coach</label>
                        <label className='NumberCalculator'>{studentsPerCoach}</label>
                    </div>
                    <input type="range" min="1" max="50" value={studentsPerCoach} onChange={e => setStudentsPerCoach(Number(e.target.value))} />
                    </div>
                    <div className="input-group">
                    <div className='ContainerInputCalculator'>
                        <label className='SmallDescriptionCalculator'>Prix moyen de l'abonnement</label>
                        <label className='NumberCalculator'>{pricePerStudent}</label>
                    </div>
                    <input type="range" min="50" max="500" step="10" value={pricePerStudent} onChange={e => setPricePerStudent(Number(e.target.value))} />
                    </div>
                </div>
            </div>
            <div className='ContainerRightCalculator'>
                <div className='ResultsContainerCalculator'>
                    <label className='ResultsBigCalculator'>🎉</label>
                    <label className='ResultsCalculator'>{calculateEarnings()}€</label>
                    <label className='ResultsSmallCalculator'>/mois</label>
                    <label className='ResultsSmallSmallCalculator'>Monétisez votre réseau et gagner des revenus passifs pour le restant de vos jours !</label>
                    <button 
                            className="buttontitle ButonCalculatorWidth" 
                            onClick={() => goToProgramCreation()}
                        >
                            <span className='buttonText'>C'est parti !</span>
                            <img className="ProgramCreationStylesConfigureIcon" src={require('../assets/goWorkout_Icon.png')} alt="Add"/>
                        </button>
                </div>
            </div>
        </div>
    </div>
    );
  }
  
  export default Calculator;
