import React, { useContext, useEffect, useState } from 'react'
import MessageChat from './MessageChat.tsx';
import { ChatContext } from './context/ChatContext.js';
import { onSnapshot, doc } from 'firebase/firestore';
import { firestore } from '../firebase.js';

const MessagesChat = () => {

  const[messages, setMessages] =useState<any[]>([]);
  const { data } = useContext (ChatContext);

  useEffect(() => {
    const unSub = onSnapshot (doc(firestore, "chats", data.chatId), (doc) => {
    doc.exists() && setMessages (doc.data().messages)

})
return ()=> {
unSub()
}
},[data.chatId])

    return (
      <div className='MessagesChat'>
        {messages.map((m)=>(

        <MessageChat message={m} key={m.id} />

        ))}
      </div>
    );
  };
  
  export default MessagesChat;