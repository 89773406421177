// src/components/LoadingAnimation.tsx
import React from 'react';
import '../App.css'

const LoadingAnimation = () => (
  <div className="App-header">
    <img src={require('../assets/repp_logo_black.png')} className="App-logo" alt="logo" />
  </div>
);

export default LoadingAnimation;
