import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { collection, getDocs, doc, getDoc, setDoc, } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { firestore } from '../../firebase.js';
import { useLocation } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';
import FooterPayment from '../../components/FooterPayment.tsx';
import CancelSubscriptionModal from '../../components/CancelSubscriptionModal.tsx';
import { UserContext } from '../../components/UserContext.tsx';
import '../ManageSubscriptionsStyles.css';

interface Coach {
  id: string;
  Name: string;
  profilePicUri?: string;
  subscriptionId: string;
  isCancelled?: boolean;
  cancellationDate?: Date | string | null; // Allow null as a valid type
  engagementPeriod?: string;
  // Add other properties of a coach here if needed
}

interface Student {
  id: string;
  Name: string;
  profilePicUri?: string;
}

const PaymentManageSubscriptions = () => {
  const history = useHistory();
  const location = useLocation();
  const coachName = location.state?.coachName; // Access coachName from the state
  const { userRole } = useContext(UserContext);
  const [coaches, setCoaches] = useState<Coach[]>([]);
  const [students, setStudents] = useState<Student[]>([]); // For coach's students
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const [activeIcon, setActiveIcon] = useState<'personne' | 'eleves' | null>(null);
  const [selectedCoachId, setSelectedCoachId] = useState<string | null>(null);
  const [selectedStudentId, setSelectedStudentId] = useState<string | null>(null);

  const auth = getAuth();
  const currentUser = auth.currentUser; // Define currentUser here

  const fetchData = async () => {
    if (!currentUser) {
      console.log('User not logged in');
      return;
    }
    setIsLoading(true);
    try {
      let studentsList: Student[] = [];
  
      if (userRole === 'coach') {
        // Updated reference to target 'myPaidStudents' instead of 'myStudents'
        const myPaidStudentsRef = collection(firestore, 'users', currentUser.uid, 'myPaidStudents');
  
        const studentsSnapshot = await getDocs(myPaidStudentsRef);
  
        studentsSnapshot.forEach((docSnapshot) => {
          const studentData = docSnapshot.data();
          studentsList.push({
            id: docSnapshot.id,
            Name: studentData.name || 'Unknown Name',
            profilePicUri: studentData.profilePicUri || ''
          });
        });
      }
  
      setStudents(studentsList);
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setIsLoading(false);
    }
  };  

  // Update the useEffect hook
  useEffect(() => {
    fetchData();
  }, [currentUser, userRole]);

  const handleResignClick = (studentId: string) => {
    setSelectedStudentId(studentId);
    setIsCancelModalVisible(true);
  };

  const handleConfirmResign = async () => {
    alert('Veuillez réessayer plus tard.');
  };

  return (
    <div className="SuprememainContainerProfile">
      <div className="leftsideContainerProfile">
        <div className="LogoIconContainerProfile">
          <img className="LogoProfile" src={require('../../assets/ReReReDesign.png')} alt="Logo" />
        </div>
        <FooterPayment setActiveIcon={setActiveIcon} activeIcon={activeIcon} />
      </div>
      <div className='ProfilePageContent'>
        <div className='ProfileScreenContentContainer'>
          <div className='FirstLayerManageSubscriptionsScreenContentContainer'>
            <div className='TitleProfileScreenContentContainer'>
              <span> {userRole === 'coach' ? 'Mes élèves' : 'Abonnement'}</span>
            </div>
          </div>
          {isLoading ? (
            <div className="ProfilePageLoadingContainer">
              <div className="spinner"></div>
            </div>
          ) : (
            <div className="MySubscriptionsContainer">
              {userRole === 'coach' && (
                <>
                  {students.length > 0 ? (
                    students.map((student) => (
                      <div>
                        <div key={student.id} className="MySubscriptionsCard">
                          <div className='MySubscriptionsCardPhotoName'>
                            <img
                              src={student.profilePicUri || require('../../assets/default_profile_picture.png')}
                              className="MySubscriptionsProfilePic"
                              alt="Student"
                            />
                            <span className="MySubscriptionsName">{student.Name}</span>
                          </div>
                          <button
                            className='MySubscriptionsCancelButton'
                            onClick={() => handleResignClick(student.id)}
                            disabled={isProcessing}
                          >
                            {isProcessing ? (
                              <div className="spinner ManageSubscriptionStylesSpinnerSmall"></div>
                            ) : (
                              "Résilier l'élève"
                            )}
                          </button>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className={userRole === 'coach' ? "MySubscriptionsCenteredContainer" : "MySubscriptionsCenteredContainerStudent"}>
                      <span className="MySubscriptionsNoSubscriptionsText">
                        Vous n'avez pas encore d'élèves.
                      </span>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
          <CancelSubscriptionModal
            visible={isCancelModalVisible}
            onConfirm={handleConfirmResign}
            onCancel={() => {
              setIsCancelModalVisible(false);
              setSelectedCoachId(null); // Reset the selectedCoachId
              setSelectedStudentId(null); // Reset the selectedStudentId
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentManageSubscriptions;