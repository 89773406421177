import React, { useState } from 'react';
import usePaymentNavigationLanding from './PaymentNavigationLanding.tsx'; // Adjust the import path as needed

const LandingPageHeader = () => {
    const { navigateToPres2, navigateToFAQPayment, navigateToAboutUsPayment, navigateToCheckout, openEmail, navigateToDemoCreation } = usePaymentNavigationLanding();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

    return (
        <header className="LandingPageStylesHeaderContainer">
            <div onClick={navigateToPres2}>
                <img src={require('../../assets/ReReReDesign.png')} alt="Logo" className="LandingPageStylesLogo" />
            </div>
            <img src={require('../../assets/hamburger_menu.png')} alt="Menu" className="HamburgerMenuIcon" onClick={toggleMenu} />
            <div className={`MenuOverlay ${isMenuOpen ? 'Active' : ''}`} onClick={toggleMenu}></div>
            <nav className={`LandingPageStylesNavigationContainer ${isMenuOpen ? 'Active' : ''}`}>
                <img src={require('../../assets/cross_modal.png')} alt="Close" className="CloseMenuIcon" onClick={toggleMenu} />
                <div
                    className='LandingPageStylesContainerMenuMobileTextButton'
                    onClick={navigateToFAQPayment}
                    style={{ cursor: 'pointer' }}
                >
                    <span className='LandingPageStylesButtonNavigation'>FAQ</span>
                    <img className='LandingPageStylesMenuMobileIcon' src={require('../../assets/next_icon_gris.png')} alt="Next" />
                </div>
                <div
                    className='LandingPageStylesContainerMenuMobileTextButton'
                    onClick={navigateToAboutUsPayment}
                    style={{ cursor: 'pointer' }}
                >
                    <span className='LandingPageStylesButtonNavigation'>Qui sommes-nous ?</span>
                    <img className='LandingPageStylesMenuMobileIcon' src={require('../../assets/next_icon_gris.png')} alt="Next" />
                </div>
                <div
                    className='LandingPageStylesContainerMenuMobileTextButton'
                    onClick={openEmail}
                    style={{ cursor: 'pointer' }}
                >
                    <span className='LandingPageStylesButtonNavigation'>Nous contacter</span>
                    <img className='LandingPageStylesMenuMobileIcon' src={require('../../assets/next_icon_gris.png')} alt="Next" />
                </div>
                <button onClick={() => navigateToDemoCreation()} className="VioletButton LandingPageStylesAddWidth LandingPageStylesAddMargingTop">Je démarre !</button>
                <div className="LandingPageFooterMessage">
                    <span>Fait avec ❤️ par l'équipe Repp</span>
                </div>
            </nav>
        </header>
    );
};

export default LandingPageHeader;