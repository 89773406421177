import React from 'react';
import './ConfirmationModalStyles.css';

const ConfirmationModal = ({ visible, onConfirm, onCancel }) => {
  if (!visible) {
    return null;
  }

  return (
    <div className="confirmationModalBackdrop" onClick={onCancel}>
      <div className="confirmationModalContainer" onClick={(e) => e.stopPropagation()}>
        <p className="confirmationModalTitle">Voulez-vous supprimer ce block?</p>
        <div className='confirmationModalButtonContainer'>
          <button className="confirmationModalButton confirmationModalButtonYes" onClick={onConfirm}>
            Oui
          </button>
          <button className="confirmationModalButton" onClick={onCancel}>
            Non
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
