import React, { useState } from 'react';
import '../../components/EditProfileModalStyles.css';
import { auth, firestore } from '../../firebase';
import { doc, updateDoc } from 'firebase/firestore';

const MembershipEditModal = ({ isVisible, onCancel, onSave, initialData, isStripeProcessing }) => {
    // Initial states for benefits, assuming a max of 4 benefits for simplicity
    const [benefit1, setBenefit1] = useState(initialData.benefit1 || '');
    const [benefit2, setBenefit2] = useState(initialData.benefit2 || '');
    const [benefit3, setBenefit3] = useState(initialData.benefit3 || '');
    const [benefit4, setBenefit4] = useState(initialData.benefit4 || '');
    const [coachingFee, setCoachingFee] = useState(initialData.coachingFee || '');
    const [engagementPeriod, setEngagementPeriod] = useState(initialData.engagementPeriod || '');
    const [buttonColor, setButtonColor] = useState(initialData.buttonColor || '#005B96'); // Default color

    const modalClass = `EditProfileModal ${isVisible ? 'visible' : ''}`;

    const handleSave = async () => {
        const user = auth.currentUser;
        if (user) {
            const membershipDocRef = doc(firestore, 'users', user.uid, 'membership', 'membershipInfo');

            const validatedCoachingFee = coachingFee ? parseFloat(coachingFee) : 0; // Use 0 or another default value as appropriate

            const updatedData = {
                coachingFee: validatedCoachingFee,
                engagementPeriod: engagementPeriod,
                benefit1: benefit1,
                benefit2: benefit2,
                benefit3: benefit3,
                benefit4: benefit4,
            };

            try {
                await updateDoc(membershipDocRef, updatedData);
                console.log("Membership data updated successfully.");
                onSave(updatedData);
            } catch (error) {
                console.error("Error updating Membership data: ", error);
            }
        } else {
            console.error("User not authenticated.");
        }
    };

    if (!isVisible) return null;

    return (
        <div className={modalClass}>
            <div className='EditProfileModalContentContainer'>
                <div className="EditProfileModalHeader">
                    <button onClick={onCancel}>Annuler</button>
                    <h1>Modifier l'abonnement</h1>
                    <button style={{ backgroundColor: buttonColor }} className='BlueButton EditProfileModalAddWidth' onClick={handleSave}>
                        <span className='EditProfileButtonText'>
                            {isStripeProcessing ? (
                                <div className='spinnerContainer' style={{ display: 'inline-block' }}>
                                    <div className="spinnerWaitStripe"></div>
                                </div>
                            ) : (
                                <img className="EditProfileSaveIcon" src={require('../../assets/Sauvegarder_icon.png')} alt="Save" />
                            )}
                        </span>
                    </button>
                </div>
                <div className="EditProfileModalContent">
                    {/* Dynamically render benefit inputs */}
                    {[benefit1, benefit2, benefit3, benefit4].map((benefit, index) => (
                        <div key={index} className="EditProfileModalInputContainer">
                            <label htmlFor={`benefit-${index}`}>Avantage {index + 1}</label>
                            <input
                                className="input"
                                id={`benefit-${index}`}
                                type="text"
                                value={benefit}
                                onChange={(e) => {
                                    // Update the corresponding benefit based on the index
                                    const setters = [setBenefit1, setBenefit2, setBenefit3, setBenefit4];
                                    const setBenefit = setters[index];
                                    setBenefit(e.target.value);
                                }}
                            />
                        </div>
                    ))}
                    <div className="EditProfileModalInputContainer">
                        <label htmlFor="coachingFee">Montant du coaching /mois</label>
                        <div style={{ position: 'relative' }}>
                            <input
                                className="input"
                                id="coachingFee"
                                type="number"
                                value={coachingFee}
                                onChange={(e) => setCoachingFee(e.target.value)}
                            />
                            <span style={{
                                position: 'absolute',
                                right: '10px',
                                top: '-11px',
                                bottom: '0',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '0 5px',
                            }}>€</span>
                        </div>
                    </div>
                    <div className='EditProfileModalPeriodContainer'>
                        <span className='EditProfileModalPeriod'>Période d'engagement :</span>
                        <select value={engagementPeriod} onChange={(e) => setEngagementPeriod(e.target.value)} className='EngagementPeriodSelector'>
                            <option value="">Aucun engagement</option>
                            <option value="2">2 mois</option>
                            <option value="3">3 mois</option>
                            <option value="4">4 mois</option>
                            <option value="5">5 mois</option>
                            <option value="6">6 mois</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MembershipEditModal;