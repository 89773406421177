// PopupModal.js
import React from 'react';
import './PopupAboStyles.css'; 

const PopupModal = ({ message, onClose }) => {
    return (
        <div className="popup-modal">
            <div className="popup-modal-content">
                <p>{message}</p>
                <button onClick={onClose}>OK</button>
            </div>
        </div>
    );
};

export default PopupModal;
