import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../components/UserContext.tsx';
import ProgramEditor from '../../components/ProgramEditor.tsx';
import { useUserId } from '../../types/utils.ts';
import { useSelectedUser } from '../../components/SelectedUserContext.tsx';
import Footer from '../../components/Footer.tsx';
import { StudentSelector } from '../../components/StudentSelector.tsx';

function ProgramCreation() {
  const { userRole } = useContext(UserContext);
  const userId = useUserId(); // This hook call must be at the top level of your component
  const { selectedUserId } = useSelectedUser();
  const [activeIcon, setActiveIcon] = useState<'personne' | 'calendrier' | 'stats' | 'maison' | 'invitation' | 'eleves' | 'parametres' | null>(null);

  return (
    <div className='SuprememainContainerPCreation'>
      <div className="leftsideContainerPCreation">
        <div className="LogoIconContainerHome">
          <img className="LogoPCreation" src={require('../../assets/ReReReDesign.png')} alt="Logo" />
        </div>
        <Footer setActiveIcon={setActiveIcon} activeIcon={activeIcon} />
      </div>
      <div className='ProgramCreationStylesFullPageContainer'>
        <div className='PCreationScreenContentContainer'>
          <div className='FirstLayerPCreationScreenContentContainer'>
            <div className='TitlePCreationScreenContentContainer'>
              Planning
            </div>
          </div>

          {userRole === 'coach' && <StudentSelector />}

          {userId && <ProgramEditor key={selectedUserId || userId} userIdProp={selectedUserId || userId} />}
        </div>
      </div>
    </div>
  );
}

export default ProgramCreation;
