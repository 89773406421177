import React, { useState, useEffect } from 'react';
import './ExerciseSelectionModalStyles.css';
import { firestore } from '../firebase';
import { collection, addDoc, getDocs, deleteDoc } from 'firebase/firestore';

interface Exercise {
  id: string;
  name: string;
}

const ExerciseSelectionModal = ({ visible, setVisible, onSelect, userId }) => {
    const [selectedExercises, setSelectedExercises] = useState<string[]>([]);
    const [newExercise, setNewExercise] = useState<string>('');
    const [exercises, setExercises] = useState<Exercise[]>([]); // Explicitly type the state variable
    const [isRemoveMode, setIsRemoveMode] = useState(false);

    useEffect(() => {
        if (userId) {
            const fetchExercises = async () => {
                const exercisesCollectionRef = collection(firestore, 'users', userId, 'exercises');
                const querySnapshot = await getDocs(exercisesCollectionRef);
                const fetchedExercises: Exercise[] = [];
                querySnapshot.forEach(doc => {
                    fetchedExercises.push({ id: doc.id, name: doc.data().name });
                });
                setExercises(fetchedExercises);
            };

            fetchExercises();
        }
    }, [userId]);    

    const handleAddExercise = async () => {
        if (newExercise.trim() && userId) {
            const exercisesCollectionRef = collection(firestore, 'users', userId, 'exercises');
            const docRef = await addDoc(exercisesCollectionRef, { name: newExercise });
            setExercises(prevExercises => [...prevExercises, { id: docRef.id, name: newExercise }]);
            setNewExercise('');
        }
    };

    const handleRemoveExercises = async () => {
        if (selectedExercises.length > 0 && userId) {
            const exercisesCollectionRef = collection(firestore, 'users', userId, 'exercises');
            
            // Assuming each exercise name is unique
            const querySnapshot = await getDocs(exercisesCollectionRef);
            querySnapshot.forEach(async (doc) => {
                if (selectedExercises.includes(doc.data().name)) {
                    // Delete the document
                    await deleteDoc(doc.ref);
                }
            });
    
            // Update the local state to reflect the changes
            setExercises(prevExercises => prevExercises.filter(exercise => !selectedExercises.includes(exercise.name)));
            setSelectedExercises([]);
            setIsRemoveMode(false);
        }
    };

    const handleSelect = (exerciseName: string) => {
        setSelectedExercises(prevSelection => {
            if (prevSelection.includes(exerciseName)) {
                return prevSelection.filter(item => item !== exerciseName);
            } else {
                return [...prevSelection, exerciseName];
            }
        });
    };

    const handleSave = () => {
        if (selectedExercises.length > 0) {
            onSelect(selectedExercises);
            setSelectedExercises([]);
        }
        setVisible(false);
    };

    const sortedExercises = [...exercises].sort((a, b) => a.name.localeCompare(b.name));

    if (!visible || !userId) {
        return null;
    }

    return (
        <div className="modalContainer" onClick={() => setVisible(false)}>
            <div className="modalContent" onClick={(e) => e.stopPropagation()}>
                <div className='ExerciseHeader'>
                {isRemoveMode ? (
                    <>
                    <button onClick={() => setIsRemoveMode(false)}>
                            <span>Annuler</span>
                        </button>
                        <button onClick={handleRemoveExercises} className="removeSaveButton">
                            <span className="saveButtonText">Supprimer</span>
                        </button>
                    </>
                ) : (
                    <>
                        <button onClick={() => setIsRemoveMode(true)}>
                            <span>Retirer</span>
                        </button>
                        <button onClick={handleSave} className="BlueButton ExerciseSelectionModalStylesAddWidth">
                            <span className="saveButtonText">Ajouter</span>
                        </button>
                    </>
                )}
                </div>
                <div className="exerciseListContainer"> {/* New div for scrolling */}
                    {sortedExercises.map((exercise) => (
                        <button
                            key={exercise.id}
                            className={`optionButton ${selectedExercises.includes(exercise.name) ? (isRemoveMode ? 'removeSelectedOption' : 'selectedOption') : ''}`}
                            onClick={() => handleSelect(exercise.name)}
                        >
                            <span className="optionText">{exercise.name}</span>
                        </button>
                    ))}
                </div>
                {!isRemoveMode && (
                    <div className="ModalAddExerciseContainer">
                        <input 
                            value={newExercise}
                            onChange={(e) => setNewExercise(e.target.value)}
                            className="ModalNewExerciseInput"
                            placeholder="Ajouter un exercice à la liste"
                        />
                        <button 
                            onClick={handleAddExercise}
                            disabled={!newExercise.trim()}
                            className="ModalAddExerciseButton"
                        >
                            Créer
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ExerciseSelectionModal;
