import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { collection, doc, deleteDoc, setDoc, query, where, getDocs } from 'firebase/firestore';
import { firestore, auth } from '../firebase';
import ConfirmationModal from './ConfirmationModal.tsx';
import './BlockConfigurationStyles.css';

const BlockConfiguration = ({ onConfigureBlock, modalWeek, userId }) => {
  const [startWeek, setStartWeek] = useState(moment().format('YYYY-MM-DD'));
  const [endWeek, setEndWeek] = useState('');
  const [isBlockSetupModalVisible, setIsBlockSetupModalVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [tempStartWeek, setTempStartWeek] = useState(startWeek); // Temporary variable for modal
  const [tempEndWeek, setTempEndWeek] = useState(endWeek); // Temporary variable for modal
  const [blockData, setBlockData] = useState({});
  const [isBlockFound, setIsBlockFound] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [expandedBlocks, setExpandedBlocks] = useState({});
  const weekArray = Array.from({ length: moment(endWeek).diff(startWeek, 'weeks') + 1 }, (_, i) => moment(startWeek).add(i, 'weeks'));

  // Initialize expandedBlocks state on component mount
  useEffect(() => {
    if (!isEditMode) {
      const initialExpandedState = {};
      weekArray.forEach((_, index) => {
        initialExpandedState[`week-${index + 1}`] = true; // All weeks expanded by default
      });
      setExpandedBlocks(initialExpandedState);
    }
  }, [weekArray.length]); // Dependencies on isEditMode and weekArray.length  

  // Toggle expanded state for a block
  const toggleBlockExpansion = (weekNumber) => {
    setExpandedBlocks(prevState => ({
      ...prevState,
      [weekNumber]: !prevState[weekNumber]
    }));
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  useEffect(() => {
    setStartWeek(modalWeek);
    setEndWeek(moment(modalWeek).add(3, 'weeks').format('YYYY-MM-DD'));
    setTempStartWeek(modalWeek); // Update temp variables as well
    setTempEndWeek(moment(modalWeek).add(3, 'weeks').format('YYYY-MM-DD'));
  }, [modalWeek]);

  const confirmDelete = async () => {
    await deleteBlock();
    setShowDeleteConfirmation(false); // Hide the modal after deletion
  };

  const goToNextStartWeek = () => {
    setTempStartWeek(prevDate => moment(prevDate).add(1, 'weeks').format('YYYY-MM-DD'));
  };

  const goToPreviousStartWeek = () => {
    setTempStartWeek(prevDate => moment(prevDate).subtract(1, 'weeks').format('YYYY-MM-DD'));
  };

  const goToNextEndWeek = () => {
    setTempEndWeek(prevDate => moment(prevDate).add(1, 'weeks').format('YYYY-MM-DD'));
  };

  const goToPreviousEndWeek = () => {
    setTempEndWeek(prevDate => moment(prevDate).subtract(1, 'weeks').format('YYYY-MM-DD'));
  };

  const formatWeekForDisplay = (weekDate) => {
    const formatDate = (date) => {
      const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short' };
      return new Intl.DateTimeFormat('fr-FR', options).format(date);
    };

    const startOfWeek = moment(weekDate).startOf('isoWeek').toDate();
    const endOfWeek = moment(weekDate).endOf('isoWeek').toDate();
    return `${formatDate(startOfWeek)} - ${formatDate(endOfWeek)}`;
  };

  const handleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  const handleBlockSetup = async () => {
    const isRangeValid = await validateDateRange(tempStartWeek, tempEndWeek);
    if (!isRangeValid) {
      // Set state to show error message in modal
      setErrorMsg("Ce block chevauche les semaines d'un block existant.");
      // Do not close the modal or save the block if the range is not valid
      return;
    }

    const adjustedStartWeek = moment(tempStartWeek).startOf('isoWeek').format('YYYY-MM-DD');
    const adjustedEndWeek = moment(tempEndWeek).endOf('isoWeek').format('YYYY-MM-DD');

    setStartWeek(adjustedStartWeek);
    setEndWeek(adjustedEndWeek);
    // Reset blockData to empty as this is a new block configuration
    setBlockData({});
    onConfigureBlock(adjustedStartWeek, adjustedEndWeek);
    setIsBlockSetupModalVisible(false);
    saveBlockDataToFirestore(adjustedStartWeek, adjustedEndWeek); // Pass adjusted week values directly
  };

  const handleContentClick = (e) => {
    e.stopPropagation();
  };

  const saveBlockDataToFirestore = async (startWeek, endWeek, exitEditMode = false) => {
    if (!userId) {
      console.log("No userId provided, aborting saveBlockDataToFirestore");
      return; // Ensure there's a user ID
    }    
    const blockId = `${startWeek}_to_${endWeek}`; // Use passed startWeek and endWeek for blockId
    const blockDocRef = doc(firestore, 'users', userId, 'blocks', blockId);
    
    try {
      await setDoc(blockDocRef, {
        startDate: startWeek,
        endDate: endWeek,
        blockData
      });
      if (exitEditMode) {
        setIsEditMode(false); // Disable edit mode only if exitEditMode is true
      }      
      fetchBlockData(); // Refresh the block data
    } catch (error) {
      console.error('Error saving block data to Firestore:', error);
    }
  };  

  const saveBlockDataOnly = async () => {
    if (!userId) {
      console.log("No userId provided, aborting saveBlockDataOnly");
      return; // Ensure there's a user ID
    }
  
    const blockId = `${startWeek}_to_${endWeek}`; // Identify the block by start and end weeks
    const blockDocRef = doc(firestore, 'users', userId, 'blocks', blockId);
  
    try {
      // Specifically update the blockData field within the document
      await setDoc(blockDocRef, { blockData }, { merge: true });
    } catch (error) {
      console.error('Error saving block data to Firestore:', error);
    }
  };  

  // Modify the onChange handler to set block data
  const handleBlockDataChange = (weekIndex, value) => {
    setBlockData({ ...blockData, [`week-${weekIndex}`]: value });
  };

  const deleteBlock = async () => {
    if (!userId) return;
  
    const blockId = `${startWeek}_to_${endWeek}`;
    const blockDocRef = doc(firestore, 'users', userId, 'blocks', blockId);
  
    try {
      await deleteDoc(blockDocRef);
      console.log('Block deleted successfully');
      setIsBlockFound(false); // Reset block found flag
      setIsEditMode(false); // Exit edit mode
    } catch (error) {
      console.error('Error deleting block from Firestore:', error);
    }
  };

  const fetchBlockData = async () => {
    setIsLoading(true); // Start loading
    if (!userId) {
      console.log('No userId provided, exiting fetchBlockData');
      return;
    }

    try {
      const blocksCollectionRef = collection(firestore, 'users', userId, 'blocks');
      const blocksQuery = query(blocksCollectionRef, where("endDate", ">=", modalWeek));  
      const querySnapshot = await getDocs(blocksQuery);
  
      if (querySnapshot.empty) {
        console.log('No documents found that match the query');
        setIsBlockFound(false); // Set to false when no documents are found
        setBlockData({}); // Reset block data if no blocks found
        return;
      }
  
      let foundBlock = false;
  
      for (const doc of querySnapshot.docs) {
        const block = doc.data();
        const adjustedStartDate = moment(block.startDate).startOf('isoWeek').format('YYYY-MM-DD'); // Start of the week
        const adjustedEndDate = moment(block.endDate).endOf('isoWeek').format('YYYY-MM-DD'); // End of the week

        if (adjustedStartDate <= modalWeek && adjustedEndDate >= modalWeek) {
          setStartWeek(adjustedStartDate);
          setEndWeek(adjustedEndDate);
          setBlockData(block.blockData || {});
          setIsBlockFound(true); // Set to true when a block is found
          foundBlock = true;
          break; // Break out of the loop after the first match
        }
      }
  
      if (!foundBlock) {
        setIsBlockFound(false);
        setBlockData({}); // Reset block data if no active blocks found
      }
    } catch (error) {
      console.error('Error fetching block data from Firestore:', error);
    }
    finally {
      setIsLoading(false); // Stop loading after data is fetched or in case of an error 
    }
  };

  const validateDateRange = async (start, end) => {
    if (moment(start).isAfter(moment(end))) {
      console.error('Start date is after end date.');
      return false;
    }
    try {
      const blocksCollectionRef = collection(firestore, 'users', userId, 'blocks');
      const querySnapshot = await getDocs(blocksCollectionRef);
  
      for (const doc of querySnapshot.docs) {
        const block = doc.data();
        if (moment(start).isSameOrBefore(moment(block.endDate)) &&
            moment(end).isSameOrAfter(moment(block.startDate))) {
          // Overlap detected
          return false;
        }
      }
      // No overlap found
      return true;
    } catch (error) {
      console.error('Error validating date range:', error);
      return false;
    }
  };
  
  useEffect(() => {
    fetchBlockData();
  }, [modalWeek, userId]);

  useEffect(() => {
    if (tempStartWeek !== startWeek || tempEndWeek !== endWeek) {
      setErrorMsg('');
    }
  }, [tempStartWeek, tempEndWeek, startWeek, endWeek]);
  
  return (
    <div className="blockConfigurationStylesContainer">
      {isLoading ? (
        <div className="blockConfigurationStylesLoadingContainer">
          <div className="blockConfigurationSpinner"></div>
        </div>
      ) : (
        <>
      <div className='blockConfigurationStylesTitleAndEditContainer'>
            <span className='blockConfigurationStylesTitle'>Block</span>
            {isBlockFound && (
              <button onClick={handleEditMode}>
                <img className="blockConfigurationStylesEditIcon" src={require('../assets/edit_icon.png')} alt="Edit"/>
              </button>
            )}
      </div>
      {!isBlockFound ? (
        <button className='BlueButton' style={{width: 200}} onClick={() => setIsBlockSetupModalVisible(true)}>Créer un block</button>
      ) : (
        <>
          {weekArray.map((week, index) => (
            <div key={index} className="weekColumn" onClick={() => toggleBlockExpansion(`week-${index + 1}`)}>
              <div className='clicktoExpandOrCollapse'>
              <label className='expandOrCollapse'>{`Semaine ${index + 1}`}</label>
              </div>
              {expandedBlocks[`week-${index + 1}`] && (
              <div onClick={stopPropagation} className='BlockConfigurationStylesContainerNote'>
                <textarea
                  className={isEditMode ? "WeekWorkoutStylesNotes" : "WeekWorkoutStylesNotesDisplayOnly"}
                  placeholder={`Semaine ${index + 1}`}
                  rows={4}
                  disabled={!isEditMode}
                  value={blockData[`week-${index + 1}`] || ''}
                  onChange={(e) => handleBlockDataChange(index + 1, e.target.value)}
                  onBlur={saveBlockDataOnly}
                />
              </div>
              )}
            </div>
          ))}
          {isEditMode && (
            <div className='BlockConfigurationStylesSaveDeleteContainer'>
              <button className='BlueButton' onClick={() => saveBlockDataToFirestore(startWeek, endWeek, true)}>Sauvegarder</button>
              <button className='toggleSignUpButton' onClick={() => setShowDeleteConfirmation(true)}>Supprimer ce block</button>
            </div>
          )}
          <ConfirmationModal
            visible={showDeleteConfirmation}
            onConfirm={confirmDelete}
            onCancel={() => setShowDeleteConfirmation(false)}
          />
        </>
      )}
      {isBlockSetupModalVisible && (
        <div className="modalContainer" onClick={() => setIsBlockSetupModalVisible(false)}>
          <div className="modalContent" onClick={handleContentClick}>
            <div className='modalTitleContainer'>
              <span className='modalTitreContainer' style={{fontSize: '20px'}}>Paramétrer le block</span>
            </div>
            <div style={{fontSize: '16px', marginBottom: '15px'}}>
              Nombre de semaines: {moment(tempEndWeek).diff(moment(tempStartWeek), 'weeks') + 1}
            </div>
            <div className='modalStartAndEndWeekContainer'>
              <div className='modalContainerStartWeek'>
                <span className='modalSousTitreContainer'>Début du block</span>
                <div className='modalArrowsAndDateContainer'>
                  <button className='modalArrow' onClick={goToPreviousStartWeek}>←</button>
                  <span className='modalDateFixedWidthContainer'>{formatWeekForDisplay(tempStartWeek)}</span>
                  <button className='modalArrow' onClick={goToNextStartWeek}>→</button>
                </div>
              </div>
              <div className='modalContainerStartWeek'>
                <span className='modalSousTitreContainer'>Fin du block</span>
                <div className='modalArrowsAndDateContainer'>
                  <button className='modalArrow' onClick={goToPreviousEndWeek}>←</button>
                  <span className='modalDateFixedWidthContainer'>{endWeek ? formatWeekForDisplay(tempEndWeek) : 'Select End Week'}</span>
                  <button className='modalArrow' onClick={goToNextEndWeek}>→</button>
                </div>
              </div>
            </div>
            <button className="BlueButton" onClick={handleBlockSetup}>Configurer le block</button>
            {errorMsg && <span className='blockConfigurationStylesErrorMessage'>{errorMsg}</span>}
          </div>
        </div>
      )}
      </>
      )}
    </div>
  );  
};

export default BlockConfiguration;
