import React, { useEffect, useRef } from 'react';
import { auth } from '../firebase.js';
import { useHistory } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import './ParametresModalStyles.css';

const ParametresModal = ({ visible, setVisible, coachName }) => {
    const history = useHistory();
    const modalRef = useRef<HTMLDivElement>(null);

    const navigateToManageSubscriptions = () => {
        history.push({
            pathname: '/ManageSubscriptions',
            state: { coachName } // Pass coachName in the state
          });        
        setVisible(false); // Close the modal
    };

    const navigateToResources = () => {
        history.push({
            pathname: '/Ressources',
          });        
        setVisible(false); // Close the modal
    };

    const openEmail = () => {
        const email = 'contactinfo.repp@gmail.com';
        window.location.href = `mailto:${email}`;
    };

    const handleLogOutAndCloseModal = async () => {
        try {
            await signOut(auth);
            setVisible(false); // Close the modal after logging out
            history.push('/');
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
                setVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [setVisible]);

    useEffect(() => {
        document.body.style.overflow = visible ? 'hidden' : 'auto';
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [visible]);

    if (!visible) return null;

    return (
        <div className="modal">
            <div className="safeArea" ref={modalRef}>
                <button onClick={navigateToManageSubscriptions} className="optionContainer desktopHidden">
                    <span>Gérer mes abonnements</span>
                </button>
                <button onClick={navigateToResources} className="optionContainer desktopHidden">
                    <span>Ressources</span>
                </button>
                <button onClick={openEmail} className="optionContainer">
                    <span>Contactez-nous</span>
                </button>
                <button onClick={handleLogOutAndCloseModal} className="lastOptionContainer">
                    <span>Déconnexion</span>
                </button>
            </div>
        </div>
    );
};

export default ParametresModal;
