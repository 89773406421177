import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import '../ProfileCreationStyles.css';
import { auth } from '../../firebase.js';
import { createUserWithEmailAndPassword } from "firebase/auth";
import { firestore } from '../../firebase.js';
import { doc, setDoc, addDoc, collection } from "firebase/firestore";
import BackButton from '../../components/BackButton.tsx';
import { onAuthStateChanged } from 'firebase/auth';


function ProfileCreation() {
  const [Name, setName] = useState('');
  const [gender, setGender] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();

  const allFieldsFilled = Name && gender && email && password;

  async function onFormSubmit() {
    try {
      if (!allFieldsFilled) {
        alert('All fields are required');
        return;
      }
  
      // Create user account
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

  
      // Store additional profile information in Firestore
      await setDoc(doc(firestore, "users", user.uid), {
        uid:user.uid,
        Name,
        gender,
        needsPRSetup: true,
      });
      await setDoc(doc(firestore, "userChats", user.uid),{} );
   

      // Initialize default exercises in the subcollection
      const exercisesCollectionRef = collection(firestore, 'users', user.uid, 'exercises');
      const defaultExercises = ['Muscle Up', 'Pull Up/Chin Up', 'Dip', 'Squat'];
      defaultExercises.forEach(async (exerciseName) => {
        await addDoc(exercisesCollectionRef, { name: exerciseName });
      });
      history.push('/categ'); // Redirect to the next route
    } catch (error) {
      console.error('Error in account creation:', error);
      handleFirebaseError(error); // Function to handle Firebase-specific errors
    }
  }
  
  function handleFirebaseError(error) {
    let errorMessage = error.message || 'Something went wrong. Please try again.';

  
    if (error.code) {
      switch (error.code) {
        case 'auth/email-already-in-use':
          errorMessage = 'The email address is already in use.';
          break;
        case 'auth/invalid-email':
          errorMessage = 'The email address is invalid.';
          break;
        case 'auth/weak-password':
          errorMessage = 'The password is too weak.';
          break;
        case 'auth/too-many-requests':
          errorMessage = 'Too many attempts. Please try again later.';
          break;
        default:
          break;
      }
    }
  
    alert(errorMessage);
  }

  return (
    <div>
    <BackButton to="/welcome" arrowColor='black' />
    <div className='fullPageContainer'>
      <div className="container">
        <div className="ProfileCreationLogoContainer">
          <img src={require('../../assets/R.png')} alt="ProfileCreationLogo" className="ProfileCreationLogo" />
        </div>

        <h1 className="title">Créer votre profil</h1>
        <p className="description">Le premier pas vers vos objectifs</p>

        <div className="fieldContainer">
          <label className="label">Nom</label>
          <input
            className="input"
            type="text"
            placeholder="Entrer votre pseudo"
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        <div className="fieldContainer">
          <label className="label">Genre</label>
          <div className="ProfileCreationGenderOptionContainer">
            <button className={`ProfileCreationGenderOption ${gender === 'Homme' ? 'ProfileCreationGenderOptionSelected' : ''}`} onClick={() => setGender('Homme')}>
              Homme
            </button>
            <button className={`ProfileCreationGenderOption ${gender === 'Femme' ? 'ProfileCreationGenderOptionSelected' : ''}`} onClick={() => setGender('Femme')}>
              Femme
            </button>
          </div>
        </div>

        <div className="fieldContainer">
          <label className="label">Email</label>
          <input
            className="input"
            type="email"
            placeholder="Entrez votre email"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div className="fieldContainer">
          <label className="label">Mot de passe</label>
          <input
            className="input"
            type="password"
            placeholder="Entrez votre mot de passe"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <div style={{ marginTop: 10, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <button
          className={`buttontitle ${!allFieldsFilled ? 'buttonDisabled' : ''}`}
          onClick={onFormSubmit}
          disabled={!allFieldsFilled}
        >
          <span className={allFieldsFilled ? 'buttonText' : 'buttonTextDisabled'}>
            Suivant
          </span>
        </button>
        </div>
      </div>
    </div>
  </div>
  );
}

export default ProfileCreation;
