import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { collection, getDocs } from 'firebase/firestore';
import { firestore } from '../firebase';
import { UserContext } from '../components/UserContext.tsx';
import { useUserId } from '../types/utils.ts';

interface Student {
  id: string;
  name: string;
}

const WeekCopyModal = ({ isVisible, onClose, modalWeek, onPreviousWeek, onNextWeek, onSelectUserId, onCopyWeek, DefaultSelectedUserId }) => {

  const [selectedUserId, setSelectedUserId] = useState(DefaultSelectedUserId);
  const [students, setStudents] = useState<Student[]>([]); // Specify the type of students array
  const { userRole } = useContext(UserContext);
  const userId = useUserId();

  // Suppose you fetch the students in this modal itself, or pass them as props if already available in the parent component
  useEffect(() => {
    const fetchStudents = async () => {
      if (userId && userRole === 'coach') {
        const studentsCollectionRef = collection(firestore, 'users', userId, 'myStudents');
        const querySnapshot = await getDocs(studentsCollectionRef);
        const fetchedStudents = querySnapshot.docs.map(doc => ({
          id: doc.id,
          name: doc.data().Name, // Make sure the field name matches your Firestore structure
        }));
        setStudents(fetchedStudents);
      }
    };

    if (userRole === 'coach') {
      fetchStudents();
    }
  }, [userId, userRole]); // Re-fetch when userId or userRole changes

  if (!isVisible) return null;

  const handleContainerClick = onClose;

  const handleContentClick = (e) => {
    e.stopPropagation();
  };

  const handleSelectionChange = (event) => {
    const newSelectedUserId = event.target.value;
    setSelectedUserId(newSelectedUserId);
    onSelectUserId(newSelectedUserId); // Notify the parent component
  };

  const formatWeekForDisplay = (weekDate) => {
    const formatDate = (date) => {
      const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short' };
      return new Intl.DateTimeFormat('fr-FR', options).format(date);
    };

    const startOfWeek = moment(weekDate).startOf('isoWeek').toDate(); // Convert Moment to Date
    const endOfWeek = moment(weekDate).endOf('isoWeek').toDate(); // Convert Moment to Date
    return `${formatDate(startOfWeek)} - ${formatDate(endOfWeek)}`;
  };

  return (
    <div className="modalContainer" onClick={handleContainerClick}>
      <div className="modalContent" onClick={handleContentClick}>
        {userRole === 'coach' && (
          <div>
            <div className='WeekCopyModalTitreContainer'>
              <span className='modalWeekCopyChoose'>Sélectionner l'élève de destination</span>
            </div>
            <select onChange={handleSelectionChange} value={selectedUserId} className="ProgramCreationStylesSelect" style={{ marginBottom: '10px' }}>
              {students.map(student => (
                <option key={student.id} value={student.id}>{student.name}</option>
              ))}
            </select>
          </div>
        )}
        <div className='WeekCopyModalTitreContainer'>
          <button className='modalArrow' onClick={onPreviousWeek}>←</button>
          <span className='modalWeekCopyChoose'>Les semaines disponibles</span>
          <button className='modalArrow' onClick={onNextWeek}>→</button>
        </div>
        <button className="optionButton" onClick={() => onCopyWeek(modalWeek, selectedUserId)}>Coller sur la semaine du {formatWeekForDisplay(modalWeek)}</button>
      </div>
    </div>
  );
};

export default WeekCopyModal;
