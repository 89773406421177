import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import './ProfilePageStyles.css';
import Footer from '../components/Footer.tsx';
import ImageSelectionModal from '../components/ImageSelectionModal.tsx';
import ParametresModal from '../components/ParametresModal.tsx';
import { firestore, auth, storage } from '../firebase.js';
import { collection, getDocs, doc, getDoc, updateDoc, serverTimestamp, setDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import EditProfileModal from '../components/EditProfileModal.tsx';
import '../normalize.css';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { UserContext } from '../components/UserContext.tsx';

function ProfilePage() {

  const history = useHistory();
  const [activeIcon, setActiveIcon] = useState<'personne' | 'calendrier' | 'stats' | 'maison' | 'invitation' | 'eleves' | 'parametres' | null>(null);
  const [bannerUri, setBannerUri] = useState('');
  const [profilePicUri, setProfilePicUri] = useState('');
  const [Name, setName] = useState('');
  const [isImageModalVisible, setImageModalVisible] = useState(false);
  const [selectedImageType, setSelectedImageType] = useState<'profile' | 'banner' | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isParametresModalVisible, setParametresModalVisible] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [engagementPeriod, setEngagementPeriod] = useState('');
  const [subscriptionButton, setSubscriptionButton] = useState(false);
  const [coachingFee, setCoachingFee] = useState('');
  const [questionnaireUrl, setQuestionnaireUrl] = useState('');
  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const [stripeAccountSetupComplete, setStripeAccountSetupComplete] = useState(false);
  const [stripeAccountLink, setStripeAccountLink] = useState('');
  const functions = getFunctions();
  const [initialProfileData, setInitialProfileData] = useState({});
  const { userRole } = useContext(UserContext); // Use the context
  const createStripeConnectAccount = httpsCallable(functions, 'createStripeConnectAccount');
  const [triggerFetch, setTriggerFetch] = useState(0); // Using a counter as an example

  function capitalizeName(name) {
    if (!name) return ""; // Return an empty string if name is falsy
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  }

  interface CreatePriceRequest {
    amount: number;  // The amount in cents
    coachName: string;
    userId: string;
  }

  interface CreatePriceResponse {
    priceId: string;
  }

  interface ConnectAccountResponse {
    accountId: string;
  }

  useEffect(() => {
    if (isEditModalVisible) {
      // Disable scrolling on body
      document.body.style.overflow = 'hidden';
    } else {
      // Re-enable scrolling on body
      document.body.style.overflow = 'auto';
    }
  
    // Cleanup function to ensure scrolling is re-enabled when the component is unmounted or modal visibility changes
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isEditModalVisible]);

  useEffect(() => {
    let isActive = true;

    interface StripeAccountLinkResponse {
      url: string;
    }

    const fetchStripeAccountLink = async (accountId) => {
      if (!accountId) return;
      try {
        const generateLink = httpsCallable<{}, StripeAccountLinkResponse>(functions, 'generateStripeAccountLink');
        const response = await generateLink({ accountId: accountId });
        setStripeAccountLink(response.data.url);
      } catch (error) {
        console.error('Error fetching Stripe account link:', error);
      }
    };

    const fetchData = async () => {
      const user = auth.currentUser;
      if (user && isActive) {
        try {
          // Fetch main user document for the name
          const userDocRef = doc(firestore, 'users', user.uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            setName(userDoc.data().Name || '');
            setStripeAccountSetupComplete(userDoc.data().stripeAccountSetupComplete || false);

            // Check if the coach has a Stripe account linked
            if (userRole === 'coach' && !userDoc.data().coachStripeAccountId) {
              console.log("en cours de création stripe link")
              // Call the function to create a Stripe Connect account
              const connectAccountResult = await createStripeConnectAccount({ email: user.email, role: 'coach' });
              const connectAccountData = connectAccountResult.data as ConnectAccountResponse;
              const accountId = connectAccountData.accountId;

              // Update Firestore with the new accountId
              await updateDoc(userDocRef, { coachStripeAccountId: accountId, stripeAccountSetupComplete: false });

              // Fetch the Stripe account link
              await fetchStripeAccountLink(accountId);
              window.location.reload();
            } else if (!userDoc.data().stripeAccountSetupComplete) {
              await fetchStripeAccountLink(userDoc.data().coachStripeAccountId);
            }
          }

          // Reference to the profileInfo subcollection
          const profileInfoRef = collection(firestore, 'users', user.uid, 'profileInfo');
          const querySnapshot = await getDocs(profileInfoRef);

          querySnapshot.forEach((doc) => {
            if (doc.exists()) {
              const data = doc.data();
              // Set the state with fetched data
              setTitle(data.title || '');
              setDescription(data.description || '');
              setProfilePicUri(data.profilePicUri || '');
              setBannerUri(data.bannerUri || '');
              setSubscriptionButton(data.subscription_button || false);
              setCoachingFee(data.coachingFee || '');
              setQuestionnaireUrl(data.questionnaireUrl || ''); // Set the questionnaire URL
              setEngagementPeriod(data.engagementPeriod || '');
            }
          });

          // Set initial profile data after fetching and setting all state variables
          setInitialProfileData({
            Name: userDoc.data()?.Name ?? '',
            title: querySnapshot.docs[0].data().title || '',
            description: querySnapshot.docs[0].data().description || '',
            subscription_button: querySnapshot.docs[0].data().subscription_button || false,
            coachingFee: querySnapshot.docs[0].data().coachingFee || '',
            questionnaireUrl: querySnapshot.docs[0].data().questionnaireUrl || '',
            engagementPeriod: querySnapshot.docs[0].data().engagementPeriod || '', // Assuming it's part of the same document
          });

        } catch (error) {
          console.error("Error fetching data", error);
        } finally {
          if (isActive) {
            setIsLoading(false);
          }
        }
      }
    };

    fetchData();

    return () => {
      isActive = false; // Cleanup
    };
  }, [triggerFetch]);

  const handleEditImage = (type) => {
    setSelectedImageType(type);
    const fileInput = document.getElementById('fileInput');
    if (fileInput) {
      fileInput.click();
    } else {
      console.error('File input not found');
    }
  };

  const handleImageSelect = (event, type) => {
    const file = event.target.files[0];
    handleFileUpload(file, type);
  };

  const handleFileUpload = async (file, type) => {
    if (!file) {
      console.log('No file selected');
      return;
    }

    try {
      const uploadUri = type === 'profile' ? 'profile_pics/' : 'banners/';
      const filename = `${uploadUri}${new Date().getTime()}-${file.name}`;
      const storageRef = ref(storage, filename);

      await uploadBytes(storageRef, file);
      const url = await getDownloadURL(storageRef);

      const user = auth.currentUser;
      if (user) {
        // Reference to the profileInfo subcollection
        const profileInfoRef = collection(firestore, 'users', user.uid, 'profileInfo');
        const querySnapshot = await getDocs(profileInfoRef);
        let profileInfoDoc;

        // Assuming there is only one document in the profileInfo subcollection
        querySnapshot.forEach(doc => {
          profileInfoDoc = doc;
        });

        if (profileInfoDoc) {
          const updateData = type === 'profile' ? { profilePicUri: url } : { bannerUri: url };
          // Updating the document in the profileInfo subcollection
          await updateDoc(doc(firestore, 'users', user.uid, 'profileInfo', profileInfoDoc.id), updateData);
          type === 'profile' ? setProfilePicUri(url) : setBannerUri(url);
        }
      }
    } catch (error) {
      console.error('Error during file upload:', error);
    }
  };

  const handleCloseModal = () => {
    setParametresModalVisible(false);
  };

  const handleSaveProfile = async (updatedData, initialData) => {
    const user = auth.currentUser;

    if (user) {
      try {
        let updatePayload = {
          ...updatedData,
          profilePicUri, // Ensure current profile picture URI is preserved
          bannerUri, // Ensure current banner URI is preserved
        };

        // For 'eleve', override the updatePayload to include only specific fields along with the URIs
      if (userRole !== 'coach') {
        updatePayload = {
          Name: updatedData.Name,
          title: updatedData.title,
          description: updatedData.description,
          profilePicUri, // Still preserving the profile picture URI
          bannerUri, // Still preserving the banner URI
        };
      }

        // Update the name in the main user document
        const userDocRef = doc(firestore, 'users', user.uid);
        await updateDoc(userDocRef, {
          Name: updatedData.Name,
        });

        // Define the coachProfileInfo document reference
        const profileDocName = userRole === 'coach' ? 'coachProfileInfo' : 'studentProfileInfo';
        const profileInfoDocRef = doc(firestore, 'users', user.uid, 'profileInfo', profileDocName);
  
        if (userRole === 'coach') {
          if (updatedData.coachingFee !== initialData.coachingFee) {
            // Calculate the new total coaching fee
            const newCoachingFee = parseFloat(updatedData.coachingFee);
            const serviceFee = newCoachingFee * 0.02;
            let coachingFeeTotal = newCoachingFee + serviceFee;
            coachingFeeTotal = Math.round(coachingFeeTotal * 100) / 100;
  
            // Declare and use the createStripePrice function
            const createStripePrice = httpsCallable<CreatePriceRequest, CreatePriceResponse>(getFunctions(), 'createStripePrice');
            const priceResponse = await createStripePrice({
              amount: Math.round(coachingFeeTotal * 100), // Convert to cents
              coachName: updatedData.Name,
              userId: user.uid,
            });
  
            // Update Firestore with the new priceId and the coachingFeeTotal
            await setDoc(profileInfoDocRef, {
              ...updatedData, // Include other updated data
              coachingFee: newCoachingFee, // Ensure that coachingFee is stored as a number
              priceId: priceResponse.data.priceId,
              coachingFeeTotal: coachingFeeTotal,
            }, { merge: true });
          } else {
            // Update the document without changing the price
            updatedData.coachingFee = parseFloat(updatedData.coachingFee);
            console.log(updatedData.coachingFee)
  
            await setDoc(profileInfoDocRef, updatePayload, { merge: true });
          }
        } else { // For 'eleve', only save Name, title, and description
          await setDoc(profileInfoDocRef, updatePayload, { merge: true });
        }

        // Update local state to reflect changes
        // (Assuming you have state variables like setName, setTitle, etc.)
        setName(updatedData.Name);
        setTitle(updatedData.title);
        setDescription(updatedData.description);
        if (userRole === 'coach') {
          setSubscriptionButton(updatedData.subscription_button);
          setQuestionnaireUrl(updatedData.questionnaireUrl); // Update local state for coach only
        }

        // Close the modal after saving
        // (Assuming you have a state variable like setEditModalVisible for modal visibility)
        setEditModalVisible(false);
        setTriggerFetch(prev => prev + 1); // Increment the counter to trigger re-fetching

      } catch (error) {
        console.error('Error updating profile:', error);
      }
    } else {
      console.error('No user is signed in.');
    }
  };

  return (
    <>
      <input
        type="file"
        id="fileInput"
        style={{ display: 'none' }}
        onChange={(event) => handleImageSelect(event, selectedImageType)}
        accept="image/*"
      />
      {isParametresModalVisible && (
        <div className="ProfilePageOverlay" onClick={handleCloseModal}></div>
      )}

      <div className="SuprememainContainerProfile">
        <div className="leftsideContainerProfile">
          <div className="LogoIconContainerProfile">
            <img className="LogoProfile" src={require('../assets/ReReReDesign.png')} alt="Logo" />
          </div>
          <Footer setActiveIcon={setActiveIcon} activeIcon={activeIcon} />
        </div>
        <div className='ProfilePageContent'>
          <div className='ProfileScreenContentContainer'>
            <div className='FirstLayerProfileScreenContentContainer'>
              <div className='TitleProfileScreenContentContainer'>
                Profil
              </div>
            </div>
            {isLoading ? (
              <div className="ProfilePageLoadingContainer">
                <div className="spinner"></div>
              </div>
            ) : (
              <div>
                <button className='ProfilePageBannerButton' onClick={() => handleEditImage('banner')}>
                  <div className="ProfilePageBannerContainer">
                    <img
                      className="ProfilePageBannerImage"
                      src={bannerUri || require('../assets/default_banner.png')}
                      alt="Banner"
                    />
                  </div>
                </button>
                <div className="ProfilePageProfileImageContainer">
                  <button onClick={() => handleEditImage('profile')}>
                    <img
                      className="ProfilePageProfileImage"
                      src={profilePicUri || require('../assets/default_profile_picture.png')}
                      alt="Profile"
                    />
                  </button>
                </div>
                <ImageSelectionModal
                  visible={isImageModalVisible}
                  onEdit={handleEditImage}
                  onCancel={() => setImageModalVisible(false)}
                />
                <div className='ProfilePageContainerMenu'>
                  <button className='ProfilePageButtonHoverEffect' onClick={() => setParametresModalVisible(true)}>
                    <div className="ProfilePageMenuContainer">
                      <img className="ProfilePageMenu" src={require('../assets/menu.png')} alt="Menu" />
                    </div>
                  </button>
                </div>
                <div className="ProfilePageNameContainer">
                  <span className="ProfilePageName">{capitalizeName(Name)}</span>
                </div>
                <div>
                  <div className='ProfilePageInfoContainer'>
                    <span className='ProfilePageInfo'>{title}</span>
                    <span className='ProfilePageInfo'>{description}</span>
                  </div>
                </div>
                <>
                </>
                <div className="ProfilePageActionButtons">
                  <button className="ProfilePageSearchButton ProfilePageAddMarginRight" onClick={() => setEditModalVisible(true)}>Modifier votre profil</button>
                  <button className="ProfilePageSearchButtonShare ProfilePageAddMarginLeft">Partager votre profil</button>
                </div>
                {!stripeAccountSetupComplete && stripeAccountLink && (
                  <div className='ProfilePageStylesButtonContainer ProfilePagePaddingButton ProfilePageAddMarginStripeConfiguration'>
                    <a href={stripeAccountLink}>
                      <button className="buttontitle">
                        <div className='ProgramCreationStylesConfigureButtonContainer'>
                          <span className='buttonText'>Finaliser la configuration pour recevoir vos paiements</span>
                          <img className="ProgramCreationStylesConfigureIcon" src={require('../assets/goWorkout_Icon.png')} alt="Add" />
                        </div>
                      </button>
                    </a>
                  </div>
                )}
                {isEditModalVisible && (
                  <EditProfileModal
                    isVisible={isEditModalVisible}
                    onCancel={() => setEditModalVisible(false)}
                    onSave={(updatedData) => handleSaveProfile(updatedData, initialProfileData)}
                    initialData={{ Name, title, description, subscription_button: subscriptionButton, coachingFee, questionnaireUrl: questionnaireUrl, engagementPeriod: engagementPeriod }}
                  />
                )}
                <ParametresModal
                  visible={isParametresModalVisible}
                  setVisible={setParametresModalVisible}
                  coachName={Name} // Passing the coach's name as a prop
                />
              </div>
            )}
          </div>
        </div>
      </div>

    </>
  );
}
export default ProfilePage;