import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './firebase';
import { AuthContextProvider } from './components/context/AuthContext';
import { ChatContextProvider } from './components/context/ChatContext';
import { HelmetProvider } from 'react-helmet-async'; // Import HelmetProvider

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HelmetProvider> {/* Wrap the top level with HelmetProvider */}
    <AuthContextProvider>
      <ChatContextProvider>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </ChatContextProvider>
    </AuthContextProvider>
  </HelmetProvider>
);

// Register the service worker directly here
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/firebase-messaging-sw.js').then(registration => {
      console.log('ServiceWorker registration successful with scope: ', registration.scope);
    }, err => {
      console.log('ServiceWorker registration failed: ', err);
    });
  });
} else {
  console.log("Service workers are not supported.");
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
