import React, { useState,useContext, useCallback } from 'react';
import { firestore } from '../firebase.js';
import { collection, query, where, getDocs, doc, limit, updateDoc, setDoc, getDoc,serverTimestamp } from "firebase/firestore"
import { useHistory } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { AuthContext } from './context/AuthContext.js';

type User = {
  id: string;
  Name: string;
  role: string;
  profileInfo?: {
    title: string;
    description: string;
    profilePicUri: string;
    bannerUri: string
  };
};




const SearchBarChat = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [users, setUsers] = useState<User[]>([]);
  const history = useHistory(); // Use history for navigation
  const {currentUser} = useContext(AuthContext)

  const clearSearch = () => {
    setSearchTerm('');
    setUsers([]);
  };

  const searchUsers = async (searchQuery) => {
    const searchQueryNormalized = searchQuery.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

    // Check if the search query is empty and clear the users if it is
    if (!searchQueryNormalized.trim()) {
      setUsers([]); // Clear any existing results
    return; // Exit the function early
    }
    try {
      const usersRef = collection(firestore, 'users');
      const q = query(usersRef); // Fetch all users since we are filtering in the client
      const querySnapshot = await getDocs(q);
  
      const matchingUsers: User[] = [];
      for (let docSnapshot of querySnapshot.docs) {
        const docData = docSnapshot.data();
        if (docData) {
          const userData = { ...docData, id: docSnapshot.id } as Partial<User>;
          const userNameNormalized = userData.Name?.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
  
          // Check if normalized Name matches the normalized search query
          if (userNameNormalized && userNameNormalized.includes(searchQueryNormalized)) {
            // Fetch profileInfo subcollection
            const profileInfoRef = collection(firestore, 'users', docSnapshot.id, 'profileInfo');
            const profileInfoSnapshot = await getDocs(profileInfoRef);
  
            let profileInfo = { title: '', description: '', profilePicUri: '', bannerUri: '' }; // Default values for profileInfo
            profileInfoSnapshot.forEach(doc => {
              if (doc.exists()) {
                Object.assign(profileInfo, doc.data());
              }
            });
  
            matchingUsers.push({ ...userData, profileInfo } as User);
          }
        }
      }
  
      setUsers(matchingUsers);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((searchQuery) => {
      searchUsers(searchQuery);
    }, 300), // 300 milliseconds delay
    [] // Dependencies array
  );

  const handleSearchChange = (searchQuery: string) => {
    setSearchTerm(searchQuery);
    if (searchQuery.trim() === '') {
      // Clear the users array if the search query is empty
      setUsers([]);
      debouncedSearch.cancel();
    } else {
      // Use the debounced function for non-empty queries
      debouncedSearch(searchQuery);
    }
  };

  const handleUserSelect = async (selectedUser) => {
    //check whether the group (chats) in firestore) exists, if not create
    

  const combinedId = 
  currentUser.uid > selectedUser.id
    ? currentUser.uid + selectedUser.id 
    : selectedUser.id + currentUser.uid;  

    console.log("1:", selectedUser.id);
    console.log("2:", selectedUser.Name);
    console.log("3:", selectedUser.profileInfo?.profilePicUri);
    console.log("1:", currentUser.uid);
    console.log("2:", currentUser.Name);
    console.log("3:", currentUser.profilePicUri);
  
    
  try{

  const res = await getDoc (doc(firestore, "chats", combinedId));

  if (!res.exists()){
    //create a chat in chats collection
    await setDoc(doc(firestore, "chats", combinedId),{messages: [] })

  

    //create user chats
    await updateDoc(doc(firestore, "userChats", currentUser.uid),{
      [combinedId +".userInfo"]: {
        uid :selectedUser.id,
        Name : selectedUser.Name,
        profilePicUri: selectedUser.profileInfo?.profilePicUri
      },
      [combinedId +".date"] : serverTimestamp()
    });
    
    await updateDoc(doc(firestore, "userChats", selectedUser.id),{
      [combinedId +".userInfo"]: {
        uid :currentUser.uid,
        Name : currentUser.Name,
        profilePicUri: currentUser.profilePicUri
      },
      [combinedId +".date"] : serverTimestamp()
    });
    
  }

} catch (err){console.error("Error in handleUserSelect:", err);}

clearSearch();
};
    return (
      
      <div className = 'SearchBarChat'>
        <div className ="SearchForm">
        <input type ="text" placeholder='Chercher un utilisateur' value={searchTerm}
          onChange={(e) => handleSearchChange(e.target.value)}/>
        </div>
        <div className="ResultsContainer">
      {users.map((user) => (
        <div onClick={() => handleUserSelect(user)} className="Card" key={user.id}>
          <img
            src={user.profileInfo?.profilePicUri || require('../assets/default_profile_picture.png')}
            className="ProfilePic"
            alt="Profile"
          />
          <span className="Name">{user.Name}</span>
        </div>
      ))}
      </div>
    </div>
      
    );
  };
  
  export default SearchBarChat;