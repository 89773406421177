import React, { useEffect, useState, useContext, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { collection, getDocs, doc, getDoc, updateDoc, setDoc, deleteDoc } from 'firebase/firestore';
import moment from 'moment';
import { firestore, auth } from '../firebase';
import '../screens/CoachScreens/WeekWorkoutStyles.css';
import '../screens/StudentScreens/ProgramCreationStyles.css';
import { UserContext } from '../components/UserContext.tsx';
import { Exercise } from '../types/types.ts';
import ExerciseSelectionModal from '../components/ExerciseSelectionModal.tsx';
import ContextMenuModal from '../components/ContextMenuModal.tsx';
import DateSelectionModal from '../components/DateSelectionModal.tsx';
import WeekCopyModal from '../components/WeekCopyModal.tsx';
import BlockConfiguration from '../components/BlockConfiguration.tsx';
import ExcelModal from '../components/ExcelModal.tsx';
import * as XLSX from 'xlsx';
import DayContainer from '../components/DayContainer.tsx';
import { useUserId } from '../types/utils.ts';
import { useDate } from './DateContext.tsx';
import { useBlockConfig } from './BlockConfigContext.tsx'; // Adjust the import path as necessary

interface WeekWorkouts {
    [date: string]: Exercise[];
}
interface ExerciseName {
    id: string;
    name: string;
}
interface ExcelRow {
    Date: string;
    ExerciseName: string;
    SetNumber: number;
    Reps: string;
    Weight: string;
    RPE: string;
    Rest: string;
    NotesCoach: string;
    NotesEleve: string;

}
interface WeekWorkoutEditorProps {
    userIdProp?: string; // Make userIdProp optional
}

function formatDate(dateString: string) {
    if (typeof dateString !== 'string' || !dateString) {
        throw new Error("Invalid input: dateString is either not a string or empty");
    }

    const dateSeparator = dateString.includes('-') ? '-' : '/';
    const dateParts = dateString.split(dateSeparator);

    if (dateParts.length !== 3) {
        throw new Error("Date string does not have three parts");
    }

    try {
        const year = parseInt(dateParts[0], 10);
        const month = parseInt(dateParts[1], 10) - 1; // Months are 0-indexed in JavaScript Date
        const day = parseInt(dateParts[2], 10);

        if (isNaN(year) || isNaN(month) || isNaN(day)) {
            throw new Error("Error parsing date parts");
        }

        const dateObject = new Date(year, month, day);
        if (isNaN(dateObject.getTime())) {
            throw new Error("Invalid Date object created");
        }

        // Specify the exact string literals for weekday and month
        const optionsForDay: Intl.DateTimeFormatOptions = { weekday: 'long' as const };
        const dayName = new Intl.DateTimeFormat('fr-FR', optionsForDay).format(dateObject);

        const optionsForDate: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short' as const };
        const monthAndDay = new Intl.DateTimeFormat('fr-FR', optionsForDate).format(dateObject);

        return { dayName, monthAndDay };
    } catch (error) {
        return { dayName: "Date invalide", monthAndDay: "" };
    }
}

const WeekWorkoutEditor = ({ userIdProp }: WeekWorkoutEditorProps) => {
    const location = useLocation();
    const [weekWorkouts, setWeekWorkouts] = useState<WeekWorkouts>({});
    const { selectedDate, setSelectedDate } = useDate();
    const currentUserID = useUserId(); // Always call the hook at the top level
    const userId = userIdProp || currentUserID; // Determine the userId to use
    const { userRole } = useContext(UserContext);
    const [areButtonsVisible, setAreButtonsVisible] = useState(true);
    const [isEditMode, setIsEditMode] = useState(false);
    const [isExerciseModalVisible, setIsExerciseModalVisible] = useState(false);
    const [currentAddingDate, setCurrentAddingDate] = useState<string | null>(null);
    const [isContextMenuVisible, setIsContextMenuVisible] = useState(false);
    const [isDateSelectionModalVisible, setIsDateSelectionModalVisible] = useState(false);
    const [currentDayClicked, setCurrentDayClicked] = useState(null); // Store the day that was clicked
    const [isCopyModalVisible, setIsCopyModalVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(true); // Start with true if data is loaded asynchronously
    const areAllExercisesEmpty = Object.values(weekWorkouts).every(exercises => exercises.length === 0);
    const [modalWeek, setModalWeek] = useState(selectedDate); // Initialize with the same week as the main view
    const [isWeekCopyModalVisible, setIsWeekCopyModalVisible] = useState(false);
    const [allExercises, setAllExercises] = useState<ExerciseName[]>([]);
    const [videoUrls, setVideoUrls] = useState({});
    const { showBlockConfig, setShowBlockConfig } = useBlockConfig();
    const [blockStartWeek, setBlockStartWeek] = useState(''); // For BlockConfiguration
    const [blockEndWeek, setBlockEndWeek] = useState(''); // For BlockConfiguration
    const [expandedDates, setExpandedDates] = useState({});
    const [currentScrollPosition, setCurrentScrollPosition] = useState(0);
    const [isExcelModalVisible, setIsExcelModalVisible] = useState(false);
    const toggleExcelModal = () => {
        setIsExcelModalVisible(!isExcelModalVisible);
    };
    const [showImportSuccessMessage, setShowImportSuccessMessage] = useState(false);
    const [isNotMobile, setIsNotMobile] = useState(window.innerWidth > 950);
    const [selectedUserIdForCopy, setSelectedUserIdForCopy] = useState(userId);

    useEffect(() => {
        const handleResize = () => {
            setIsNotMobile(window.innerWidth > 950);
        };

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Clean up event listener
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        // Disable scrolling when the modal is visible
        if (isExerciseModalVisible) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        // Cleanup function
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isExerciseModalVisible]);

    useEffect(() => {
        if (!isEditMode) {
            const newExpandedDates = {};
            Object.keys(weekWorkouts).forEach(date => {
                newExpandedDates[date] = true; // Set each date to expanded by default
            });
            setExpandedDates(newExpandedDates);
        }
    }, [weekWorkouts, isEditMode]);

    useEffect(() => {
        if (userId) {
            const fetchExercises = async () => {
                const exercisesCollectionRef = collection(firestore, 'users', userId, 'exercises');
                const querySnapshot = await getDocs(exercisesCollectionRef);
                let fetchedExercises: ExerciseName[] = [];
                querySnapshot.forEach(doc => {
                    fetchedExercises.push({ id: doc.id, name: doc.data().name });
                });

                // Sort the fetched exercises alphabetically by name
                fetchedExercises = fetchedExercises.sort((a, b) => a.name.localeCompare(b.name));

                setAllExercises(fetchedExercises);
            };

            fetchExercises();
        }
    }, [userId]);

    function excelSerialDateToJSDate(serial) {
        const utc_days = Math.floor(serial - 25569);
        const utc_value = utc_days * 86400;
        const date_info = new Date(utc_value * 1000);

        const fractional_day = serial - Math.floor(serial) + 0.0000001;

        let total_seconds = Math.floor(86400 * fractional_day);

        const seconds = total_seconds % 60;
        total_seconds -= seconds;

        const hours = Math.floor(total_seconds / (60 * 60));
        const minutes = Math.floor(total_seconds / 60) % 60;

        return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
    }

    // Format JavaScript Date to YYYY-MM-DD
    function formatDateToYYYYMMDD(date) {
        const d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        return [year, month.padStart(2, '0'), day.padStart(2, '0')].join('-');
    }

    const handleFileUpload = async (e) => {
        if (!userId) {
            console.error("User ID is undefined. Cannot upload file.");
            return; // Or handle the lack of a userId more gracefully
        }

        // Local interfaces definition
        interface DetailedSet {
            setNumber: number;
            completed: string;
        }

        interface ExerciseSet {
            setNumber?: number;
            repos?: string;
            reps?: string;
            rpe?: string;
            weight?: string;
            completed: string;
            detailedSets: DetailedSet[];
        }

        interface Exercise {
            name: string;
            note?: string;
            coachNote?: string;
            sets: ExerciseSet[];
            id?: string;
        }

        interface ExercisesByDate {
            [key: string]: Exercise[];
        }
        const file = e.target.files[0];
        const data = await file.arrayBuffer();
        const workbook = XLSX.read(data, { type: 'buffer' });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json<ExcelRow>(worksheet);
        const exercisesByDate: ExercisesByDate = {};

        // Aggregate exercises by date
        jsonData.forEach((row) => {
            // Skip the row if the ExerciseName is missing or empty
            if (!row.ExerciseName) {
                return; // In forEach, use return to skip to the next iteration
            }
            // Convert Excel serial date to JavaScript Date object
            const jsDate = excelSerialDateToJSDate(row.Date);
            // Format the JavaScript Date object to YYYY-MM-DD string
            const workoutDate = formatDateToYYYYMMDD(jsDate);

            if (!exercisesByDate[workoutDate]) {
                exercisesByDate[workoutDate] = [];
            }

            // Append the exercise to its corresponding date array
            console.log(exercisesByDate[workoutDate]);
            exercisesByDate[workoutDate].push({
                name: row.ExerciseName,
                ...(row.NotesEleve ? { note: row.NotesEleve } : {}),
                ...(row.NotesCoach ? { coachNote: row.NotesCoach } : {}),
                sets: [{
                    ...(row.SetNumber ? { setNumber: row.SetNumber } : {}),
                    ...(row.Rest ? { repos: row.Rest } : {}),
                    ...(row.Reps ? { reps: row.Reps } : {}),
                    ...(row.RPE ? { rpe: row.RPE } : {}),
                    ...(row.Weight ? { weight: row.Weight } : {}),
                    completed: "0", // Convert to string to match your model
                    detailedSets: Array.from({ length: row.SetNumber }, (_, i) => ({
                        setNumber: i + 1,
                        completed: "0" // Assuming default to "0"
                    }))
                }]
            });
        });
        for (const [date, exercises] of Object.entries(exercisesByDate)) {

            const workoutDayRef = doc(firestore, 'users', userId, 'workoutDays', date);

            if (exercises.length === 0) {
                await deleteDoc(workoutDayRef); // Delete if no exercises
            } else {
                const modifiedExercises = exercises.map((exercise, index) => ({
                    ...exercise,
                    id: exercise.id || `exercise-${index}`, // Generate an ID if necessary
                    sets: exercise.sets.map(set => ({
                        ...set,
                        completed: set.completed.toString(),
                        detailedSets: set.detailedSets.map(dSet => ({
                            ...dSet,
                            completed: dSet.completed.toString()
                        }))
                    }))
                }));
                await setDoc(workoutDayRef, { exercises: modifiedExercises }, { merge: true });

            }
        }
        toggleExcelModal();
        setShowImportSuccessMessage(true);
        setTimeout(() => setShowImportSuccessMessage(false), 3000);
    };

    const handleOpenModal = () => {
        const scrollPosition = window.pageYOffset; // Capture scroll position
        setCurrentScrollPosition(scrollPosition); // Store it in state (if needed)
        setIsExerciseModalVisible(true); // Open the modal
    };

    const closeModal = () => {
        setIsExerciseModalVisible(false);
        window.scrollTo(0, currentScrollPosition);
    };

    const onConfigureBlock = (startWeek, endWeek) => {
        setBlockStartWeek(startWeek);
        setBlockEndWeek(endWeek);
        setShowBlockConfig(true); // Signal to show BlockConfiguration
    };

    const toggleBlockConfigVisibility = () => {
        setShowBlockConfig(prevState => !prevState);
        console.log(showBlockConfig)
    };

    const handleExerciseNameChange = (e, date, exerciseIndex) => {
        const newExerciseName = e.target.value;
        setWeekWorkouts(prevWeekWorkouts => {
            const updatedWeekWorkouts = { ...prevWeekWorkouts };
            const updatedExercises = [...updatedWeekWorkouts[date]];
            updatedExercises[exerciseIndex].name = newExerciseName || "";
            updatedWeekWorkouts[date] = updatedExercises;
            return updatedWeekWorkouts;
        });
    };

    const fetchVideoUrlsForWeek = async () => {
        if (!userId) {
            console.error("User ID is undefined. Cannot upload file.");
            return; // Or handle the lack of a userId more gracefully
        }
        const { startOfWeek, endOfWeek } = getWeekStartAndEnd(selectedDate);
        let tempVideoUrls = {};

        for (let d = moment(startOfWeek); d.isSameOrBefore(endOfWeek); d.add(1, 'days')) {
            const date = d.format('YYYY-MM-DD');

            const workoutDayRef = doc(firestore, 'users', userId, 'workoutDays', date);
            const workoutDaySnapshot = await getDoc(workoutDayRef);

            if (workoutDaySnapshot.exists()) {
                const exercises = workoutDaySnapshot.data().exercises || [];
                tempVideoUrls[date] = {};

                exercises.forEach(exercise => {
                    // Assuming each exercise document has an 'id' and an optional 'videoUrl'
                    if (exercise.videoUrl) {
                        tempVideoUrls[date][exercise.id] = exercise.videoUrl;
                    }
                });
            }
        }

        setVideoUrls(tempVideoUrls);
    };

    useEffect(() => {
        if (userId && selectedDate) {
            fetchVideoUrlsForWeek();
        }
    }, [userId, selectedDate]); // Fetch video URLs when userId or selectedDate changes

    // Show, Close the context menu for a specific day
    const showContextMenu = (day) => {
        setCurrentDayClicked(day);
        setIsContextMenuVisible(true);
    };
    const closeContextMenu = () => {
        setIsContextMenuVisible(false);
    };
    // Show, Close the date selection modal
    const showDateSelectionModal = () => {
        setModalWeek(selectedDate); // Set modalWeek to the current week's date
        setIsContextMenuVisible(false);
        setIsDateSelectionModalVisible(true);
    };
    const closeDateSelectionModal = () => {
        setIsDateSelectionModalVisible(false);
    };
    // Show, Close the copy modal
    const showCopyModal = () => {
        setModalWeek(selectedDate); // Set modalWeek to the current week's date
        setIsContextMenuVisible(false);
        setIsCopyModalVisible(true);
    };
    const closeCopyModal = () => {
        setIsCopyModalVisible(false);
    };
    const showWeekCopyModal = () => {
        const nextWeek = moment(selectedDate).add(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD');
        setModalWeek(nextWeek); // Set to next week
        setIsWeekCopyModalVisible(true);
        setIsContextMenuVisible(false); // Close the ContextMenuModal
    };
    const closeWeekCopyModal = () => {
        setIsWeekCopyModalVisible(false);
    };
    // Logic to determine available dates for moving exercises
    const getAvailableDates = (weekDate) => {
        // Example logic, modify based on your date structure
        return Object.keys(weekWorkouts).filter(date => weekWorkouts[date].length === 0);
    };
    // Logic to get all dates of the week
    const getAllWeekDates = (weekDate): string[] => {
        const { startOfWeek, endOfWeek } = getWeekStartAndEnd(weekDate);
        let dates: string[] = []; // Explicitly declaring the array as string[]
        for (let d = moment(startOfWeek); d.isSameOrBefore(endOfWeek); d.add(1, 'days')) {
            dates.push(d.format('YYYY-MM-DD'));
        }
        return dates;
    };

    const goToNextWeek = () => {
        setSelectedDate(moment(selectedDate).add(1, 'weeks').format('YYYY-MM-DD'));
    };
    const goToPreviousWeek = () => {
        setSelectedDate(moment(selectedDate).subtract(1, 'weeks').format('YYYY-MM-DD'));
    };
    const goToNextWeekInModal = () => {
        setModalWeek(prevDate => moment(prevDate).add(1, 'weeks').format('YYYY-MM-DD'));
    };
    const goToPreviousWeekInModal = () => {
        setModalWeek(prevDate => moment(prevDate).subtract(1, 'weeks').format('YYYY-MM-DD'));
    };

    // Handler for moving exercises to the new date
    const moveExercisesToNewDate = (newDate) => {
        if (currentDayClicked === null) return; // Ensure currentDayClicked is not null

        setWeekWorkouts(prevWeekWorkouts => {
            // Clone the previous state
            const updatedWorkouts = { ...prevWeekWorkouts };

            // Move the exercises from the current day to the new day
            updatedWorkouts[newDate] = [...(updatedWorkouts[currentDayClicked] || [])];

            // Ensure the current day still exists but with no exercises
            updatedWorkouts[currentDayClicked] = [];

            return updatedWorkouts;
        });

        setIsDateSelectionModalVisible(false);
    };

    const updateFirestoreForDate = async (date, exercises, selectedUserIdForCopy) => {
        if (!selectedUserIdForCopy) {
            console.error("User ID is undefined. Cannot upload file.");
            return; // Or handle the lack of a userId more gracefully
        }
        const workoutDayRef = doc(firestore, 'users', selectedUserIdForCopy, 'workoutDays', date);
        try {
            const workoutDaySnapshot = await getDoc(workoutDayRef);
            let updatedExercises = exercises;

            // If there's already data for this date, combine it with the new exercises
            if (workoutDaySnapshot.exists()) {
                const existingExercises = workoutDaySnapshot.data().exercises || [];
                updatedExercises = [...existingExercises, ...exercises];
            }

            // Update Firestore
            await setDoc(workoutDayRef, { exercises: updatedExercises }, { merge: true });
        } catch (error) {
            console.error('Error updating Firestore:', error);
        }
    };

    const copyExercisesToNewDate = async (newDate, selectedUserId) => {
        if (currentDayClicked === null) return;

        const isTargetDateInCurrentWeek = moment(newDate).isSame(selectedDate, 'week');
        const exercisesToCopy = (weekWorkouts[currentDayClicked] || []).map((exercise, index) => {
            const newExerciseId = `exercise-${newDate.replace(/-/g, '')}-${index}`; // Generate a new unique ID for the exercise
            const { videoUrl, ...rest } = exercise; // Destructure to exclude videoUrl
            return {
                ...rest,
                id: newExerciseId, // Assign the new unique ID
                sets: exercise.sets.map(set => ({
                    ...set,
                    completed: '0', // Reset completed status
                    detailedSets: set.detailedSets ? set.detailedSets.map(detailedSet => ({
                        ...detailedSet,
                        completed: '0' // Reset detailed set completed status
                    })) : []
                }))
            };
        });

        if (isTargetDateInCurrentWeek) {
            setWeekWorkouts(prevWeekWorkouts => {
                const updatedWorkouts = { ...prevWeekWorkouts };
                updatedWorkouts[newDate] = [...(updatedWorkouts[newDate] || []), ...exercisesToCopy];
                return updatedWorkouts;
            });
        } else {
            // Update Firestore for the target date in a different week
            await updateFirestoreForDate(newDate, exercisesToCopy, selectedUserId);
            setSelectedDate(moment(newDate).startOf('isoWeek').format('YYYY-MM-DD'));
        }

        setIsCopyModalVisible(false);
    };

    const handleSelectUserId = (userId) => {
        setSelectedUserIdForCopy(userId);
    };

    const copyWeekToSelectedWeek = async (selectedWeek, selectedUserIdForCopy) => {
        const startOfSelectedWeek = moment(selectedWeek).startOf('isoWeek'); // Monday
        const endOfSelectedWeek = moment(selectedWeek).endOf('isoWeek'); // Sunday

        let newWeekWorkouts: Record<string, any[]> = {}; // Explicitly type newWeekWorkouts
        for (let d = moment(startOfSelectedWeek); d.isSameOrBefore(endOfSelectedWeek); d.add(1, 'days')) {
            const day = d.format('YYYY-MM-DD');
            const dayOfWeekCurrent = d.isoWeekday() - 1; // Monday (0) to Sunday (6)
            const currentWeekDay = moment(selectedDate).startOf('isoWeek').add(dayOfWeekCurrent, 'days').format('YYYY-MM-DD');

            // Copy exercises but reset notes and completed status
            newWeekWorkouts[day] = (weekWorkouts[currentWeekDay] || []).map((exercise, index) => {
                const newExerciseId = `exercise-${day}-${index}`;
                const { videoUrl, ...rest } = exercise; // Exclude videoUrl
                return {
                    ...rest,
                    id: newExerciseId, // Assign the new unique ID
                    note: '', // Reset student note
                    coachNote: '', // Reset coach note
                    sets: exercise.sets.map(set => ({
                        ...set,
                        completed: '0', // Reset completed status
                        detailedSets: set.detailedSets ? set.detailedSets.map(detailedSet => ({
                            ...detailedSet,
                            completed: '0' // Reset detailed set completed status
                        })) : []
                    }))
                };
            });
        }

        // Update state
        setWeekWorkouts(newWeekWorkouts);

        // Function to check if newWeekWorkouts is empty
        const isWorkoutsEmpty = (workouts: Record<string, any[]>) => {
            return !Object.values(workouts).some(day => day.length > 0);
        };

        // Update Firestore only if newWeekWorkouts is not empty
        if (!isWorkoutsEmpty(newWeekWorkouts)) {
            for (const [date, exercises] of Object.entries(newWeekWorkouts)) {
                await updateFirestoreForDate(date, exercises, selectedUserIdForCopy);
            }
        }
        // New addition: Delete Firestore documents for days without exercises
        for (const [date, exercises] of Object.entries(newWeekWorkouts)) {
            if (exercises.length === 0) {
                // Path to the document for the day
                const dayDocRef = doc(firestore, 'users', selectedUserIdForCopy, 'workoutDays', date);
                // Delete the document
                await deleteDoc(dayDocRef);
            }
        }

        // Update the main view to display the selected week
        setSelectedDate(moment(selectedWeek).format('YYYY-MM-DD'));

        // Close the modal
        setIsWeekCopyModalVisible(false);
    };

    const handleTextChange = (date, key, exerciseIndex, setIndex, text) => {
        let newValue;

        if (key === 'setNumber') {
            // Parse the text to a number for 'setNumber', defaulting to 0 if parsing fails
            newValue = isNaN(parseInt(text)) ? 0 : parseInt(text);
        } else {
            // Directly use the text for all other fields
            newValue = text;
        }

        setWeekWorkouts(prevWeekWorkouts => {
            // Clone the previous state
            const updatedWeekWorkouts = { ...prevWeekWorkouts };

            // Ensure that there are exercises for the given date
            if (updatedWeekWorkouts[date]) {
                const updatedExercises = [...updatedWeekWorkouts[date]];

                // Check if the specified exercise and set exist
                if (updatedExercises[exerciseIndex] && updatedExercises[exerciseIndex].sets[setIndex]) {
                    updatedExercises[exerciseIndex].sets[setIndex][key] = newValue;
                }

                // Update the exercises for the given date
                updatedWeekWorkouts[date] = updatedExercises;
            }

            return updatedWeekWorkouts;
        });
    };

    const addExercises = (date, exerciseNames) => {
        const newExercises = exerciseNames.map(exerciseName => ({
            name: exerciseName,
            sets: [{ setNumber: '', reps: '', weight: '', rpe: '', completed: '0', repos: '' }],
        }));

        setWeekWorkouts(prevWeekWorkouts => {
            // Clone the previous state
            const updatedWeekWorkouts = { ...prevWeekWorkouts };

            // Check if there are already exercises for the given date
            if (updatedWeekWorkouts[date]) {
                updatedWeekWorkouts[date] = [...updatedWeekWorkouts[date], ...newExercises];
            } else {
                updatedWeekWorkouts[date] = newExercises;
            }

            return updatedWeekWorkouts;
        });

        setAllExercises(prevAllExercises => {
            const updatedAllExercises = [...prevAllExercises];
            exerciseNames.forEach(name => {
                if (!updatedAllExercises.some(ex => ex.name === name)) {
                    updatedAllExercises.push({ id: `temp-${name}`, name: name }); // Use a temporary ID
                }
            });
            return updatedAllExercises;
        });

        setAreButtonsVisible(true);
        setIsEditMode(true);
        setIsExerciseModalVisible(false);
    };

    const deleteExercise = async (date, exerciseIndex) => {
        if (userId && date !== null && exerciseIndex !== null) {
            setWeekWorkouts(prevWeekWorkouts => {
                const updatedWeekWorkouts = { ...prevWeekWorkouts };
                updatedWeekWorkouts[date] = updatedWeekWorkouts[date].filter((_, index) => index !== exerciseIndex);

                return updatedWeekWorkouts;
            });

            // Update Firestore
            try {
                const workoutDayRef = doc(firestore, 'users', userId, 'workoutDays', date);
                const updatedExercises = weekWorkouts[date].filter((_, index) => index !== exerciseIndex);

                await updateDoc(workoutDayRef, { exercises: updatedExercises });
            } catch (error) {
                console.error("Error updating Firestore: ", error);
            }
        }
    };

    // Function to calculate the start and end dates of the week
    const getWeekStartAndEnd = (date) => {
        const startOfWeek = moment(date).startOf('isoWeek'); // ISO week starts on Monday
        const endOfWeek = moment(date).endOf('isoWeek'); // End of the week (Sunday)

        return { startOfWeek, endOfWeek };
    };

    const fetchWorkoutDataForWeek = async (date) => {
        if (!date || !userId) return;

        try {
            const { startOfWeek, endOfWeek } = getWeekStartAndEnd(date);
            const workoutDayCollectionRef = collection(firestore, 'users', userId, 'workoutDays');
            const workoutDaysSnapshot = await getDocs(workoutDayCollectionRef);

            const fetchedWeekWorkouts = {};
            for (let d = moment(startOfWeek); d.isSameOrBefore(endOfWeek); d.add(1, 'days')) {
                const formattedDate = d.format('YYYY-MM-DD');
                fetchedWeekWorkouts[formattedDate] = []; // Initialize each day with an empty array
            }

            workoutDaysSnapshot.docs.forEach(doc => {
                const docDate = doc.id; // The document ID is the date
                if (fetchedWeekWorkouts.hasOwnProperty(docDate)) {
                    fetchedWeekWorkouts[docDate] = doc.data().exercises;
                }
            });

            setWeekWorkouts(fetchedWeekWorkouts);
        } catch (error) {
            console.error('Error fetching workout data for week:', error);
        }
    };

    useEffect(() => {
        if (!selectedDate) {
            setSelectedDate(moment().format('YYYY-MM-DD')); // Set to current date if not provided
        }
    }, [selectedDate]);

    useEffect(() => {
        if (selectedDate) {
            fetchWorkoutDataForWeek(selectedDate);
            setIsLoading(false);
        }
    }, [selectedDate, userId]);

    const handleCoachNoteChange = (e, exerciseIndex, date) => {
        const updatedWeekWorkouts = { ...weekWorkouts };
        updatedWeekWorkouts[date][exerciseIndex].coachNote = e.target.value;
        setWeekWorkouts(updatedWeekWorkouts);
    };

    const handleNoteChange = (e, exerciseIndex, date) => {
        const updatedWeekWorkouts = { ...weekWorkouts };
        updatedWeekWorkouts[date][exerciseIndex].note = e.target.value;
        setWeekWorkouts(updatedWeekWorkouts);
    };

    const updateNoteInFirestore = async (exerciseId, noteText, date, isCoachNote) => {
        if (!userId) {
            console.error("User ID is undefined. Cannot upload file.");
            return; // Or handle the lack of a userId more gracefully
        }
        try {
            if (!auth.currentUser) {
                throw new Error("No authenticated user found");
            }
            const targetUserId = userRole === 'coach' ? userId : auth.currentUser.uid;
            const workoutDayDocRef = doc(firestore, 'users', targetUserId, 'workoutDays', date);
            const workoutDayDoc = await getDoc(workoutDayDocRef);

            if (workoutDayDoc.exists()) {
                const workoutDayData = workoutDayDoc.data();
                const exercises = workoutDayData.exercises || [];

                const exerciseIndex = exercises.findIndex(ex => ex.id === exerciseId);
                if (exerciseIndex !== -1) {
                    if (isCoachNote) {
                        exercises[exerciseIndex].coachNote = noteText;
                    } else {
                        exercises[exerciseIndex].note = noteText;
                    }

                    await updateDoc(workoutDayDocRef, { exercises });
                }
            }
        } catch (error) {
            console.error("Error updating note in Firestore:", error);
        }
    };

    const saveWorkoutData = async () => {
        try {
            if (userId) {
                // Iterate over each day in weekWorkouts
                for (const [date, exercises] of Object.entries(weekWorkouts)) {
                    const workoutDayDocRef = doc(firestore, 'users', userId, 'workoutDays', date);

                    if (exercises.length === 0) {
                        // If there are no exercises for the date, delete the Firestore document
                        await deleteDoc(workoutDayDocRef);
                    } else {
                        // Prepare exercises data for Firestore
                        const modifiedExercises = exercises.map((exercise, index) => {
                            const newSets = exercise.sets.map(set => {
                                const existingDetailedSets = set.detailedSets && set.detailedSets.length === set.setNumber ?
                                    set.detailedSets :
                                    [];

                                // Reset detailedSetsArray based on the current setNumber
                                let detailedSetsArray: { setNumber: number; completed: string; }[] = [];
                                const setNumber = Number(set.setNumber); // Ensure setNumber is treated as a number

                                // Populate detailedSetsArray based on the current setNumber
                                for (let i = 1; i <= setNumber; i++) {
                                    const existingSet = existingDetailedSets.find(ds => ds.setNumber === i);
                                    detailedSetsArray.push({
                                        setNumber: i,
                                        completed: existingSet ? existingSet.completed : "0" // Preserve existing completed status or default to "0"
                                    });
                                }

                                return {
                                    ...set,
                                    completed: set.completed.toString(), // Ensure completed is a string
                                    detailedSets: detailedSetsArray
                                };
                            });

                            return {
                                ...exercise,
                                id: exercise.id || `exercise-${index}`, // Use existing id or generate a simple unique ID
                                sets: newSets,
                                note: exercise.note || "", // Keep existing note
                                coachNote: exercise.coachNote || "" // Keep existing coachNote
                            };
                        });

                        // Recreate the document with the updated data
                        await setDoc(workoutDayDocRef, { exercises: modifiedExercises });
                    }
                }
            } else {
                console.error('No user signed in');
            }
        } catch (error) {
            console.error('Error saving week workouts data:', error);
        }
    };

    const handleExerciseDrop = (droppedItem, targetDate, targetIndex = null) => {
        const { id: droppedExerciseId, date: originalDate } = droppedItem;

        if (!weekWorkouts[originalDate]) {
            console.error(`No exercises found for original date: ${originalDate}`);
            return;
        }

        const exerciseIndex = weekWorkouts[originalDate].findIndex(exercise => exercise.id === droppedExerciseId);
        if (exerciseIndex === -1) {
            console.error(`Exercise with ID ${droppedExerciseId} not found in date ${originalDate}`);
            return;
        }

        const [droppedExercise] = weekWorkouts[originalDate].splice(exerciseIndex, 1);

        if (!weekWorkouts[targetDate]) {
            weekWorkouts[targetDate] = [];
        }

        // If targetIndex is not provided or invalid, push to the end of the target day's list
        if (targetIndex === null || targetIndex >= weekWorkouts[targetDate].length) {
            weekWorkouts[targetDate].push(droppedExercise);
        } else {
            // Insert the droppedExercise at the specified index within the target date's list
            weekWorkouts[targetDate].splice(targetIndex, 0, droppedExercise);
        }

        // Assuming you have a setter function for weekWorkouts state, call it here to update the state with the modified weekWorkouts object
        // This is a crucial step to ensure React re-renders the component with updated data
        setWeekWorkouts({ ...weekWorkouts });
    };

    const onReorderExercise = (draggedIndex, targetIndex, date) => {
        setWeekWorkouts(prevWeekWorkouts => {
            // Your existing logic for reordering the exercises...
            const exercisesForDate = [...(prevWeekWorkouts[date] || [])];
            if (exercisesForDate.length > 0) {
                const [reorderedExercise] = exercisesForDate.splice(draggedIndex, 1);
                exercisesForDate.splice(targetIndex, 0, reorderedExercise);

                // Prepare the update for Firestore after state is updated
                //updateFirestoreWithNewOrder(date, exercisesForDate);

                return {
                    ...prevWeekWorkouts,
                    [date]: exercisesForDate,
                };
            }

            return prevWeekWorkouts;
        });
    };

    const { startOfWeek, endOfWeek } = getWeekStartAndEnd(selectedDate);
    const formattedStartOfWeek = formatDate(startOfWeek.format('YYYY-MM-DD')).monthAndDay;
    const formattedEndOfWeek = formatDate(endOfWeek.format('YYYY-MM-DD')).monthAndDay;
    const formattedWeekRange = `${formattedStartOfWeek} - ${formattedEndOfWeek}`;

    return (
        <>
            <div className='WeekWorkoutStylesContentContainer'>
                <div className='WeekWorkoutStylesSecondContainer'>
                    {isLoading ? (
                        <div className="ProfilePageLoadingContainer">
                            <div className="spinner"></div>
                        </div>
                    ) : (
                        <>
                            <div className="WeekWorkoutStylesWorkoutContainer">
                                <div className="WeekWorkoutStylesTitleContainer">
                                    <div className='WeekWorkoutStylesSemaineDateContainer'>
                                        <div className='WeekWorkoutStylesWeekAndArrowsContainer'>
                                            {!isEditMode && (
                                                <button className="StatsStylesArrowButtonContainer" onClick={goToPreviousWeek}>
                                                    <img className='StatsStylesArrowButtonLeft' src={require('../assets/previous_icon.png')} alt="Next Week" />
                                                </button>
                                            )}
                                            <span className="WeekWorkoutStylesTitle">
                                                <span className="week-prefix">Semaine du </span>
                                                {formattedWeekRange}
                                            </span>
                                            {!isEditMode && (
                                                <button className="StatsStylesArrowButtonContainer" onClick={goToNextWeek}>
                                                    <img className='StatsStylesArrowButton' src={require('../assets/next_icon.png')} alt="Next Week" />
                                                </button>
                                            )}
                                        </div>
                                        {areButtonsVisible && (
                                            <div className='ProgramCreationStylesEditAndBlockIconContainer'>
                                                {!isEditMode ? (
                                                    <button
                                                        className={`ProgramCreationStylesEditIconContainer ${areAllExercisesEmpty ? 'ProgramCreationStylesEditIconContainerDisabled' : ''}`}
                                                        onClick={() => !areAllExercisesEmpty && setIsEditMode(true)}
                                                    >
                                                        <img className="ProgramCreationStylesEditIcon" src={require('../assets/edit_icon.png')} alt="Edit" />
                                                    </button>
                                                ) : (
                                                    <div className="ProgramCreationStylesEditIconContainer">
                                                        <div className='WeekWorkoutStylesSaveButtonTopPage'>
                                                            <button
                                                                className=""
                                                                onClick={() => {
                                                                    saveWorkoutData(); // Call the save function
                                                                    setIsEditMode(false); // Then set edit mode to false
                                                                }}
                                                            >
                                                                Sauvegarder
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                                {/* This button will always be displayed regardless of isEditMode */}
                                                <button
                                                    className="WeekWorkoutStylesBlockIconContainer"
                                                    onClick={() => toggleBlockConfigVisibility()}
                                                >
                                                    <img
                                                        className="ProgramCreationStylesEditIcon"
                                                        src={require('../assets/block_icon.png')}
                                                        alt="Block"
                                                    />
                                                </button>
                                                <button
                                                    className="WeekWorkoutStylesExcelIconContainer"
                                                    onClick={toggleExcelModal}
                                                >
                                                    <img
                                                        className="ProgramCreationStylesEditIcon"
                                                        src={require('../assets/ExcelImport.png')}
                                                        alt="Excel import"
                                                    />
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {areAllExercisesEmpty && !isEditMode ? (
                                    <div className="NodataContainerStats">
                                        <div className="MessageNodata">
                                            <img className="StatsNoDataIllustration" src={require('../assets/No_data_found_illustration.png')} alt="Add" />
                                            <span className='StatsNoDataExplanation'>Vous n'avez pas de programme pour la semaine en cours.</span>
                                            <button className="BlueButton WeekWorkoutStylesAddMargin" onClick={() => setIsEditMode(true)}>
                                                <div className='ProgramCreationStylesConfigureButtonContainer'>
                                                    <span>Démarrer</span>
                                                    <img className="ProgramCreationStylesStartIcon" src={require('../assets/Start_creation.png')} alt="Add" />
                                                </div>
                                            </button>
                                            <button className="WeekWorkoutStylesButtonModalToggle" onClick={toggleExcelModal}>
                                                <span>Importer un fichier excel</span>
                                                <img
                                                    className="ProgramCreationStylesEditIcon"
                                                    src={require('../assets/ExcelImport.png')}
                                                    alt="Excel import"
                                                />
                                                {/* Optionally include an icon with the button */}
                                            </button>
                                        </div>
                                    </div>
                                ) : (
                                    Object.entries(weekWorkouts).map(([date, exercises]) => (
                                        <DayContainer
                                            key={date}
                                            date={date}
                                            exercises={exercises}
                                            onDropExercise={handleExerciseDrop} // Function to handle exercise drop
                                            onReorderExercise={onReorderExercise}
                                            handleExerciseNameChange={handleExerciseNameChange} // Function to handle name change
                                            handleTextChange={handleTextChange} // Function to handle text changes
                                            handleCoachNoteChange={handleCoachNoteChange} // Function for coach note changes
                                            handleNoteChange={handleNoteChange} // Function for note changes
                                            updateNoteInFirestore={updateNoteInFirestore} // Function to update Firestore
                                            deleteExercise={deleteExercise} // Function to delete an exercise
                                            isEditMode={isEditMode} // Boolean indicating if edit mode is active
                                            videoUrls={videoUrls} // Object holding video URLs
                                            allExercises={allExercises} // Array of all exercises
                                            showContextMenu={showContextMenu} // Function to show context menu
                                            formatDate={formatDate} // Function to format date strings
                                            stopPropagation={(e) => e.stopPropagation()} // Function to stop click propagation
                                            userRole={userRole} // User role (coach or eleve)
                                            handleOpenModal={handleOpenModal} // Function to handle modal opening
                                            setCurrentAddingDate={setCurrentAddingDate} // Function to set the current date for adding exercises
                                            weekWorkouts={weekWorkouts}
                                        />
                                    ))
                                )}
                                <ContextMenuModal
                                    isVisible={isContextMenuVisible}
                                    onClose={closeContextMenu}
                                    onMoveDay={showDateSelectionModal}
                                    onCopyDay={showCopyModal}
                                    onCopyWeek={showWeekCopyModal}
                                />
                                <DateSelectionModal
                                    isVisible={isCopyModalVisible || isDateSelectionModalVisible}
                                    onClose={isCopyModalVisible ? closeCopyModal : closeDateSelectionModal}
                                    availableDates={isCopyModalVisible ? getAllWeekDates(modalWeek) : getAvailableDates(modalWeek)}
                                    onSelectDate={isCopyModalVisible ? (newDate) => copyExercisesToNewDate(newDate, userId) : moveExercisesToNewDate} // Adjusted
                                    formatDate={formatDate}
                                    onPreviousWeek={goToPreviousWeekInModal}
                                    onNextWeek={goToNextWeekInModal}
                                    isCopyMode={isCopyModalVisible} // Set isCopyMode based on isCopyModalVisible
                                />
                                <WeekCopyModal
                                    isVisible={isWeekCopyModalVisible}
                                    onClose={closeWeekCopyModal}
                                    modalWeek={modalWeek}
                                    onPreviousWeek={goToPreviousWeekInModal}
                                    onNextWeek={goToNextWeekInModal}
                                    onSelectUserId={handleSelectUserId} // New prop for selecting a user ID
                                    onCopyWeek={(selectedWeek) => copyWeekToSelectedWeek(selectedWeek, selectedUserIdForCopy)} // Adjusted to include selectedUserId
                                    DefaultSelectedUserId={userId}
                                />
                                <ExerciseSelectionModal
                                    visible={isExerciseModalVisible}
                                    setVisible={closeModal}
                                    onSelect={(exerciseNames) => addExercises(currentAddingDate, exerciseNames)}
                                    userId={userId}
                                />
                            </div>
                            {isEditMode && (
                                <div className='WeekWorkoutStylesButtonContainer WeekWorkoutStylesAddMarginTopButton'>
                                    <button
                                        className="BlueButton WeekWorkoutStylesAddWidth"
                                        onClick={() => {
                                            saveWorkoutData(); // Call the save function
                                            setIsEditMode(false); // Then set edit mode to false
                                        }}
                                    >
                                        Sauvegarder
                                    </button>
                                </div>
                            )}
                        </>
                    )}
                </div>
                <div className='WeekWorkoutStylesBlockContainer'>
                    {showBlockConfig && (
                        <BlockConfiguration
                            onConfigureBlock={onConfigureBlock}
                            modalWeek={selectedDate} // Passing the current week as modalWeek
                            userId={userId} // Pass the userId to the child component
                        />
                    )}
                </div>
            </div>
            <ExcelModal
                isVisible={isExcelModalVisible}
                onClose={toggleExcelModal}
                onFileUpload={handleFileUpload}
            />
            {showImportSuccessMessage && (
                <div className="importSuccessModal">
                    Vos données ont été importées
                </div>
            )}
        </>
    );
};

export default WeekWorkoutEditor;