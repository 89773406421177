import React, { useEffect, useState } from 'react';
import '../../components/FAQStyles.css'; // Make sure to import the CSS file
import { getAnalytics, logEvent } from 'firebase/analytics'; // Import Firebase Analytics functions
import PaymentNavigationLanding from './PaymentNavigationLanding.tsx';
import LandingPageHeader from './LandingPageHeader.tsx';

const FAQPayment: React.FC = () => {
  // Explicitly type the openFAQs state as an array of numbers
  const [openFAQs, setOpenFAQs] = useState<{ section: number; question: number }[]>([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const analytics = getAnalytics(); // Initialize Firebase Analytics
  const { navigateToAboutUsPayment, navigateToCheckout, openEmail, navigateToFAQPayment } = PaymentNavigationLanding();

  const sections = [
    {
      title: 'Fonctionnement et Paiement',
      faqs: [
        {
          question: 'Comment et à quelle fréquence suis-je payé ?',
          answer: 'Vos clients se rendent sur votre profil pour vous régler. Les transactions arriveront directement sur votre compte bancaire. Si vous avez mis en place une possibilité de paiement ponctuel alors le paiement arrivera tout de suite. Dans le cas d\'un abonnement, le premier paiement arrivera tout de suite puis tous les mois au moment du renouvelement de l\'abonnement.'
        },
        {
          question: 'Je n\'ai plus besoin de relancer mes clients pour les paiements ?',
          answer: 'Pour les paiements par abonnement, absolument ! Vos clients s\'abonnent via votre profil, et les transactions sont prélevées automatiquement sur leur carte chaque mois. Les fonds sont ensuite directement transférés sur votre compte bancaire.'
        },
        {
          question: 'Puis-je configurer une période d\'engagement minimum ?',
          answer: 'Oui, vous avez la possibilité de définir une durée d\'engagement minimale pour vos abonnements, jusqu\'à 6 mois, directement depuis votre profil.'
        },
      ],
    },
    {
      title: 'Mise en place du profil',
      faqs: [
        {
          question: 'La mise en place du profil prend-elle du temps ?',
          answer: 'Non, vous pouvez être opérationnel en quelques minutes seulement !'
        },
        {
          question: 'Que dois je faire pour avoir un profil fonctionnel ?',
          answer: 'Il vous suffira de configurer vos offres ainsi que vos informations bancaires.'
        },
        {
          question: 'Puis-je personnaliser mon profil ?',
          answer: 'Oui, en plus de vos offres vous pouvez paramétrer une photo de profil, une description ainsi que choisir la couleur d\'affichage de votre profil.'
        },
        {
          question: 'Comment cela se passe-t-il pour mes élèves dont l\'abonnement est déjà en cours ?',
          answer: 'Demandez à votre client d\'effectuer son prochain paiement sur votre profil. Il sera ensuite prélevé automatiquement.'
        },
      ],
    },
    {
      title: 'Processus d\'Inscription',
      faqs: [
        {
          question: 'Quel est le processus pour mes clients ?',
          answer: 'Aucun processus, ils devront juste indiquer leur nom et leur adresse email lorsqu\'ils se rendront sur votre profil, pour effectuer le réglement. Ces informations serviront à vous tenir informés de l\'origine de vos transactions.'
        },
        {
          question: 'Quel est le processus pour moi ?',
          answer: (
            <span>
              Suivez <a className='linkFAQ' href="https://reppwebapp.fr/modulepaiement" target="_blank" rel="noopener noreferrer">ce lien</a> pour créer votre profil. Vous aurez à effectuer un paiement unique pour pouvoir utiliser votre profil de manière illimitée. En moins de 5 minutes, vous pourrez recevoir des paiements sécurisés via notre partenaire Stripe !
            </span>
          )
        },
      ],
    },
    {
      title: 'Coût et Programme d\'affiliation',
      faqs: [
        {
          question: 'Quel est le coût d\'utilisation de profil ?',
          answer: (
            <span>
              Avec Repp, vous ne payez qu'une seule fois. Vous pouvez ensuite utiliser votre profil pour toujours. Notre partenaire stripe prélèvera cependant 2% sur les transactions afin d'assurer la sécurité et la rapidité des versements. Nous ne touchons aucun argent sur ce pourcentage.
            </span>
          )
        },
        {
          question: 'Comment fonctionne le programme d\'affiliation ?',
          answer: (
            <span>
              Pour toute personne qui achète un profil Repp via votre recommandation, nous procéderons à un remboursement de 30% par personne !
            </span>
          )
        },
      ],
    },
    // Add more sections as needed
  ];

  const toggleFAQ = (sectionIndex: number, questionIndex: number) => {
    const isOpen = openFAQs.some(openIndex => openIndex.section === sectionIndex && openIndex.question === questionIndex);
    if (isOpen) {
      setOpenFAQs(openFAQs.filter(openIndex => openIndex.section !== sectionIndex || openIndex.question !== questionIndex));
    } else {
      setOpenFAQs([...openFAQs, { section: sectionIndex, question: questionIndex }]);
    }
  };

  useEffect(() => {
    logEvent(analytics, 'payment_faq_visit');
  }, []);

  return (
    <>
      <div className="LandingPageStylesMainContainerOtherPages">
        <LandingPageHeader />
        <div className='MainContainerFAQ'>
          <div className='ContainerBothSidesFAQ'>
            <div className='ContainerLeftFAQ'>
              <span className='SmallTitleFAQ'>Support</span>
              <span className='BigTitleFAQ'>FAQs</span>
              <span className='SmallDescriptionFAQ'>
                Vous ne trouvez pas la réponse à votre question ? Contactez-nous
                <span style={{ cursor: 'pointer', textDecoration: 'underline', marginLeft: '3px' }} onClick={openEmail}>ici</span>.
              </span>
            </div>
            <div className="faq-container">
              {sections.map((section, sectionIndex) => (
                <div key={sectionIndex}>
                  <div className='TitleFAQTest'>
                    <span className="faq-Title">{section.title}</span>
                  </div>
                  {section.faqs.map((faq, faqIndex) => (
                    <div key={faqIndex} className={`faq-item ${openFAQs.some(openIndex => openIndex.section === sectionIndex && openIndex.question === faqIndex) ? 'open' : ''}`}>
                      <div className="faq-question" onClick={() => toggleFAQ(sectionIndex, faqIndex)}>
                        {faq.question}
                        <span className="arrow">▼</span>
                      </div>
                      {openFAQs.some(openIndex => openIndex.section === sectionIndex && openIndex.question === faqIndex) && (
                        <div className="faq-answer">
                          {typeof faq.answer === 'string' ? faq.answer : faq.answer}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <footer className="LandingPageStylesFooterContainer">
        <div className="LandingFooter-section">
          <img src={require('../../assets/repp_logo_white.png')} alt="Logo" className="LandingPageStylesLogoFooter" />
          <span style={{ marginTop: '20px' }}>© 2024 Repp Company.</span>
        </div>
        <div className="LandingFooter-section">
          <h5>Entreprise</h5>
          <button onClick={navigateToAboutUsPayment} className='LandingFooter-button'>À propos</button>
          <button onClick={navigateToFAQPayment} className='LandingFooter-button'>FAQ</button>
          <button onClick={openEmail} className='LandingFooter-button'>Nous contacter</button>
        </div>
        <div className="LandingFooter-section">
          <h5>Plateforme</h5>
          <button onClick={() => navigateToCheckout()} className='LandingFooter-button'>Créer un compte</button>
        </div>
      </footer>
    </>
  );
};

export default FAQPayment;
