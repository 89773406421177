import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import '../LandingPageStyles.css';
import { getAnalytics, logEvent } from 'firebase/analytics'; // Import Firebase Analytics functions
import { Helmet } from 'react-helmet-async';
import PaymentNavigationLanding from './PaymentNavigationLanding.tsx';
import LandingPageHeader from './LandingPageHeader.tsx';

const LandingPayment2 = () => {
    const history = useHistory();
    const analytics = getAnalytics(); // Initialize Firebase Analytics
    const [isNotMobile, setIsNotMobile] = useState(window.innerWidth > 950);
    const { navigateToAboutUsPayment, navigateToCheckout, openEmail, navigateToFAQPayment, navigateToDemoCreation } = PaymentNavigationLanding();

    useEffect(() => {
        const handleResize = () => {
            setIsNotMobile(window.innerWidth > 768);
        };

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const imageSrc = isNotMobile
        ? require('../../assets/ScreenAppliPayment-7.png') // Desktop image
        : require('../../assets/ScreenAppliPayment-8.png'); // Mobile image

    const IllustrationSrc = isNotMobile
        ? require('../../assets/PaymentModulaIllustration.png') // Desktop image
        : require('../../assets/PaymentModulaIllustrationMobile.png'); // Mobile image

    const coachProfiles = [
        'Coach_exemple_1.png',
        'Coach_exemple_2.png',
        'Coach_exemple_6.png',
        'Coach_exemple_9.png',
        'Coach_exemple_5.png',
        'Coach_exemple_10.png',
        'Coach_exemple_7.png',
        'Coach_exemple_3.png',
        'Coach_exemple_4.png',
        'Coach_exemple_8.png',
    ];

    const [currentSlide, setCurrentSlide] = useState(0);

    // Automatically move to the next slide every 3 seconds
    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentSlide((prevSlide) =>
                prevSlide === coachProfiles.length - 1 ? 0 : prevSlide + 1
            );
        }, 1000); // Adjust time for slide change as needed

        return () => clearInterval(timer); // Clean up the interval on component unmount
    }, []);

    useEffect(() => {
        logEvent(analytics, 'payment_landing_page_visit');
    }, []);

    const navigateToExamples = () => {
        const section = document.getElementById('ils-utilisent-repp');
        if (section) {
            section.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    useEffect(() => {
        let observer;

        // Select all elements from multiple classes
        const elements = document.querySelectorAll(
            '.LandingPageStylesAddMaringPayment, ' +
            '.LandingPageStylesFourthSectionContainer, ' +
            '.LandingPageStylesFirstSectionContainer, ' +
            '.LandingPageStylesPaymentCardSection, ' +
            '.LandingPaymentCoachProfileSlider, ' +
            '.LandingPageStylesFirstSectionContainerLastRow, ' +
            '.LandingPageStylesFifthSectionContainer'
        );

        if (elements.length) {
            observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            // Once an element is in view, add the class to trigger the animation
                            entry.target.classList.add('fadeInVisible');
                        }
                    });
                },
                { threshold: 0.1 } // Adjust threshold value based on your needs
            );

            // Observe each element
            elements.forEach((element) => {
                observer.observe(element);
            });
        }

        // Cleanup function
        return () => {
            if (observer) {
                elements.forEach((element) => {
                    observer.unobserve(element);
                });
            }
        };
    }, []); // This ensures the effect runs only once when the component mounts

    return (
        <>
            <Helmet>
                <title>Plateforme de Coaching en Ligne - Musculation et Sports de Force</title>
                <meta name="description" content="Plateforme dédiée aux coachs de salle de sport en ligne, permettant de gérer et suivre facilement les programmes d'entraînement personnalisés de leurs élèves, tout en assurant une gestion fluide des paiements." />
                <meta name="keywords" content="coaching en ligne, musculation, sports de force, gestion entraînement, programme d'entraînement, suivi sportif, paiement pour coachs, entraînement personnalisé, plateforme pour coachs, coaching fitness" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <link rel="canonical" href="https://www.reppwebapp.fr/" />

                {/* Open Graph / Facebook */}
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.reppwebapp.fr/" />
                <meta property="og:title" content="Plateforme de Coaching en Ligne - Musculation et Sports de Force" />
                <meta property="og:description" content="Optimisez votre coaching avec notre plateforme spécialement conçue pour les entraîneurs de musculation et de sports de force, facilitant la création, le suivi des programmes d'entraînement et la gestion des paiements." />
                <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/repp-bf58c.appspot.com/o/Banner_fb.png?alt=media&token=64049cd3-3a06-40de-a4f8-44356fceb318" />

                {/* Twitter */}
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://www.reppwebapp.fr/" />
                <meta property="twitter:title" content="Plateforme de Coaching en Ligne - Musculation et Sports de Force" />
                <meta property="twitter:description" content="Optimisez votre coaching avec notre plateforme spécialement conçue pour les entraîneurs de musculation et de sports de force, facilitant la création, le suivi des programmes d'entraînement et la gestion des paiements." />
                <meta property="twitter:image" content="https://firebasestorage.googleapis.com/v0/b/repp-bf58c.appspot.com/o/Banner_twitter.png?alt=media&token=58bd519a-f0ed-407e-a5df-d0be83a03e94" />

                {/* Structured Data */}
                <script type="application/ld+json">
                    {`
            {
              "@context": "http://schema.org",
              "@type": "Organization",
              "url": "https://www.reppwebapp.fr/",
              "logo": "https://firebasestorage.googleapis.com/v0/b/repp-bf58c.appspot.com/o/Banner_twitter.png?alt=media&token=58bd519a-f0ed-407e-a5df-d0be83a03e94"
            }
        `}
                </script>
            </Helmet>
            <div className="LandingPageStylesMainContainerPayment">
                <LandingPageHeader />
                <main>
                    <section className="LandingPageStylesFirstSectionContainer">
                        <div className="LandingPageStylesTextContainerPayment2">
                            {/* Use h1 for the main title of the page, ensuring it's descriptive of the page's content */}
                            <h1 className='LandingPageStylesTitlePayment2'>
                                Vous avez des clients ?<br className="LandingPageGoToLine" />
                                <span className='LandingPageStylesTitlePayment2 LandingPageStylesTitleAddColorPayment'>Facilitez vos paiements.</span>
                            </h1>
                            {/* Use paragraph tag for descriptions for better semantics */}
                            <p className='LandingPageStylesDescription'>
                                Partagez votre profil. Soyez payés.
                            </p>
                            <div className="LandingPageButtonsContainerPayment2">
                                {/* Add aria-label if needed for more descriptive accessibility */}
                                <button onClick={() => navigateToDemoCreation()} className="VioletButton LandingPageStylesContainerButtonArrow LandingPageStylesAddHeight">
                                    Créer mon profil
                                    <img className='LandingPageStylesArrow' src={require('../../assets/Arrow-rightPayment.png')} alt="Commencez avec StreetLifting" />
                                </button>
                                <button onClick={navigateToExamples} className="BlueButton LandingPageStylesAddColorPayment LandingPageStylesAddHeight">
                                    Voir les coachs
                                    <img className='LandingPageStylesArrow' src={require('../../assets/Arrow-rightPayment.png')} alt="Commencez avec StreetLifting" />
                                </button>
                            </div>
                        </div>
                        <div className='LandingPaymentStylesIllustrationContainer'>
                            <img
                                src={IllustrationSrc}
                                alt="Coaching demonstration"
                                className="LandingPageStylesIllustration"
                            />
                        </div>
                    </section>
                    <div className='LandingPageStylesAddMaringPayment'>
                        <h3 className='LandingPageStylesContainerTextPayment'>Vous êtes pro, alors soyez payés comme un pro.</h3>
                        <section className="LandingPageStylesThirdSectionContainerPayment">
                            <article className='LandingPageStylesContainerIconAndTextDescriptionTitlePayment'>
                                <img src={require('../../assets/Features Overview-pana.png')} alt="Liberté d'esprit" className="LandingPageStylesFirstIconPayment2" />
                                <div className='LandingPageStylesContainerIconAndTextPayment'>
                                    <h3 className='LandingPageStylesContainerTextPayment2'>CONFIGUREZ VOTRE OFFRE SUR VOTRE PROFIL</h3>
                                </div>
                            </article>
                            <article className='LandingPageStylesContainerIconAndTextDescriptionTitlePayment'>
                                <img src={require('../../assets/Share link-pana.png')} alt="Liberté d'esprit" className="LandingPageStylesFirstIconPayment2" />
                                <div className='LandingPageStylesContainerIconAndTextPayment'>
                                    <h3 className='LandingPageStylesContainerTextPayment2'>ENVOYEZ LE LIEN À VOS CLIENTS</h3>
                                </div>
                            </article>
                            <article className='LandingPageStylesContainerIconAndTextDescriptionTitlePayment'>
                                <img src={require('../../assets/Revenue-pana.png')} alt="Liberté d'esprit" className="LandingPageStylesFirstIconPayment2" />
                                <div className='LandingPageStylesContainerIconAndTextPayment'>
                                    <h3 className='LandingPageStylesContainerTextPayment2'>RECEVEZ L'ARGENT SUR VOTRE COMPTE BANCAIRE</h3>
                                </div>
                            </article>
                        </section>
                    </div>
                    <section id="ils-utilisent-repp" className="LandingPaymentCoachProfileSlider">
                        <h2 className="LandingPageStylesTitleLittle LandingPaymentSectionTitle">Ils utilisent Repp</h2>
                        <div className="LandingPaymentSliderContainer">
                            <div className="LandingPaymentSlides">
                                {coachProfiles.map((src, index) => (
                                    <img key={index} src={require(`../../assets/${src}`)} alt={`Coach ${index + 1}`} className="LandingPaymentSlide" />
                                ))}
                                {/* Duplicate the content for a seamless loop */}
                                {coachProfiles.map((src, index) => (
                                    <img key={`duplicate-${index}`} src={require(`../../assets/${src}`)} alt={`Coach ${index + 1}`} className="LandingPaymentSlide" />
                                ))}
                            </div>
                        </div>
                    </section>
                    <section className="LandingPageStylesFourthSectionContainer LandingPageStylesAddMaringBottom">
                        <img
                            src={require('../../assets/Suivi2.png')}
                            alt="Coaching demonstration"
                            className="LandingPageStylesImageCoach"
                        />
                        <div className="LandingPageStylesTextContainer LandingPageStylesAddMarginLeft">
                            <h2 className='LandingPageStylesTitleLittle'>Des fonctionnalités pensées pour vous</h2>
                            <p className='LandingPageStylesDescription'>
                                Fixez un tarif, une période d'engagement, un abonnement mensuel... C'est vous le maître à bord !
                            </p>
                            <div className="LandingPageButtonsContainerFourthSection">
                                <button onClick={() => navigateToDemoCreation()} className="VioletButton LandingPageStylesAddWidthLastButton">
                                    Je mets en place mon offre !
                                    <img className="ProgramCreationStylesConfigureIcon" src={require('../../assets/goWorkout_Icon.png')} alt="Je passe" />
                                </button>
                            </div>
                        </div>
                    </section>
                    <section className="LandingPageStylesFirstSectionContainer">
                        <div className="LandingPageStylesTextContainer">
                            <h2 className='LandingPageStylesTitleLittle'>Gardez le contrôle</h2>
                            <p className='LandingPageStylesDescription'>
                                Visualisez en un clin d'oeil vos paiements reçus, et à venir. Gagnez en sérénité !
                            </p>
                            <div className="LandingPageButtonsContainerFourthSection">
                                <button onClick={() => navigateToDemoCreation()} className="VioletButton LandingPageStylesAddWidthLastButton">
                                    Je prends la main !
                                    <img className="ProgramCreationStylesConfigureIcon" src={require('../../assets/goWorkout_Icon.png')} alt="Inviter" />
                                </button>
                            </div>
                        </div>
                        <img src={imageSrc} alt="Des élèves participant à une séance de formation" className="LandingPageStylesImageCoach" />
                    </section>
                    <div className='LandingPageStylesPaymentTitlePricing'>
                        <h2 className="PaymentCardTitle">Payez une fois, utilisez à vie.</h2>
                    </div>
                    <section className="LandingPageStylesPaymentCardSection">
                        <div className="PaymentCard" onClick={() => navigateToCheckout()}>
                            <p className="PaymentPriceTextTitle">Formule Professionnelle</p>
                            <div className='PaymentPriceContainer'>
                                <p className="PaymentPrice">39€</p>
                                <p className="PaymentPriceOriginal">49€</p>
                            </div>
                            <p className="PaymentPriceTextDescriptionText">Idéal pour les indépendants</p>
                            <ul className="PaymentBenefits">
                                <div className='LandingPageStylesPaymentContainer'>
                                    <img src={require('../../assets/BenefitsPricing.png')} alt="Bénéfices de l'offre" className="LandingPageStylesPaymentBenefitsPricingIcon" />
                                    <span className='ProfilePageSearchBenefits PaymentPriceBenefits'>Paiement unique</span>
                                </div>
                                <div className='LandingPageStylesPaymentContainer'>
                                    <img src={require('../../assets/BenefitsPricing.png')} alt="Bénéfices de l'offre" className="LandingPageStylesPaymentBenefitsPricingIcon" />
                                    <span className='ProfilePageSearchBenefits PaymentPriceBenefits'>Image professionnelle</span>
                                </div>
                                <div className='LandingPageStylesPaymentContainer'>
                                    <img src={require('../../assets/BenefitsPricing.png')} alt="Bénéfices de l'offre" className="LandingPageStylesPaymentBenefitsPricingIcon" />
                                    <span className='ProfilePageSearchBenefits PaymentPriceBenefits'>Paiements automatisés</span>
                                </div>
                                <div className='LandingPageStylesPaymentContainer'>
                                    <img src={require('../../assets/BenefitsPricing.png')} alt="Bénéfices de l'offre" className="LandingPageStylesPaymentBenefitsPricingIcon" />
                                    <span className='ProfilePageSearchBenefits PaymentPriceBenefits'>Personnalisation totale de vos offres</span>
                                </div>
                            </ul>
                            <div className="LandingPageButtonsContainerFourthSection LandingPageStylesAddFullWidth">
                                <button onClick={() => navigateToDemoCreation()} className="VioletButton LandingPageStylesAddWidthLastButton">
                                    Je crée mon profil !
                                    <img className="ProgramCreationStylesConfigureIcon" src={require('../../assets/goWorkout_Icon.png')} alt="Inviter" />
                                </button>
                            </div>
                        </div>
                    </section>
                    <section className="LandingPageStylesFirstSectionContainerLastRow LandingPageStylesAddMaringBottom">
                        <img src={require('../../assets/Ok-bro.png')} alt="Des élèves participant à une séance de formation" className="LandingPageStylesImageCoachPayment2" />
                        <div className="LandingPageStylesTextContainerLastRow">
                            <h2 className='LandingPageStylesTitleLittle'>Vos clients vous disent merci</h2>
                            <p className='LandingPageStylesDescription'>
                                Pas besoin d'application ou de virement bancaire, il leur suffit d'un clic pour vous régler !
                            </p>
                            <div className="LandingPageButtonsContainerFourthSection">
                                <button onClick={() => navigateToDemoCreation()} className="VioletButton LandingPageStylesAddWidthLastButton">
                                    Je simplifie mes paiements !
                                    <img className="ProgramCreationStylesConfigureIcon" src={require('../../assets/goWorkout_Icon.png')} alt="Inviter" />
                                </button>
                            </div>
                        </div>
                    </section>
                    <section className="LandingPageStylesFifthSectionContainer LandingPageStylesAddMaringBottom">
                        <img src={require('../../assets/Men talking-pana.png')} alt="Coaching demonstration" className="LandingPageStylesImageCoachPayment3" />
                        <div className="LandingPageStylesTextContainer">
                            <div className='LandingPageStylesAddMaringPayment2'>
                                <img src={require('../../assets/Quotes.png')} alt="Liberté d'esprit" className="LandingPageStylesFirstIconPayment3" />
                                <h3 className='LandingPageStylesContainerTextPaymentQuote'>Je t'envoie mon Repp</h3>
                            </div>
                        </div>
                    </section>
                </main>
            </div>
            <footer className="LandingPageStylesFooterContainer">
                <div className="LandingFooter-section">
                    <img src={require('../../assets/repp_logo_white.png')} alt="Repp Company Logo" className="LandingPageStylesLogoFooter" />
                    <p style={{ marginTop: '20px' }}>© 2024 Repp Company.</p>
                </div>
                <nav className="LandingFooter-section">
                    <h5>Entreprise</h5>
                    <ul>
                        <li><button onClick={navigateToAboutUsPayment} className='LandingFooter-button'>À propos</button></li>
                        <li><button onClick={navigateToFAQPayment} className='LandingFooter-button'>FAQ</button></li>
                        <li><button onClick={openEmail} className='LandingFooter-button'>Nous contacter</button></li>
                    </ul>
                </nav>
                <nav className="LandingFooter-section">
                    <h5>Plateforme</h5>
                    <ul>
                        <li><button onClick={() => navigateToDemoCreation()} className='LandingFooter-button'>Créer un compte</button></li>
                    </ul>
                </nav>
            </footer>
        </>
    );
};

export default LandingPayment2;