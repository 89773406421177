// DateContext.tsx
import moment from 'moment';
import React, { createContext, useState, useContext, ReactNode } from 'react';

type DateContextType = {
  selectedDate: string;
  setSelectedDate: (date: string) => void;
};

const defaultState: DateContextType = {
  selectedDate: moment().format('YYYY-MM-DD'), // Default to today's date in 'YYYY-MM-DD' format
  setSelectedDate: () => {},
};

const DateContext = createContext<DateContextType>(defaultState);

export const useDate = () => useContext(DateContext);

type DateProviderProps = {
  children: ReactNode;
};

export const DateProvider: React.FC<DateProviderProps> = ({ children }) => {
  const [selectedDate, setSelectedDate] = useState<string>(moment().format('YYYY-MM-DD')); // Initialize with today's date

  return (
    <DateContext.Provider value={{ selectedDate, setSelectedDate }}>
      {children}
    </DateContext.Provider>
  );
};
