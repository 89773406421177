import React, { useState, useEffect, useRef } from 'react';
import Calendar from 'react-calendar';
import moment from 'moment';
import 'react-calendar/dist/Calendar.css'; 
import './CalendarComponentStyles.css';

function CalendarComponent({ onDaySelect, markedDates, selectedDate }) {
  const initialDate = selectedDate ? moment(selectedDate, 'YYYY-MM-DD').toDate() : new Date();
  const [currentDate, setCurrentDate] = useState(initialDate);

  useEffect(() => {
    // Set the locale for moment to French
    moment.locale('fr');
  
    // Update locale settings for 'fr' to set the first day of the week to Monday
    moment.updateLocale('fr', {
      week: {
        dow: 1, // Set Monday as the first day of the week
      },
    });
  }, []);
  

  const handleDayClick = (value) => {
    const formattedDate = {
      dateString: moment(value).format('YYYY-MM-DD'),
      day: value.getDate(),
      month: value.getMonth() + 1,
      year: value.getFullYear(),
    };
    setCurrentDate(value); // Update the internal state
    onDaySelect(formattedDate);
  };

  const tileClassName = ({ date, view }) => {
    // Only modify the style for month view
    if (view === 'month') {
      const formattedDate = moment(date).format('YYYY-MM-DD');

      // Add a specific class for the selected date
      if (formattedDate === selectedDate) {
        return 'selectedDate';
      }

      // Convert the date and selectedDate to moment objects
    const momentDate = moment(date);
    const momentSelectedDate = moment(selectedDate, 'YYYY-MM-DD');

    // Check if the date is in the same week as the selected date
    if (momentDate.isSame(momentSelectedDate, 'week')) {
      return 'dateInSelectedWeek';
    }

      const status = markedDates[formattedDate]?.status;
      switch (status) {
        case 'completed':
          return 'dateCompleted';
        case 'notCompleted':
          return 'dateNotCompleted';
        case 'failed':
          return 'dateFailed';
        case 'allFailed':
            return 'dateallFailed'; 
        default:
          return;
      }
    }
  };

  const tileContent = ({ date, view }) => {
    if (view === 'month') {
      const formattedDate = moment(date).format('YYYY-MM-DD');
      const status = markedDates[formattedDate]?.status;

      switch (status) {
        case 'completed':
          return <div className="dateCompleted"></div>;
        case 'notCompleted':
          return <div className="dateNotCompleted"></div>;
        case 'failed':
          return <div className="dateFailed"></div>;
        case 'allFailed':
            return <div className="dateallFailed"></div>;  
        default:
          return null;
      }
    }
  };
  
  return (
      <Calendar
        value={currentDate}
        onClickDay={handleDayClick}
        tileClassName={tileClassName}
        tileContent={tileContent}
        locale="fr-FR"
        next2Label={null} // Removes the double arrow for next year
        prev2Label={null} // Removes the double arrow for previous year  
      />      
  );
}

export default CalendarComponent;

