import React from 'react'
import '../screens/ChatPageStyles.css';
import { useContext } from 'react';
import { AuthContext } from './context/AuthContext.js';
import { auth } from '../firebase';
import defaultProfilePic from '../assets/default_profile_picture.png';


const NavBarChat = () => {

  const{currentUser} = useContext(AuthContext)
  console.log(currentUser);

  
  const defaultName = 'User';

  // Check if currentUser is not null before accessing its properties
  const profilePicUri = currentUser && currentUser.profilePicUri ? currentUser.profilePicUri : defaultProfilePic;
  const Name = currentUser ? currentUser.Name : defaultName;


    return (
      <div className ='NavBarChat'>
        <img className="logo" src={require('../assets/Rwhite.png')} alt="logo" />
        <div className = "Utilisateur">
            <img src={profilePicUri} alt="" />
            <span>{Name}</span>
    
        </div>

        </div>


    );
  };
  
  export default NavBarChat;