import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { firestore } from '../firebase.js';
import { collection, doc, getDocs, getDoc } from 'firebase/firestore';
import moment from 'moment';
import { Exercise } from '../types/types.ts';
import { useDate } from './DateContext.tsx';
import CalendarComponent from './CalendarComponent.tsx';
import '../screens/StudentScreens/ProgramCreationStyles.css';
import { useUserId } from '../types/utils.ts';

interface ProgramEditorProps {
  userIdProp?: string; // Make userIdProp optional
}

function formatDate(dateString: string) {
  if (typeof dateString !== 'string' || !dateString) {
    throw new Error("Invalid input: dateString is either not a string or empty");
  }

  const dateSeparator = dateString.includes('-') ? '-' : '/';
  const dateParts = dateString.split(dateSeparator);

  if (dateParts.length !== 3) {
    throw new Error("Date string does not have three parts");
  }

  try {
    const year = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1; // Months are 0-indexed in JavaScript Date
    const day = parseInt(dateParts[2], 10);

    if (isNaN(year) || isNaN(month) || isNaN(day)) {
      throw new Error("Error parsing date parts");
    }

    const dateObject = new Date(year, month, day);
    if (isNaN(dateObject.getTime())) {
      throw new Error("Invalid Date object created");
    }

    // Specify the exact string literals for weekday and month
    const optionsForDay: Intl.DateTimeFormatOptions = { weekday: 'long' as const };
    const dayName = new Intl.DateTimeFormat('fr-FR', optionsForDay).format(dateObject);

    const optionsForDate: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short' as const };
    const monthAndDay = new Intl.DateTimeFormat('fr-FR', optionsForDate).format(dateObject);

    return { dayName, monthAndDay };
  } catch (error) {
    return { dayName: "Date invalide", monthAndDay: "" };
  }
}

function ProgramEditor({ userIdProp }: ProgramEditorProps) {
  const history = useHistory();
  const { selectedDate, setSelectedDate } = useDate();
  let formattedDate = { dayName: "", monthAndDay: "" };
    if (selectedDate && typeof selectedDate === 'string') {
        formattedDate = formatDate(selectedDate);
    }
    const { dayName, monthAndDay } = formattedDate;
  const currentUserID = useUserId(); // Always call the hook at the top level
  const userId = userIdProp || currentUserID; // Determine the userId to use
  let initialSelectedDay;
    if (selectedDate) {
      // Assuming your selectedDate is in 'YYYY-MM-DD' format, parse it to match your calendar's expected object format
      const dateObject = moment(selectedDate, 'YYYY-MM-DD');
      initialSelectedDay = {
        dateString: selectedDate,
        day: dateObject.date(),
        month: dateObject.month() + 1, // moment's month is 0-indexed
        year: dateObject.year(),
      };
    } else {
      // If there is no selected date in the context, default to today's date
      initialSelectedDay = {
        dateString: moment().format('YYYY-MM-DD'),
        day: moment().date(),
        month: moment().month() + 1, // Add 1 because moment's months are 0-indexed
        year: moment().year(),
      };
    }
  const [savedDates, setSavedDates] = useState<{ [key: string]: { selected: true, selectedColor: string, marked: true, selectedTextColor: string, dotColor: string} }>({});
  const [areButtonsVisible, setAreButtonsVisible] = useState(false);
  const [exercises, setExercises] = useState<Exercise[]>([]);
  const [exercisesData, setExercisesData] = useState<{ [date: string]: Exercise[] }>({});
  const [fetchedDates, setFetchedDates] = useState<Set<string>>(new Set());

  const fetchWorkoutData = async (dateString: string, forceRefresh = false) => {
  if (!dateString || (!forceRefresh && fetchedDates.has(dateString))) return;
    const userId = userIdProp || currentUserID; // Get the user ID
    if (userId) {
      try {
        const workoutDayCollectionRef = collection(firestore, 'users', userId, 'workoutDays');
        const workoutDaysSnapshot = await getDocs(workoutDayCollectionRef);
        
        const fetchedExercisesData = {};
        workoutDaysSnapshot.forEach(doc => {
          fetchedExercisesData[doc.id] = doc.data().exercises;
        });

        setExercisesData(fetchedExercisesData);
        const getOverallSetStatus = (exercises: Exercise[]): string => {
          let totalSets = 0;
          let failedSets = 0;
          let completedSets = 0;
        
          exercises.forEach(exercise => {
            exercise.sets.forEach(set => {
              totalSets += 1;
              if (set.completed === "2") {
                failedSets += 1;
              } else if (set.completed === "1") {
                completedSets += 1;
              }
            });
          });
        
          if (failedSets === totalSets) {
            return "allFailed"; // All sets are failed
          } else if (completedSets === totalSets) {
            return "completed"; // All sets are completed
          } else if (failedSets > 0) {
            return "failed"; // At least one set has failed
          } else {
            return "other"; // Any other condition
          }
        };
        
        const fetchedSavedDates = Object.keys(fetchedExercisesData).reduce((acc, currentDateString) => {
          const overallStatus = getOverallSetStatus(fetchedExercisesData[currentDateString]);
          let status;
        
          switch (overallStatus) {
            case "completed":
              status = 'completed';
              break;
              case "allFailed":
              status = 'allFailed';
              break;
            case "failed":
              status = 'failed';
              break;
            default:
              status = 'notCompleted';
          }
        
          return {
            ...acc,
            [currentDateString]: { ...acc[currentDateString], status },
          };
        }, {});
    
        setSavedDates(fetchedSavedDates);
  
        const currentDayExercises = fetchedExercisesData[dateString];
        if (currentDayExercises && currentDayExercises.length > 0) {
          setExercises(currentDayExercises);
          setAreButtonsVisible(true);
        } else {
          setExercises([]);
          setAreButtonsVisible(false);
        }
        setFetchedDates(prevFetchedDates => new Set([...prevFetchedDates, dateString]));
      } catch (error) {
        console.error('Error fetching workout data:', error);
      }
    }
  };

  const markedDates = useMemo(() => {
    return {
      ...savedDates,
      [selectedDate || '']: {
        ...savedDates[selectedDate || ''],
        selected: true,
        selectedColor: 'black',
        selectedTextColor: 'yellow',
        dotColor: 'yellow',
      },
    };
  }, [selectedDate, savedDates]);

  const handleDaySelect = (day) => {
    setSelectedDate(day.dateString);
    const selectedDateExercises = exercisesData[day.dateString];
    if (selectedDateExercises && selectedDateExercises.length > 0) {
      setExercises(selectedDateExercises);
      setAreButtonsVisible(true);
    } else {
      setExercises([]);
      setAreButtonsVisible(false);
    }
  };

  useEffect(() => {
    if (!selectedDate) {
      setSelectedDate(moment().format('YYYY-MM-DD'));
    }
  }, [selectedDate, setSelectedDate]);

  useEffect(() => {
    const initiateDataFetch = async () => {
      // Fetch workout data if selectedDate is set
      if (selectedDate) {
        await fetchWorkoutData(selectedDate);
      }
    };
  
    initiateDataFetch();
  }, [selectedDate]);

  return (
    <>
      <div className="ProgramCreationStylesContainer">
        <div className="ProgramCreationStylesScrollContainer">
          <div className='ProgramCreationStylesCalendarContainer'>
            <CalendarComponent
                onDaySelect={handleDaySelect}
                markedDates={markedDates}
                selectedDate={selectedDate} // Pass this prop to CalendarComponent
              />
            </div>
          
          {selectedDate && (
            <div className="ProgramCreationStylesWorkoutContainer">
              <div className='ProgramCreationStylesInfoWorkoutContainer'>
              <div className="ProgramCreationStylesTitleContainer">
                <span className="ProgramCreationStylesTitle">{dayName} {monthAndDay}</span>
              </div>
                {exercises.length === 0 && (
                  <div className='ProgramCreationStylesGoToWorkoutEmptyButtonContainer'>
                    <button
                          className="ProgramCreationStylesGoToWorkoutButton"
                          onClick={() => {
                            if (selectedDate) {
                                history.push('/WeekWorkout', {
                                    date: selectedDate, // pass the selected date
                                    userId: userId, // pass the userId
                                });
                            }
                        }}                    
                        >
                        <div className='ProgramCreationStylesConfigureButtonContainer'>
                          <span className="ProgramCreationStylesGoToWorkoutButtonText">Programme de la semaine</span>
                          <img className="ProgramCreationStylesConfigureIconCleetRoue" src={require('../assets/RouePlanning2.png')} alt="Add"/>
                        </div>
                      </button>
                  </div>
                )}

                {areButtonsVisible && exercises.length > 0 && (    
                    <div className="ProgramCreationStylesHeaderContainer">
                      <div className="ProgramCreationStylesSetColumnContainer">
                          <span className="ProgramCreationStylesHeaderTextProgramCreationPage">Exo</span>
                      </div>
                        <div className="ProgramCreationStylesSetColumnContainer">
                            <span className="ProgramCreationStylesHeaderTextProgramCreationPage">Série</span>
                        </div>
                        <div className="ProgramCreationStylesFakeXContainer">
                            <span>x</span>
                        </div>
                        <div className="ProgramCreationStylesRepsColumnContainer">
                            <span className="ProgramCreationStylesHeaderTextProgramCreationPage">Reps</span>
                        </div>
                        <div className="ProgramCreationStylesWeightColumnContainer">
                            <span className="ProgramCreationStylesHeaderTextProgramCreationPage">Poids</span>
                        </div>
                        <div className="ProgramCreationStylesRPEColumnContainer">
                            <span className="ProgramCreationStylesHeaderTextProgramCreationPage">RPE</span>
                        </div>
                        <div className="ProgramCreationStylesDeleteButtonContainer">
                        </div>
                    </div>
                )}
    
                {exercises.map((exercise, exerciseIndex) => (
                  <div key={exerciseIndex} className="ProgramCreationStylesExerciseContainer">
                    {exercise.sets.map((set, setIndex) => {
                      const setCompleted = exercisesData[selectedDate || ''] 
                        ? String(exercisesData[selectedDate || ''][exerciseIndex]?.sets[setIndex]?.completed) || "0"
                        : "0";

                        let detailClass = ''; // Default class
                        if (set.completed === "1") {
                          detailClass = "setDetailValidated";
                        } else if (set.completed === "2") {
                          detailClass = "setDetailNotValidated";
                        } else {
                          detailClass = "setDetailNotAttempted";
                        }
                      return (
                        <div key={`${exercise.name}_set_${setIndex}`} className="ProgramCreationStylesSetContainer">
                          <div className="ProgramCreationStylesExerciseColumnContainer" lang="fr">
                            <span className='ProgramCreationStylesExerciseNameOfEachLine'>{exercise.name}</span>
                          </div>
                          <div className={`ProgramCreationStylesSetColumnContainer ${detailClass}`}>
                            <span className='ProgramCreationStylesNonEditModeInput'>{set.setNumber}</span>
                          </div>
                          <div className="ProgramCreationStylesXContainer">
                            <span>x</span>
                          </div>
                          <div className={`ProgramCreationStylesRepsColumnContainer ${detailClass}`}>
                          <span className='ProgramCreationStylesNonEditModeInput'>{set.reps}</span>
                          </div>
                          <div className={`ProgramCreationStylesWeightColumnContainer ${detailClass}`}>
                          <span className='ProgramCreationStylesNonEditModeInput'>{set.weight}</span>
                          </div>
                          <div className={`ProgramCreationStylesRPEColumnContainer ${detailClass}`}>
                          <span className='ProgramCreationStylesNonEditModeInput'>{set.rpe}</span>
                          </div>
                          <div className="ProgramCreationStylesDeleteButtonContainer">
                            {setCompleted === "1" ? (
                                <img className="ProgramCreationStylesCheckboxDisabledOrValidatedIcon" src={require('../assets/validated_icon.png')} alt="Validated"/>
                              ) : setCompleted === "2" ? (
                                <img className="ProgramCreationStylesCheckboxDisabledOrValidatedIcon" src={require('../assets/fail_icon.png')} alt="Failed"/>
                              ) : (
                                <img className="ProgramCreationStylesCheckboxDisabledOrValidatedIcon" src={require('../assets/checkbox_disabled.png')} alt="Not Attempted"/>
                              )
                            }
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
              {areButtonsVisible && (
            <div className="ProgramCreationStylesGoToWorkoutButtonContainer">
                {exercises.length > 0 && (
                        <button
                            className="ProgramCreationStylesGoToWorkoutButton"
                            onClick={() => {
                                if (selectedDate) {
                                    history.push('/Workout', {
                                        exercises: exercisesData[selectedDate],
                                        date: selectedDate, // pass the selected date
                                        userId: userId,
                                        dayName: dayName,
                                        monthAndDay: monthAndDay,
                                    });
                                }
                            }}
                        >
                          <div className='ProgramCreationStylesConfigureButtonContainer'>
                            <span className="ProgramCreationStylesGoToWorkoutButtonText">Voir cette séance</span>
                            <img className="ProgramCreationStylesConfigureIconArrow" src={require('../assets/ArrowPlanning2.png')} alt="Add"/>
                          </div>          
                        </button>
                )}
                    <button
                        className="ProgramCreationStylesGoToWorkoutButton"
                        onClick={() => {
                          if (selectedDate) {
                              history.push('/WeekWorkout', {
                                  date: selectedDate, // pass the selected date
                                  userId: userId, // pass the userId
                              });
                          }
                      }}                    
                      >        
                      <div className='ProgramCreationStylesConfigureButtonContainer'>
                        <span className="ProgramCreationStylesGoToWorkoutButtonText">Configurer le programme</span>
                        <img className="ProgramCreationStylesConfigureIconVis" src={require('../assets/CléMolette2.png')} alt="Add"/>
                      </div>
                    </button>
                </div>
        )}
            </div>
          )}
  
          
        </div>
      </div>
    </>
  );
}
  export default ProgramEditor;