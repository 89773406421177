import React, { useState, useContext } from 'react';
import { UserContext } from './UserContext.tsx';
import './EditProfileModalStyles.css';

const EditProfileModal = ({ isVisible, onCancel, onSave, initialData }) => {
  const { userRole } = useContext(UserContext); // Use the context
  const [name, setName] = useState(initialData.Name);
  const [title, setTitle] = useState(initialData.title);
  const [description, setDescription] = useState(initialData.description);
  const [subscriptionButton, setSubscriptionButton] = useState(initialData.subscription_button);
  const [coachingFee, setCoachingFee] = useState(initialData.coachingFee || ''); // New state for coaching 
  const [questionnaireUrl, setQuestionnaireUrl] = useState(initialData.questionnaireUrl || '');
  const modalClass = `EditProfileModal ${isVisible ? 'visible' : ''}`;
  const [engagementPeriod, setEngagementPeriod] = useState(initialData.engagementPeriod);

  const handleSave = () => {
    onSave({
      Name: name.toLowerCase(), // Convert name to lowercase before saving
      title: title, // Pass the updated title
      description: description, // Pass the updated description
      subscription_button: subscriptionButton, // Include the toggle state
      coachingFee: coachingFee, // Include coaching fee only if subscription is enabled
      questionnaireUrl: questionnaireUrl, // Include the URL
      engagementPeriod: engagementPeriod || null, // Include the engagement period, set to null if not selected
    });
  };

  const handlePeriodChange = (event) => {
    setEngagementPeriod(event.target.value);
  };

  if (!isVisible) return null;

  return (
    <div className={modalClass}>
      <div className='EditProfileModalContentContainer'>
        <div className="EditProfileModalHeader">
          <button onClick={onCancel}>Annuler</button>
          <h1>Modifier le profil</h1>
          <button
            className='BlueButton EditProfileModalAddWidth'
            onClick={handleSave}
          >
            <span className='EditProfileButtonText'>
              <img className="EditProfileSaveIcon" src={require('../assets/Sauvegarder_icon.png')} alt="Menu" />
            </span>
          </button>
        </div>
        <div className="EditProfileModalContent">
          <label htmlFor="name">Nom</label>
          <input className='input' id="name" type="text" value={name} onChange={(e) => setName(e.target.value)} />

          <label htmlFor="title">Titre</label>
          <input className='input' id="title" type="text" value={title} onChange={(e) => setTitle(e.target.value)} />

          <label htmlFor="description">Bio</label>
          <textarea className='input' id="description" value={description} onChange={(e) => setDescription(e.target.value)} />
          {userRole === 'coach' && (
            <div className="">
              <label htmlFor="url">Questionnaire d'inscription (URL complet)</label>
              <input
                className='input'
                id="questionnaireUrl"
                type="text"
                placeholder="Entrez l'URL"
                value={questionnaireUrl}
                onChange={(e) => setQuestionnaireUrl(e.target.value)}
              />
            </div>
          )}
        </div>
        {userRole === 'coach' && (
          <div>
            <div className="EditProfileModalToggleContainer">
              <div className='EditProfileModalToggleLine'>
                <label htmlFor="subscriptionToggle">Bouton abonnement</label>
                <input
                  type="checkbox"
                  id="subscriptionToggle"
                  className="EditProfileModalToggleSwitch"
                  checked={subscriptionButton}
                  onChange={(e) => setSubscriptionButton(e.target.checked)}
                />
              </div>
              <div className='EditProfileModalDescriptionToggleLine'>
                <span className="EditProfileModalToggleText">Cette option permet aux utilisateurs de s'abonner à vous directement depuis votre profil.</span>
              </div>
            </div>
            <div className="EditProfileModalInputContainer">
              <label htmlFor="coachingFee">Montant du coaching /mois</label>
              <div className='EditProfileModalInputAndPeriod'>
                <div className="inputWrapper">
                  <input
                    className="input"
                    type="number"
                    step="any" // Allows decimal values
                    placeholder="Entrez le montant"
                    value={coachingFee}
                    onChange={(e) => setCoachingFee(e.target.value)}
                    min="0" // Prevents negative numbers
                  />
                  <span className="currencySymbol">€</span>
                </div>
                <div className='EditProfileModalPeriodContainer'>
                  <span className='EditProfileModalPeriod'>Période d'engagement :</span>
                  <select value={engagementPeriod} onChange={handlePeriodChange} className='EngagementPeriodSelector'>
                    <option value="">Aucun engagement</option>
                    <option value="2">2 mois</option>
                    <option value="3">3 mois</option>
                    <option value="4">4 mois</option>
                    <option value="5">5 mois</option>
                    <option value="6">6 mois</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EditProfileModal;
