import React, { createContext, useContext, useState, ReactNode, Dispatch, SetStateAction } from 'react';

interface BlockConfigContextType {
  showBlockConfig: boolean;
  setShowBlockConfig: Dispatch<SetStateAction<boolean>>; // Correct typing for setState function
}

const initialContextValue: BlockConfigContextType = {
  showBlockConfig: false,
  setShowBlockConfig: () => {}, // Keep as is; TypeScript knows this is a placeholder
};

const BlockConfigContext = createContext<BlockConfigContextType>(initialContextValue);

export const useBlockConfig = () => useContext(BlockConfigContext);

interface BlockConfigProviderProps {
  children: ReactNode;
}

export const BlockConfigProvider = ({ children }: BlockConfigProviderProps) => {
  const [showBlockConfig, setShowBlockConfig] = useState<boolean>(false);

  return (
    <BlockConfigContext.Provider value={{ showBlockConfig, setShowBlockConfig }}>
      {children}
    </BlockConfigContext.Provider>
  );
};
