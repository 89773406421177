import { useHistory } from 'react-router-dom';
import { logEvent, getAnalytics } from 'firebase/analytics'; // Assuming this is how you import logEvent

// Custom hook for navigation
function PaymentNavigationLanding() {
    const history = useHistory();
    const analytics = getAnalytics(); // Initialize Firebase Analytics

    const navigate = (path: string, event?: string, eventParams?: Record<string, any>) => {
        if (event) {
            logEvent(analytics, event);
        }
        history.push(path);
    };

    const openEmail = () => {
        logEvent(analytics, 'send_email_clicked'); // Log the event
        const email = 'contactinfo.repp@gmail.com';
        window.location.href = `mailto:${email}`;
    };

    const navigateToLogin = (buttonLabel) => {
        // Log the event with additional parameters
        navigate('/paymentlogin', 'payment_create_account_clicked', { button_label: buttonLabel });
    };

    const navigateToCheckout = () => {
        // // Try to get the price from sessionStorage
        // let price = sessionStorage.getItem('checkoutPrice');
    
        // // If there's no price already stored, decide one randomly
        // if (!price) {
        //     const isPrice39 = Math.random() < 0.5; // 50% chance
        //     price = isPrice39 ? '39' : '29'; // Determine the price
        //     sessionStorage.setItem('checkoutPrice', price); // Store the price in sessionStorage
        // }

        const price = '39';
        sessionStorage.setItem('checkoutPrice', price); // Store the price in sessionStorage
    
        logEvent(analytics, 'checkout_visit', { price: parseInt(price, 10) }); // Log event with price
        navigate(`/checkout?price=${price}`, 'checkout_visit'); // Navigate with price query param
    };  
    
    const navigateToDemoCreation = () => {
        // Log the event with additional parameters
        navigate('/creationCompte', 'payment_demo_creation_account');
    };

    return {
        navigateToFAQPayment: () => navigate('/FAQPayment'),
        navigateToAboutUsPayment: () => navigate('/AboutUsPayment'),
        navigateToCheckout,
        navigateToPres: () => navigate('/PresentationPayment'),
        navigateToPres2: () => navigate('/ModulePaiement'),
        openEmail, // Make openEmail available
        navigateToLogin,
        navigateToDemoCreation
    };
}

export default PaymentNavigationLanding;