import React, { useEffect, useRef } from 'react';
import { auth } from '../../firebase.js';
import { useHistory } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import '../../components/ParametresModalStyles.css';

const PaymentParametersModal = ({ visible, setVisible, coachName, isStripeProcessing }) => {
    const history = useHistory();
    const modalRef = useRef<HTMLDivElement>(null);

    const openEmail = () => {
        const email = 'contactinfo.repp@gmail.com';
        window.location.href = `mailto:${email}`;
    };

    const handleLogOutAndCloseModal = async () => {
        try {
            await signOut(auth);
            setVisible(false); // Close the modal after logging out
            history.push('/ModulePaiement');
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
                setVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [setVisible]);

    useEffect(() => {
        document.body.style.overflow = visible ? 'hidden' : 'auto';
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [visible]);

    if (!visible) return null;

    return (
        <div className="ParametersPaymentModal">
            <div className="safeArea" ref={modalRef}>
                <button onClick={openEmail} className="optionContainer">
                    <span>Contactez-nous</span>
                </button>
                <button onClick={handleLogOutAndCloseModal} className="lastOptionContainer">
                    <span>Déconnexion</span>
                </button>
            </div>
        </div>
    );
};

export default PaymentParametersModal;
