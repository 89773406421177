import React, { useEffect, useState } from 'react';
import './FAQStyles.css'; // Make sure to import the CSS file
import { useHistory } from 'react-router-dom';
import { getAnalytics, logEvent } from 'firebase/analytics'; // Import Firebase Analytics functions

const FAQ: React.FC = () => {
  // Explicitly type the openFAQs state as an array of numbers
  const [openFAQs, setOpenFAQs] = useState<{ section: number; question: number }[]>([]);
  const history = useHistory();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const analytics = getAnalytics(); // Initialize Firebase Analytics

  const sections = [
    {
      title: 'Paiements et Facturation',
      faqs: [
        {
          question: 'Comment et à quelle fréquence suis-je payé ?',
          answer: 'Les paiements sont effectués directement sur votre compte bancaire tous les mois au moment de la facturation de vos élèves. La plateforme s\'occupe de tout le processus de facturation et de paiement pour vous.'
        },
        {
          question: 'Je n\'ai plus besoin de relancer mes élèves pour les paiements ?',
          answer: 'Absolument. La plateforme automatise les abonnements et les paiements. Les élèves s\'abonnent via votre profil, et les frais sont prélevés automatiquement, avec les fonds transférés directement sur votre compte chaque mois.'
        },
        {
          question: 'Puis-je configurer une période d\'engagement minimum ?',
          answer: 'Oui, vous avez la possibilité de définir une durée d\'engagement minimale pour vos abonnements, jusqu\'à 6 mois, directement depuis votre profil.'
        },
      ],
    },
    {
      title: 'Intégration et Transition',
      faqs: [
        {
          question: 'Les fonctionnalités Excel que j\'utilise sont-elles disponibles sur la plateforme ?',
          answer: 'Absolument, la plateforme intègre toutes les fonctionnalités que vous exploitez habituellement sur Excel avec vos élèves, y compris le copier-coller, la création de blocs, l\'analyse statistique, et plus encore. Conçue avec l\'expertise des coachs en ligne, notre plateforme vise à optimiser et faciliter votre gestion, rendant le suivi de vos élèves plus efficace et intuitif.'
        },
        {
          question: 'Comment transférer tous les programmes excels actuels de mes élèves ?',
          answer: 'Pour une transition en quelques minutes, utilisez notre fonctionnalité d\'importation Excel. Téléchargez notre modèle, remplissez-le avec les informations de vos élèves, et importez-le sur la plateforme. Les programmes de tous vos élèves seront alors automatiquements créés.'
        },
        {
          question: 'Comment puis-je inviter mes élèves sur la plateforme ?',
          answer: 'Pour inviter vos élèves, rendez-vous dans la section "Gérer mes abonnements" sur votre profil. De là, vous pouvez envoyer une invitation par e-mail à vos élèves pour qu\'ils rejoignent la plateforme.'
        },
        {
          question: 'Comment cela se passe-t-il pour mes élèves dont l\'abonnement est déjà en cours ?',
          answer: 'Il est possible de spécifier une date de début de paiement pour chacun de vos élèves. Cette date détermine le commencement du prochain cycle de paiement, assurant ainsi une transition en douceur. Jusqu\'à cette date, l\'élève bénéficie de l\'accès complet aux fonctionnalités, comme s\'il était pleinement abonné.'
        },
      ],
    },
    {
      title: 'Processus d\'Inscription',
      faqs: [
        {
          question: 'Quel est le processus d\'inscription pour les élèves ?',
          answer: 'Après avoir reçu une invitation par e-mail, les élèves peuvent créer leur compte sur la plateforme et accéder à leur onglet Planning. C\'est ici qu\'ils retrouveront les programmes que vous avez préparés pour eux et pourront les compléter au fil de la semaine.'
        },
        {
          question: 'Quel est le processus d\'inscription pour les coachs ?',
          answer: (
            <span>
              Suivez <a className='linkFAQ' href="https://reppwebapp.fr" target="_blank" rel="noopener noreferrer">ce lien</a> pour créer votre compte sur notre plateforme. En moins de 5 minutes, vous pourrez recevoir des paiements sécurisés via notre partenaire Stripe, et inviter vos élèves pour élaborer leurs programmes.
            </span>
          )
        },
      ],
    },
    {
      title: 'Coût et Programme d\'affiliation',
      faqs: [
        {
          question: 'Quel est le coût d\'utilisation de la plateforme ?',
          answer: (
            <span>
              Avec Repp, vous ne payez que pour ce que vous utilisez. L'accès à la plateforme est entièrement gratuit pour vos élèves, tandis qu'une commission de 10% est prélevée sur les abonnements. De plus, notre programme d'affiliation vous permet de gagner 5% À VIE sur tous les revenus des coachs que vous parrainez. Utilisez notre <a className='linkFAQ' href="https://reppwebapp.fr/CalculAffiliation" target="_blank" rel="noopener noreferrer">calculateur de revenus</a> pour estimer vos revenus passifs.
            </span>
          )
        },
        {
          question: 'Comment fonctionne le programme d\'affiliation ?',
          answer: (
            <span>
              Lorsqu'un nouveau coach rejoint la plateforme et sélectionne un parrain parmi les coachs déjà inscrits, la commission standard de 10% prélevée par la plateforme est répartie, vous attribuant ainsi 5% des revenus générés par ce coach. Cela constitue un excellent moyen de valoriser votre réseau et de bénéficier d'un revenu passif À VIE sans effort supplémentaire. Utilisez notre <a className='linkFAQ' href="https://reppwebapp.fr/CalculAffiliation" target="_blank" rel="noopener noreferrer">calculateur de revenus</a> pour estimer ce que cela peut vous apporter.
            </span>
          )
        },
      ],
    },
    // Add more sections as needed
  ];

  const toggleFAQ = (sectionIndex: number, questionIndex: number) => {
    const isOpen = openFAQs.some(openIndex => openIndex.section === sectionIndex && openIndex.question === questionIndex);
    if (isOpen) {
      setOpenFAQs(openFAQs.filter(openIndex => openIndex.section !== sectionIndex || openIndex.question !== questionIndex));
    } else {
      setOpenFAQs([...openFAQs, { section: sectionIndex, question: questionIndex }]);
    }
  };

  useEffect(() => {
    logEvent(analytics, 'faq_visit');
  }, []);

  const navigateToFAQ = () => {
    history.push('/FAQ');
  };

  const navigateToAboutUs = () => {
    history.push('/AboutUs');
  };

  const navigateToLogin = (buttonLabel) => {
    logEvent(analytics, 'create_account_clicked', { button_label: buttonLabel });
    history.push('/login');
  };

  const navigateToPres = () => {
    history.push('/');
  };

  const openEmail = () => {
    logEvent(analytics, 'send_email_clicked');
    const email = 'contactinfo.repp@gmail.com';
    window.location.href = `mailto:${email}`;
  };

  return (
    <>
      <div className="LandingPageStylesMainContainerOtherPages">
        <header className="LandingPageStylesHeaderContainer">
          <div onClick={navigateToPres}>
            <img src={require('../assets/ReReReDesign.png')} alt="Logo" className="LandingPageStylesLogo" />
          </div>
          <img src={require('../assets/hamburger_menu.png')} alt="Menu" className="HamburgerMenuIcon" onClick={toggleMenu} />
          <div className={`MenuOverlay ${isMenuOpen ? 'Active' : ''}`} onClick={toggleMenu}></div>
          <nav className={`LandingPageStylesNavigationContainer ${isMenuOpen ? 'Active' : ''}`}>
            <img src={require('../assets/cross_modal.png')} alt="Close" className="CloseMenuIcon" onClick={toggleMenu} />
            <div
              className='LandingPageStylesContainerMenuMobileTextButton'
              onClick={navigateToFAQ}
              style={{ cursor: 'pointer' }} // Add cursor pointer style to indicate it's clickable
            >
              <span className='LandingPageStylesButtonNavigation'>FAQ</span>
              <img className='LandingPageStylesMenuMobileIcon' src={require('../assets/next_icon_gris.png')} alt="Next" />
            </div>
            <div
              className='LandingPageStylesContainerMenuMobileTextButton'
              onClick={navigateToAboutUs}
              style={{ cursor: 'pointer' }}
            >
              <span className='LandingPageStylesButtonNavigation'>Qui sommes-nous ?</span>
              <img className='LandingPageStylesMenuMobileIcon' src={require('../assets/next_icon_gris.png')} alt="Next" />
            </div>
            <div
              className='LandingPageStylesContainerMenuMobileTextButton'
              onClick={openEmail}
              style={{ cursor: 'pointer' }}
            >
              <span className='LandingPageStylesButtonNavigation'>Nous contacter</span>
              <img className='LandingPageStylesMenuMobileIcon' src={require('../assets/next_icon_gris.png')} alt="Next" />
            </div>
            <button onClick={() => navigateToLogin('Créer mon compte')} className="BlueButton LandingPageStylesAddWidth LandingPageStylesAddMargingTop">Créer mon compte</button>
            <div className="LandingPageFooterMessage">
              <span>Fait avec ❤️ par l'équipe Repp</span>
            </div>
          </nav>
        </header>
        <div className='MainContainerFAQ'>
          <div className='ContainerBothSidesFAQ'>
            <div className='ContainerLeftFAQ'>
              <span className='SmallTitleFAQ'>Support</span>
              <span className='BigTitleFAQ'>FAQs</span>
              <span className='SmallDescriptionFAQ'>
                Vous ne trouvez pas la réponse à votre question ? Contactez-nous
                <span style={{ cursor: 'pointer', textDecoration: 'underline', marginLeft: '3px' }} onClick={openEmail}>ici</span>.
              </span>
            </div>
            <div className="faq-container">
              {sections.map((section, sectionIndex) => (
                <div key={sectionIndex}>
                  <div className='TitleFAQTest'>
                    <span className="faq-Title">{section.title}</span>
                  </div>
                  {section.faqs.map((faq, faqIndex) => (
                    <div key={faqIndex} className={`faq-item ${openFAQs.some(openIndex => openIndex.section === sectionIndex && openIndex.question === faqIndex) ? 'open' : ''}`}>
                      <div className="faq-question" onClick={() => toggleFAQ(sectionIndex, faqIndex)}>
                        {faq.question}
                        <span className="arrow">▼</span>
                      </div>
                      {openFAQs.some(openIndex => openIndex.section === sectionIndex && openIndex.question === faqIndex) && (
                        <div className="faq-answer">
                          {typeof faq.answer === 'string' ? faq.answer : faq.answer}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <footer className="LandingPageStylesFooterContainer">
        <div className="LandingFooter-section">
          <img src={require('../assets/repp_logo_white.png')} alt="Logo" className="LandingPageStylesLogoFooter" />
          <span style={{ marginTop: '20px' }}>© 2024 Repp Company.</span>
        </div>
        <div className="LandingFooter-section">
          <h5>Entreprise</h5>
          <button onClick={navigateToAboutUs} className='LandingFooter-button'>À propos</button>
          <button onClick={navigateToFAQ} className='LandingFooter-button'>FAQ</button>
          <button onClick={openEmail} className='LandingFooter-button'>Nous contacter</button>
        </div>
        <div className="LandingFooter-section">
          <h5>Plateforme</h5>
          <button onClick={() => navigateToLogin('Créer mon compte footer')} className='LandingFooter-button'>Créer un compte</button>
          <button onClick={() => navigateToLogin('Inviter mes élèves footer')} className='LandingFooter-button'>Inviter vos élèves</button>
        </div>
      </footer>
    </>
  );
};

export default FAQ;
