import React, { ReactElement, useState, useEffect } from 'react';
import { firestore, auth } from '../../firebase.js';
import { doc, getDoc } from 'firebase/firestore';
import LottieCheckbox from '../../components/LottieCheckBox.tsx';
import LottieCross from '../../components/LottieCross.tsx';
import { useHistory, useLocation } from 'react-router-dom';
import { ExerciseSet, CompletedSetStatus, CheckboxState } from '../../types/types.ts';
import '../StudentScreens/WorkoutStyles.css';
import '../StudentScreens/ProgramCreationStyles.css';
import BackButton from '../../components/BackButton.tsx';


// Assuming CompletedSetStatus is defined elsewhere in your project
let completedMainSets: CompletedSetStatus[] = [];

// Function to format the date
function formatDate(dateString: string) {
  if (typeof dateString !== 'string' || !dateString) {
    throw new Error("Invalid input: dateString is either not a string or empty");
  }

  const dateSeparator = dateString.includes('-') ? '-' : '/';
  const dateParts = dateString.split(dateSeparator);

  if (dateParts.length !== 3) {
    throw new Error("Date string does not have three parts");
  }

  try {
    const year = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1; // Months are 0-indexed in JavaScript Date
    const day = parseInt(dateParts[2], 10);

    if (isNaN(year) || isNaN(month) || isNaN(day)) {
      throw new Error("Error parsing date parts");
    }

    const dateObject = new Date(year, month, day);
    if (isNaN(dateObject.getTime())) {
      throw new Error("Invalid Date object created");
    }

    // Specify the exact string literals for weekday and month
    const optionsForDay: Intl.DateTimeFormatOptions = { weekday: 'long' as const };
    const dayName = new Intl.DateTimeFormat('fr-FR', optionsForDay).format(dateObject);

    const optionsForDate: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short' as const };
    const monthAndDay = new Intl.DateTimeFormat('fr-FR', optionsForDate).format(dateObject);

    return { dayName, monthAndDay };
  } catch (error) {
    return { dayName: "Date invalide", monthAndDay: "" };
  }
}

const WorkoutSubscribe = () => {
  const location = useLocation();
  const { exercises, date, creatorId } = location.state || { exercises: [], date: null, creatorId: null };
  const { dayName, monthAndDay } = formatDate(date);
  const [comment, setComment] = useState('');
  const [checkboxState, setCheckboxState] = React.useState<CheckboxState>({} as CheckboxState);  // Create state to track checkboxes
  const history = useHistory();

  
  useEffect(() => {
    const fetchCheckboxState = async () => {
      try {
        const docRef = doc(firestore, 'users', creatorId, 'workoutDays', date);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          if (data && data.exercises) {
            const fetchedCheckboxState = {};

            data.exercises.forEach((exercise, exerciseIndex) => {
              exercise.sets.forEach((set, setIndex) => {
                fetchedCheckboxState[`${exerciseIndex}-${setIndex}`] = set.completed;

                if (set.detailedSets) {
                  set.detailedSets.forEach((detailedSet, detailedSetIndex) => {
                    fetchedCheckboxState[`${exerciseIndex}-${setIndex}-${detailedSetIndex + 1}`] = detailedSet.completed;
                  });
                }
              });
            });

            setCheckboxState(fetchedCheckboxState);
            const commentFromFirestore = data.comment || '';
            setComment(commentFromFirestore);
          }
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error fetching data from Firestore:', error);
      }
    };

    fetchCheckboxState();
  }, [creatorId, date]);

  const renderDetailedSets = (
    set: ExerciseSet, 
    setNumber: number, 
    exerciseIndex: number, 
    setIndex: number
  ) => {
    let detailedSets: ReactElement[] = [];
    for (let i = 1; i <= setNumber; i++) {
      const key = `${exerciseIndex}-${setIndex}-${i}`;
  
      detailedSets.push(
        <div key={i} className="WorkoutStylesRowContainer">
          <div className="WorkoutStylesSetColumnContainer">
            <div className="WorkoutStylesInputContainer">
              <span className="ProgramCreationStylesInputSaved WorkoutStylesDetailedSet">1</span>
            </div>
          </div>
          <div className="WorkoutStylesXContainer">
            <span className="WorkoutStylesDetailedSet">x</span>
          </div>
          <div className="WorkoutStylesRepsColumnContainer">
            <div className="WorkoutStylesInputContainer">
              <span className="ProgramCreationStylesInputSaved WorkoutStylesDetailedSet">{set.reps}</span>
            </div>
          </div>
          <div className="WorkoutStylesWeightColumnContainer">
            <div className="WorkoutStylesInputContainer">
              <span className="ProgramCreationStylesInputSavedWeight WorkoutStylesDetailedSet">{set.weight}</span>
            </div>
          </div>
          <div className="WorkoutStylesRPEColumnContainerWorkout">
            <div className="WorkoutStylesDisplayRPE">
              <span className="ProgramCreationStylesInputSaved WorkoutStylesDetailedSet">{set.rpe}</span>
            </div>
          </div>
          <button 
              className="WorkoutStylesCheckboxContainer"
              disabled={true}
          >
            <LottieCheckbox 
              isChecked={checkboxState[key] === "1"} 
            />
          </button>
          <button 
              className="WorkoutStylesCheckboxContainer"
              disabled={true}
          >
            <LottieCross 
              isChecked={checkboxState[key] === "2"}
            />
          </button>
        </div>
      );
    }
    return detailedSets;
  };

  const renderSetsWithDetails = (set, exerciseIndex, setIndex) => {
    if (typeof set.setNumber === 'number') {
      return renderDetailedSets(set, set.setNumber, exerciseIndex, setIndex); // pass setIndex here
    }
    return null;
  };

  return (
    <div className="ProgramCreationStylesScrollContainer">
        <BackButton 
        to={`/ProgramCreationSubscribe/${creatorId}`} 
        selectedDate={date} 
        creatorId={creatorId} 
      />
      <div className="WorkoutStylesDateContainer">
        <span className="WorkoutStylesDateDayName">{dayName}</span>
        <span className="WorkoutStylesDateMonthName">{monthAndDay}</span>
      </div>
      <div className="ProgramCreationStylesWorkoutContainer">
        <div className="ProgramCreationStylesTitleContainer">
          <span className="ProgramCreationStylesTitle">Séance</span>
        </div>
        {exercises.map((exercise, exerciseIndex) => (
          <div key={exerciseIndex} className="WorkoutStylesExerciseContainerWorkout">
          <div className="ProgramCreationStylesExerciseNameContainer">
            <span className="ProgramCreationStylesExerciseName">{exercise.name}</span>
          </div>
          <div className="WorkoutStylesHeaderContainerWorkout">
            <div className="WorkoutStylesSetColumnContainer">
              <div className="WorkoutStylesHeaderSetContainer">
                <span className="ProgramCreationStylesHeaderText">Série</span>
              </div>
            </div>
            <div className="WorkoutStylesFakeXContainer">
              <span>x</span>
            </div>
            <div className="WorkoutStylesRepsColumnContainer">
              <div className="WorkoutStylesHeaderSetContainer">
                <span className="ProgramCreationStylesHeaderText">Reps</span>
              </div>
            </div>
            <div className="WorkoutStylesWeightColumnContainer">
              <div className="WorkoutStylesHeaderSetContainer">
                <span className="ProgramCreationStylesHeaderText">Poids</span>
              </div>
            </div>
            <div className="WorkoutStylesRPEColumnContainerWorkout">
              <div className="WorkoutStylesHeaderSetContainer">
                <span className="ProgramCreationStylesHeaderText">RPE</span>
              </div>
            </div>
            <div className="WorkoutStylesFakeLottieContainer">
              <span className="WorkoutStylesFakeLottie">État</span>
            </div>
          </div>
          {exercise.sets.map((set, setIndex) => (
            <div key={setIndex} className="WorkoutStylesWorkoutSetContainer">
              <div className="WorkoutStylesRowContainerMainSet">
                <div className="WorkoutStylesSetColumnContainer">
                  <div className="WorkoutStylesMainSetContainer">
                    <span className="WorkoutStylesMainSet">{set.setNumber}</span>
                  </div>
                </div>
                <div className="WorkoutStylesMainSetContainer">
                  <div className="ProgramCreationStylesXContainer">
                    <span>x</span>
                  </div>
                </div>
                <div className="WorkoutStylesRepsColumnContainer">
                  <div className="WorkoutStylesMainSetContainer">
                    <span className="WorkoutStylesMainSet">{set.reps}</span>
                  </div>
                </div>
                <div className="WorkoutStylesWeightColumnContainer">
                  <div className="WorkoutStylesMainSetContainer">
                    <span className="WorkoutStylesMainSet">{set.weight}</span>
                  </div>
                </div>
                <div className="WorkoutStylesRPEColumnContainerWorkout">
                  <div className="WorkoutStylesMainSetContainer">
                      <span className="WorkoutStylesMainSet">{set.rpe}</span>
                  </div>
                </div>
                <LottieCheckbox 
                  isChecked={checkboxState[`${exerciseIndex}-${setIndex}`] === "1"}
                />
                <LottieCross 
                  isChecked={checkboxState[`${exerciseIndex}-${setIndex}`] === "2"}
                />
              </div>
              {renderSetsWithDetails(set, exerciseIndex, setIndex)}
            </div>
          ))}
        </div>        
        ))}
      </div>
      <div className="WorkoutStylesTitleComponents">
        <span className="ProgramCreationStylesTitle">Notes</span>
      </div>
      <textarea
        className="WorkoutStylesCommentInput"
        rows={4}
        placeholder="Commentaires par rapport à cette session..."
        value={comment}
      />
    </div>
  );
};

export default WorkoutSubscribe;
  