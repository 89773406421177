import React, { useContext, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import './Footer.css';
import { UserContext } from './UserContext.tsx';

type FooterProps = {
    setActiveIcon: React.Dispatch<React.SetStateAction<'personne'| 'eleves'| null>>;
    activeIcon: 'personne' | 'eleves' | null;
};

const FooterPayment: React.FC<FooterProps> = ({ setActiveIcon, activeIcon }) => {
    const location = useLocation();
    const { userRole } = useContext(UserContext);

    const history = useHistory(); // Initialize history object

    // Functions to determine the link based on the user's role

    const getProfileLink = () => '/PaymentProfile';
    const getElevesLink = () => '/PaymentManageStudents';

    useEffect(() => {
        if (location.pathname === getProfileLink()) {
            setActiveIcon('personne');
        } else if (location.pathname === getElevesLink()) {
            setActiveIcon('eleves');
        }
    }, [location, setActiveIcon]);

    const handleNavigation = (path: string, iconName: 'personne' | 'eleves') => {
        history.push(path); // Navigate to the specified path
        setActiveIcon(iconName); // Update the active icon state
    };

    return (
        <div className="Footer">
            <div className="FooterIconContainer" onClick={() => handleNavigation(getProfileLink(), 'personne')}>
                <div className={`NiceEffectContainer ${activeIcon === 'personne' ? 'active' : ''}`}>
                    <img
                        className="FooterIcon"
                        src={activeIcon === 'personne' ? require('../assets/ProfileFilled2.png') : require('../assets/ProfilePlain2.png')}
                        alt="Profile"
                    />
                    <span className={activeIcon === 'personne' ? 'FooterActiveText' : 'FooterInactiveText'}>
                        Profil
                    </span>
                </div>
            </div>

            <div className="FooterIconContainer" onClick={() => handleNavigation(getElevesLink(), 'eleves')}>
                <div className={`NiceEffectContainer ${activeIcon === 'eleves' ? 'active' : ''}`}>
                    <img
                        className="FooterIconEqualSize"
                        src={
                            activeIcon === 'eleves' ?
                                (userRole === 'coach' ? require('../assets/ElevesFilled.png') : require('../assets/Subscription_filled.png')) :
                                (userRole === 'coach' ? require('../assets/ElevesPlain.png') : require('../assets/Subscription_plain.png'))
                        }
                        alt="Eleves"
                    />
                    <span className={activeIcon === 'eleves' ? 'FooterActiveText' : 'FooterInactiveText'}>
                        {userRole === 'coach' ? 'Mes élèves' : 'Abonnement'}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default FooterPayment;