import { createContext, useEffect, useState } from "react";
import { auth } from "../../firebase";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, collection, query, getDocs } from 'firebase/firestore';
import { firestore } from "../../firebase";

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const userRef = doc(firestore, 'users', user.uid);
          const userSnap = await getDoc(userRef);
          let userData = userSnap.exists() ? userSnap.data() : {};

          // Query to fetch all documents in the profileInfo subcollection
          const profileInfoRef = collection(firestore, 'users', user.uid, 'profileInfo');
          const querySnapshot = await getDocs(query(profileInfoRef));

          let profilePicUri = null;
          querySnapshot.forEach((doc) => {
            // Process each document
            // Example: Assuming we want to use the first one
            if (!profilePicUri) {
              profilePicUri = doc.data().profilePicUri;
            }
          });

          setCurrentUser({
            ...user,
            ...userData,
            profilePicUri // Add the profilePicUri from the profileInfo subcollection
          });
        } catch (error) {
          console.error("Error fetching user details:", error);
          setCurrentUser(user); // Set only basic auth user data in case of an error
        }
      } else {
        setCurrentUser(null); // No user is logged in
      }
    });

    return () => {
      unsub();
    };
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser }}>
      {children}
    </AuthContext.Provider>
  );
};
