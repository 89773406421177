import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import './../ProfilePageStyles.css';
import './PaymentProfilePageStyles.css';
import ImageSelectionModal from '../../components/ImageSelectionModal.tsx';
import PaymentParametersModal from './PaymentParametersModal.tsx';
import PaymentEditProfileModal from './PaymentEditProfile.tsx';
import '../../normalize.css';
import DemoOneTimePaymentEditModal from './DemoOneTimePaymentEditModal.tsx';
import DemoMembershipEditModal from './DemoMembershipEditModal.tsx';
import BenefitsIcon from './BenefitsIcon.tsx';
import StripeWrapperMembership from './PaymentMembershipComponent.tsx';
import StripeWrapperOneTime from './PaymentOneTimeComponent.tsx';
import AccountCreationModal from './AccountCreationModal.tsx';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { firestore, storage } from '../../firebase.js';
import { doc, setDoc } from 'firebase/firestore';

function DemoPublicProfile() {
    const location = useLocation();
    const history = useHistory();
    const { name, gender } = location.state || {}; // Defaulting to an empty object if state is undefined
    const [bannerUri, setBannerUri] = useState(require('../../assets/demo_banner.jpg'));
    const [profilePicUri, setProfilePicUri] = useState(require('../../assets/demo_photo_profil.jpg'));
    const [Name, setName] = useState(name);
    const [isImageModalVisible, setImageModalVisible] = useState(false);
    const [selectedImageType, setSelectedImageType] = useState<'profile' | 'banner' | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isParametresModalVisible, setParametresModalVisible] = useState(false);
    const [title, setTitle] = useState('Coach diplômé: BPJEPS AF 🎓');
    const [description, setDescription] = useState('Je vous accompagne vers vos objectifs');
    const [engagementPeriod, setEngagementPeriod] = useState('3');
    const [coachingFee, setCoachingFee] = useState('80');
    const [isEditModalVisible, setEditModalVisible] = useState(false);
    const [isOneTimeEditModalVisible, setIsOneTimeEditModalVisible] = useState(false);
    const [isMembershipEditModalVisible, setIsMembershipEditModalVisible] = useState(false);
    const [stripeAccountSetupComplete, setStripeAccountSetupComplete] = useState(false);
    const [benefit1, setBenefit1] = useState('Programme personnalisé chaque semaine');
    const [benefit2, setBenefit2] = useState('Échanges directs avec le coach');
    const [benefit3, setBenefit3] = useState('Suivi des progrès avec des indicateurs clés');
    const [benefit4, setBenefit4] = useState('Conseils et analyses techniques détaillés');
    const [oneTimeBenefit1, setoneTimeBenefit1] = useState('Une séance en présentiel');
    const [oneTimeBenefit2, setoneTimeBenefit2] = useState('Entraînement spécifique');
    const [oneTimeBenefit3, setoneTimeBenefit3] = useState('Une analyse morphologique');
    const [oneTimeBenefit4, setoneTimeBenefit4] = useState('Conseils et analyse pour la suite');
    const [paymentFee, setPaymentFee] = useState('50');
    const [isStripeProcessing, setIsStripeProcessing] = useState(false);
    const [isCopied, setIsCopied] = useState(false);
    const [showSubscriptionBlock, setShowSubscriptionBlock] = useState(true);
    const [showOneTimePaymentBlock, setShowOneTimePaymentBlock] = useState(true);
    const [buttonColor, setButtonColor] = useState('#2D2D2D'); // Default color
    const [showStripeOneTime, setShowStripeOneTime] = useState(false);
    const [showStripe, setShowStripe] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);

    function capitalizeName(name) {
        if (!name) return ""; // Return an empty string if name is falsy
        return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
    }

    useEffect(() => {
        if (gender === 'Femme') {
            setButtonColor('#5841D8'); // Set to different color for female selection
            setDescription('Je vous aide à accomplir tous vos objectifs 🔥')
        }
        // No else clause needed as the default color suits the "Homme" selection
    }, [location.search]);

    useEffect(() => {
        if (isEditModalVisible) {
            // Disable scrolling on body
            document.body.style.overflow = 'hidden';
        } else {
            // Re-enable scrolling on body
            document.body.style.overflow = 'auto';
        }

        // Cleanup function to ensure scrolling is re-enabled when the component is unmounted or modal visibility changes
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isEditModalVisible]);

    const handleCreateAccount = () => {
        setIsModalVisible(true); // Show the modal
    };

    const handleFileUploadReal = async (file, type, userId) => {
        if (!file) {
            console.log('No file selected');
            return;
        }
        try {
            const uploadUri = type === 'profile' ? 'profile_pics/' : 'banners/';
            const filename = `${uploadUri}${new Date().getTime()}-${file.name}`;
            const storageRef = ref(storage, filename);

            await uploadBytes(storageRef, file);
            const url = await getDownloadURL(storageRef);

            const userDocRef = doc(firestore, 'users', userId);
            const updateData = type === 'profile' ? { profilePicUri: url } : { bannerUri: url };
            await setDoc(userDocRef, updateData, { merge: true });
        } catch (error) {
            console.error('Error during file upload:', error);
        }
    };

    function dataURLtoFile(dataurl, filename) {
        if (!dataurl) {
            console.error('Invalid data URL provided');
            return null;
        }
        let arr = dataurl.split(',');
        if (arr.length < 2) {
            console.error('Data URL is not in the expected format');
            return null;
        }
        let mimeMatch = arr[0].match(/:(.*?);/);
        if (!mimeMatch) {
            console.error('MIME type not found in data URL');
            return null;
        }
        let mime = mimeMatch[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
    
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
    
        return new File([u8arr], filename, { type: mime });
    }    

    const handleModalSubmit = async (userId) => {
        // Assume that `profilePicUri` and `bannerUri` are already set to the data URLs of the images
        // Convert data URLs to file objects
        const profilePicFile = profilePicUri ? dataURLtoFile(profilePicUri, `profile-${userId}-${new Date().getTime()}.png`) : null;
        const bannerFile = bannerUri ? dataURLtoFile(bannerUri, `banner-${userId}-${new Date().getTime()}.png`) : null;

        // Upload profile picture if it exists
        if (profilePicFile) {
            await handleFileUploadReal(profilePicFile, 'profile', userId);
        }

        // Upload banner if it exists
        if (bannerFile) {
            await handleFileUploadReal(bannerFile, 'banner', userId);
        }

        setIsModalVisible(false);
        history.push('/Finalisation');
    };

    const calculatePricingDetails = (baseAmount) => {
        const baseAmountNumber = Number(baseAmount); // Ensure baseAmount is treated as a number
        if (isNaN(baseAmountNumber)) {
            console.error("Invalid baseAmount: ", baseAmount);
            return { baseAmount: 0, serviceFee: 0, totalAmount: 0 }; // Return default object if invalid
        }
        const serviceFee = baseAmountNumber * 0.02; // 2% service fee
        const totalAmount = baseAmountNumber + serviceFee;
        return { baseAmount: baseAmountNumber, serviceFee, totalAmount };
    };

    const handleBackFromStripe = () => {
        setShowStripe(false);
    };

    const handleBackFromStripeOneTime = () => {
        setShowStripeOneTime(false);
    };

    const handleEditImage = (type) => {
        setSelectedImageType(type);
        const fileInput = document.getElementById('fileInput');
        if (fileInput) {
            fileInput.click();
        } else {
            console.error('File input not found');
        }
    };

    const handleImageSelect = (event, type) => {
        const file = event.target.files[0];
        handleFileUpload(file, type);
    };

    const handleFileUpload = (file, type) => {
        if (!file) {
            console.log('No file selected');
            return;
        }

        const reader = new FileReader();

        reader.onloadend = () => {
            // Check if reader.result is a string before updating state
            if (typeof reader.result === 'string') {
                // Update local state with the new image URL
                if (type === 'profile') {
                    setProfilePicUri(reader.result);
                } else {
                    setBannerUri(reader.result);
                }
            } else {
                console.error('File read error: result is not a string.');
            }
        };

        reader.onerror = (error) => {
            console.error('Error reading file:', error);
        };

        // Read the file as a Data URL (base64 encoded)
        reader.readAsDataURL(file);
    };

    const handleCloseModal = () => {
        setParametresModalVisible(false);
    };

    const toggleOneTimePaymentEditModal = () => {
        setIsOneTimeEditModalVisible(!isOneTimeEditModalVisible);
    };

    const toggleMembershipEditModal = () => {
        setIsMembershipEditModalVisible(!isMembershipEditModalVisible);
    };

    const handleSaveMembership = async (updatedData) => {
        // Directly update local state with the new data
        // Assuming updatedData contains the fields: coachingFee, benefit1, benefit2, benefit3, benefit4
        setCoachingFee(updatedData.coachingFee);
        setBenefit1(updatedData.benefit1);
        setBenefit2(updatedData.benefit2);
        setBenefit3(updatedData.benefit3);
        setBenefit4(updatedData.benefit4);
        setEngagementPeriod(updatedData.engagementPeriod);

        // Close the modal, assuming you have a state to control its visibility
        setIsMembershipEditModalVisible(false);

        // No need to set processing states or trigger fetches since we're not interacting with external services
    };

    const handleSaveOneTimePayment = (updatedData) => {
        // Update local state directly with the new data
        // Assuming updatedData contains the fields: paymentFee, oneTimeBenefit1, oneTimeBenefit2, oneTimeBenefit3, oneTimeBenefit4
        setButtonColor(buttonColor);
        setPaymentFee(updatedData.paymentFee);
        setoneTimeBenefit1(updatedData.oneTimeBenefit1);
        setoneTimeBenefit2(updatedData.oneTimeBenefit2);
        setoneTimeBenefit3(updatedData.oneTimeBenefit3);
        setoneTimeBenefit4(updatedData.oneTimeBenefit4);

        // Close the modal after updating the state
        setIsOneTimeEditModalVisible(false);

        // Since there's no interaction with external services, we can remove any processing indicators
    };

    const handleSaveProfile = (updatedData) => {
        // Directly update the component's local state with the new data
        // This assumes that updatedData includes all the fields you want to update
        setName(updatedData.Name);
        setTitle(updatedData.title);
        setDescription(updatedData.description);
        setShowOneTimePaymentBlock(updatedData.showOneTimePaymentBlock);
        setShowSubscriptionBlock(updatedData.showSubscriptionBlock);
        setButtonColor(updatedData.buttonColor);
        // Assuming updatedData includes updated URIs for profilePicUri and bannerUri if they were changed
        // Update these if included in the update, to reflect changes in the UI
        if (updatedData.profilePicUri) {
            setProfilePicUri(updatedData.profilePicUri);
        }
        if (updatedData.bannerUri) {
            setBannerUri(updatedData.bannerUri);
        }

        // Close the modal after state update
        setEditModalVisible(false);

        // No need to indicate processing or interact with Firestore, so we can remove any related logic
    };

    return (
        <>
            <input
                type="file"
                id="fileInput"
                style={{ display: 'none' }}
                onChange={(event) => handleImageSelect(event, selectedImageType)}
                accept="image/*"
            />
            {isParametresModalVisible && (
                <div onClick={handleCloseModal}></div>
            )}

            <div className="SuprememainContainerProfile">
                <div className='ProfilePageContent'>
                    <div className='ProfileScreenContentContainer'>
                        <div className='FirstLayerProfileScreenContentContainerDemo'>
                            <div className='TitleProfileScreenContentContainer'>
                                Profil
                            </div>
                            <button className='VioletPaymentButton' style={{ width: 'auto' }} onClick={handleCreateAccount}>Sauvegarder votre profil</button>
                        </div>
                        {isLoading ? (
                            <div className="ProfilePageLoadingContainer">
                                <div className="spinner"></div>
                            </div>
                        ) : (
                            <div>
                                <button className='ProfilePageBannerButton' onClick={() => handleEditImage('banner')}>
                                    <div className="ProfilePageBannerContainer">
                                        <img
                                            className="ProfilePageBannerImage"
                                            src={bannerUri || require('../../assets/default_banner.png')}
                                            alt="Banner"
                                        />
                                    </div>
                                </button>
                                <div className="ProfilePageProfileImageContainer">
                                    <button onClick={() => handleEditImage('profile')}>
                                        <img
                                            className="ProfilePageProfileImage"
                                            src={profilePicUri || require('../../assets/default_profile_picture.png')}
                                            alt="Profile"
                                        />
                                    </button>
                                </div>
                                <ImageSelectionModal
                                    visible={isImageModalVisible}
                                    onEdit={handleEditImage}
                                    onCancel={() => setImageModalVisible(false)}
                                />
                                <div className='ProfilePageContainerMenu'>
                                    <button className='ProfilePageButtonHoverEffect' onClick={() => setParametresModalVisible(true)}>
                                        <div className="ProfilePageMenuContainer">
                                            <img className="ProfilePageMenu" src={require('../../assets/menu.png')} alt="Menu" />
                                        </div>
                                    </button>
                                </div>
                                <div className="ProfilePageNameContainer">
                                    <span className="ProfilePageName">{capitalizeName(Name)}</span>
                                </div>
                                <div>
                                    <div className='ProfilePageInfoContainer'>
                                        <span className='ProfilePageInfo'>{title}</span>
                                        <span className='ProfilePageInfo'>{description}</span>
                                    </div>
                                </div>
                                <div className="ProfilePageActionButtons">
                                    <button className="ProfilePageSearchButton ProfilePageAddMarginRight" onClick={() => setEditModalVisible(true)}>Modifier votre profil</button>
                                    {stripeAccountSetupComplete && (
                                        <>
                                            <button className="ProfilePageSearchButtonShare ProfilePageAddMarginLeft">
                                                Partager votre profil
                                            </button>
                                            {isCopied && <span style={{ marginLeft: '10px', fontSize: '14px' }}>Copié !</span>}
                                        </>
                                    )}
                                </div>
                                <>
                                </>
                                <div className={`PaymentProfilePageActionButtons`}>
                                    {showOneTimePaymentBlock && (
                                        <div className='PaymentProfilePageContainer'>
                                            <div className='PaymentProfilePageContainerEdit'>
                                                <button
                                                    className="ProgramCreationStylesEditIconContainer"
                                                    onClick={toggleOneTimePaymentEditModal}
                                                >
                                                    <img className="ProgramCreationStylesEditIcon" src={require('../../assets/edit_icon.png')} alt="Edit" />
                                                </button>
                                            </div>
                                            <span className='ProfilePageTitleFutura'>Coaching en présentiel</span>
                                            <span className='ProfilePageDescriptionContainerRight'>Aucun engagement</span>
                                            {oneTimeBenefit1 && (
                                                <div className='ProfilePageSearchBenefitsContainer'>
                                                    <BenefitsIcon className="ProfilePageSearchBenefitsIcon" color={buttonColor} />
                                                    <span className='ProfilePageSearchBenefits'>{oneTimeBenefit1}</span>
                                                </div>
                                            )}
                                            {oneTimeBenefit2 && (
                                                <div className='ProfilePageSearchBenefitsContainer'>
                                                    <BenefitsIcon className="ProfilePageSearchBenefitsIcon" color={buttonColor} />
                                                    <span className='ProfilePageSearchBenefits'>{oneTimeBenefit2}</span>
                                                </div>
                                            )}
                                            {oneTimeBenefit3 && (
                                                <div className='ProfilePageSearchBenefitsContainer'>
                                                    <BenefitsIcon className="ProfilePageSearchBenefitsIcon" color={buttonColor} />
                                                    <span className='ProfilePageSearchBenefits'>{oneTimeBenefit3}</span>
                                                </div>
                                            )}
                                            {oneTimeBenefit4 && (
                                                <div className='ProfilePageSearchBenefitsContainer'>
                                                    <BenefitsIcon className="ProfilePageSearchBenefitsIcon" color={buttonColor} />
                                                    <span className='ProfilePageSearchBenefits'>{oneTimeBenefit4}</span>
                                                </div>
                                            )}
                                            <button style={{ backgroundColor: buttonColor }} className="BlueButton BlueButtonAddPadding ProfilePageStylesAddMargin" onClick={() => setShowStripeOneTime(true)}>
                                                <span className='buttonText'>Payer {paymentFee}€</span>
                                            </button>
                                            {showStripeOneTime && (
                                                <StripeWrapperOneTime
                                                    pricingDetails={calculatePricingDetails(paymentFee)}
                                                    buttoncolor={buttonColor}
                                                    coachId={'123'}
                                                    name={Name}
                                                    onPaymentSuccess={(paymentMethodId, amount, email) => { }}
                                                    onBack={handleBackFromStripeOneTime} // Pass the callback as a prop
                                                />
                                            )}
                                        </div>
                                    )}
                                    {showSubscriptionBlock && (
                                        <div className='PaymentProfilePageContainer'>
                                            <div className='PaymentProfilePageContainerEdit'>
                                                <button
                                                    className="ProgramCreationStylesEditIconContainer"
                                                    onClick={toggleMembershipEditModal}
                                                >
                                                    <img className="ProgramCreationStylesEditIcon" src={require('../../assets/edit_icon.png')} alt="Edit" />
                                                </button>
                                            </div>
                                            <span className='ProfilePageTitleFutura'>Coaching en ligne</span>
                                            {/* Check for engagementPeriod and display conditionally */}
                                            {engagementPeriod ? (
                                                <span className='ProfilePageDescriptionContainerRight'>Engagement {engagementPeriod} mois</span>
                                            ) : (
                                                <span className='ProfilePageDescriptionContainerRight'>Aucun engagement</span>
                                            )}
                                            {benefit1 && (
                                                <div className='ProfilePageSearchBenefitsContainer'>
                                                    <BenefitsIcon className="ProfilePageSearchBenefitsIcon" color={buttonColor} />
                                                    <span className='ProfilePageSearchBenefits'>{benefit1}</span>
                                                </div>
                                            )}
                                            {benefit2 && (
                                                <div className='ProfilePageSearchBenefitsContainer'>
                                                    <BenefitsIcon className="ProfilePageSearchBenefitsIcon" color={buttonColor} />
                                                    <span className='ProfilePageSearchBenefits'>{benefit2}</span>
                                                </div>
                                            )}
                                            {benefit3 && (
                                                <div className='ProfilePageSearchBenefitsContainer'>
                                                    <BenefitsIcon className="ProfilePageSearchBenefitsIcon" color={buttonColor} />
                                                    <span className='ProfilePageSearchBenefits'>{benefit3}</span>
                                                </div>
                                            )}
                                            {benefit4 && (
                                                <div className='ProfilePageSearchBenefitsContainer'>
                                                    <BenefitsIcon className="ProfilePageSearchBenefitsIcon" color={buttonColor} />
                                                    <span className='ProfilePageSearchBenefits'>{benefit4}</span>
                                                </div>
                                            )}
                                            <button style={{ backgroundColor: buttonColor }} className="BlueButton BlueButtonAddPadding ProfilePageStylesAddMargin" onClick={() => setShowStripe(true)}>
                                                <span className='buttonText'>S'abonner pour {coachingFee}€/mois</span>
                                            </button>
                                            {showStripe && (
                                                <StripeWrapperMembership
                                                    pricingDetails={calculatePricingDetails(coachingFee)}
                                                    buttoncolor={buttonColor}
                                                    coachId={'456'}
                                                    name={Name}
                                                    engagementPeriod={engagementPeriod} // Assuming engagementPeriod is stored here
                                                    onPaymentSuccess={(paymentMethodId, amount, email) => { }}
                                                    onBack={handleBackFromStripe} // Pass the callback as a prop
                                                />
                                            )}
                                        </div>
                                    )}
                                </div>
                                {isEditModalVisible && (
                                    <PaymentEditProfileModal
                                        isVisible={isEditModalVisible}
                                        onCancel={() => setEditModalVisible(false)}
                                        onSave={(updatedData) => handleSaveProfile(updatedData)}
                                        initialData={{ Name, title, description, showSubscriptionBlock, showOneTimePaymentBlock, buttonColor }}
                                    />
                                )}
                                {isModalVisible && (
                                    <AccountCreationModal
                                        visible={isModalVisible}
                                        setVisible={setIsModalVisible}
                                        onSubmit={handleModalSubmit}
                                        // Existing props
                                        name={Name}
                                        title={title}
                                        description={description}
                                        // New props for membership benefits
                                        benefit1={benefit1}
                                        benefit2={benefit2}
                                        benefit3={benefit3}
                                        benefit4={benefit4}
                                        engagementPeriod={engagementPeriod}
                                        // New props for one-time payment benefits
                                        oneTimeBenefit1={oneTimeBenefit1}
                                        oneTimeBenefit2={oneTimeBenefit2}
                                        oneTimeBenefit3={oneTimeBenefit3}
                                        oneTimeBenefit4={oneTimeBenefit4}
                                        // New props for profile details (some are already included, so only new ones added)
                                        showOneTimePaymentBlock={showOneTimePaymentBlock}
                                        showSubscriptionBlock={showSubscriptionBlock}
                                        buttonColor={buttonColor}
                                        bannerUri={bannerUri}
                                        profilePicUri={profilePicUri}
                                    // Consider any other props you might need from updated states
                                    />
                                )}
                                <PaymentParametersModal
                                    visible={isParametresModalVisible}
                                    setVisible={setParametresModalVisible}
                                    coachName={Name} // Passing the coach's name as a prop
                                    isStripeProcessing={isStripeProcessing} // Pass this prop to control the spinner.
                                />
                                <DemoOneTimePaymentEditModal
                                    isVisible={isOneTimeEditModalVisible}
                                    onCancel={() => setIsOneTimeEditModalVisible(false)}
                                    onSave={(updatedData) => handleSaveOneTimePayment(updatedData)}
                                    initialData={{
                                        buttonColor,
                                        oneTimeBenefit1, // Directly pass state values
                                        oneTimeBenefit2,
                                        oneTimeBenefit3,
                                        oneTimeBenefit4,
                                        paymentFee,
                                    }}
                                    isStripeProcessing={isStripeProcessing} // Pass this prop to control the spinner.
                                />
                                <DemoMembershipEditModal
                                    isVisible={isMembershipEditModalVisible}
                                    onCancel={() => setIsMembershipEditModalVisible(false)}
                                    onSave={(updatedData) => handleSaveMembership(updatedData)}
                                    initialData={{
                                        buttonColor,
                                        benefit1, // Directly pass state values
                                        benefit2,
                                        benefit3,
                                        benefit4,
                                        coachingFee, // Assuming this state holds your initial coaching fee
                                        engagementPeriod, // Assuming this state holds your initial engagement period
                                    }}
                                    isStripeProcessing={isStripeProcessing} // Pass this prop to control the spinner.
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
export default DemoPublicProfile;