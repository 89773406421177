import React, { useContext, useEffect, useState } from 'react';
import Footer from '../components/Footer.tsx';
import './StatisticsStyles.css';
import { UserContext } from '../components/UserContext.tsx';
import { firestore } from '../firebase'
import { collection, getDocs, query, where } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { useUserId } from '../types/utils.ts';
import { useSelectedUser } from '../components/SelectedUserContext.tsx';


interface Student {
    id: string;
    name: string;
}

const Statistics = () => {

    const { userRole } = useContext(UserContext);
    const userId = useUserId();
    const { selectedUserId, setSelectedUserId } = useSelectedUser();
    const [students, setStudents] = useState<Student[]>([]);

    const [activeIcon, setActiveIcon] = useState<'personne' | 'calendrier' | 'stats' | 'maison' | 'invitation' | 'eleves' | 'parametres' | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [exerciseDetails, setExerciseDetails] = useState<Map<string, { totalSets: number, totalWeight: number, totalRPE: number, totalReps: number, RPEmax: number, ChargeMax: number, setsValid: boolean, repsValid: boolean, weightValid: boolean, rpeValid: boolean }>>(new Map());
    const [weekDates, setWeekDates] = useState({ start: '', end: '' });

    const [weekOffset, setWeekOffset] = useState(0); // 0 for the current week, -1 for the previous week, etc.
    const goToPreviousWeek = () => {
        setWeekOffset(weekOffset - 1);
    };

    const goToNextWeek = () => {
        setWeekOffset(weekOffset + 1);
    };

    const formatRPE = (rpe) => {
        const formattedRPE = Math.round(rpe * 10) / 10; // Round to one decimal place
        return formattedRPE % 1 === 0 ? formattedRPE.toFixed(0) : formattedRPE.toFixed(1); // Remove decimal if zero
    };

    useEffect(() => {
        // Fetch students if userRole is coach
        const fetchStudents = async () => {
            if (userId && userRole === 'coach') {
                const studentsCollectionRef = collection(firestore, 'users', userId, 'myStudents');
                const querySnapshot = await getDocs(studentsCollectionRef);
                const fetchedStudents = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    name: doc.data().Name, // Adjust the field name if necessary
                }));
                setStudents(fetchedStudents);
            }
        };

        if (userRole === 'coach') {
            fetchStudents();
        }
    }, [userRole, userId]);



    useEffect(() => {
        setIsLoading(true);
        const auth = getAuth();
        const currentUser = auth.currentUser;

        if (currentUser) {

            const today = new Date();
            today.setHours(0, 0, 0, 0);  // Set time to start of the day
            const currentWeekDay = today.getDay();
            const firstDayOfWeek = today.getDate() - currentWeekDay + (currentWeekDay === 0 ? -6 : 1); // Start week on Monday
            const startOfWeek = new Date(today.setDate(firstDayOfWeek + weekOffset * 7));
            const endOfWeek = new Date(new Date(startOfWeek).setDate(startOfWeek.getDate() + 6));

            const formatDate = (date) => {
                const options = { day: 'numeric', month: 'short' }; // format as "DD Month"
                return date.toLocaleDateString('fr-FR', options); // use 'fr-FR' locale for French month names
            };
            setWeekDates({ start: formatDate(startOfWeek), end: formatDate(endOfWeek) });

            const targetUserId = selectedUserId || currentUser.uid; // Use selectedUserId if available
            const workoutsRef = collection(firestore, 'users', targetUserId, 'workoutDays');


            getDocs(workoutsRef).then(snapshot => {
                let exerciseCounts = new Map<string, { totalSets: number, totalWeight: number, totalRPE: number, totalReps: number, RPEmax: number, ChargeMax: number, setsValid: boolean, repsValid: boolean, weightValid: boolean; rpeValid: boolean }>();



                snapshot.docs.forEach(doc => {
                    const docDate = new Date(doc.id);

                    if (docDate >= startOfWeek && docDate <= endOfWeek) {
                        const data = doc.data();
                        if (data.exercises && Array.isArray(data.exercises)) {
                            data.exercises.forEach((exercise: any) => {
                                let totalSets = 0;
                                let totalWeight = 0;
                                let totalRPE = 0;
                                let setsValid = true;
                                let weightValid = true;
                                let rpeValid = true;
                                let repsValid = true;
                                let totalReps = 0;
                                let RPEmax = 0;
                                let ChargeMax = 0;

                                exercise.sets.forEach((set: any) => {
                                    const setNumber = Number(set.setNumber);
                                    const weight = Number(set.weight);
                                    const reps = Number(set.reps);
                                    const rpe = Number(set.rpe);

                                    if (!isNaN(setNumber)) {
                                        totalSets += setNumber;
                                    } else {
                                        setsValid = false;
                                    }
                                    if (!isNaN(reps) && !isNaN(setNumber)) {
                                        totalReps += reps * setNumber;
                                    } else {
                                        repsValid = false;
                                    }
                                    if (!isNaN(weight) && !isNaN(reps) && !isNaN(setNumber)) {
                                        totalWeight += setNumber * weight * reps;
                                        ChargeMax = Math.max(ChargeMax, weight);
                                    } else {
                                        weightValid = false;
                                    }
                                    if (!isNaN(rpe) && !isNaN(setNumber)) {
                                        totalRPE += (rpe * setNumber);
                                        RPEmax = Math.max(RPEmax, rpe);
                                    } else {
                                        rpeValid = false;
                                    }
                                });

                                const existingDetails = exerciseCounts.get(exercise.name) || { totalSets: 0, totalWeight: 0, totalRPE: 0, totalReps: 0, RPEmax: 0, ChargeMax: 0, repsValid: true, setsValid: true, weightValid: true, rpeValid: true };
                                exerciseCounts.set(exercise.name, {
                                    totalSets: existingDetails.totalSets + totalSets,
                                    totalWeight: existingDetails.totalWeight + totalWeight,
                                    totalRPE: existingDetails.totalRPE + totalRPE,
                                    totalReps: existingDetails.totalReps + totalReps,
                                    RPEmax: Math.max(existingDetails.RPEmax, RPEmax),
                                    ChargeMax: Math.max(existingDetails.ChargeMax, ChargeMax),
                                    repsValid: existingDetails.repsValid && repsValid,
                                    setsValid: existingDetails.setsValid && setsValid,
                                    weightValid: existingDetails.weightValid && weightValid,
                                    rpeValid: existingDetails.rpeValid && rpeValid
                                });
                            });
                        }
                    }
                });
                setExerciseDetails(exerciseCounts);
                setIsLoading(false);
            }).catch(error => {
                console.error("Error fetching workouts: ", error);
                setIsLoading(false);
            });
        } else {
            setIsLoading(false);
        }
    }, [selectedUserId, weekOffset]);

    const handleSelectionChange = (event) => {
        setSelectedUserId(event.target.value);
    };


    return (
        <div className="SuprememainContainerStats">
            <div className="leftsideContainerStats">
                <div className="LogoIconContainerStats">
                    <img className="LogoStats" src={require('../assets/ReReReDesign.png')} alt="Logo" />
                </div>
                <Footer setActiveIcon={setActiveIcon} activeIcon={activeIcon} />
            </div>
            <div className="statistics-container">
                <div className='StatsScreenContentContainer'>
                    <div className='FirstLayerStatsScreenContentContainer'>
                        <div className='TitleStatsScreenContentContainer'>
                            Statistiques
                        </div>
                    </div>

                    {userRole === 'coach' && (
                        <div className='SelectorContainerStats1'>
                            <div className='SelectorContainerStats2'>
                                <select onChange={handleSelectionChange} value={selectedUserId || userId || ''} className="ProgramCreationStylesSelect">
                                    <option value={userId || ''}>Mes statistiques</option>
                                    {students.map(student => (
                                        <option key={student.id} value={student.id}>{student.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>

                    )}
                    <div className='StatsStylesContentContainer'>
                        <div className="StatsNavigationContainer">
                            <button className="StatsStylesArrowButtonContainer" onClick={goToPreviousWeek}>
                                <img className='StatsStylesArrowButtonLeft' src={require('../assets/previous_icon.png')} alt="Next Week" />
                            </button>
                            <span className="StatsStylesTitle">
                                <span className="week-prefix">Semaine du </span>
                                <span>{weekDates.start} - {weekDates.end}</span>
                            </span>
                            <button className="StatsStylesArrowButtonContainer" onClick={goToNextWeek}>
                                <img className='StatsStylesArrowButton' src={require('../assets/next_icon.png')} alt="Next Week" />
                            </button>
                        </div>

                        {isLoading ? (
                            <div className="ProfilePageLoadingContainer">
                                <div className="spinner"></div>
                            </div>
                        ) : (

                            exerciseDetails.size > 0 ? (
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Exercices</th>
                                            <th>Séries</th>
                                            <th>Répétitions</th>
                                            <th>Charge Moyenne</th>
                                            <th>Charge Max</th>
                                            <th>Tonnage</th>
                                            <th>RPE Moyen</th>
                                            <th>RPE Max</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array.from(exerciseDetails.entries()).map(([name, details]) => (
                                            <tr key={name}>
                                                <td>{name}</td>
                                                <td>{details.setsValid ? details.totalSets : 'Données à préciser'}</td>
                                                <td>{details.repsValid ? details.totalReps : 'Données à préciser'}</td>
                                                <td>{details.weightValid ? formatRPE((details.totalWeight) / (details.totalReps)) : 'Données à préciser'}</td>
                                                <td>{details.weightValid ? details.ChargeMax : 'Données à préciser'}</td>
                                                <td>{details.weightValid ? `${details.totalWeight} kg` : 'Données à préciser'}</td>
                                                <td>{details.rpeValid ? formatRPE(details.totalRPE / details.totalSets) : 'Données à préciser'}</td>
                                                <td>{details.rpeValid ? details.RPEmax : 'Données à préciser'}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : (
                                <div className="NodataContainerStats">
                                    <div className="MessageNodata">
                                        <img className="StatsNoDataIllustration" src={require('../assets/No_data_found_illustration.png')} alt="Add" />
                                        <span className='StatsNoDataExplanation'>Vous n'avez pas de programme pour la semaine en cours.</span>
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                </div>
            </div>
        </div>


    );
}

export default Statistics;
