import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { firestore } from '../firebase';
import { collection, query, where, getDocs, doc, getDoc, limit } from 'firebase/firestore';
import '../components/Search.css';
import debounce from 'lodash/debounce';

type User = {
  id: string;
  Name: string;
  role: string;
  profileInfo?: {
    title: string;
    description: string;
    profilePicUri: string;
    bannerUri: string
  };
};

const Search = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [users, setUsers] = useState<User[]>([]);
  const history = useHistory(); // Use history for navigation
  const [searchPerformed, setSearchPerformed] = useState(false); // New state variable
  const [isLoading, setIsLoading] = useState(false);

  const searchUsers = async (searchQuery) => {
    const searchQueryNormalized = searchQuery.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

    // Check if the search query is empty and clear the users if it is
    if (!searchQueryNormalized.trim()) {
      setUsers([]);
      setSearchPerformed(false); // Reset search performed flag if search query is empty
      return;
    }
    try {
      setIsLoading(true); // Set loading to true at the beginning of the search
      const usersRef = collection(firestore, 'users');
      const q = query(usersRef); // Fetch all users since we are filtering in the client
      const querySnapshot = await getDocs(q);
  
      const matchingUsers: User[] = [];
      for (let docSnapshot of querySnapshot.docs) {
        const docData = docSnapshot.data();
        if (docData) {
          const userData = { ...docData, id: docSnapshot.id } as Partial<User>;
          const userNameNormalized = userData.Name?.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
  
          // Check if normalized Name matches the normalized search query
          if (userNameNormalized && userNameNormalized.includes(searchQueryNormalized)) {
            // Fetch profileInfo subcollection
            const profileInfoRef = collection(firestore, 'users', docSnapshot.id, 'profileInfo');
            const profileInfoSnapshot = await getDocs(profileInfoRef);
  
            let profileInfo = { title: '', description: '', profilePicUri: '', bannerUri: '' }; // Default values for profileInfo
            profileInfoSnapshot.forEach(doc => {
              if (doc.exists()) {
                Object.assign(profileInfo, doc.data());
              }
            });
  
            matchingUsers.push({ ...userData, profileInfo } as User);
          }
        }
      }
  
      setUsers(matchingUsers);
      setIsLoading(false); // Set loading to false after the search is complete
      setSearchPerformed(true); // Set to true when search is performed
    } catch (error) {
      console.error('Error fetching users:', error);
      setIsLoading(false);
    }
  };

  const clearSearch = () => {
    setSearchTerm('');
    setUsers([]);
    setSearchPerformed(false); // Reset search performed flag
  };
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((searchQuery) => {
      searchUsers(searchQuery);
    }, 300), // 300 milliseconds delay
    [] // Dependencies array
  );

  const handleSearchChange = (searchQuery: string) => {
    setSearchTerm(searchQuery);
    debouncedSearch(searchQuery); // Use the debounced function
  };

  const handleUserSelect = async (userId) => {
    try {
      const userDocRef = doc(firestore, "users", userId);
      const userDocSnap = await getDoc(userDocRef);
  
      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
  
        // Fetch profileInfo subcollection
        const profileInfoRef = collection(firestore, 'users', userId, 'profileInfo');
        const profileInfoSnapshot = await getDocs(profileInfoRef);
  
        let coachDetails = { title: '', description: '', profilePicUri: '', bannerUri: '', selectedPrice: '' }; // Default empty values for coachDetails
        profileInfoSnapshot.forEach(docSnapshot => {
          if (docSnapshot.exists()) {
            const docData = docSnapshot.data();
            coachDetails = {
              ...coachDetails,
              ...docData // Merge the new data from Firestore
            };
          }
        });
  
        // Redirect to profile page with user data and coach details
        history.push({
          pathname: `/ProfilePageSearch/${userId}`,
          state: {
            ...userData,
            coachDetails: coachDetails // Include coach details
          }
        });
      } else {
        console.log("User document not found");
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };  

  return (
    <div className='SearchMainContainerFull'>
      <div className='SearchAndMessageContainer'>
        <div className="SearchContainer">
        <button className="IconContainer">
            <img
              src={require('../assets/Loupe.png')}
              alt="Search Icon"
              className="SearchIcon"
            />
          </button>
          <input
            className="Input"
            placeholder="Trouve ton coach..."
            value={searchTerm}
            onChange={(e) => handleSearchChange(e.target.value)}
          />
        </div>
      </div>
      <div className="ResultsContainer">
      {isLoading ? (
        <div className="SearchSpinner"></div>
      ) : searchPerformed && users.length === 0 ? (
        <div className="NoUserFoundMessage">
          Aucun utilisateur trouvé
        </div>
      ) : (
        users.map((user) => (
          <div onClick={() => handleUserSelect(user.id)} className="Card" key={user.id}>
            <img
              src={user.profileInfo?.profilePicUri || require('../assets/default_profile_picture.png')}
              className="ProfilePic"
              alt="Profile"
            />
            <span className="Name">{user.Name}</span>
          </div>
        ))
      )}
      </div>
    </div>
  );
};

export default Search;
