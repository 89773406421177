import React, { useEffect, useState } from 'react';
import './LandingPageStyles.css';
import { useHistory, Link } from 'react-router-dom';
import { getAnalytics, logEvent } from 'firebase/analytics'; // Import Firebase Analytics functions
import { Helmet } from 'react-helmet-async';

const LandingPage = () => {
    const history = useHistory();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
    const analytics = getAnalytics(); // Initialize Firebase Analytics

    useEffect(() => {
        logEvent(analytics, 'landing_page_visit');
    }, []);

    const navigateToFAQ = () => {
        history.push('/FAQ');
    };

    const navigateToAboutUs = () => {
        history.push('/AboutUs');
    };

    const navigateToLogin = (buttonLabel) => {
        logEvent(analytics, 'create_account_clicked', { button_label: buttonLabel });
        history.push('/login');
    };

    const navigateToPres = () => {
        history.push('/');
    };

    const openEmail = () => {
        logEvent(analytics, 'send_email_clicked');
        const email = 'contactinfo.repp@gmail.com';
        window.location.href = `mailto:${email}`;
    };

    return (
        <>
            <Helmet>
                <title>Plateforme de Coaching en Ligne - Musculation et Sports de Force</title>
                <meta name="description" content="Plateforme dédiée aux coachs de salle de sport en ligne, permettant de gérer et suivre facilement les programmes d'entraînement personnalisés de leurs élèves, tout en assurant une gestion fluide des paiements." />
                <meta name="keywords" content="coaching en ligne, musculation, sports de force, gestion entraînement, programme d'entraînement, suivi sportif, paiement pour coachs, entraînement personnalisé, plateforme pour coachs, coaching fitness" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <link rel="canonical" href="https://www.reppwebapp.fr/" />

                {/* Open Graph / Facebook */}
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.reppwebapp.fr/" />
                <meta property="og:title" content="Plateforme de Coaching en Ligne - Musculation et Sports de Force" />
                <meta property="og:description" content="Optimisez votre coaching avec notre plateforme spécialement conçue pour les entraîneurs de musculation et de sports de force, facilitant la création, le suivi des programmes d'entraînement et la gestion des paiements." />
                <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/repp-bf58c.appspot.com/o/Banner_fb.png?alt=media&token=64049cd3-3a06-40de-a4f8-44356fceb318" />

                {/* Twitter */}
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://www.reppwebapp.fr/" />
                <meta property="twitter:title" content="Plateforme de Coaching en Ligne - Musculation et Sports de Force" />
                <meta property="twitter:description" content="Optimisez votre coaching avec notre plateforme spécialement conçue pour les entraîneurs de musculation et de sports de force, facilitant la création, le suivi des programmes d'entraînement et la gestion des paiements." />
                <meta property="twitter:image" content="https://firebasestorage.googleapis.com/v0/b/repp-bf58c.appspot.com/o/Banner_twitter.png?alt=media&token=58bd519a-f0ed-407e-a5df-d0be83a03e94" />

                {/* Structured Data */}
                <script type="application/ld+json">
                    {`
            {
              "@context": "http://schema.org",
              "@type": "Organization",
              "url": "https://www.reppwebapp.fr/",
              "logo": "https://firebasestorage.googleapis.com/v0/b/repp-bf58c.appspot.com/o/Banner_twitter.png?alt=media&token=58bd519a-f0ed-407e-a5df-d0be83a03e94"
            }
        `}
                </script>
            </Helmet>
            <div className="LandingPageStylesMainContainer">
                <header className="LandingPageStylesHeaderContainer">
                    <div onClick={navigateToPres}>
                        <img src={require('../assets/ReReReDesign.png')} alt="StreetLifting Logo" className="LandingPageStylesLogo" />
                    </div>
                    <img src={require('../assets/hamburger_menu.png')} alt="Menu" className="HamburgerMenuIcon" onClick={toggleMenu} />
                    <div className={`MenuOverlay ${isMenuOpen ? 'Active' : ''}`} onClick={toggleMenu}></div>
                    <nav className={`LandingPageStylesNavigationContainer ${isMenuOpen ? 'Active' : ''}`}>
                        <img src={require('../assets/cross_modal.png')} alt="Close" className="CloseMenuIcon" onClick={toggleMenu} />
                        <Link to="/FAQ" className='LandingPageStylesContainerMenuMobileTextButton' onClick={() => { setIsMenuOpen(false); }}>
                            <span className='LandingPageStylesButtonNavigation'>FAQ</span>
                            <img className='LandingPageStylesMenuMobileIcon' src={require('../assets/next_icon_gris.png')} alt="Next" />
                        </Link>
                        <Link to="/AboutUs" className='LandingPageStylesContainerMenuMobileTextButton' onClick={() => { setIsMenuOpen(false); }}>
                            <span className='LandingPageStylesButtonNavigation'>Qui sommes-nous ?</span>
                            <img className='LandingPageStylesMenuMobileIcon' src={require('../assets/next_icon_gris.png')} alt="Next" />
                        </Link>
                        <a href="mailto:contactinfo.repp@gmail.com" className='LandingPageStylesContainerMenuMobileTextButton'>
                            <span className='LandingPageStylesButtonNavigation'>Nous contacter</span>
                            <img className='LandingPageStylesMenuMobileIcon' src={require('../assets/next_icon_gris.png')} alt="Compose email" />
                        </a>
                        <button onClick={() => navigateToLogin('Créer mon compte')} className="BlueButton LandingPageStylesAddWidth LandingPageStylesAddMargingTop">Créer mon compte</button>
                        <div className="LandingPageFooterMessage">
                            <span>Fait avec ❤️ par l'équipe Repp</span>
                        </div>
                    </nav>
                </header>
                <main>
                    <section className="LandingPageStylesFirstSectionContainer">
                        <div className="LandingPageStylesTextContainer">
                            {/* Use h1 for the main title of the page, ensuring it's descriptive of the page's content */}
                            <h1 className='LandingPageStylesTitle'>
                                Le coaching. <br className="LandingPageHiedOnMobile" />
                                <span className='LandingPageStylesTitle LandingPageStylesTitleAddColor'>Simplement.</span>
                            </h1>
                            {/* Use paragraph tag for descriptions for better semantics */}
                            <p className='LandingPageStylesDescription'>
                                Une plateforme développée par des coachs, pour des coachs. Dédiée à la musculation et aux sports de force.
                            </p>
                            <div className="LandingPageButtonsContainer">
                                {/* Add aria-label if needed for more descriptive accessibility */}
                                <button onClick={() => navigateToLogin('Démarrer')} className="BlueButton LandingPageStylesContainerButtonArrow LandingPageStylesAddHeight">
                                    Démarrer
                                    <img className='LandingPageStylesArrow' src={require('../assets/Arrow_right.png')} alt="Commencez avec StreetLifting" />
                                </button>
                                <button onClick={navigateToFAQ} className="BlueButton LandingPageStylesAddColor LandingPageStylesAddHeight">
                                    En savoir plus
                                </button>
                            </div>
                        </div>
                        {/* Ensure the alt text is descriptive */}
                        <img src={require('../assets/Image_Pres_Landing.png')} alt="Démonstration d'exercice" className="LandingPageStylesImageCoach" />
                    </section>
                    <section className="LandingPageStylesSecondSectionContainer LandingPageStylesAddMaring">
                        <div className="LandingPageStylesTextContainerSecondSection">
                            {/* Use semantic HTML where appropriate for better SEO and accessibility */}
                            <h2 className='LandingPageStylesTitle LandingPageStylesAddFont'>
                                Libérez-vous des <br className="LandingPageHiedOnMobile" /> contraintes 🏋️
                            </h2>
                            {/* Use <p> for descriptive text to improve document structure and readability */}
                            <p className='LandingPageStylesDescription '>
                                Fini les paiements en retard, les tableaux Excel complexes et le désordre des messages WhatsApp. Votre temps est <strong>précieux</strong> : focalisez-vous sur la progression de vos élèves.
                            </p>
                        </div>
                    </section>
                    <div className='LandingPageStylesAddMaring'>
                        <section className="LandingPageStylesThirdSectionContainer">
                            <article className='LandingPageStylesContainerIconAndTextDescriptionTitle'>
                                <img src={require('../assets/First_icon_landing.png')} alt="Liberté d'esprit" className="LandingPageStylesFirstIcon" />
                                <div className='LandingPageStylesContainerIconAndText'>
                                    <h3 className='LandingPageStylesContainerText'>Une liberté d'esprit</h3>
                                    <p className='LandingPageStylesContainerSubText'>Bénéficiez de paiements sécurisés et réguliers.</p>
                                </div>
                            </article>
                            <article className='LandingPageStylesContainerIconAndTextDescriptionTitle'>
                                <img src={require('../assets/Second_icon_landing.png')} alt="Optimisation du coaching" className="LandingPageStylesFirstIcon" />
                                <div className='LandingPageStylesContainerIconAndText'>
                                    <h3 className='LandingPageStylesContainerText'>Optimisez votre coaching</h3>
                                    <p className='LandingPageStylesContainerSubText'>Concentrez-vous sur vos élèves et leur évolution.</p>
                                </div>
                            </article>
                            <article className='LandingPageStylesContainerIconAndTextDescriptionTitle'>
                                <img src={require('../assets/Third_icon_landing.png')} alt="Augmentation des revenus" className="LandingPageStylesFirstIcon" />
                                <div className='LandingPageStylesContainerIconAndText'>
                                    <h3 className='LandingPageStylesContainerText'>Augmentez vos revenus</h3>
                                    <p className='LandingPageStylesContainerSubText'>Invitez des coachs et profitez d'un revenu passif durable.</p>
                                </div>
                            </article>
                        </section>
                    </div>
                    <section className="LandingPageStylesFourthSectionContainer LandingPageStylesAddMaringBottom">
                        <img src={require('../assets/Image_Pres_Landing2.png')} alt="Coaching demonstration" className="LandingPageStylesImageCoach" />
                        <div className="LandingPageStylesTextContainer">
                            <h2 className='LandingPageStylesTitle'>Devenez un coach référence</h2>
                            <p className='LandingPageStylesDescription'>
                                Rendez votre coaching plus professionnel grâce à un meilleur suivi pour vos élèves.
                                Bénéficiez de statistiques adaptées et d'une interface facile à utiliser.
                            </p>
                            <div className="LandingPageButtonsContainerFourthSection">
                                <button onClick={() => navigateToLogin('Je commence')} className="BlueButton LandingPageStylesAddWidthLastButton">
                                    Je commence maintenant
                                    <img className="ProgramCreationStylesConfigureIcon" src={require('../assets/goWorkout_Icon.png')} alt="Commencer" />
                                </button>
                            </div>
                        </div>
                    </section>
                    <section className="LandingPageStylesFirstSectionContainer LandingPageStylesAddMaringBottom">
                        <div className="LandingPageStylesTextContainer">
                            <h2 className='LandingPageStylesTitle'>Invitez vos élèves</h2>
                            <p className='LandingPageStylesDescription'>
                                En quelques clics, créez ou importez les programmes Excel de vos élèves et invitez-les à rejoindre la plateforme. Tout est prêt pour commencer !
                            </p>
                            <div className="LandingPageButtonsContainerFourthSection">
                                <button onClick={() => navigateToLogin('Inviter mes élèves')} className="BlueButton LandingPageStylesAddWidthLastButton">
                                    J'invite mes élèves
                                    <img className="ProgramCreationStylesConfigureIcon" src={require('../assets/goWorkout_Icon.png')} alt="Inviter" />
                                </button>
                            </div>
                        </div>
                        <img src={require('../assets/Image_Pres_Landing3.png')} alt="Des élèves participant à une séance de formation" className="LandingPageStylesImageCoach" />
                    </section>
                </main>
            </div>
            <footer className="LandingPageStylesFooterContainer">
                <div className="LandingFooter-section">
                    <img src={require('../assets/repp_logo_white.png')} alt="Repp Company Logo" className="LandingPageStylesLogoFooter" />
                    <p style={{ marginTop: '20px' }}>© 2024 Repp Company.</p>
                </div>
                <nav className="LandingFooter-section">
                    <h5>Entreprise</h5>
                    <ul>
                        <li><button onClick={navigateToAboutUs} className='LandingFooter-button'>À propos</button></li>
                        <li><button onClick={navigateToFAQ} className='LandingFooter-button'>FAQ</button></li>
                        <li><button onClick={openEmail} className='LandingFooter-button'>Nous contacter</button></li>
                    </ul>
                </nav>
                <nav className="LandingFooter-section">
                    <h5>Plateforme</h5>
                    <ul>
                        <li><button onClick={() => navigateToLogin('Créer mon compte footer')} className='LandingFooter-button'>Créer un compte</button></li>
                        <li><button onClick={() => navigateToLogin('Inviter mes élèves footer')} className='LandingFooter-button'>Inviter vos élèves</button></li>
                    </ul>
                </nav>
            </footer>
        </>
    );
};

export default LandingPage;