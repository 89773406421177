import React, { useEffect, useRef } from 'react';
import lottie, { AnimationItem } from 'lottie-web';
import crossAnimation from '../assets/cross_animation.json';
import '../screens/StudentScreens/WorkoutStyles.css';

const LottieCross = ({ isChecked }) => {
    const animationContainer = useRef<HTMLDivElement>(null);
    const animationInstance = useRef<AnimationItem | null>(null);

    useEffect(() => {
        if (animationContainer.current) {
            animationInstance.current = lottie.loadAnimation({
                container: animationContainer.current,
                renderer: 'svg',
                loop: false,
                autoplay: false, // Changed to false to not autoplay on mount
                animationData: crossAnimation,
            });
            animationInstance.current.setSpeed(5); // Adjust the speed as needed
        }

        return () => animationInstance.current?.destroy();
    }, []);

    useEffect(() => {
        if (animationInstance.current) {
            const direction = isChecked ? 1 : -1;
            animationInstance.current.setDirection(direction);
            animationInstance.current.play();
        }
    }, [isChecked]);

    return <div ref={animationContainer} className="WorkoutStylesCheckboxContainer" />;
};

export default LottieCross;
