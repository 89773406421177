import React, { useState } from 'react';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import './PaymentComponentStyles.css';
import BackButton from './BackButton.tsx';

if (!process.env.REACT_APP_STRIPE_PUBLIC_KEY) {
    throw new Error("Stripe public key is not defined in the environment variables.");
  }
  
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
  
const PaymentComponent = ({ pricingDetails, coachId, name, engagementPeriod, onPaymentSuccess, onBack }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [isProcessing, setIsProcessing] = useState(false);

    const handleSubscribe = async (event) => {
        event.preventDefault();
        if (!stripe || !elements) {
            return;
        }

        setIsProcessing(true);

        try {
            const cardElement = elements.getElement(CardElement);
            if (!cardElement) {
                console.error('Card element not found');
                setIsProcessing(false);
                return;
            }

            // Create a payment method and associate it with the customer
            const paymentMethodResponse = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
            });

            if (paymentMethodResponse.error) {
                console.error('Error creating payment method:', paymentMethodResponse.error.message);
                setIsProcessing(false);
                return;
            }

            const paymentMethodId = paymentMethodResponse.paymentMethod.id;

            // Call your function to handle the subscription creation
            onPaymentSuccess(paymentMethodId);
        } catch (error) {
            console.error('Error during subscription process:', error);
            setIsProcessing(false);
        }
    };

    return (
        <div className="payment-container">
            <div className='PaymentModalLeftContainer'>
                <div className='PaymentModalArrowAndLogoContainer'>
                    <button onClick={onBack} className="PaymentModalBackButtonContainer">
                        <div className="PaymentModalBackButtonCircle">
                            <img src={require('../assets/back_arrow_white.png')} alt="Back" className="PaymentModalBackButtonImage" />
                        </div>
                    </button>
                        <img src={require('../assets/repp_logo_white.png')} className="PaymentModalLogo" alt="Logo"/>
                </div>
                <span className='PaymentModalTitleLeft'>À quelques clics d'une nouvelle vie.</span>
                <span className='PaymentModalDescriptionLeft'>Finaliser le paiement afin de démarrer l'aventure avec {name} !</span>
                <span className='PaymentModalSmallLeft'>Propulsé par stripe</span>
            </div>
            <div className='PaymentModalRightContainer'>
                <div className='PaymentModalArrowAndLogoContainerSmallScreen'>
                    <button onClick={onBack} className="PaymentModalBackButtonContainerSmallScreen">
                        <div className="PaymentModalBackButtonCircle">
                            <img src={require('../assets/back_arrow_black.png')} alt="Back" className="PaymentModalBackButtonImage" />
                        </div>
                    </button>
                        <img src={require('../assets/repp_logo_black.png')} className="PaymentModalLogo" alt="Logo"/>
                </div>
                <div className='PaymentModalTitleContainer'>
                    <span className='PaymentModalTitle'>Moyen de paiement</span>
                </div>
                <div className='PaymentModalInfoAndCardContainer'>
                    <div className="PaymentModalPricingDetails">
                        <div className='PaymentModalDetailLine'>
                            <div className='PaymentModalAbonnementContainer'>
                                <p className="PaymentModalIntitule">Abonnement :</p>
                                <p className="PaymentModalIntitulePetit">/mois</p>
                                {engagementPeriod && <p className="PaymentModalIntitulePetit">Engagement de {engagementPeriod} mois</p>}
                            </div>
                            <span className="PaymentModalPrice">{pricingDetails.baseAmount.toFixed(2)}€</span>
                        </div>
                        <div className='PaymentModalDetailLine'>
                            <span className="PaymentModalIntitule">Frais de service bancaires (2%) :</span>
                            <span className="PaymentModalPrice">{pricingDetails.serviceFee.toFixed(2)}€</span>
                        </div>
                        <div className='PaymentModalDetailLine'>
                            <span className="totalAmount">Total:</span>
                            <span className="totalAmount">{pricingDetails.totalAmount.toFixed(2)}€</span>
                        </div>
                    </div>
                    <form onSubmit={handleSubscribe}>
                        <CardElement />
                        <button className='buttontitle PaymentModalMarginButton' type="submit" disabled={isProcessing || !stripe || !elements}>
                            <span className='buttonText'>
                                {isProcessing ? "Processing…" : `S'abonner`}
                            </span>
                            <img className="ProgramCreationStylesConfigureIcon" src={require('../assets/goWorkout_Icon.png')} alt="Add"/>
                        </button>
                    </form>
                </div>
                <div className='PaymentModalSmallScreenStripeContainer'>
                    <span className='PaymentModalSmallLeftSmallScreen'>Propulsé par stripe</span>
                </div>
            </div>
        </div>
    );
};

export const StripeWrapper = ({ pricingDetails, coachId, name, engagementPeriod, onPaymentSuccess, onBack }) => {
    return (
        <Elements stripe={stripePromise}>
            <PaymentComponent pricingDetails={pricingDetails} coachId={coachId} name={name} engagementPeriod={engagementPeriod} onPaymentSuccess={onPaymentSuccess} onBack={onBack} />
        </Elements>
    );
};

export default StripeWrapper;
