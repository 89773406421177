import React from 'react';
import { useHistory } from 'react-router-dom';

const BeforeCheckout = () => {
    const history = useHistory();

    // Function to navigate to the actual checkout page
    const goToCheckout = () => {
        // Navigate to the checkout page
        history.push('/checkout');
    };

    return (
        <div className="LandingPageStylesMainContainerPayment">
            <div className='LandingPageStylesPaymentTitlePricing'>
                <h2 className="PaymentCardTitleFirst">Votre profil est sauvegardé 🎉.</h2>
                <h2 className="PaymentCardTitle">Payez une fois, utilisez à vie.</h2>
            </div>
            <section className="LandingPageStylesPaymentCardSection">
                <div className="PaymentCard" onClick={() => goToCheckout()}>
                    <p className="PaymentPriceTextTitle">Formule Professionnelle</p>
                    <div className='PaymentPriceContainer'>
                        <p className="PaymentPrice">39€</p>
                        <p className="PaymentPriceOriginal">49€</p>
                    </div>
                    <p className="PaymentPriceTextDescriptionText">Idéal pour les indépendants</p>
                    <ul className="PaymentBenefits">
                        <div className='LandingPageStylesPaymentContainer'>
                            <img src={require('../../assets/BenefitsPricing.png')} alt="Bénéfices de l'offre" className="LandingPageStylesPaymentBenefitsPricingIcon" />
                            <span className='ProfilePageSearchBenefits PaymentPriceBenefits'>Zéro commission sur vos revenus</span>
                        </div>
                        <div className='LandingPageStylesPaymentContainer'>
                            <img src={require('../../assets/BenefitsPricing.png')} alt="Bénéfices de l'offre" className="LandingPageStylesPaymentBenefitsPricingIcon" />
                            <span className='ProfilePageSearchBenefits PaymentPriceBenefits'>Utilisation à vie</span>
                        </div>
                        <div className='LandingPageStylesPaymentContainer'>
                            <img src={require('../../assets/BenefitsPricing.png')} alt="Bénéfices de l'offre" className="LandingPageStylesPaymentBenefitsPricingIcon" />
                            <span className='ProfilePageSearchBenefits PaymentPriceBenefits'>Image professionnelle</span>
                        </div>
                        <div className='LandingPageStylesPaymentContainer'>
                            <img src={require('../../assets/BenefitsPricing.png')} alt="Bénéfices de l'offre" className="LandingPageStylesPaymentBenefitsPricingIcon" />
                            <span className='ProfilePageSearchBenefits PaymentPriceBenefits'>Paiements automatisés</span>
                        </div>
                        <div className='LandingPageStylesPaymentContainer'>
                            <img src={require('../../assets/BenefitsPricing.png')} alt="Bénéfices de l'offre" className="LandingPageStylesPaymentBenefitsPricingIcon" />
                            <span className='ProfilePageSearchBenefits PaymentPriceBenefits'>Personnalisation totale de vos offres</span>
                        </div>
                    </ul>
                    <div className="LandingPageButtonsContainerFourthSection LandingPageStylesAddFullWidth">
                        <button onClick={() => goToCheckout()} className="VioletButton LandingPageStylesAddWidthLastButton">
                            Je finalise mon profil !
                            <img className="ProgramCreationStylesConfigureIcon" src={require('../../assets/goWorkout_Icon.png')} alt="Inviter" />
                        </button>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default BeforeCheckout;