import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import './../ProfilePageStyles.css';
import './PaymentProfilePageStyles.css';
import ImageSelectionModal from '../../components/ImageSelectionModal.tsx';
import PaymentParametersModal from './PaymentParametersModal.tsx';
import { firestore, auth, storage } from '../../firebase.js';
import { collection, getDocs, doc, getDoc, updateDoc, serverTimestamp, setDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import PaymentEditProfileModal from './PaymentEditProfile.tsx';
import '../../normalize.css';
import { getFunctions, httpsCallable } from 'firebase/functions';
import FooterPayment from '../../components/FooterPayment.tsx';
import OneTimePaymentEditModal from './OneTimePaymentEditModal.tsx';
import MembershipEditModal from './MembershipEditModal.tsx';
import BenefitsIcon from './BenefitsIcon.tsx';

function ProfilePagePayment() {

  const history = useHistory();
  const [activeIcon, setActiveIcon] = useState<'personne' | 'eleves' | null>(null);
  const [bannerUri, setBannerUri] = useState('');
  const [profilePicUri, setProfilePicUri] = useState('');
  const [Name, setName] = useState('');
  const [isImageModalVisible, setImageModalVisible] = useState(false);
  const [selectedImageType, setSelectedImageType] = useState<'profile' | 'banner' | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isParametresModalVisible, setParametresModalVisible] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [engagementPeriod, setEngagementPeriod] = useState('');
  const [coachingFee, setCoachingFee] = useState('');
  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const [isOneTimeEditModalVisible, setIsOneTimeEditModalVisible] = useState(false);
  const [isMembershipEditModalVisible, setIsMembershipEditModalVisible] = useState(false);
  const [stripeAccountSetupComplete, setStripeAccountSetupComplete] = useState(false);
  const functions = getFunctions();
  const [initialProfileData, setInitialProfileData] = useState({});
  const [benefit1, setBenefit1] = useState<string[]>([]);
  const [benefit2, setBenefit2] = useState<string[]>([]);
  const [benefit3, setBenefit3] = useState<string[]>([]);
  const [benefit4, setBenefit4] = useState<string[]>([]);
  const [oneTimeBenefit1, setoneTimeBenefit1] = useState<string[]>([]);
  const [oneTimeBenefit2, setoneTimeBenefit2] = useState<string[]>([]);
  const [oneTimeBenefit3, setoneTimeBenefit3] = useState<string[]>([]);
  const [oneTimeBenefit4, setoneTimeBenefit4] = useState<string[]>([]);
  const [paymentFee, setPaymentFee] = useState('');
  const createStripeConnectAccount = httpsCallable(functions, 'createStripeConnectAccount');
  const [triggerFetch, setTriggerFetch] = useState(0); // Using a counter as an example
  const [isStripeProcessing, setIsStripeProcessing] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [showSubscriptionBlock, setShowSubscriptionBlock] = useState(true);
  const [showOneTimePaymentBlock, setShowOneTimePaymentBlock] = useState(true);
  const [buttonColor, setButtonColor] = useState('#005B96'); // Default color

  function capitalizeName(name) {
    if (!name) return ""; // Return an empty string if name is falsy
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  }

  interface CreatePriceRequest {
    amount: number;  // The amount in cents
    coachName: string;
    userId: string;
  }

  interface CreatePriceResponse {
    priceId: string;
  }

  interface ConnectAccountResponse {
    accountId: string;
  }

  interface StripeAccountLinkResponse {
    url: string;
  }

  interface MembershipData {
    coachingFee: string;
    engagementPeriod: string;
    benefit1: string;
    benefit2: string;
    benefit3: string;
    benefit4: string;
  }

  interface OneTimePaymentData {
    paymentFee: string;
    oneTimeBenefit1: string;
    oneTimeBenefit2: string;
    oneTimeBenefit3: string;
    oneTimeBenefit4: string;
  }

  const handleGenerateStripeLink = async () => {
    const user = auth.currentUser;
    if (!user) return;

    setIsStripeProcessing(true);

    const userDocRef = doc(firestore, 'users', user.uid);
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists() && userDoc.data().coachStripeAccountId && !userDoc.data().stripeAccountSetupComplete) {
      try {
        const generateLink = httpsCallable<{}, StripeAccountLinkResponse>(functions, 'generateStripeAccountLinkPaymentProject');
        const response = await generateLink({ accountId: userDoc.data().coachStripeAccountId });
        // Directly use the fetched URL for redirection instead of waiting for state update
        const fetchedStripeAccountLink = response.data.url;
        if (fetchedStripeAccountLink) {
          console.log(fetchedStripeAccountLink);
          // Proceed with redirection or other actions using fetchedStripeAccountLink
          window.location.href = fetchedStripeAccountLink;
        }
      } catch (error) {
        console.error('Error fetching Stripe account link:', error);
        setIsStripeProcessing(false);
      }
    } else {
      console.error("Stripe account ID not found or setup is already complete.");
    }
  };

  useEffect(() => {
    if (isEditModalVisible) {
      // Disable scrolling on body
      document.body.style.overflow = 'hidden';
    } else {
      // Re-enable scrolling on body
      document.body.style.overflow = 'auto';
    }

    // Cleanup function to ensure scrolling is re-enabled when the component is unmounted or modal visibility changes
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isEditModalVisible]);

  useEffect(() => {
    let isActive = true;

    const fetchData = async () => {
      const user = auth.currentUser;
      if (user && isActive) {
        try {
          const userDocRef = doc(firestore, 'users', user.uid);
          const userDoc = await getDoc(userDocRef);
          // Initialize membershipData with default values
          let membershipData = { coachingFee: '', engagementPeriod: '', benefit1: '', benefit2: '', benefit3: '', benefit4: '' };
          let oneTimeData = { paymentFee: '', oneTimeBenefit1: '', oneTimeBenefit2: '', oneTimeBenefit3: '', oneTimeBenefit4: '' };

          if (userDoc.exists()) {
            setName(userDoc.data().Name || '');
            setTitle(userDoc.data().title || '');
            setDescription(userDoc.data().description || '');
            setProfilePicUri(userDoc.data().profilePicUri || '');
            setBannerUri(userDoc.data().bannerUri || '');
            setStripeAccountSetupComplete(userDoc.data().stripeAccountSetupComplete || false);
            setShowOneTimePaymentBlock(userDoc.data().showOneTimePaymentBlock);
            setShowSubscriptionBlock(userDoc.data().showSubscriptionBlock);
            setButtonColor(userDoc.data().buttonColor || '#005B96')

            // Fetch OneTimePayment data
            const oneTimePaymentDocRef = doc(firestore, 'users', user.uid, 'onetimepayment', 'oneTimePaymentInfo');
            const oneTimePaymentDoc = await getDoc(oneTimePaymentDocRef);
            if (oneTimePaymentDoc.exists()) {
              const fetchedData = oneTimePaymentDoc.data();
              setPaymentFee(fetchedData.paymentFee || '');
              setoneTimeBenefit1(fetchedData.oneTimeBenefit1 || '');
              setoneTimeBenefit2(fetchedData.oneTimeBenefit2 || '');
              setoneTimeBenefit3(fetchedData.oneTimeBenefit3 || '');
              setoneTimeBenefit4(fetchedData.oneTimeBenefit4 || '');
            }

            // Fetch Membership data, including benefits
            const membershipDocRef = doc(firestore, 'users', user.uid, 'membership', 'membershipInfo');
            const membershipDoc = await getDoc(membershipDocRef);
            if (membershipDoc.exists()) {
              const fetchedData = membershipDoc.data();
              setCoachingFee(fetchedData.coachingFee || '');
              setEngagementPeriod(fetchedData.engagementPeriod || '');
              setBenefit1(fetchedData.benefit1 || '');
              setBenefit2(fetchedData.benefit2 || '');
              setBenefit3(fetchedData.benefit3 || '');
              setBenefit4(fetchedData.benefit4 || '');
            }

            if (!userDoc.data().coachStripeAccountId) {
              console.log("Creating Stripe link in progress");
              // Call the function to create a Stripe Connect account
              const connectAccountResult = await createStripeConnectAccount({ email: user.email, role: 'coach' });
              const connectAccountData = connectAccountResult.data as ConnectAccountResponse;
              const accountId = connectAccountData.accountId;

              // Update Firestore with the new accountId
              await updateDoc(userDocRef, { coachStripeAccountId: accountId, stripeAccountSetupComplete: false });

              window.location.reload();
            }

            // After all data is fetched and state updates are handled, set initial profile data
            setInitialProfileData({
              Name: userDoc.data().Name || '',
              title: userDoc.data().title || '',
              description: userDoc.data().description || '',
              profilePicUri: userDoc.data().profilePicUri || '',
              bannerUri: userDoc.data().bannerUri || '',
              coachingFee: coachingFee || '', // Fetched from Membership data
              engagementPeriod: engagementPeriod || '', // Fetched from Membership data
              benefit1: membershipData.benefit1 || '',
              benefit2: membershipData.benefit2 || '',
              benefit3: membershipData.benefit3 || '',
              benefit4: membershipData.benefit4 || '',
              oneTimeBenefit1: oneTimeData.oneTimeBenefit1 || '',
              oneTimeBenefit2: oneTimeData.oneTimeBenefit2 || '',
              oneTimeBenefit3: oneTimeData.oneTimeBenefit3 || '',
              oneTimeBenefit4: oneTimeData.oneTimeBenefit4 || '',
              showSubscriptionBlock: userDoc.data().showSubscriptionBlock || true,
              showOneTimePaymentBlock: userDoc.data().showOneTimePaymentBlock || true,
              buttonColor: userDoc.data().buttonColor || '#005B96',
            });
          }
        } catch (error) {
          console.error("Error fetching data", error);
        } finally {
          if (isActive) {
            setIsLoading(false);
          }
        }
      }
    };

    fetchData();

    return () => {
      isActive = false; // Cleanup
    };
  }, [triggerFetch]);

  const copyToClipboard = () => {
    const user = auth.currentUser;
    if (user) {
      const coachUrl = `https://reppwebapp.fr/coach-profile?coachId=${user.uid}`;
      navigator.clipboard.writeText(coachUrl).then(() => {
        // Show copied message
        setIsCopied(true);
        // Hide message after 2 seconds
        setTimeout(() => setIsCopied(false), 2000);
      }).catch(err => {
        console.error('Failed to copy: ', err);
      });
    }
  };

  const handleEditImage = (type) => {
    setSelectedImageType(type);
    const fileInput = document.getElementById('fileInput');
    if (fileInput) {
      fileInput.click();
    } else {
      console.error('File input not found');
    }
  };

  const handleImageSelect = (event, type) => {
    const file = event.target.files[0];
    handleFileUpload(file, type);
  };

  const handleFileUpload = async (file, type) => {
    if (!file) {
      console.log('No file selected');
      return;
    }

    try {
      const uploadUri = type === 'profile' ? 'profile_pics/' : 'banners/';
      const filename = `${uploadUri}${new Date().getTime()}-${file.name}`;
      const storageRef = ref(storage, filename);

      await uploadBytes(storageRef, file);
      const url = await getDownloadURL(storageRef);

      const user = auth.currentUser;
      if (user) {
        // Directly updating the user's root document with the new image URL
        const userDocRef = doc(firestore, 'users', user.uid);
        const updateData = type === 'profile' ? { profilePicUri: url } : { bannerUri: url };
        await updateDoc(userDocRef, updateData);

        // Update local state with the new image URL
        type === 'profile' ? setProfilePicUri(url) : setBannerUri(url);
      }
    } catch (error) {
      console.error('Error during file upload:', error);
    }
  };

  const handleCloseModal = () => {
    setParametresModalVisible(false);
  };

  const toggleOneTimePaymentEditModal = () => {
    setIsOneTimeEditModalVisible(!isOneTimeEditModalVisible);
  };

  const toggleMembershipEditModal = () => {
    setIsMembershipEditModalVisible(!isMembershipEditModalVisible);
  };

  const handleSaveMembership = async (updatedData, initialData) => {
    setIsStripeProcessing(true);
    const user = auth.currentUser;

    if (user) {
      try {
        // Prepare the payload for updating the user's document. Include all updated fields.
        let updatePayload = {
          ...updatedData,
        };

        // Update the user's root document directly
        const userDocRef = doc(firestore, 'users', user.uid, 'membership', 'membershipInfo');

        if (updatedData.coachingFee !== initialData.coachingFee && updatedData.coachingFee !== 0) {

          // Calculate the new total coaching fee if it has changed
          const newCoachingFee = parseFloat(updatedData.coachingFee);
          const serviceFee = newCoachingFee * 0.02; // Assuming a 2% service fee
          let coachingFeeTotal = newCoachingFee + serviceFee;
          coachingFeeTotal = Math.round(coachingFeeTotal * 100) / 100;

          // Create a new Stripe price if the coaching fee has changed
          const createStripePrice = httpsCallable<CreatePriceRequest, CreatePriceResponse>(getFunctions(), 'createStripePrice');
          const priceResponse = await createStripePrice({
            amount: Math.round(coachingFeeTotal * 100), // Convert to cents
            coachName: Name,
            userId: user.uid,
          });

          // Include the new price ID and total coaching fee in the update payload
          updatePayload = {
            ...updatePayload,
            coachingFee: newCoachingFee, // Ensure the coachingFee is stored as a number
            priceId: priceResponse.data.priceId,
            coachingFeeTotal: coachingFeeTotal,
          };
        }

        // Perform the update
        await updateDoc(userDocRef, updatePayload);

        // Update local state to reflect changes
        setBenefit1(updatedData.benefit1)
        setBenefit2(updatedData.benefit2)
        setBenefit3(updatedData.benefit3)
        setBenefit4(updatedData.benefit4)

        // Close the modal and trigger a re-fetch or state update as necessary
        setIsMembershipEditModalVisible(false);
        setTriggerFetch(prev => prev + 1); // Optionally, trigger a re-fetch or state update
        setIsStripeProcessing(false);
      } catch (error) {
        console.error('Error updating profile:', error);
        setIsStripeProcessing(false);
      }
    } else {
      console.error('No user is signed in.');
      setIsStripeProcessing(false);
    }
  };

  const handleSaveOneTimePayment = async (updatedData, initialData) => {
    setIsStripeProcessing(true);
    const user = auth.currentUser;

    if (user) {
      try {
        // Prepare the payload for updating the user's document. Include all updated fields.
        let updatePayload = {
          ...updatedData,
        };

        // Update the user's root document directly
        const userDocRef = doc(firestore, 'users', user.uid, 'onetimepayment', 'oneTimePaymentInfo');

        if (updatedData.paymentFee !== initialData.paymentFee) {
          // Calculate the new total coaching fee if it has changed
          const newPaymentFee = parseFloat(updatedData.paymentFee);
          const serviceFee = newPaymentFee * 0.02; // Assuming a 2% service fee
          let paymentFeeTotal = newPaymentFee + serviceFee;
          paymentFeeTotal = Math.round(paymentFeeTotal * 100) / 100;

          // Create a new Stripe price if the coaching fee has changed
          const createStripePrice = httpsCallable<CreatePriceRequest, CreatePriceResponse>(getFunctions(), 'createStripePriceForOneTimePayment');
          const priceResponse = await createStripePrice({
            amount: Math.round(paymentFeeTotal * 100), // Convert to cents
            coachName: Name,
            userId: user.uid,
          });

          // Include the new price ID and total coaching fee in the update payload
          updatePayload = {
            ...updatePayload,
            paymentFee: newPaymentFee, // Ensure the coachingFee is stored as a number
            priceId: priceResponse.data.priceId,
            paymentFeeTotal: paymentFeeTotal,
          };
        }

        // Perform the update
        await updateDoc(userDocRef, updatePayload);

        setoneTimeBenefit1(updatedData.oneTimeBenefit1)
        setoneTimeBenefit2(updatedData.oneTimeBenefit2)
        setoneTimeBenefit3(updatedData.oneTimeBenefit3)
        setoneTimeBenefit4(updatedData.oneTimeBenefit4)

        // Close the modal and trigger a re-fetch or state update as necessary
        setIsOneTimeEditModalVisible(false);
        setTriggerFetch(prev => prev + 1); // Optionally, trigger a re-fetch or state update
        setIsStripeProcessing(false);
      } catch (error) {
        console.error('Error updating profile:', error);
        setIsStripeProcessing(false);
      }
    } else {
      console.error('No user is signed in.');
      setIsStripeProcessing(false);
    }
  };

  const handleSaveProfile = async (updatedData) => {
    setIsStripeProcessing(true);
    const user = auth.currentUser;

    if (user) {
      try {
        // Prepare the payload for updating the user's document. Include all updated fields.
        let updatePayload = {
          ...updatedData,
          profilePicUri, // Ensure current profile picture URI is preserved
          bannerUri, // Ensure current banner URI is preserved
        };

        // Update the user's root document directly
        const userDocRef = doc(firestore, 'users', user.uid);

        // Perform the update
        await updateDoc(userDocRef, updatePayload);

        // Update local state to reflect changes
        setName(updatedData.Name);
        setTitle(updatedData.title);
        setDescription(updatedData.description);
        setShowOneTimePaymentBlock(updatedData.showOneTimePaymentBlock);
        setShowSubscriptionBlock(updatedData.showSubscriptionBlock);
        setButtonColor(updatedData.buttonColor);

        // Close the modal and trigger a re-fetch or state update as necessary
        setEditModalVisible(false);
        setTriggerFetch(prev => prev + 1); // Optionally, trigger a re-fetch or state update
        setIsStripeProcessing(false);
      } catch (error) {
        console.error('Error updating profile:', error);
        setIsStripeProcessing(false);
      }
    } else {
      console.error('No user is signed in.');
      setIsStripeProcessing(false);
    }
  };

  return (
    <>
      <input
        type="file"
        id="fileInput"
        style={{ display: 'none' }}
        onChange={(event) => handleImageSelect(event, selectedImageType)}
        accept="image/*"
      />
      {isParametresModalVisible && (
        <div onClick={handleCloseModal}></div>
      )}

      <div className="SuprememainContainerProfile">
        <div className="leftsideContainerProfile">
          <div className="LogoIconContainerProfile">
            <img className="LogoProfile" src={require('../../assets/ReReReDesign.png')} alt="Logo" />
          </div>
          <FooterPayment setActiveIcon={setActiveIcon} activeIcon={activeIcon} />
        </div>
        <div className='ProfilePageContent'>
          <div className='ProfileScreenContentContainer'>
            <div className='FirstLayerProfileScreenContentContainer'>
              <div className='TitleProfileScreenContentContainer'>
                Profil
              </div>
            </div>
            {isLoading ? (
              <div className="ProfilePageLoadingContainer">
                <div className="spinner"></div>
              </div>
            ) : (
              <div>
                <button className='ProfilePageBannerButton' onClick={() => handleEditImage('banner')}>
                  <div className="ProfilePageBannerContainer">
                    <img
                      className="ProfilePageBannerImage"
                      src={bannerUri || require('../../assets/default_banner.png')}
                      alt="Banner"
                    />
                  </div>
                </button>
                <div className="ProfilePageProfileImageContainer">
                  <button onClick={() => handleEditImage('profile')}>
                    <img
                      className="ProfilePageProfileImage"
                      src={profilePicUri || require('../../assets/default_profile_picture.png')}
                      alt="Profile"
                    />
                  </button>
                </div>
                <ImageSelectionModal
                  visible={isImageModalVisible}
                  onEdit={handleEditImage}
                  onCancel={() => setImageModalVisible(false)}
                />
                <div className='ProfilePageContainerMenu'>
                  <button className='ProfilePageButtonHoverEffect' onClick={() => setParametresModalVisible(true)}>
                    <div className="ProfilePageMenuContainer">
                      <img className="ProfilePageMenu" src={require('../../assets/menu.png')} alt="Menu" />
                    </div>
                  </button>
                </div>
                <div className="ProfilePageNameContainer">
                  <span className="ProfilePageName">{capitalizeName(Name)}</span>
                </div>
                <div>
                  <div className='ProfilePageInfoContainer'>
                    <span className='ProfilePageInfo'>{title}</span>
                    <span className='ProfilePageInfo'>{description}</span>
                  </div>
                </div>
                <div className="ProfilePageActionButtons">
                  <button className="ProfilePageSearchButton ProfilePageAddMarginRight" onClick={() => setEditModalVisible(true)}>Modifier votre profil</button>
                  {stripeAccountSetupComplete && (
                    <>
                      <button className="ProfilePageSearchButtonShare ProfilePageAddMarginLeft" onClick={copyToClipboard}>
                        Partager votre profil
                      </button>
                      {isCopied && <span style={{ marginLeft: '10px', fontSize: '14px' }}>Copié !</span>}
                    </>
                  )}
                  {!stripeAccountSetupComplete && (
                    <div className='PaymentProfilePageFinaliserContainer ProfilePageAddMarginLeft'>
                      <button onClick={handleGenerateStripeLink} style={{ backgroundColor: buttonColor }} className="BlueButton PaymentAddWidthButton PaymentProfilePageFinaliserAddPadding">
                        <div className='PaymentStylesConfigureButtonContainer'>
                          <span className='buttonText'>Finaliser la configuration pour recevoir vos paiements</span>
                          {isStripeProcessing ? (
                            <div className='spinnerContainer' style={{ display: 'inline-block', marginLeft: '10px' }}>
                              <div className="spinnerWaitStripe"></div>
                            </div>
                          ) : (
                            <img className="PaymentStylesConfigureIcon" src={require('../../assets/goWorkout_Icon.png')} alt="Add" />
                          )}
                        </div>
                      </button>
                    </div>
                  )}
                </div>
                <>
                </>
                <div className={`PaymentProfilePageActionButtons ${!stripeAccountSetupComplete ? 'PaymentDisabledDiv' : ''}`}>
                  {showOneTimePaymentBlock && (
                    <div className='PaymentProfilePageContainer'>
                      <div className='PaymentProfilePageContainerEdit'>
                        <button
                          className="ProgramCreationStylesEditIconContainer"
                          onClick={toggleOneTimePaymentEditModal}
                        >
                          <img className="ProgramCreationStylesEditIcon" src={require('../../assets/edit_icon.png')} alt="Edit" />
                        </button>
                      </div>
                      <span className='ProfilePageTitleFutura'>Coaching en présentiel</span>
                      <span className='ProfilePageDescriptionContainerRight'>Aucun engagement</span>
                      {oneTimeBenefit1 && (
                        <div className='ProfilePageSearchBenefitsContainer'>
                          <BenefitsIcon className="ProfilePageSearchBenefitsIcon" color={buttonColor} />
                          <span className='ProfilePageSearchBenefits'>{oneTimeBenefit1}</span>
                        </div>
                      )}
                      {oneTimeBenefit2 && (
                        <div className='ProfilePageSearchBenefitsContainer'>
                          <BenefitsIcon className="ProfilePageSearchBenefitsIcon" color={buttonColor} />
                          <span className='ProfilePageSearchBenefits'>{oneTimeBenefit2}</span>
                        </div>
                      )}
                      {oneTimeBenefit3 && (
                        <div className='ProfilePageSearchBenefitsContainer'>
                          <BenefitsIcon className="ProfilePageSearchBenefitsIcon" color={buttonColor} />
                          <span className='ProfilePageSearchBenefits'>{oneTimeBenefit3}</span>
                        </div>
                      )}
                      {oneTimeBenefit4 && (
                        <div className='ProfilePageSearchBenefitsContainer'>
                          <BenefitsIcon className="ProfilePageSearchBenefitsIcon" color={buttonColor} />
                          <span className='ProfilePageSearchBenefits'>{oneTimeBenefit4}</span>
                        </div>
                      )}
                      <button style={{ backgroundColor: buttonColor }} className="BlueButton BlueButtonAddPadding ProfilePageStylesAddMargin">
                        <span className='buttonText'>Payer {paymentFee}€</span>
                      </button>
                    </div>
                  )}
                  {showSubscriptionBlock && (
                    <div className='PaymentProfilePageContainer'>
                      <div className='PaymentProfilePageContainerEdit'>
                        <button
                          className="ProgramCreationStylesEditIconContainer"
                          onClick={toggleMembershipEditModal}
                        >
                          <img className="ProgramCreationStylesEditIcon" src={require('../../assets/edit_icon.png')} alt="Edit" />
                        </button>
                      </div>
                      <span className='ProfilePageTitleFutura'>Coaching en ligne</span>
                      {/* Check for engagementPeriod and display conditionally */}
                      {engagementPeriod ? (
                        <span className='ProfilePageDescriptionContainerRight'>Engagement {engagementPeriod} mois</span>
                      ) : (
                        <span className='ProfilePageDescriptionContainerRight'>Aucun engagement</span>
                      )}
                      {benefit1 && (
                        <div className='ProfilePageSearchBenefitsContainer'>
                          <BenefitsIcon className="ProfilePageSearchBenefitsIcon" color={buttonColor} />
                          <span className='ProfilePageSearchBenefits'>{benefit1}</span>
                        </div>
                      )}
                      {benefit2 && (
                        <div className='ProfilePageSearchBenefitsContainer'>
                          <BenefitsIcon className="ProfilePageSearchBenefitsIcon" color={buttonColor} />
                          <span className='ProfilePageSearchBenefits'>{benefit2}</span>
                        </div>
                      )}
                      {benefit3 && (
                        <div className='ProfilePageSearchBenefitsContainer'>
                          <BenefitsIcon className="ProfilePageSearchBenefitsIcon" color={buttonColor} />
                          <span className='ProfilePageSearchBenefits'>{benefit3}</span>
                        </div>
                      )}
                      {benefit4 && (
                        <div className='ProfilePageSearchBenefitsContainer'>
                          <BenefitsIcon className="ProfilePageSearchBenefitsIcon" color={buttonColor} />
                          <span className='ProfilePageSearchBenefits'>{benefit4}</span>
                        </div>
                      )}
                      <button style={{ backgroundColor: buttonColor }} className="BlueButton BlueButtonAddPadding ProfilePageStylesAddMargin">
                        <span className='buttonText'>S'abonner pour {coachingFee}€/mois</span>
                      </button>
                    </div>
                  )}
                </div>
                {isEditModalVisible && (
                  <PaymentEditProfileModal
                    isVisible={isEditModalVisible}
                    onCancel={() => setEditModalVisible(false)}
                    onSave={(updatedData) => handleSaveProfile(updatedData)}
                    initialData={{ Name, title, description, showSubscriptionBlock, showOneTimePaymentBlock, buttonColor }}
                  />
                )}
                <PaymentParametersModal
                  visible={isParametresModalVisible}
                  setVisible={setParametresModalVisible}
                  coachName={Name} // Passing the coach's name as a prop
                  isStripeProcessing={isStripeProcessing} // Pass this prop to control the spinner.
                />
                <OneTimePaymentEditModal
                  isVisible={isOneTimeEditModalVisible}
                  onCancel={() => setIsOneTimeEditModalVisible(false)}
                  onSave={(updatedData) => handleSaveOneTimePayment(updatedData, initialProfileData)}
                  initialData={{
                    buttonColor,
                    oneTimeBenefit1: oneTimeBenefit1, // Assuming this state holds your initial benefits data
                    oneTimeBenefit2: oneTimeBenefit2, // Assuming this state holds your initial benefits data
                    oneTimeBenefit3: oneTimeBenefit3, // Assuming this state holds your initial benefits data
                    oneTimeBenefit4: oneTimeBenefit4, // Assuming this state holds your initial benefits data
                    paymentFee: paymentFee,
                  }}
                  isStripeProcessing={isStripeProcessing} // Pass this prop to control the spinner.
                />
                <MembershipEditModal
                  isVisible={isMembershipEditModalVisible}
                  onCancel={() => setIsMembershipEditModalVisible(false)}
                  onSave={(updatedData) => handleSaveMembership(updatedData, initialProfileData)}
                  initialData={{
                    buttonColor,
                    benefit1: benefit1, // Assuming this state holds your initial benefits data
                    benefit2: benefit2, // Assuming this state holds your initial benefits data
                    benefit3: benefit3, // Assuming this state holds your initial benefits data
                    benefit4: benefit4, // Assuming this state holds your initial benefits data
                    coachingFee: coachingFee, // Assuming this state holds your initial coaching fee
                    engagementPeriod: engagementPeriod, // Assuming this state holds your initial engagement period
                  }}
                  isStripeProcessing={isStripeProcessing} // Pass this prop to control the spinner.
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
export default ProfilePagePayment;