// Import React's useState hook
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import './DemoGenderSelectionStyles.css';
// Import the Modal Component
import UserInformationModal from './UserInformationModal.tsx'; // Adjust the path as necessary

function CreateAccountPayment() {
    const history = useHistory();
    // State to control the visibility of the modal
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedGender, setSelectedGender] = useState('');

    const handleGenderSelect = (gender) => {
        setSelectedGender(gender); // Save selected gender to state
        setIsModalVisible(true); // Show the modal
    };

    // Handle what happens after modal data is submitted
    const handleModalSubmit = (name, email) => {
        setIsModalVisible(false); // Hide the modal
        // Here you can handle the data (name, email, and gender) as needed, e.g., saving to Firestore
        // Redirect or perform further actions
        history.push('/ProfileDemo', { name, gender: selectedGender });
    };

    const closeModal = () => {
        setIsModalVisible(false);
    };    

    return (
        <div className="gender-selection-container">
            <div className='DemoGenderStylesMainContainer'>
                <h1 className='DemoGenderStylesTitle'>Quel type de coach êtes-vous ?</h1>
                <div className='DemoGenderStylesContainerGenderChoice'>
                    <div className="gender-card" onClick={() => handleGenderSelect('Homme')}>
                        <img src={require('../../assets/Male_avatar_demo.png')} alt="Homme" />
                        <p>Coach Masculin</p>
                    </div>
                    <div className="gender-card" onClick={() => handleGenderSelect('Femme')}>
                        <img src={require('../../assets/Female_avatar_demo.png')} alt="Femme" />
                        <p>Coach Féminin</p>
                    </div>
                </div>
            </div>
            {isModalVisible && (
                <UserInformationModal 
                    visible={isModalVisible} 
                    onSubmit={handleModalSubmit} 
                    onClose={closeModal} // Add this line
                />
            )}
        </div>
    );
}

export default CreateAccountPayment;