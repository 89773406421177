import React, { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import BackButton from '../../components/BackButton.tsx';
import './WeekWorkoutStyles.css';
import '../StudentScreens/ProgramCreationStyles.css';
import Footer from '../../components/Footer.tsx';
import { useSelectedUser } from '../../components/SelectedUserContext.tsx';
import WeekWorkoutEditor from '../../components/WeekWorkoutEditor.tsx';
import { StudentSelector } from '../../components/StudentSelector.tsx';
import { UserContext } from '../../components/UserContext.tsx';
import { BlockConfigProvider, useBlockConfig } from '../../components/BlockConfigContext.tsx';

const WeekWorkout = () => {
  const location = useLocation();
  const { userRole } = useContext(UserContext);
  const [activeIcon, setActiveIcon] = useState<'personne' | 'calendrier' | 'stats' | 'maison' | 'invitation' | 'eleves' | 'parametres' | null>(null);
  const { selectedUserId } = useSelectedUser();
  const userId = location.state?.userId || selectedUserId; // Retrieve userId from the passed state
  const { showBlockConfig } = useBlockConfig();
  const [isNotMobile, setIsNotMobile] = useState(window.innerWidth > 950);

  return (
      <div className="SuprememainContainerProfile">
        <div className="leftsideContainerProfile" style={{ display: showBlockConfig || !isNotMobile ? 'none' : 'flex' }}>
          <div className="LogoIconContainerProfile">
            <img className="LogoProfile" src={require('../../assets/ReReReDesign.png')} alt="Logo" />
          </div>
          <Footer setActiveIcon={setActiveIcon} activeIcon={activeIcon} />
        </div>
        <div className="ProfilePageContent" style={{ maxWidth: showBlockConfig ? '1400px' : '1000px' }}>
          <div className="ProfileScreenContentContainer" style={{ maxWidth: showBlockConfig ? '1400px' : '1000px' }}>
            <div className='FirstLayerWeekWorkoutScreenContentContainer'>
              <div className='TitleProfileScreenContentContainer'>
                <BackButton to="/ProgramCreation" arrowColor='black' />
                <span className='WeekWorkoutHideMobile'>Programme de la semaine</span>
                <span className='WeekWorkoutShowMobile'>Programme</span>
              </div>
            </div>

            {userRole === 'coach' && <StudentSelector />}

            {userId && <WeekWorkoutEditor key={selectedUserId || userId} userIdProp={selectedUserId || userId} />}

          </div>
        </div>
      </div>
  );
};

export default WeekWorkout;