import React from 'react';

const BenefitsIcon = ({ color = "currentColor", className, style = {} }) => (
    <svg width="54px" height="49px" viewBox="0 0 54 49" className={className} style={{ color, ...style }} xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <g fill="currentColor">
        <path d="M0.487 28.533L18.772 48.405c.761.827 2.078.786 2.786-.086l31.03-39.489c.661-.815.509-2.017-.334-2.642L45.392.362c-.805-.597-1.94-.44-2.553.352L21.797 27.904a1.75 1.75 0 0 1-2.76-1.825l-9.068-9.07c-.725-.725-1.902-.718-2.619.014L.526 25.996c-.685.7-.667 1.815.487 2.537z"/>
      </g>
    </g>
  </svg>
);

export default BenefitsIcon;