import React, { useContext, useEffect, useState } from 'react'
import { ChatContext } from './context/ChatContext.js';
import { AuthContext } from './context/AuthContext.js';
import { Timestamp, arrayUnion, doc, getDoc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { firestore, storage } from '../firebase.js';
import { v4 as uuid } from "uuid";
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';

const InputChat = () => {

  

  const [text, setText] = useState("")
  const [media, setMedia] = useState<File | null>(null);
  const [isCoach, setIsCoach] = useState(false);

  const {currentUser} = useContext(AuthContext)
  const {data} = useContext(ChatContext)

  useEffect(() => {
    // Fetch the role of the current user
    const fetchUserRole = async () => {
      if (currentUser) {
        const userRef = doc(firestore, 'users', currentUser.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          setIsCoach(userSnap.data().role === 'coach'); // Check if the user is a coach
        }
      }
    };
    fetchUserRole();
  }, [currentUser]);

  const sendSubscriptionLink = async () => {
    // Logic to send a subscription link message in the chat
    // This can be a special message with a flag or type indicating a subscription link
    const subscriptionLinkMessage = {
      id: uuid(),
      senderId: currentUser.uid,
      date: Timestamp.now(),
      text: `Clique ici pour devenir mon élève !`, // Custom text or structure
      subscriptionLink: true // Flag to indicate this is a subscription link message
    };

    try {
      await updateDoc(doc(firestore, "chats", data.chatId), {
        messages: arrayUnion(subscriptionLinkMessage)
      });

      await updateDoc(doc(firestore, "userChats", currentUser.uid), {
        [data.chatId + ".lastMessage"]: {
          text: "Lien d'abonnement envoyé"
        },
        [data.chatId + ".date"]: serverTimestamp()
      });
  
      // Update the userChats collection for the receiver
      await updateDoc(doc(firestore, "userChats", data.user.uid), {
        [data.chatId + ".lastMessage"]: {
          text: "Lien d'abonnement reçu"
        },
        [data.chatId + ".date"]: serverTimestamp()
      });

     

    } catch (error) {
      console.error("Error sending subscription link: ", error);
    }
  };


  

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevents the default action of the enter key in a form
      handleSend();
    }
  };

 

  const handleSend = async()=>{
    

    if (media) {
      const storageRef = ref(storage, uuid());
      const uploadTask = uploadBytesResumable(storageRef, media);

      uploadTask.on(
        "state_changed",
        null,
        (error) => {
          console.error("Upload failed", error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          await updateDoc(doc(firestore, "chats", data.chatId), {
            messages: arrayUnion({
              id: uuid(),
              text,
              senderId: currentUser.uid,
              date: Timestamp.now(),
              media: downloadURL, // Updated from img to media
              type: media.type.startsWith('video/') ? 'video' : 'image', // Add media type
            }),
          });
        });
      }
    );

    }
    else{
await updateDoc(doc(firestore,"chats",data.chatId),{
  messages: arrayUnion({
    id: uuid(),
    text,
    senderId:currentUser.uid,
    date: Timestamp.now(),

  }),

});

    }
    await updateDoc(doc(firestore,"userChats",currentUser.uid),{
      [data.chatId + ".lastMessage"]:{
        text
      },
      [data.chatId+".date"]: serverTimestamp()
    
  });
  await updateDoc(doc(firestore,"userChats",data.user.uid),{
    [data.chatId + ".lastMessage"]:{
      text
    },
    [data.chatId+".date"]: serverTimestamp()
  
});

setText("");
setMedia(null);
  };

 

    return (
      <div className = 'InputChat'>
      <input type ="text" placeholder='Écrivez quelque chose...' onChange={e=>setText(e.target.value)}value={text} onKeyPress={handleKeyPress}/>
      <div className="send">
        <input type ="file" style = {{display:"none"}} id= "file"           onChange={e => {
            if (e.target.files && e.target.files.length > 0) {
              setMedia(e.target.files[0]);
            } else {
              setMedia(null);
            }
          }} /> 
        <label htmlFor="file">
          <img src={require('../assets/ImageChat.png')} alt="" />
          </label>    
          <button onClick={handleSend}>Envoyer</button>
      </div>

      </div>
    );
  };
  
  export default InputChat;