import { doc, onSnapshot } from 'firebase/firestore';
import React, { useContext, useEffect } from 'react'
import { useState } from 'react';
import { firestore } from '../firebase.js';
import { AuthContext } from './context/AuthContext.js';
import { ChatContext } from './context/ChatContext.js';

const ChatsChat = ({ toggleSidebar }) => {

    interface UserInfo {
        profilePicUri: string;
        Name: string;
        lastMessage?: {
            text: string;
        };
    }
    interface LastMessage {
        text: string;
    }
  
    interface Chat {
        userInfo: UserInfo;
        lastMessage?: LastMessage;
        date: any;
       
    }

    const [chats, setChats] = useState<{[key: string]: Chat}>({});
    const {currentUser} = useContext(AuthContext)
    const {dispatch} = useContext(ChatContext)

    useEffect(()=> {
        const getChats = () => {
            if (!currentUser || !currentUser.uid) {
                return;
            }
        const unsub = onSnapshot(doc(firestore, "userChats", currentUser.uid), (doc) => {
            const data = doc.data();
            if (data) {
                setChats(data as {[key: string]: Chat});
            } else {
                setChats({});
            }
});
    return () =>{
        unsub();
    };
};
if (currentUser && currentUser.uid) {
    getChats();
}
}, [currentUser]);

const handleSelect = (u) =>{
dispatch ({type: "CHANGE_USER",payload: u});
toggleSidebar(); // Call toggleSidebar when a chat is selected
}




    return (
      <div className='ChatsChat'>
        {Object.entries(chats)?.sort((a,b)=>b[1].date - a[1].date).map((chat) => (
                <div className ="UserChat" 
                key={chat[0]} 
                onClick ={() => handleSelect(chat[1].userInfo)}>
            <img src={chat[1].userInfo.profilePicUri ? chat[1].userInfo.profilePicUri : require ( '../assets/default_profile_picture.png')} alt=""/> 
            <div className = "userChatInfo" >
            <span>{chat[1].userInfo.Name}</span>
            <p>{chat[1].lastMessage?.text}</p>
                </div>   
                </div> 
        ))}
      </div>
    );
  };
  
  export default ChatsChat;