import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from '../firebase'; // Ensure the path matches your project structure
import { getAnalytics, logEvent } from 'firebase/analytics'; // Import Firebase Analytics functions

const DemoLogin = () => {
  const history = useHistory();
  const analytics = getAnalytics(); // Initialize Firebase Analytics

  useEffect(() => {
    logEvent(analytics, 'demo_page_visit');

    const demoEmail = 'melanievercoutrecoaching@gmail.com'; // Replace with your demo account's email
    const demoPassword = 'MelDog123'; // Replace with your demo account's password

    const loginDemoUser = async () => {
      try {
        await signInWithEmailAndPassword(auth, demoEmail, demoPassword);
        console.log('Demo user logged in successfully');
        history.push('/home'); // Redirect to a desired page after login
      } catch (error) {
        console.error('Error logging in demo user:', error);
        // Handle error (e.g., redirect to an error page or display a message)
      }
    };

    loginDemoUser();
  }, [history]);

  return <div>Logging in...</div>; // Or any loading indicator you prefer
};

export default DemoLogin;
