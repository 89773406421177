import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './PaymentLoginScreenStyles.css';
import '../commonStyles.css';
import '../CategoryPageStyles.css';
import { auth, firestore, storage } from '../../firebase.js';
import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";

// Helper function to validate email
const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

const AccountCreationModal = ({ visible, onSubmit, setVisible, name, title, description, benefit1, benefit2, benefit3, benefit4, oneTimeBenefit1, oneTimeBenefit2, oneTimeBenefit3, oneTimeBenefit4, showOneTimePaymentBlock, showSubscriptionBlock, buttonColor, bannerUri, profilePicUri, engagementPeriod }) => {
    const history = useHistory();
    const [password, setPassword] = useState('');
    const [currentName, setCurrentName] = useState(name);
    const [email, setEmail] = useState('');
    const isFormValid = name.trim() !== '' && isValidEmail(email);
    const modalRef = useRef<HTMLDivElement>(null);
    const allSignUpFieldsFilled = currentName && email && password; // Assuming gender is a required field for sign-up

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
                setVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [setVisible]);


    async function handleSSOSignUp() {
        const provider = new GoogleAuthProvider();
        const result = await signInWithPopup(auth, provider);

        try {
            const lowercaseName = currentName.toLowerCase();
            const role = 'coach';

            const userProfile = {
                uid: result.user.uid,
                Name: lowercaseName,
                role: role,
                stripeAccountSetupComplete: false,
                title: title,
                description: description,
                showSubscriptionBlock: showSubscriptionBlock,
                showOneTimePaymentBlock: showOneTimePaymentBlock,
                buttonColor: buttonColor,
            };

            await setDoc(doc(firestore, "users", result.user.uid), userProfile);

            // Membership data setup
            const membershipData = {
                benefit1: benefit1,
                benefit2: benefit2,
                benefit3: benefit3,
                benefit4: benefit4,
                engagementPeriod: engagementPeriod,
            };

            // Prepare oneTimePayment data with similar structure
            const oneTimePaymentData = {
                oneTimeBenefit1: oneTimeBenefit1,
                oneTimeBenefit2: oneTimeBenefit2,
                oneTimeBenefit3: oneTimeBenefit3,
                oneTimeBenefit4: oneTimeBenefit4,
            };

            // Save oneTimePayment data
            const oneTimePaymentDocRef = doc(firestore, "users", result.user.uid, "onetimepayment", 'oneTimePaymentInfo');
            await setDoc(oneTimePaymentDocRef, oneTimePaymentData);

            const newMembershipDocRef = doc(firestore, "users", result.user.uid, "membership", 'membershipInfo');
            await setDoc(newMembershipDocRef, membershipData);

            onSubmit(result.user.uid);
        } catch (error) {
            console.error('Error during the signup process:', error);
            // handleFirebaseError is assumed to be defined elsewhere
            handleFirebaseError(error);
        }
    }

    async function handleSignUp() {
        if (!allSignUpFieldsFilled) {
            alert('All fields are required');
            return;
        }

        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            const lowercaseName = currentName.toLowerCase();
            const role = 'coach';

            const userProfile = {
                uid: user.uid,
                Name: lowercaseName,
                role: role,
                stripeAccountSetupComplete: false,
                title: title,
                description: description,
                showSubscriptionBlock: showSubscriptionBlock,
                showOneTimePaymentBlock: showOneTimePaymentBlock,
                buttonColor: buttonColor,
            };

            await setDoc(doc(firestore, "users", user.uid), userProfile);

            // Membership data setup
            const membershipData = {
                benefit1: benefit1,
                benefit2: benefit2,
                benefit3: benefit3,
                benefit4: benefit4,
                engagementPeriod: engagementPeriod,
            };

            // Prepare oneTimePayment data with similar structure
            const oneTimePaymentData = {
                oneTimeBenefit1: oneTimeBenefit1,
                oneTimeBenefit2: oneTimeBenefit2,
                oneTimeBenefit3: oneTimeBenefit3,
                oneTimeBenefit4: oneTimeBenefit4,
            };

            // Save oneTimePayment data
            const oneTimePaymentDocRef = doc(firestore, "users", user.uid, "onetimepayment", 'oneTimePaymentInfo');
            await setDoc(oneTimePaymentDocRef, oneTimePaymentData);

            const newMembershipDocRef = doc(firestore, "users", user.uid, "membership", 'membershipInfo');
            await setDoc(newMembershipDocRef, membershipData);
            onSubmit(user.uid);
        } catch (error) {
            console.error('Error during the signup process:', error);
            // handleFirebaseError is assumed to be defined elsewhere
            handleFirebaseError(error);
        }
    }

    function handleFirebaseError(error) {
        let errorMessage = error.message || 'Something went wrong. Please try again.';
        if (error.code) {
            switch (error.code) {
                case 'auth/email-already-in-use':
                    errorMessage = 'The email address is already in use.';
                    break;
                case 'auth/invalid-email':
                    errorMessage = 'The email address is invalid.';
                    break;
                case 'auth/weak-password':
                    errorMessage = 'The password is too weak.';
                    break;
                case 'auth/too-many-requests':
                    errorMessage = 'Too many attempts. Please try again later.';
                    break;
                default:
                    break;
            }
        }
        alert(errorMessage);
    }

    if (!visible) return null;

    return (
        <div className="modal" onClick={() => { }}>
            <div className="safeArea" ref={modalRef}>
                <div className='LoginStylesSSOContainer'>
                    <button onClick={handleSSOSignUp} className="LoginStylesButtonSignInGoogle">
                        <span className=''>S'inscrire avec Google</span>
                        <img className="LoginStylesGoogleIcon" src={require('../../assets/Google_logo.png')} alt="Add" />
                    </button>
                    <div className='LoginStylesOrMessageLine'>
                        <span className='LoginStylesGoogleOrTitle'>Ou</span>
                    </div>
                </div>
                <div className="fieldContainer">
                    <label className="PaymentLabel">Nom</label>
                    <input
                        className="input"
                        type="text"
                        placeholder="Entrer votre pseudo"
                        value={currentName}
                        onChange={(e) => setCurrentName(e.target.value)}
                    />
                </div>
                <div className="fieldContainer">
                    <label className="PaymentLabel">Email</label>
                    <input
                        className="input"
                        type="email"
                        placeholder="Entrez votre email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>

                <div className="fieldContainer">
                    <label className="PaymentLabel">Mot de passe</label>
                    <input
                        className="input"
                        type="password"
                        placeholder="Entrez votre mot de passe"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <button
                    className={`buttontitle VioletPaymentButton ${!allSignUpFieldsFilled && !isFormValid ? 'buttonDisabled' : ''}`}
                    onClick={handleSignUp}
                    disabled={!allSignUpFieldsFilled}
                >
                    <span className={`buttonText ${!allSignUpFieldsFilled ? 'buttonTextDisabled' : ''}`}>
                        Suivant
                    </span>
                </button>
            </div>
        </div>
    );
};

export default AccountCreationModal;