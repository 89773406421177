import '../screens/ChatPageStyles.css';
import MessagesChat from './MessagesChat.tsx';
import InputChat from './InputChat.tsx';
import React, { useContext, useEffect } from 'react'
import { ChatContext } from './context/ChatContext.js';

const ChatChat = ({ toggleSidebar }) => {

  const { data } = useContext(ChatContext)


  return (
    <div className='ChatChat'>
      <div className='ChatInfo'>
        <div className="back-arrow" onClick={toggleSidebar}>
          <img src={require('../assets/back_arrow_white.png')} alt="Back" />
        </div>
        <span>{data.user?.Name}</span>

      </div>

      <MessagesChat />
      <InputChat />
    </div>
  );
};

export default ChatChat;