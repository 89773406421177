import React, { createContext, useState, useContext, ReactNode } from 'react';
import { useUserId } from '../types/utils.ts';

interface SelectedUserContextType {
  selectedUserId: string;
  setSelectedUserId: (userId: string) => void;
}

const defaultContextValue: SelectedUserContextType = {
  selectedUserId: '',
  setSelectedUserId: () => {}, // Empty function as default
};

const SelectedUserContext = createContext<SelectedUserContextType>(defaultContextValue);

export const useSelectedUser = () => useContext(SelectedUserContext);

interface SelectedUserProviderProps {
  children: ReactNode; // This line defines the type for children
}

export const SelectedUserProvider: React.FC<SelectedUserProviderProps> = ({ children }) => {
  const currentUserID = useUserId(); // Get the authenticated user's ID
  const [selectedUserId, setSelectedUserId] = useState(currentUserID || '');

  return (
    <SelectedUserContext.Provider value={{ selectedUserId, setSelectedUserId }}>
      {children}
    </SelectedUserContext.Provider>
  );
};
