import React from 'react';

const ContextMenuModal = ({ isVisible, onClose, onMoveDay, onCopyDay, onCopyWeek }) => {
    if (!isVisible) return null;

    const handleContainerClick = () => {
      onClose();
  };

  const handleContentClick = (e) => {
      e.stopPropagation(); // Prevents the click from bubbling up to the container
  };

  return (
    <div className="modalContainer" onClick={handleContainerClick}>
        <div className="modalContent" onClick={handleContentClick}>
            <button className="optionButton" onClick={onMoveDay}>Déplacer l'entraînement</button>
            <button className="optionButton" onClick={onCopyDay}>Copier le workout</button>
            <button className="optionButton" onClick={onCopyWeek}>Copier toute la semaine</button>
        </div>
    </div>
);
};

export default ContextMenuModal;
