import React, { createContext, useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { firestore,} from '../firebase.js';
import { auth } from '../firebase';

// Define the shape of the context data
interface UserContextType {
  userRole: string | null;
  setUserRole: (role: string | null) => void;
  userData: any;
  setUserData: (data: any) => void;
}

// Create the context with default values
export const UserContext = createContext<UserContextType>({
  userRole: null,
  setUserRole: () => {}, // Default empty function
  userData: null,
  setUserData: () => {}, // Default empty function
});

  interface UserProviderProps {
    children: React.ReactNode;
  }
  
  // Context Provider component
  export const UserProvider: React.FunctionComponent<UserProviderProps> = ({ children }) => {
    const [userRole, setUserRole] = useState<string | null>(null);
    const [userData, setUserData] = useState<any>(null);
  
    useEffect(() => {
      auth.onAuthStateChanged(async (user) => {
        if (user) {
          const userDocRef = doc(firestore, "users", user.uid);
          const userDoc = await getDoc(userDocRef);
  
          if (userDoc.exists()) {
            const data = userDoc.data();
            setUserRole(data.role); // Assuming 'role' field contains 'coach' or 'student'
            setUserData(data);
          }
        } else {
          setUserRole(null);
          setUserData(null);
        }
      });
    }, []);
  
    return (
      <UserContext.Provider value={{ userRole, setUserRole, userData, setUserData }}>
      {children}
      </UserContext.Provider>
    );
  };
