import React, { ReactElement, useState, useEffect, useRef, useContext } from 'react';
import { firestore, auth } from '../../firebase.js';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import LottieCheckbox from '../../components/LottieCheckBox.tsx';
import LottieCross from '../../components/LottieCross.tsx';
import { useHistory, useLocation } from 'react-router-dom';
import { ExerciseSet, CompletedSetStatus, CheckboxState } from '../../types/types.ts';
import Chrono from '../../components/Chrono.tsx';
import './WorkoutStyles.css';
import './ProgramCreationStyles.css';
import BackButton from '../../components/BackButton.tsx';
import { UserContext } from '../../components/UserContext.tsx';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import Modal from 'react-modal';
import Footer from '../../components/Footer.tsx';

// Assuming CompletedSetStatus is defined elsewhere in your project
let completedMainSets: CompletedSetStatus[] = [];

const Workout = () => {
  const location = useLocation();
  const { exercises, date, userId, dayName, monthAndDay } = location.state || { exercises: [], date: null, userId: null, dayName: null, monthAndDay: null };
  const [comment, setComment] = useState('');
  const [checkboxState, setCheckboxState] = React.useState<CheckboxState>({} as CheckboxState);  // Create state to track checkboxes
  const history = useHistory();
  const [note, setNote] = useState('');
  const [showInputField, setShowInputField] = useState(false);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [currentExerciseId, setCurrentExerciseId] = useState(null);
  const [coachNotes, setCoachNotes] = useState({});
  const [showCoachNoteField, setShowCoachNoteField] = useState(false);
  const [selectedCoachNote, setSelectedCoachNote] = useState("");
  const { userRole } = useContext(UserContext);
  const [videoUrls, setVideoUrls] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [currentVideoUrl, setCurrentVideoUrl] = useState('');
  const videoRef = useRef<HTMLVideoElement>(null);
  const [activeIcon, setActiveIcon] = useState<'personne' | 'calendrier' | 'stats' | 'maison' | 'invitation' | 'eleves' | 'parametres' | null>(null);

  function capitalizeName(name) {
    if (!name) return ""; // Return an empty string if name is falsy
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  }

  const fetchVideoUrls = async () => {
    if (!auth.currentUser) {
      console.error("No authenticated user found");
      return;
    }
    const targetUserId = userRole === 'coach' ? userId : auth.currentUser.uid;
    const workoutDayDocRef = doc(firestore, 'users', targetUserId, 'workoutDays', date);

    try {
      const workoutDayDoc = await getDoc(workoutDayDocRef);
      if (workoutDayDoc.exists()) {
        const workoutDayData = workoutDayDoc.data();
        const fetchedVideoUrls = {};

        workoutDayData.exercises.forEach(exercise => {
          if (exercise.videoUrl) {
            fetchedVideoUrls[exercise.id] = exercise.videoUrl;
          }
        });

        setVideoUrls(fetchedVideoUrls);
      } else {
        console.error("Document does not exist");
      }
    } catch (error) {
      console.error("Error fetching video URLs from Firestore:", error);
    }
  };

  const handleVideoUpload = async (event, exerciseId) => {
    const file = event.target.files[0];
    if (!file) return;
    setIsLoading(true); // Start loading

    const storage = getStorage();
    const storageRef = ref(storage, `videos/${file.name}`);

    try {
      // Upload the file to Firebase Storage
      await uploadBytes(storageRef, file);

      // Get the download URL and store it in Firestore
      const url = await getDownloadURL(storageRef);
      await saveVideoUrlToFirestore(exerciseId, url);

    } catch (error) {
      console.error("Error uploading video: ", error);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  const saveVideoUrlToFirestore = async (exerciseId, url) => {
    try {
      if (!auth.currentUser) {
        throw new Error("No authenticated user found");
      }

      const targetUserId = userRole === 'coach' ? userId : auth.currentUser.uid;
      const workoutDayDocRef = doc(firestore, 'users', targetUserId, 'workoutDays', date);

      const workoutDayDoc = await getDoc(workoutDayDocRef);
      if (workoutDayDoc.exists()) {
        const workoutDayData = workoutDayDoc.data();
        const exerciseIndex = workoutDayData.exercises.findIndex(ex => ex.id === exerciseId);
        if (exerciseIndex !== -1) {
          // Update the video URL for the specific exercise
          workoutDayData.exercises[exerciseIndex].videoUrl = url;

          // Update the document in Firestore
          await updateDoc(workoutDayDocRef, workoutDayData);
          setVideoUrls(prevUrls => ({ ...prevUrls, [exerciseId]: url }));
        } else {
          console.error("Exercise not found");
        }
      } else {
        console.error("Document does not exist");
      }
    } catch (error) {
      console.error("Error saving video URL to Firestore:", error);
    }
  };

  const playVideo = (videoUrl) => {
    setCurrentVideoUrl(videoUrl);
    setIsVideoModalOpen(true);

    // Wait for the next render to ensure the video element is mounted
    setTimeout(() => {
      if (videoRef.current) {
        videoRef.current.play().catch((e) => {
          console.error("Error trying to play the video: ", e);
        });
      }
    }, 0); // setTimeout with 0 delay to defer the execution until after the render
  };

  // This function toggles the visibility of the input field
  const handleWriteNoteClick = async (exerciseId) => {
    // Close the coach note field if it's open
    if (showCoachNoteField) {
      closeCoachNoteField();
    }

    // Toggle the personal note field
    if (currentExerciseId !== exerciseId) {
      setCurrentExerciseId(exerciseId);
      try {
        if (!auth.currentUser) {
          throw new Error("No authenticated user found");
        }

        // Use the userId for coaches or the current user's ID for students
        const targetUserId = userRole === 'coach' ? userId : auth.currentUser.uid;

        const workoutDayDocRef = doc(firestore, 'users', targetUserId, 'workoutDays', date);
        const workoutDayDoc = await getDoc(workoutDayDocRef);

        if (workoutDayDoc.exists()) {
          const workoutDayData = workoutDayDoc.data();
          const currentExercise = workoutDayData.exercises.find(ex => ex.id === exerciseId);

          if (userRole === 'coach') {
            // For coaches, handle coachNote
            if (currentExercise && currentExercise.coachNote) {
              setNote(currentExercise.coachNote);
            } else {
              setNote('');
            }
          } else {
            // For students, handle personal note
            if (currentExercise && currentExercise.note) {
              setNote(currentExercise.note);
            } else {
              setNote('');
            }
          }
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching exercise note from Firestore:", error);
      }

      setShowInputField(true);
    } else {
      closeInputField(); // Close the personal note field if it's already open for this exercise
    }
  };

  const handleReadNoteClick = (exerciseId) => {
    // Close the personal note field if it's open
    if (showInputField) {
      closeInputField();
    }

    // Toggle the coach note field
    if (showCoachNoteField !== exerciseId) {
      const exerciseIndex = exercises.findIndex(ex => ex.id === exerciseId);
      if (exerciseIndex !== -1) {
        if (userRole === 'coach') {
          // For coaches, handle personal notes
          const selectedNote = exercises[exerciseIndex].note || "";
          setSelectedCoachNote(selectedNote); // Use a different state or modify the existing one as needed
        } else {
          // For students, handle coach notes
          setSelectedCoachNote(coachNotes[exerciseIndex]);
        }
        setShowCoachNoteField(exerciseId); // Open the note field
      } else {
        console.error("Exercise not found");
      }
    } else {
      closeCoachNoteField();
    }
  };

  // This function sets the input field visibility to false
  const closeInputField = async () => {
    if (currentExerciseId) {
      await updateNoteInFirestore(currentExerciseId, note, date);
    }
    setShowInputField(false);
    setCurrentExerciseId(null); // Reset the current exercise ID
    setNote(''); // Clear the note state
  };

  const closeCoachNoteField = () => {
    setShowCoachNoteField(false);
    setSelectedCoachNote(""); // Optionally clear the selected coach note
  };

  // This function handles the click outside the textarea
  const handleClickOutside = (event) => {
    let targetElement = event.target;
    let isNoteIconClicked = false;

    while (targetElement != null) {
      if (targetElement.classList && targetElement.classList.contains('note-icon')) {
        isNoteIconClicked = true;
        break;
      }
      targetElement = targetElement.parentElement;
    }

    if (!isNoteIconClicked) {
      if (textAreaRef.current && !textAreaRef.current.contains(event.target)) {
        closeInputField();
      }
      if (showCoachNoteField) {
        closeCoachNoteField(); // Function to close the coach note field
      }
    }
  };

  const updateNoteInFirestore = async (exerciseId, noteText, date) => {
    try {
      if (!auth.currentUser) {
        throw new Error("No authenticated user found");
      }

      // Determine the target user ID based on the user's role
      const targetUserId = userRole === 'coach' ? userId : auth.currentUser.uid;

      // Reference to the workout day document of the target user
      const workoutDayDocRef = doc(firestore, 'users', targetUserId, 'workoutDays', date);

      // Get the current exercises array
      const workoutDayDoc = await getDoc(workoutDayDocRef);
      if (workoutDayDoc.exists()) {
        const workoutDayData = workoutDayDoc.data();
        const exercises = workoutDayData.exercises || [];

        // Find the exercise to update
        const exerciseIndex = exercises.findIndex(ex => ex.id === exerciseId);
        if (exerciseIndex !== -1) {
          if (userRole === 'coach') {
            // Update coachNote for a coach viewing a student's exercise
            exercises[exerciseIndex].coachNote = noteText;
          } else {
            // Update personal note for a student
            exercises[exerciseIndex].note = noteText;
          }

          // Update the exercises array in Firestore
          await updateDoc(workoutDayDocRef, { exercises });
        } else {
          console.error("Exercise not found");
        }
      } else {
        console.error("Document does not exist");
      }
    } catch (error) {
      console.error("Error updating note in Firestore:", error);
    }
  };

  // Effect for adding/removing the event listener
  useEffect(() => {
    // Add when the input field is visible
    if (showInputField || showCoachNoteField) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    // Cleanup the event listener when the component unmounts or the input field is hidden
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showInputField, showCoachNoteField]); // Depend on both states

  useEffect(() => {
    const fetchCheckboxState = async () => {
      try {
        if (!auth.currentUser) {
          throw new Error("No authenticated user found");
        }

        // Use the student's userId if the user is a coach, otherwise use the current user's ID
        const targetUserId = userRole === 'coach' ? userId : auth.currentUser.uid;

        const docRef = doc(firestore, 'users', targetUserId, 'workoutDays', date);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          if (data && data.exercises) {
            const fetchedCheckboxState = {};
            const fetchedCoachNotes = {}; // Object to store coach notes
            data.exercises.forEach((exercise, exerciseIndex) => {
              fetchedCoachNotes[exerciseIndex] = exercise.coachNote || "";
              exercise.sets.forEach((set, setIndex) => {
                fetchedCheckboxState[`${exerciseIndex}-${setIndex}`] = set.completed;
                if (set.detailedSets) {
                  set.detailedSets.forEach((detailedSet, detailedSetIndex) => {
                    fetchedCheckboxState[`${exerciseIndex}-${setIndex}-${detailedSetIndex + 1}`] = detailedSet.completed;
                  });
                }
              });
            });

            setCheckboxState(fetchedCheckboxState);
            setCoachNotes(fetchedCoachNotes); // Update the state with fetched coach notes
            const commentFromFirestore = data.comment || "";
            setComment(commentFromFirestore);
          }
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching data from Firestore:", error);
      }
    };
    fetchVideoUrls();
    fetchCheckboxState();
  }, [date, userId, userRole]); // Add userId and userRole as dependencies

  const allDetailedSetsCheckedWithCurrent = (
    exerciseIndex: number,
    setIndex: number,
    setNumber: number,
    currentState: CheckboxState
  ): string => {
    let isAnySetFailed = false;
    let areAllSetsSuccessful = true;

    for (let i = 1; i <= setNumber; i++) {
      const currentSetState = currentState[`${exerciseIndex}-${setIndex}-${i}`];

      if (currentSetState === "2") { // Check if any set is "2" (Fail)
        isAnySetFailed = true;
        break; // No need to check further, as one failed set marks the main set as failed
      }

      if (currentSetState !== "1") { // Check if not all sets are "1" (Success)
        areAllSetsSuccessful = false;
      }
    }

    if (isAnySetFailed) {
      return "2"; // If any set failed, return "2" (Fail)
    } else if (areAllSetsSuccessful) {
      return "1"; // If all sets are successful, return "1" (Success)
    } else {
      return "0"; // Otherwise, return "0" (notAttempted)
    }
  };

  const updateDetailedSetCompletionStatus = async (
    exerciseIndex: number,
    setIndex: number,
    detailedSetIndex: number,
    newDetailedSetState: string, // Assuming the completed state is a boolean
    date: string // Date should be passed to this function
  ) => {
    try {
      if (!auth.currentUser) {
        throw new Error("No authenticated user found");
      }

      // Use the student's userId if the user is a coach, otherwise use the current user's ID
      const targetUserId = userRole === 'coach' ? userId : auth.currentUser.uid;

      const docRef = doc(firestore, 'users', targetUserId, 'workoutDays', date);

      // Fetch the entire document
      const docSnapshot = await getDoc(docRef);
      const data = docSnapshot.data();

      if (!data) {
        console.error("Document does not exist or failed to fetch data.");
        return;
      }

      // Modify the fetched object in memory
      if (data.exercises &&
        data.exercises[exerciseIndex] &&
        data.exercises[exerciseIndex].sets &&
        data.exercises[exerciseIndex].sets[setIndex] &&
        data.exercises[exerciseIndex].sets[setIndex].detailedSets &&
        data.exercises[exerciseIndex].sets[setIndex].detailedSets[detailedSetIndex]) {
        data.exercises[exerciseIndex].sets[setIndex].detailedSets[detailedSetIndex].completed = newDetailedSetState;
      }

      // Update the Firestore with the modified object
      await setDoc(docRef, data); // This will rewrite the entire document with the updated data
    } catch (error) {
      console.error("Error updating Firestore:", error);
    }
  };

  const updateMainSetCompletionStatus = async (
    exerciseIndex: number,
    setIndex: number,
    newMainSetState: string,
    date: string // Date should be passed to this function
  ) => {
    try {
      if (!auth.currentUser) {
        throw new Error("No authenticated user found");
      }

      // Use the student's userId if the user is a coach, otherwise use the current user's ID
      const targetUserId = userRole === 'coach' ? userId : auth.currentUser.uid;

      const docRef = doc(firestore, 'users', targetUserId, 'workoutDays', date);

      // Fetch the entire document
      const docSnapshot = await getDoc(docRef);
      const data = docSnapshot.data();

      if (!data) {
        console.error("Document does not exist or failed to fetch data.");
        return;
      }

      // Modify the fetched object in memory
      if (data.exercises &&
        data.exercises[exerciseIndex] &&
        data.exercises[exerciseIndex].sets &&
        data.exercises[exerciseIndex].sets[setIndex]) {
        data.exercises[exerciseIndex].sets[setIndex].completed = newMainSetState;
      }

      // Update the Firestore with the modified object
      await setDoc(docRef, data); // This will rewrite the entire document with the updated data
    } catch (error) {
      console.error("Error updating Firestore:", error);
    }
  };

  const renderDetailedSets = (
    set: ExerciseSet,
    setNumber: number,
    exerciseIndex: number,
    setIndex: number
  ) => {
    let detailedSets: ReactElement[] = [];
    for (let i = 1; i <= setNumber; i++) {
      const key = `${exerciseIndex}-${setIndex}-${i}`;

      detailedSets.push(
        <div key={i} className="WorkoutStylesRowContainer">
          <div className="WorkoutStylesSetColumnContainer">
            <div className="WorkoutStylesInputContainer">
              <span className="WorkoutStylesInputSaved WorkoutStylesDetailedSet">1</span>
            </div>
          </div>
          <div className="WorkoutStylesXContainer">
            <span className="WorkoutStylesDetailedSet">x</span>
          </div>
          <div className="WorkoutStylesRepsColumnContainer">
            <div className="WorkoutStylesInputContainer">
              <span className="WorkoutStylesInputSaved WorkoutStylesDetailedSet">{set.reps}</span>
            </div>
          </div>
          <div className="WorkoutStylesWeightColumnContainer">
            <div className="WorkoutStylesInputContainer">
              <span className="WorkoutStylesInputSaved WorkoutStylesDetailedSet">{set.weight}</span>
            </div>
          </div>
          <div className="WorkoutStylesRPEColumnContainerWorkout">
            <div className="WorkoutStylesInputContainer">
              <span className="WorkoutStylesInputSaved WorkoutStylesDetailedSet">{set.rpe}</span>
            </div>
          </div>
          <div className="WorkoutStylesReposColumnContainer">
            <div className="WorkoutStylesInputContainer">
              <span className="WorkoutStylesInputSaved WorkoutStylesDetailedSet">{set.repos}</span>
            </div>
          </div>
          <div className="WorkoutStylesStateColumnContainerWorkout">
            <button
              className="WorkoutStylesCheckboxContainer"
              onClick={async () => {
                const currentState = checkboxState[key];
                let newDetailedSetState = currentState !== "1" ? "1" : "0";  // Toggle between "1" and "0"

                const newCheckboxState = { ...checkboxState, [key]: newDetailedSetState };
                const mainSetStatus = allDetailedSetsCheckedWithCurrent(
                  exerciseIndex, setIndex, setNumber, newCheckboxState
                );

                newCheckboxState[`${exerciseIndex}-${setIndex}`] = mainSetStatus;
                setCheckboxState(newCheckboxState);

                // Note: No need for comparison here, just pass newDetailedSetState directly
                await updateDetailedSetCompletionStatus(exerciseIndex, setIndex, i - 1, newDetailedSetState, date);

                // For mainSetStatus, you might need to determine the correct value to pass (0, 1, or 2)
                // based on your application logic
                await updateMainSetCompletionStatus(exerciseIndex, setIndex, mainSetStatus, date);
              }}
            >
              <LottieCheckbox
                isChecked={checkboxState[key] === "1"}
              />
            </button>
            <button
              className="WorkoutStylesCheckboxContainer"
              onClick={async () => {
                const currentState = checkboxState[key];
                let newDetailedSetState = currentState !== "2" ? "2" : "0";  // Toggle between "1" and "0"

                const newCheckboxState = { ...checkboxState, [key]: newDetailedSetState };
                const mainSetStatus = allDetailedSetsCheckedWithCurrent(
                  exerciseIndex, setIndex, setNumber, newCheckboxState
                );

                newCheckboxState[`${exerciseIndex}-${setIndex}`] = mainSetStatus;

                setCheckboxState(newCheckboxState);

                // Note: Directly pass newDetailedSetState
                await updateDetailedSetCompletionStatus(exerciseIndex, setIndex, i - 1, newDetailedSetState, date);

                // For mainSetStatus, you might need to determine the correct value to pass (0, 1, or 2)
                // based on your application logic
                await updateMainSetCompletionStatus(exerciseIndex, setIndex, mainSetStatus, date);
              }}
            >
              <LottieCross
                isChecked={checkboxState[key] === "2"}
              />
            </button>
          </div>
        </div>
      );
    }
    return detailedSets;
  };

  const renderSetsWithDetails = (set, exerciseIndex, setIndex) => {
    if (typeof set.setNumber === 'number') {
      return renderDetailedSets(set, set.setNumber, exerciseIndex, setIndex); // pass setIndex here
    }
    return null;
  };

  const handleMainSetClick = async (exerciseIndex, setIndex, newState) => {
    const newCheckboxState = { ...checkboxState };
    // Assuming each main set has a predetermined number of detailed sets
    const setNumber = exercises[exerciseIndex].sets[setIndex].setNumber;

    for (let i = 1; i <= setNumber; i++) {
      const key = `${exerciseIndex}-${setIndex}-${i}`;
      newCheckboxState[key] = newState; // Update each detailed set state
    }

    newCheckboxState[`${exerciseIndex}-${setIndex}`] = newState; // Update main set state

    setCheckboxState(newCheckboxState); // Update state in component

    // Persist changes to Firestore
    await updateMainSetCompletionStatus(exerciseIndex, setIndex, newState, date); // Update main set in Firestore
    for (let i = 0; i < setNumber; i++) {
      // Update each detailed set in Firestore
      await updateDetailedSetCompletionStatus(exerciseIndex, setIndex, i, newState, date);
    }
  };

  exercises.forEach((exercise, index) => {
    let allCompleted = true;
    let anyNotCompleted = false;

    exercise.sets.forEach((set, setIndex) => {
      if (checkboxState[`${index}-${setIndex}`] !== "1") {
        allCompleted = false;
      }
      if (checkboxState[`${index}-${setIndex}`] === "2") {
        anyNotCompleted = true;
      }
    });
    if (allCompleted) {
      exercise.overallCompletion = "completed";
    } else if (anyNotCompleted) {
      exercise.overallCompletion = "notCompleted";
    } else {
      exercise.overallCompletion = "notAttempted";
    }
  });

  return (
    <div className="SuprememainContainerWorkout">
      <div className="leftsideContainerWorkout">
        <div className="LogoIconContainerWorkout">
          <img className="LogoWorkout" src={require('../../assets/ReReReDesign.png')} alt="Logo" />
        </div>
        <Footer setActiveIcon={setActiveIcon} activeIcon={activeIcon} />
      </div>
      <div className='WorkoutStylesGlobalContainer'>
        <div className='WorkoutScreenContentContainer'>
          <div className='FirstLayerWorkoutScreenContentContainer'>
            <div className='WorkoutStylesBackButtonContainer'>
              <BackButton
                to="/ProgramCreation" hoverBackgroundColor="F7F8FA"
                arrowColor="black"
              />
              <span className='TitleWorkoutScreenContentContainer'>{capitalizeName(dayName)} {monthAndDay}</span>
            </div>
          </div>
          <div className='WorkoutScreenContentCenterContainer'>
            <div className="WorkoutStylesScrollContainer">
              {exercises.map((exercise, exerciseIndex) => (
                <div key={exerciseIndex} className="WorkoutStylesExerciseContainerWorkout">
                  <div className={`ProgramCreationStylesExerciseNameContainer ${exercise.overallCompletion === "completed" ? "exerciseCompleted" : exercise.overallCompletion === "notCompleted" ? "exerciseNotCompleted" : "exerciseNotAttempted"}`}>
                    <span className="ProgramCreationStylesExerciseName">{exercise.name}</span>
                    <div key={exercise.id} className='WorkoutStylesNotesContainer'>

                      {userRole === 'coach' ? (
                        // For coaches, display personal note icon and editable coach note
                        <>
                          {exercise.note && (
                            <img
                              className="WorkoutStylesNotes"
                              src={require('../../assets/note_to_read.png')}
                              alt="NotesToRead"
                              onClick={() => handleReadNoteClick(exercise.id)}
                            />
                          )}
                          <img
                            className="WorkoutStylesNotes note-icon"
                            src={require('../../assets/note_to_write.png')}
                            alt="NotesToWrite"
                            onClick={() => handleWriteNoteClick(exercise.id)}
                          />
                          {showInputField && currentExerciseId === exercise.id && (
                            <textarea
                              ref={textAreaRef}
                              rows={3}
                              className="WorkoutStylesInputField"
                              placeholder="Vos remarques de coach..."
                              value={note}
                              onChange={(e) => setNote(e.target.value)}
                              onBlur={closeInputField}
                              style={{ height: 'auto' }}
                            />
                          )}
                        </>
                      ) : (
                        // For students, display coach note icon and editable personal note
                        <>
                          {coachNotes[exerciseIndex] && (
                            <img
                              className="WorkoutStylesNotes"
                              src={require('../../assets/note_to_read.png')}
                              alt="NotesToRead"
                              onClick={() => handleReadNoteClick(exercise.id)}
                            />
                          )}
                          <img
                            className="WorkoutStylesNotes note-icon"
                            src={require('../../assets/note_to_write.png')}
                            alt="NotesToWrite"
                            onClick={() => handleWriteNoteClick(exercise.id)}
                          />
                          {showInputField && currentExerciseId === exercise.id && (
                            <textarea
                              ref={textAreaRef}
                              rows={3}
                              className="WorkoutStylesInputField"
                              placeholder="Vos remarques..."
                              value={note}
                              onChange={(e) => setNote(e.target.value)}
                              onBlur={closeInputField}
                              style={{ height: 'auto' }}
                            />
                          )}
                        </>
                      )}
                      {showCoachNoteField === exercise.id && (
                        <textarea
                          readOnly
                          rows={3}
                          className="WorkoutStylesInputField"
                          value={selectedCoachNote}
                          style={{ height: 'auto' }}
                        />
                      )}
                      <input
                        type="file"
                        accept="video/*"
                        onChange={(e) => handleVideoUpload(e, exercise.id)}
                        style={{ display: 'none' }}
                        id={`video-upload-${exercise.id}`}
                      />
                      {isLoading ? (
                        <div className='WorkoutStylesNotes'>
                          <div className="WorkoutStylesSpinner"></div>
                        </div>
                      ) : (
                        <label htmlFor={`video-upload-${exercise.id}`}>
                          <img className="WorkoutStylesNotes" src={require('../../assets/UploadVid.png')} alt="Upload Video" />
                        </label>
                      )}
                      {/* Video Play Icon */}
                      {!isLoading && videoUrls[exercise.id] && (
                        <img className="WorkoutStylesNotes"
                          src={require('../../assets/PlayVid.png')}
                          alt="Play Video"
                          onClick={() => playVideo(videoUrls[exercise.id])}
                        />
                      )}
                      {isVideoModalOpen && currentVideoUrl && (
                        <Modal
                          isOpen={isVideoModalOpen}
                          onRequestClose={() => {
                            setIsVideoModalOpen(false);
                            setCurrentVideoUrl(''); // Reset the video URL when closing the modal
                          }}
                          contentLabel="Video Modal"
                          key={`video-modal-${currentVideoUrl}`} // Unique key based on the video URL
                        // Add additional styling or attributes here
                        >
                          <img
                            className="WorkoutStylesButtonBackModal"
                            src={require('../../assets/back_arrow_black.png')} // Path to your icon
                            alt="Back"
                            onClick={() => {
                              setIsVideoModalOpen(false);
                              setCurrentVideoUrl(''); // Also reset the video URL when clicking the back button
                            }}
                            style={{ width: '24px', height: '24px' }} // Adjust size as needed
                          />
                          <video
                            ref={videoRef}
                            className="WorkoutStylesModalVideo"
                            controls
                            disablePictureInPicture // This attribute disables the picture-in-picture feature
                          >
                            <source src={currentVideoUrl} type="video/mp4" /> {/* The type attribute is correctly placed here */}
                            Your browser does not support the video tag.
                          </video>
                        </Modal>
                      )}
                    </div>
                  </div>
                  <div className="WorkoutStylesHeaderContainerWorkout">
                    <div className="WorkoutStylesSetColumnContainer">
                      <div className="WorkoutStylesInputContainer">
                        <span className="ProgramCreationStylesHeaderText">Série</span>
                      </div>
                    </div>
                    <div className="WorkoutStylesFakeXContainer">
                      <span className='WorkoutStylesMainSet'>x</span>
                    </div>
                    <div className="WorkoutStylesRepsColumnContainer">
                      <div className="WorkoutStylesInputContainer">
                        <span className="ProgramCreationStylesHeaderText">Reps</span>
                      </div>
                    </div>
                    <div className="WorkoutStylesWeightColumnContainer">
                      <div className="WorkoutStylesInputContainer">
                        <span className="ProgramCreationStylesHeaderText">Poids</span>
                      </div>
                    </div>
                    <div className="WorkoutStylesRPEColumnContainerWorkout">
                      <div className="WorkoutStylesInputContainer">
                        <span className="ProgramCreationStylesHeaderText">RPE</span>
                      </div>
                    </div>
                    <div className="WorkoutStylesReposColumnContainer">
                      <div className="WorkoutStylesInputContainer">
                        <span className="ProgramCreationStylesHeaderText">Repos</span>
                      </div>
                    </div>
                    <div className="WorkoutStylesStateColumnContainerWorkout">
                      <div className="WorkoutStylesFakeLottieContainer">
                        <span className="WorkoutStylesFakeLottie">État</span>
                      </div>
                    </div>
                  </div>
                  {exercise.sets.map((set, setIndex) => (
                    <div key={setIndex} className="WorkoutStylesWorkoutSetContainer">
                      <div className="WorkoutStylesRowContainerMainSet">
                        <div className="WorkoutStylesSetColumnContainer">
                          <div className="WorkoutStylesInputContainer">
                            <span className="WorkoutStylesInputSaved WorkoutStylesMainSet">{set.setNumber}</span>
                          </div>
                        </div>
                        <div className="WorkoutStylesMainSetContainer">
                          <div className="WorkoutStylesXContainer">
                            <span className='WorkoutStylesMainSet'>x</span>
                          </div>
                        </div>
                        <div className="WorkoutStylesRepsColumnContainer">
                          <div className="WorkoutStylesInputContainer">
                            <span className="WorkoutStylesInputSaved WorkoutStylesMainSet">{set.reps}</span>
                          </div>
                        </div>
                        <div className="WorkoutStylesWeightColumnContainer">
                          <div className="WorkoutStylesInputContainer">
                            <span className="WorkoutStylesInputSaved WorkoutStylesMainSet">{set.weight}</span>
                          </div>
                        </div>
                        <div className="WorkoutStylesRPEColumnContainerWorkout">
                          <div className="WorkoutStylesInputContainer">
                            <span className="WorkoutStylesInputSaved WorkoutStylesMainSet">{set.rpe}</span>
                          </div>
                        </div>
                        <div className="WorkoutStylesReposColumnContainer">
                          <div className="WorkoutStylesInputContainer">
                            <span className="WorkoutStylesInputSaved WorkoutStylesMainSet">{set.repos}</span>
                          </div>
                        </div>
                        <div className="WorkoutStylesStateColumnContainerWorkout">
                          <button
                            className="WorkoutStylesCheckboxContainer"
                            onClick={() => handleMainSetClick(exerciseIndex, setIndex, checkboxState[`${exerciseIndex}-${setIndex}`] === "1" ? "0" : "1")}
                          >
                            <LottieCheckbox
                              isChecked={checkboxState[`${exerciseIndex}-${setIndex}`] === "1"}
                            />
                          </button>
                          <button
                            className="WorkoutStylesCheckboxContainer"
                            onClick={() => handleMainSetClick(exerciseIndex, setIndex, checkboxState[`${exerciseIndex}-${setIndex}`] === "2" ? "0" : "2")}
                          >
                            <LottieCross
                              isChecked={checkboxState[`${exerciseIndex}-${setIndex}`] === "2"}
                            />
                          </button>
                        </div>
                      </div>
                      {renderSetsWithDetails(set, exerciseIndex, setIndex)}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Workout;