import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import './LoginScreenStyles.css';
import './commonStyles.css';
import './CategoryPageStyles.css';
import { auth, firestore } from '../firebase';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { doc, setDoc, collection, addDoc, updateDoc, query, getDocs, where, getDoc, deleteDoc } from "firebase/firestore";
import { UserContext } from '../components/UserContext.tsx';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getMessaging, getToken } from 'firebase/messaging';
import ParrainageSelection from '../components/ParrainageSelection.tsx';
import { signInWithPopup, GoogleAuthProvider, getAdditionalUserInfo } from "firebase/auth";

function LoginScreen() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [Name, setName] = useState('');
  const [gender, setGender] = useState('');
  const [showSignUp, setShowSignUp] = useState(false);
  const [showCategoryPage, setShowCategoryPage] = useState(false);
  const [activeButton, setActiveButton] = useState(null);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [coachingFee, setCoachingFee] = useState('');
  const allRoleSelectionFieldsFilled = activeButton && (activeButton !== 'coach' || (activeButton === 'coach' && coachingFee));
  const { userRole } = useContext(UserContext); // Use the context
  const { setUserRole } = useContext(UserContext);
  const history = useHistory();
  const location = useLocation();

  const allFieldsFilled = email && password;
  const allSignUpFieldsFilled = Name && gender && email && password; // Assuming gender is a required field for sign-up
  const [randomImage, setRandomImage] = useState('');
  const [isStripeProcessing, setIsStripeProcessing] = useState(false);

  interface ConnectAccountResponse {
    accountId: string;
  }

  interface AccountLinkResponse {
    url: string;
  }

  interface CreatePriceRequest {
    amount: number;  // The amount in cents
    coachName: string;
    userId: string;
  }

  interface CreatePriceResponse {
    priceId: string;
  }

  interface Invitation {
    invitedEmail: string;
    // ... other fields present in your invitation documents
  }

  // Get the Firebase Functions instance
  const functions = getFunctions();

  // Reference your callable functions
  const createStripeConnectAccount = httpsCallable(functions, 'createStripeConnectAccount');
  const generateStripeAccountLink = httpsCallable(functions, 'generateStripeAccountLink');
  const sendSubscriptionEndEmail = httpsCallable(functions, 'sendSubscriptionEndEmail');

  const imagePaths = [
    require('../assets/image_1.jpg'),
    require('../assets/image_2.jpg'),
    require('../assets/image_3.jpg'),
    require('../assets/image_4.jpg'),
    require('../assets/image_5.jpg'),
    require('../assets/image_6.jpg')
  ];

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      // Using getAdditionalUserInfo to retrieve additional user info
      const additionalUserInfo = getAdditionalUserInfo(result);
      const isNewUser = additionalUserInfo?.isNewUser;

      if (isNewUser) {
        // Handle new user sign-up
        await handleSSOSignUp(result.user);
        setShowCategoryPage(!showCategoryPage);
        setShowSignUp(false);
        console.log('New user signed up through Google SSO');
      } else {
        // For existing users, check their role before deciding where to redirect
        const userDocRef = doc(firestore, "users", result.user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists() && userDoc.data().role) {
          // User has a role, proceed to home
          console.log('Existing user with a role signed in through Google SSO');
          history.push('/home');
        } else {
          // No role found, show role selection or appropriate action
          console.log('Existing user without a role, showing category page');
          setShowCategoryPage(true);
        }
      }
    } catch (error) {
      console.error('Error signing in with Google:', error);
    }
  };

  // Function to handle new user sign-up via SSO (e.g., Google Sign-In)
  async function handleSSOSignUp(user) {
    try {
      // Assuming 'user' contains the necessary details from the Google sign-in process
      let userName = user.displayName || user.email.split('@')[0]; // Deriving userName from displayName or email

      // Store user info in Firestore
      await setDoc(doc(firestore, "users", user.uid), {
        uid: user.uid,
        Name: userName.toLowerCase(),
      });

      // Initialize default exercises for the user
      const exercisesCollectionRef = collection(firestore, 'users', user.uid, 'exercises');
      const defaultExercises = ['Muscle Up', 'Pull Up/Chin Up', 'Dip', 'Squat'];
      for (let exerciseName of defaultExercises) {
        await addDoc(exercisesCollectionRef, { name: exerciseName });
      }

      // Optionally, check for and process any invitations or additional steps

      console.log('SSO sign-up process completed for:', userName);
      // Redirect or update UI as necessary
    } catch (error) {
      console.error('Error handling SSO sign-up:', error);
    }
  }

  const checkUserInvitations = async (userEmail, userName, userId) => {
    const invitationsRef = collection(firestore, 'invitations');
    const q = query(invitationsRef, where('invitedEmail', '==', userEmail));

    try {
      const querySnapshot = await getDocs(q);
      const currentDate = new Date(); // Get the current date

      console.log(currentDate);

      for (const docSnapshot of querySnapshot.docs) {
        const invitation = docSnapshot.data();
        const coachId = invitation.coachId;
        const startingPaymentDate = invitation.startingPaymentDate.toDate(); // Convert Firestore Timestamp to JavaScript Date

        // Create references for Firestore documents
        const currentUserSubscriptionsRef = doc(firestore, 'users', userId, 'mySubscriptions', coachId);
        const profileUserStudentsRef = doc(firestore, 'users', coachId, 'myStudents', userId);

        if (currentDate > startingPaymentDate) {

          sendSubscriptionEndEmail({ userEmail: invitation.invitedEmail, userName: userName, coachName: invitation.coachName })
            .then((result) => {
              console.log('Email notification sent:', result);
            })
            .catch((error) => {
              console.error('Error sending email notification:', error);
            });
          // Current date is past the starting payment date
          // Delete the invitation document as the date has passed
          await deleteDoc(docSnapshot.ref);

          // Remove the student from the coach's 'myStudents' collection
          await deleteDoc(profileUserStudentsRef);

          // Remove the coach from the student's 'mySubscriptions' collection
          await deleteDoc(currentUserSubscriptionsRef);
        }
      }
      return querySnapshot.size > 0; // Return true if invitations were found and processed
    } catch (error) {
      console.error('Error fetching and processing invitations:', error);
      return false;
    }
  };

  async function onLoginPress() {
    try {
      if (!allFieldsFilled) {
        alert('Email and password fields cannot be empty');
        return;
      }

      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      console.log('Logged in successfully');

      // Check for invitations
      const user = auth.currentUser;
      let role = null; // Declare role here

      if (user) {
        const userDocRef = doc(firestore, "users", user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
            const userData = userDoc.data(); // TypeScript knows userData cannot be undefined here
            const userName = userData.Name ? userData.Name : 'Unknown Name'; // Use a default name if not found
            role = userData.role; // Fetch the role here, safe to access userData
            const hasInvitations = await checkUserInvitations(user.email, userName, user.uid);
            if (hasInvitations) {
              console.log('User added to coach’s students list and vice versa');
            }
        } else {
          console.log('User document does not exist');
          // Handle case where user document does not exist, if necessary
        }
      }

      // Request notification permission after successful login
      await requestNotificationPermission(userCredential.user.uid);
      // Handling redirection
      // Debugging URLSearchParams
      const params = new URLSearchParams(location.search);
      const redirectUrl = params.get('redirect');

      if (redirectUrl) {
        history.push(redirectUrl);
      } else {
        if (role) {
          // User has a role, proceed to home
            console.log('User has a role, navigating to /home');
            history.push('/home');
        } else {
            // No role found, show role selection
            setShowCategoryPage(true);
        }
    }
    } catch (error) {
      alert('Invalid email or password. Please try again.');
      console.error(error);
    }
  }

  async function onForgotPasswordPress() {
    try {
      await sendPasswordResetEmail(auth, email);
      alert('A link to reset your password has been sent to your email.');
      setShowForgotPassword(false); // Hide the reset form after sending the email
    } catch (error) {
      alert('An error occurred while trying to send reset password email.');
      console.error(error);
    }
  }

  function toggleSignUp() {
    setShowSignUp(!showSignUp);
    setShowForgotPassword(false);
  }

  async function requestNotificationPermission(userId) {
    try {
      const messaging = getMessaging();
      // Wait for the service worker to be ready
      await navigator.serviceWorker.ready;

      await Notification.requestPermission();
      const fcmToken = await getToken(messaging, { vapidKey: "BLCkk9GW9-MQ4wrmMZPC7UOjMpEaQ3b6yK-GTS1x4cLxkT4irOxvXzQ5Z8D8hZOSPxBuXdra8fD6GiaV5972rOI" });

      if (fcmToken) {
        await setDoc(doc(firestore, "users", userId), { fcmToken }, { merge: true });
        console.log('FCM Token stored in Firestore');
      } else {
        console.log('No registration token available.');
      }
    } catch (error) {
      console.error('Error in requesting notification permission:', error);
    }
  }

  async function handleSignUp() {
    try {
      if (!allSignUpFieldsFilled) {
        alert('All fields are required');
        return;
      }
      // Sign Up Process
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      const lowercaseName = Name.toLowerCase();

      // Store user info in Firestore
      await setDoc(doc(firestore, "users", user.uid), {
        uid: user.uid,
        Name: lowercaseName, // Store the name in lowercase
        gender
      });
      await setDoc(doc(firestore, "userChats", user.uid), {});

      const exercisesCollectionRef = collection(firestore, 'users', user.uid, 'exercises');
      const defaultExercises = ['Muscle Up', 'Pull Up/Chin Up', 'Dip', 'Squat'];
      defaultExercises.forEach(async (exerciseName) => {
        await addDoc(exercisesCollectionRef, { name: exerciseName });
      });

      // Check for invitations
      if (user.email) {
        const hasInvitations = await checkUserInvitations(user.email, Name, user.uid);
        if (hasInvitations) {
          // Handle the case where invitations were found and processed
          console.log('User added to coach’s students list and vice versa');
        }
      }

      setShowCategoryPage(!showCategoryPage);
      setShowSignUp(false);
    } catch (error) {
      console.error('Error in account creation:', error);
      handleFirebaseError(error);
    }
  }

  function handleFirebaseError(error) {
    let errorMessage = error.message || 'Something went wrong. Please try again.';
    if (error.code) {
      switch (error.code) {
        case 'auth/email-already-in-use':
          errorMessage = 'The email address is already in use.';
          break;
        case 'auth/invalid-email':
          errorMessage = 'The email address is invalid.';
          break;
        case 'auth/weak-password':
          errorMessage = 'The password is too weak.';
          break;
        case 'auth/too-many-requests':
          errorMessage = 'Too many attempts. Please try again later.';
          break;
        default:
          break;
      }
    }
    alert(errorMessage);
  }

  function toggleForgotPassword() {
    setShowForgotPassword(!showForgotPassword);
    setShowSignUp(false); // Make sure to close sign up if opening forgot password
  }

  const updateImage = () => {
    const imageElem = document.querySelector('.sideImage') as HTMLImageElement;
    if (imageElem) {
      // Step 1: Start fade-out effect
      imageElem.classList.remove('sideImage-visible');

      setTimeout(() => {
        // Step 2: Change the image source after fade-out
        const randomIndex = Math.floor(Math.random() * imagePaths.length);
        setRandomImage(imagePaths[randomIndex]);

        // Step 3: Start fade-in effect
        setTimeout(() => {
          imageElem.classList.add('sideImage-visible');
        }, 100); // Short delay to allow image source to update
      }, 500); // Adjust this delay based on fade-out duration
    }
  };

  const handleClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  async function handleSelectRole() {
    try {
      setIsStripeProcessing(true); // Start loading
      // Check if a user is logged in
      if (!auth.currentUser) {
        alert('No user is currently logged in.');
        history.push('/login'); // Redirect to login page
        return;
      }

      const role = activeButton === 'coach' ? 'coach' : 'eleve';
      const userDocRef = doc(firestore, 'users', auth.currentUser.uid);

      // Insert the code snippet right here, after confirming the user's role
      const selectedCoachString = localStorage.getItem('selectedCoach');
      if (selectedCoachString) {
        const selectedCoach = JSON.parse(selectedCoachString);
        if (selectedCoach) {
          // Update the user's document with the selected coach's info
          await updateDoc(userDocRef, {
            parrain: selectedCoach.coachStripeAccountId
          });
          // Clear the selected coach information after saving
          localStorage.removeItem('selectedCoach');
        }
      }

      let coachingFeeTotal; // Declare these variables outside the if block

      if (role === 'coach' && coachingFee) {
        const serviceFee = parseFloat(coachingFee) * 0.02; // 2% service fee
        coachingFeeTotal = parseFloat(coachingFee) + serviceFee;
        coachingFeeTotal = Math.round(coachingFeeTotal * 100) / 100;
      }

      // Update the user's role
      await updateDoc(userDocRef, { role });
      setUserRole(role);

      // Prepare the profile info data
      const profileInfo = {
        title: role === 'coach' ? "Nouveau coach sur la plateforme" : "Nouveau sur la plateforme",
        description: role === 'coach' ? "Coach impliqué et à l'écoute" : "Je cherche à me dépasser",
        subscription_button: role === 'coach',
        ...(role === 'coach' && coachingFee) && { coachingFee: parseFloat(coachingFee), coachingFeeTotal },
      };

      // Path for profile info in Firestore
      const profileInfoPath = role === 'coach' ? 'coachProfileInfo' : 'studentProfileInfo';
      const profileInfoDocRef = doc(firestore, 'users', auth.currentUser.uid, 'profileInfo', profileInfoPath);
      await setDoc(profileInfoDocRef, profileInfo, { merge: true });

      // Handle Stripe Connect account creation and redirection for coaches
      if (role === 'coach') {

        // Set stripeAccountSetupComplete to false as the process is not yet complete
        await updateDoc(userDocRef, { 
          stripeAccountSetupComplete: false,
          priority: 10, // Add the priority field here
        });

        // Call Firebase Function to create Stripe Connect account
        const connectAccountResult = await createStripeConnectAccount({ email: auth.currentUser.email, role });
        const connectAccountData = connectAccountResult.data as ConnectAccountResponse; // Cast to the correct type
        const accountId = connectAccountData.accountId;

        // Create a Stripe Price for the coach
        const createPrice = httpsCallable<CreatePriceRequest, CreatePriceResponse>(getFunctions(), 'createStripePrice');
        const priceResponse = await createPrice({
          amount: parseFloat(coachingFeeTotal) * 100, // Convert to cents
          coachName: Name, // Replace with actual coach's name variable
          userId: auth.currentUser.uid,
        });

        const priceId = priceResponse.data.priceId;

        // Save the priceId in Firestore
        await setDoc(profileInfoDocRef, { priceId: priceId }, { merge: true });

        // Call Firebase Function to generate Stripe account link
        const accountLinkResult = await generateStripeAccountLink({ accountId });
        const accountLinkData = accountLinkResult.data as AccountLinkResponse; // Cast to the correct type
        const accountOnboardingLink = accountLinkData.url;

        // Redirect coach to Stripe for onboarding
        window.location.href = accountOnboardingLink;
        return; // Prevent further execution
      }

      history.push('/home'); // Redirect to home page after successful role selection and Firestore update
      await requestNotificationPermission(auth.currentUser.uid);
    } catch (error) {
      console.error('Error in role update:', error);
      handleFirebaseError(error);
      setIsStripeProcessing(false); // Only stop loading in case of an error
    }
  }

  useEffect(() => {
    updateImage(); // Initial image set

    const intervalId = setInterval(() => {
      updateImage(); // Update image every 5 seconds
    }, 5000);

    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, []);

  return (
    <div>
      <div className={showSignUp ? 'fullPageContainerMobileSignUp' : 'fullPageContainer'}>
        <div className="LoginStylesImageContainer">
          <img src={randomImage} className="sideImage" alt="Side" />
        </div>
        <div className={showSignUp ? 'LoginSignUpContainer' : 'Logincontainer'}>
          <div className="logoContainer">
            <img src={require('../assets/repp_logo_black.png')} className="logo" alt="Logo" />
          </div>

          {!showSignUp && !showForgotPassword && !showCategoryPage && (
            // Login Form
            <>
              <div className='titleAndDescriptionContainer'>
                <h1 className="title">Le coaching démarre ici.</h1>
                <p className="LoginScreenDescription">Rencontrez les meilleurs coachs pour vous surpasser.</p>
              </div>
              {!showSignUp && !showForgotPassword && !showCategoryPage && (
                <div className='LoginStylesSSOContainer'>
                  <button onClick={signInWithGoogle} className="LoginStylesButtonSignInGoogle">
                    <span className=''>Se connecter avec Google</span>
                    <img className="ProgramCreationStylesConfigureIcon" src={require('../assets/Google_logo.png')} alt="Add" />
                  </button>
                  <div className='LoginStylesOrMessageLine'>
                    <span className='LoginStylesGoogleOrTitle'>Ou</span>
                  </div>
                </div>
              )}
              <div className="fieldContainer">
                <label className="label">Email</label>
                <input
                  className="input"
                  type="email"
                  value={email}
                  placeholder="Entrez votre email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="fieldContainer">
                <label className="label">Mot de passe</label>
                <div className="passwordInputContainer">
                  <input
                    className="input"
                    type="password"
                    placeholder="Entrez votre mot de passe"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {password.length === 0 && (
                    <button
                      onClick={toggleForgotPassword}
                      className="forgotPasswordButton"
                    >
                      Oublié?
                    </button>
                  )}
                </div>
              </div>
              <div className='commonStylesButtonContainer'>
                <button
                  className={`buttontitle ${!allFieldsFilled ? 'buttonDisabled' : ''}`}
                  onClick={onLoginPress}
                  disabled={!allFieldsFilled}
                >
                  <div className='ProgramCreationStylesConfigureButtonContainer'>
                    <span className={`buttonText ${!allFieldsFilled ? 'buttonTextDisabled' : ''}`}>Connexion</span>
                    <img className="ProgramCreationStylesConfigureIcon" src={require('../assets/login_icon.png')} alt="Add" />
                  </div>
                </button>
              </div>
            </>
          )}
          {showSignUp && (
            // Sign-up Form
            <>
              <div className='titleAndDescriptionContainer'>
                <h1 className="title">Créer votre profil.</h1>
                <p className="LoginScreenDescription">Vous êtes à quelques clics de démarrer une nouvelle vie.</p>
              </div>
              <div className='LoginStylesSSOContainer'>
                <button onClick={signInWithGoogle} className="LoginStylesButtonSignInGoogle">
                  <span className=''>S'inscrire avec Google</span>
                  <img className="LoginStylesGoogleIcon" src={require('../assets/Google_logo.png')} alt="Add" />
                </button>
                <div className='LoginStylesOrMessageLine'>
                  <span className='LoginStylesGoogleOrTitle'>Ou</span>
                </div>
              </div>
              <div className="fieldContainer">
                <label className="label">Nom</label>
                <input
                  className="input"
                  type="text"
                  placeholder="Entrer votre pseudo"
                  value={Name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              <div className="fieldContainer">
                <label className="label">Genre</label>
                <div className="ProfileCreationGenderOptionContainer">
                  <button className={`ProfileCreationGenderOption ${gender === 'Homme' ? 'ProfileCreationGenderOptionSelected' : ''}`} onClick={() => setGender('Homme')}>
                    Homme
                  </button>
                  <button className={`ProfileCreationGenderOption ${gender === 'Femme' ? 'ProfileCreationGenderOptionSelected' : ''}`} onClick={() => setGender('Femme')}>
                    Femme
                  </button>
                </div>
              </div>

              <div className="fieldContainer">
                <label className="label">Email</label>
                <input
                  className="input"
                  type="email"
                  placeholder="Entrez votre email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className="fieldContainer">
                <label className="label">Mot de passe</label>
                <input
                  className="input"
                  type="password"
                  placeholder="Entrez votre mot de passe"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              <div className='commonStylesButtonContainer'>
                <button
                  className={`buttontitle ${!allSignUpFieldsFilled ? 'buttonDisabled' : ''}`}
                  onClick={handleSignUp}
                  disabled={!allSignUpFieldsFilled}
                >
                  <div className='ProgramCreationStylesConfigureButtonContainer'>
                    <span className={`buttonText ${!allSignUpFieldsFilled ? 'buttonTextDisabled' : ''}`}>Suivant</span>
                    <img className="ProgramCreationStylesConfigureIcon" src={require('../assets/goWorkout_Icon.png')} alt="Add" />
                  </div>
                </button>
              </div>
            </>
          )}

          {showCategoryPage && (
            <>
              <div className='titleAndDescriptionContainer'>
                <h1 className="title">Créer votre profil.</h1>
                <p className="LoginScreenDescription">Vous êtes à quelques clics de démarrer une nouvelle vie.</p>
              </div>

              <div className="CategoryPageSearchSection">
                <span className='CategoryPageTitleSelection'>Je suis</span>
                <div className="CategoryPageButtonsContainer">
                  <button
                    className={`CategoryPageButton ${activeButton === 'coach' ? 'CategoryPageActive' : ''}`}
                    onClick={() => handleClick('coach')}
                  >
                    Un coach
                  </button>
                  <button
                    className={`CategoryPageButton ${activeButton === 'eleves' ? 'CategoryPageActive' : ''}`}
                    onClick={() => handleClick('eleves')}
                  >
                    Un élève
                  </button>
                </div>
              </div>
              <div className="fieldContainer fieldMargin">
                <div className='labelAndDescriptionContainer'>
                  <label className={`label ${activeButton !== 'coach' && 'hiddenLabel'}`}>Montant du coaching /mois</label>
                  <label className={`descriptionLabel ${activeButton !== 'coach' && 'hiddenLabel'}`}>Ce prix peut-être modifié à tout moment sur votre profil.</label>
                </div>
                <div className={`inputWrapper ${activeButton !== 'coach' && 'hiddenInput'}`}>
                  <input
                    className="input"
                    type="number"
                    step="any" // Allows decimal values
                    placeholder="Entrez le montant"
                    value={coachingFee}
                    onChange={(e) => setCoachingFee(e.target.value)}
                    disabled={activeButton !== 'coach'}
                    min="0" // Prevents negative numbers
                  />
                  <span className="currencySymbol">€</span>
                </div>
              </div>
              <div className="fieldContainer">
                <div className='labelAndDescriptionContainer'>
                  <label className={`label ${activeButton !== 'coach' && 'hiddenLabel'}`}>Avez-vous un parrain ? (optionnel)</label>
                  <label className={`descriptionLabel ${activeButton !== 'coach' && 'hiddenLabel'}`}>Trouvez le coach qui vous a fait découvrir la plateforme.</label>
                </div>
                <div className={`inputWrapper ${activeButton !== 'coach' && 'hiddenInput'}`}>
                  <ParrainageSelection />
                </div>
              </div>
              <div className='commonStylesButtonContainer'>
                <button
                  className={`buttontitle ${!allRoleSelectionFieldsFilled || isStripeProcessing ? 'buttonDisabled' : ''}`}
                  onClick={handleSelectRole}
                  disabled={!allRoleSelectionFieldsFilled || isStripeProcessing}
                >
                  <div className='ProgramCreationStylesConfigureButtonContainer'>
                    <span className={`buttonText ${!allRoleSelectionFieldsFilled ? 'buttonTextDisabled' : ''}`}>
                      Suivant
                    </span>
                    {isStripeProcessing ? (
                      <div className='spinnerContainer' style={{ display: 'inline-block', marginLeft: '10px' }}>
                        <div className="spinnerWaitStripe"></div>
                      </div>
                    ) : (
                      <img className="ProgramCreationStylesConfigureIcon" src={require('../assets/goWorkout_Icon.png')} alt="Add" />
                    )}
                  </div>
                </button>
              </div>
            </>
          )}

          {showForgotPassword && (
            // Forgot Password Form
            <>
              <div className='titleAndDescriptionContainer'>
                <h1 className="title">Réinitialiser le mot de passe.</h1>
                <p className="LoginScreenDescription">Un email de réinitialisation de mot de passe vous sera envoyé.</p>
              </div>
              <div className="fieldContainer">
                <label className="label">Email</label>
                <input
                  type="email"
                  className="input"
                  placeholder="Entrez votre email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className='commonStylesButtonContainer'>
                <button
                  className={`buttontitle ${!email ? 'buttonDisabled' : ''}`}
                  onClick={onForgotPasswordPress}
                  disabled={!email}
                >
                  <div className='ProgramCreationStylesConfigureButtonContainer'>
                    <span className={`buttonText ${!email ? 'buttonTextDisabled' : ''}`}>Envoyer le lien</span>
                    <img className="ProgramCreationStylesConfigureIcon" src={require('../assets/send_icon.png')} alt="Add" />
                  </div>
                </button>
              </div>
            </>
          )}

          {!showSignUp && !showForgotPassword && !showCategoryPage && (
            <button onClick={toggleSignUp} className="toggleSignUpButton">
              Pas encore de compte ? Créez-en un!
            </button>
          )}
          {showSignUp && (
            <button onClick={toggleSignUp} className="toggleSignUpButton">
              Vous avez déjà un compte ? Par ici!
            </button>
          )}
          {showForgotPassword && (
            <button onClick={toggleForgotPassword} className="toggleSignUpButton">
              ← Retour à la page de connexion
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default LoginScreen;