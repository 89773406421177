import React, { useContext, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import LottieCheckbox from './LottieCheckBox.tsx';
import LottieCross from './LottieCross.tsx'; // Adjust the import path as necessary
import { UserContext } from './UserContext.tsx';
import Modal from 'react-modal';

interface DraggableExerciseItem {
    id: string;
    exerciseIndex: number;
    date: string;
}

const DraggableExercise = ({
    exercise,
    exerciseIndex,
    date,
    onReorderExercise,
    handleExerciseNameChange,
    handleTextChange,
    handleCoachNoteChange,
    handleNoteChange,
    updateNoteInFirestore,
    deleteExercise,
    videoUrls,
    allExercises,
    isEditMode,
    weekWorkouts // Make sure to pass this from the parent if needed for navigation
}) => {
    const ref = useRef(null); // This ref attaches to the component for both drag and drop
    const [, drop] = useDrop({
        accept: "exercise",
        hover(item: any) {
            if (!isEditMode || item.id === exercise.id) {
                return;
            }
            if (item.id !== exercise.id) {
                const dragIndex = item.exerciseIndex;
                const hoverIndex = exerciseIndex;

                // Only proceed if the hover index is different from the drag index
                if (dragIndex === hoverIndex) {
                    return;
                }

                // Perform the reorder
                onReorderExercise(dragIndex, hoverIndex, date);

                // Update the index for the dragged item for subsequent hovers
                item.exerciseIndex = hoverIndex;
            }
        },
        collect(monitor) {
            return {
                isOver: !!monitor.isOver(),
            };
        },
    });

    const [{ isDragging }, drag] = useDrag(() => ({
        type: "exercise",
        item: () => {
            return { id: exercise.id, exerciseIndex, date };
        },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    }));

    // Attach both drag and drop to the same ref
    drag(drop(ref));

    const { userRole } = useContext(UserContext);
    const [currentVideoUrl, setCurrentVideoUrl] = useState('');
    const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
    const videoRef = useRef<HTMLVideoElement>(null);
    const [focusContext, setFocusContext] = useState({
        date: null as string | null,
        fieldType: null as string | null,
        exerciseIndex: null as number | null,
        setIndex: null as number | null
    });

    const getInputId = (date, fieldType, exerciseIndex, setIndex) => {
        return `input-${date}-${fieldType}-${exerciseIndex}-${setIndex}`;
    };

    const getInputSelector = (date, fieldType, exerciseIndex, setIndex) => {
        const selector = `#input-${date}-${fieldType}-${exerciseIndex}-${setIndex}`;
        return selector;
    };

    const adjustTextAreaHeight = (e) => {
        e.target.style.height = 'inherit'; // Reset the height
        e.target.style.height = `${e.target.scrollHeight}px`; // Set the height to scroll height
    };

    const findNextDateWithExercises = (currentDate, direction) => {
        const dateKeys = Object.keys(weekWorkouts);
        const currentIndex = dateKeys.indexOf(currentDate);
        if (currentIndex === -1) return null;

        if (direction === 'up') {
            for (let i = currentIndex - 1; i >= 0; i--) {
                if (weekWorkouts[dateKeys[i]].length > 0) {
                    return dateKeys[i];
                }
            }
        } else if (direction === 'down') {
            for (let i = currentIndex + 1; i < dateKeys.length; i++) {
                if (weekWorkouts[dateKeys[i]].length > 0) {
                    return dateKeys[i];
                }
            }
        }
        return null;
    };

    const handleArrowNavigation = (e) => {
        if (!['ArrowRight', 'ArrowLeft', 'ArrowUp', 'ArrowDown'].includes(e.key) || !focusContext.date || focusContext.fieldType === null || focusContext.exerciseIndex === null || focusContext.setIndex === null) {
            return; // Ignore other keys or if focusContext is not fully set
        }

        const fieldOrder = ['setNumber', 'reps', 'weight', 'rpe', 'repos', 'note', 'coachNote'];
        const currentFieldIndex = fieldOrder.indexOf(focusContext.fieldType);
        let nextFieldIndex = currentFieldIndex;
        let nextExerciseIndex = focusContext.exerciseIndex;

        if (e.key === 'ArrowRight') {
            nextFieldIndex++;
        } else if (e.key === 'ArrowLeft') {
            nextFieldIndex--;
        } else if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
            nextExerciseIndex += (e.key === 'ArrowUp' ? -1 : 1);
        }

        // Wrap around logic for horizontal navigation
        if (nextFieldIndex < 0) {
            nextFieldIndex = fieldOrder.length - 1;
        } else if (nextFieldIndex >= fieldOrder.length) {
            nextFieldIndex = 0;
        }

        const setCursorAtEnd = (inputField) => {
            setTimeout(() => {
                inputField.focus();
                // Select the entire content of the input
                inputField.selectionStart = 0;
                inputField.selectionEnd = inputField.value.length;
            }, 0);
        };

        // Check if next exercise index is within the range for the current date
        if (nextExerciseIndex >= 0 && nextExerciseIndex < (weekWorkouts[focusContext.date]?.length || 0)) {
            const nextFieldType = fieldOrder[nextFieldIndex];
            const selector = getInputSelector(focusContext.date, nextFieldType, nextExerciseIndex, 0);
            const nextField = document.querySelector(selector) as HTMLInputElement | HTMLTextAreaElement;
            if (nextField) {
                setCursorAtEnd(nextField);
                setFocusContext({ date: focusContext.date, fieldType: nextFieldType, exerciseIndex: nextExerciseIndex, setIndex: 0 });
            }
        } else {
            // Move to the next or previous date
            const nextDate = findNextDateWithExercises(focusContext.date, e.key === 'ArrowUp' ? 'up' : 'down');
            if (nextDate) {
                if (e.key === 'ArrowUp') {
                    // Set to the last exercise of the previous date
                    nextExerciseIndex = weekWorkouts[nextDate].length - 1;
                } else {
                    // Set to the first exercise of the next date
                    nextExerciseIndex = 0;
                }

                const nextFieldType = fieldOrder[currentFieldIndex]; // Keep the same field type
                const selector = getInputSelector(nextDate, nextFieldType, nextExerciseIndex, 0);
                const nextField = document.querySelector(selector) as HTMLInputElement | HTMLTextAreaElement;
                if (nextField) {
                    setCursorAtEnd(nextField);
                    setFocusContext({ date: nextDate, fieldType: nextFieldType, exerciseIndex: nextExerciseIndex, setIndex: 0 });
                }
            }
        }
    };

    const playVideo = (videoUrl) => {
        setCurrentVideoUrl(videoUrl);
        setIsVideoModalOpen(true);

        // Wait for the next render to ensure the video element is mounted
        setTimeout(() => {
            if (videoRef.current) {
                videoRef.current.play().catch((e) => {
                    console.error("Error trying to play the video: ", e);
                });
            }
        }, 0); // setTimeout with 0 delay to defer the execution until after the render
    };

    return (
        <div ref={ref} style={{ opacity: isDragging ? 0.5 : 1 }} className={`WeekWorkoutStylesExerciseContainerWorkout ${isEditMode ? 'WeekWorkoutStylesRowContainerMainSetAddGrabIcon' : ''}`}>
            {exercise.sets.map((set, setIndex) => (
                <div key={setIndex} className="WeekWorkoutStylesWorkoutSetContainer">
                    <div className={`WeekWorkoutStylesRowContainerMainSet ${set.completed === "1" ? "WeekWorkoutBackgroundColorGreen" : set.completed === "2" ? "WeekWorkoutBackgroundColorRed" : ""}`}>
                        <div className="WeekWorkoutStylesExerciseColumnContainer">
                            {isEditMode ? (
                                <div className="WeekWorkoutStylesMainSet">
                                    <select className='WeekWorkoutStylesInput WeekWorkoutStylesSelector'
                                        value={allExercises.some(ex => ex.name === exercise.name) ? exercise.name : ""}
                                        onChange={(e) => handleExerciseNameChange(e, date, exerciseIndex)}
                                    >
                                        <option value="" disabled>Plus disponible</option>
                                        {allExercises.map((ex) => (
                                            <option key={ex.id} value={ex.name}>{ex.name}</option>
                                        ))}
                                    </select>
                                </div>
                            ) : (
                                <span className="WeekWorkoutStylesMainSet">{exercise.name}</span>
                            )}
                        </div>
                        <div className="WeekWorkoutStylesSetColumnContainer">
                            <div className="WeekWorkoutStylesMainSetContainer">
                                {isEditMode ? (
                                    <input
                                        id={getInputId(date, 'setNumber', exerciseIndex, setIndex)}
                                        className='WeekWorkoutStylesInput'
                                        type="text"
                                        value={String(set.setNumber)}
                                        onChange={(e) => handleTextChange(date, 'setNumber', exerciseIndex, setIndex, e.target.value)}
                                        onKeyDown={(e) => handleArrowNavigation(e)}
                                        onFocus={() => setFocusContext({ date, fieldType: 'setNumber', exerciseIndex, setIndex })}
                                    />
                                ) : (
                                    <span className="WeekWorkoutStylesMainSet">{set.setNumber}</span>
                                )}
                            </div>
                        </div>
                        <div className="WeekWorkoutStylesMainSetContainer">
                            <div className="ProgramCreationStylesXContainer">
                                <span>x</span>
                            </div>
                        </div>
                        <div className="WeekWorkoutStylesRepsColumnContainer">
                            <div className="WeekWorkoutStylesMainSetContainer">
                                {isEditMode ? (
                                    <input
                                        id={getInputId(date, 'reps', exerciseIndex, setIndex)}
                                        className='WeekWorkoutStylesInput'
                                        type="text"
                                        value={String(set.reps)}
                                        onChange={(e) => handleTextChange(date, 'reps', exerciseIndex, setIndex, e.target.value)}
                                        onKeyDown={(e) => handleArrowNavigation(e)}
                                        onFocus={() => setFocusContext({ date, fieldType: 'reps', exerciseIndex, setIndex })}
                                    />
                                ) : (
                                    <span className="WeekWorkoutStylesMainSet">{set.reps}</span>
                                )}
                            </div>
                        </div>
                        <div className="WeekWorkoutStylesWeightColumnContainer">
                            <div className="WeekWorkoutStylesMainSetContainer">
                                {isEditMode ? (
                                    <input
                                        id={getInputId(date, 'weight', exerciseIndex, setIndex)}
                                        className='WeekWorkoutStylesInput'
                                        type="text"
                                        value={String(set.weight)}
                                        onChange={(e) => handleTextChange(date, 'weight', exerciseIndex, setIndex, e.target.value)}
                                        onKeyDown={(e) => handleArrowNavigation(e)}
                                        onFocus={() => setFocusContext({ date, fieldType: 'weight', exerciseIndex, setIndex })}
                                    />
                                ) : (
                                    <span className="WeekWorkoutStylesMainSet">{set.weight}</span>
                                )}
                            </div>
                        </div>
                        <div className="WeekWorkoutStylesRPEColumnContainerWorkout">
                            <div className="WeekWorkoutStylesMainSetContainer">
                                {isEditMode ? (
                                    <input
                                        id={getInputId(date, 'rpe', exerciseIndex, setIndex)}
                                        className='WeekWorkoutStylesInput'
                                        type="text"
                                        value={set.rpe !== undefined ? String(set.rpe) : ''}  // Providing a default value if set.rpe is undefined
                                        readOnly={!isEditMode}
                                        onChange={(e) => handleTextChange(date, 'rpe', exerciseIndex, setIndex, e.target.value)}
                                        onKeyDown={(e) => handleArrowNavigation(e)}
                                        onFocus={() => setFocusContext({ date, fieldType: 'rpe', exerciseIndex, setIndex })}
                                    />
                                ) : (
                                    <span className="WeekWorkoutStylesMainSet">{set.rpe}</span>
                                )}
                            </div>
                        </div>
                        <div className="WeekWorkoutStylesReposColumnContainer">
                            <div className="WeekWorkoutStylesMainSetContainer">
                                {isEditMode ? (
                                    <input
                                        id={getInputId(date, 'repos', exerciseIndex, setIndex)}
                                        className='WeekWorkoutStylesInput'
                                        type="text"
                                        value={String(set.repos)}
                                        onChange={(e) => handleTextChange(date, 'repos', exerciseIndex, setIndex, e.target.value)}
                                        onKeyDown={(e) => handleArrowNavigation(e)}
                                        onFocus={() => setFocusContext({ date, fieldType: 'repos', exerciseIndex, setIndex })}
                                    />
                                ) : (
                                    <span className="WeekWorkoutStylesMainSet">{set.repos}</span>
                                )}
                            </div>
                        </div>
                        <div className="WeekWorkoutHideVeryTinyMobile">
                            <LottieCheckbox
                                isChecked={set.completed === "1"}
                            />
                            <LottieCross
                                isChecked={set.completed === "2"}
                            />
                        </div>
                        {userRole === 'coach' && (
                            <>
                                <div className="WeekWorkoutStylesNotesContainer">
                                    <div className='WeekWorkoutStylesHearderNotesContainer'>
                                        <textarea
                                            className="WeekWorkoutStylesNotesDisplayOnly"
                                            value={exercise.note}
                                            rows={3}
                                            disabled
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="WeekWorkoutStylesNotesContainer">
                                    <div className='WeekWorkoutStylesHearderNotesContainer'>
                                        <textarea
                                            id={getInputId(date, 'coachNote', exerciseIndex, setIndex)}
                                            className={isEditMode ? "WeekWorkoutStylesNotes" : "WeekWorkoutStylesNotesDisplayOnly"}
                                            value={exercise.coachNote}
                                            onChange={(e) => handleCoachNoteChange(e, exerciseIndex, date)}
                                            onBlur={() => updateNoteInFirestore(exercise.id, exercise.coachNote, date, true)}
                                            onInput={adjustTextAreaHeight}
                                            rows={3}
                                            disabled={!isEditMode}  // Disable textarea based on isEditMode
                                            onKeyDown={(e) => handleArrowNavigation(e)}
                                            onFocus={() => setFocusContext({ date, fieldType: 'coachNote', exerciseIndex, setIndex })}
                                        ></textarea>
                                    </div>
                                </div>
                            </>
                        )}
                        {userRole === 'eleve' && (
                            <>
                                <div className="WeekWorkoutStylesNotesContainer">
                                    <div className='WeekWorkoutStylesHearderNotesContainer'>
                                        <textarea
                                            className="WeekWorkoutStylesNotesDisplayOnly"
                                            value={exercise.coachNote}
                                            rows={3}
                                            disabled
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="WeekWorkoutStylesNotesContainer">
                                    <div className='WeekWorkoutStylesHearderNotesContainer'>
                                        <textarea
                                            id={getInputId(date, 'note', exerciseIndex, setIndex)}
                                            className={isEditMode ? "WeekWorkoutStylesNotes" : "WeekWorkoutStylesNotesDisplayOnly"}
                                            value={exercise.note}
                                            onChange={(e) => handleNoteChange(e, exerciseIndex, date)}
                                            onBlur={() => updateNoteInFirestore(exercise.id, exercise.note, date, false)}
                                            onInput={adjustTextAreaHeight}
                                            disabled={!isEditMode}  // Disable textarea based on isEditMode
                                            rows={3}
                                            onKeyDown={(e) => handleArrowNavigation(e)}
                                            onFocus={() => setFocusContext({ date, fieldType: 'note', exerciseIndex, setIndex })}
                                        ></textarea>
                                    </div>
                                </div>
                            </>
                        )}
                        <div className='WeekWorkoutStylesVideoContainer'>
                            {videoUrls[date] && videoUrls[date][exercise.id] ? (
                                <img
                                    className="WorkoutStylesNotes WorkoutPointerCursor"
                                    src={require('../assets/black_play_icon.png')}
                                    alt="Play Video"
                                    onClick={() => playVideo(videoUrls[date][exercise.id])}
                                />
                            ) : (
                                <img
                                    className="WeekWorkoutStylesFakeVideoIcon"
                                    src={require('../assets/PlayVid.png')}
                                    alt="Play Video"
                                />
                            )}
                        </div>
                        <Modal
                            isOpen={isVideoModalOpen}
                            onRequestClose={() => {
                                setIsVideoModalOpen(false)
                                setCurrentVideoUrl(''); // Reset the video URL
                            }}
                            contentLabel="Video Modal"
                            key={`video-modal-${currentVideoUrl}`} // Unique key based on the video URL
                        // Add additional styling or attributes here
                        >
                            <img className="WeekWorkoutStylesButtonBackModal"
                                src={require('../assets/back_arrow_black.png')} // Path to your icon
                                alt="Back"
                                onClick={() => setIsVideoModalOpen(false)}
                                style={{ width: '24px', height: '24px' }} // Adjust size as needed
                            />
                            <video ref={videoRef}
                                className="WorkoutStylesModalVideo"
                                controls
                                disablePictureInPicture // This attribute disables the picture-in-picture feature
                            >
                                <source src={currentVideoUrl} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </Modal>
                        {isEditMode && (
                            <button onClick={() => deleteExercise(date, exerciseIndex)}>
                                <div className='WorkoutStylesDeleteIconContainer'>
                                    <img className="ProgramCreationStylesDeleteIcon" src={require('../assets/delete_icon.png')} alt="Delete" />
                                </div>
                            </button>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default DraggableExercise;