import React from 'react'
import '../screens/ChatPageStyles.css';
import NavBarChat from './NavBarChat.tsx';
import SearchBarChat from './SearchBarChat.tsx';
import ChatsChat from './ChatsChat.tsx';


const SideBarChat = ({ toggleSidebar }) => {

    return (
      <div className = 'SideBarChat'>
        <NavBarChat />
        <SearchBarChat/>
        <ChatsChat toggleSidebar={toggleSidebar} />
      </div>
    );
  };
  
  export default SideBarChat;