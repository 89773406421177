import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { firestore, auth } from '../firebase';
import { collection, query, where, getDocs, setDoc, doc, orderBy } from 'firebase/firestore';
import { getMessaging, onMessage, getToken } from 'firebase/messaging';
import Footer from '../components/Footer.tsx';
import Search from '../components/Search.tsx';
import '../screens/HomeScreenStyles.css';

type User = {
  id: string;
  Name: string;
  role: string;
  coachDetails?: CoachDetails; // Optional property
};

type CoachDetails = {
  title: string;
  description: string;
  selectedPrice: string;
  profilePicUri: string,
  bannerUri: string;
  subscription_button: boolean;
  coachingFee?: number; // Optional property for the coaching fee
  questionnaireUrl?: string;
  engagementPeriod?: string;
};

const HomeScreen = () => {
  const history = useHistory();
  const [coaches, setCoaches] = useState<User[]>([]);
  const [activeIcon, setActiveIcon] = useState<'personne' | 'calendrier' | 'stats' | 'maison' | 'invitation' | 'eleves' | 'parametres' | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  function capitalizeName(name) {
    if (!name) return ""; // Return an empty string if name is falsy
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  }

  useEffect(() => {
    const fetchCoaches = async () => {
      const usersRef = collection(firestore, 'users');
      const q = query(usersRef, where('role', '==', 'coach'), where('stripeAccountSetupComplete', '==', true), orderBy('priority'));
      const querySnapshot = await getDocs(q);

      const coachesWithProfileInfoPromises = querySnapshot.docs.map(async (documentSnapshot) => {
        const userData = documentSnapshot.data() as User;

        const profileInfoRef = collection(firestore, 'users', documentSnapshot.id, 'profileInfo');
        const profileInfoSnapshot = await getDocs(profileInfoRef);

        let coachDetails: CoachDetails = { title: '', description: '', selectedPrice: '', profilePicUri: '', bannerUri: '', subscription_button: false, coachingFee: 0 }; // Default empty values
        profileInfoSnapshot.forEach(doc => {
          if (doc.exists()) {
            const profileInfo = doc.data();
            coachDetails = {
              ...coachDetails,
              profilePicUri: profileInfo.profilePicUri,
              bannerUri: profileInfo.bannerUri,
              title: profileInfo.title,
              description: profileInfo.description,
              selectedPrice: profileInfo.selectedPrice,
              subscription_button: profileInfo.subscription_button,
              coachingFee: profileInfo.coachingFee,
              questionnaireUrl: profileInfo.questionnaireUrl,
              engagementPeriod: profileInfo.engagementPeriod,
            };
          }
        });

        return {
          ...userData,
          id: documentSnapshot.id,
          coachDetails: coachDetails,
        };
      });

      const coachesWithProfileInfo = await Promise.all(coachesWithProfileInfoPromises);
      setCoaches(coachesWithProfileInfo.filter(coach => coach.coachDetails));
    };

    fetchCoaches();
    // Add the FCM token refresh handling here
    const messaging = getMessaging();
    getToken(messaging, { vapidKey: 'BNrGzXBQ3eeKKi2tBy1pOigGDqaJs04Hbw83GydqrLYtFQDnyDoIeXzNnIPTFNaTb9pMXo_DFVCI4oRWPPwSMA4' })
      .then((currentToken) => {
        if (currentToken) {
          console.log('Current token: ', currentToken);
          // Perform necessary action with the token
          if (auth.currentUser) {
            setDoc(doc(firestore, "users", auth.currentUser.uid), { fcmToken: currentToken }, { merge: true });
          }
        } else {
          console.log('No registration token available. Request permission to generate one.');
          // Show permission request UI or handle the absence of a token
        }
      }).catch((err) => {
        console.error('Unable to retrieve token ');
      });

    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
      // Handle foreground message
    });
    setIsLoading(false);
  }, []);

  const handleCoachSelect = (coach) => {
    // Replace spaces with hyphens and encode URI
    const coachNameSlug = encodeURIComponent(coach.Name.replace(/\s+/g, '-').toLowerCase());

    history.push({
      pathname: `/ProfilePageSearch/${coachNameSlug}`,
      state: { ...coach },
    });
  };

  const navigateToChatPage = () => {
    history.push('/Chat'); // Replace '/ChatPage' with your actual ChatPage route
  };

  return (
    <div className="SuprememainContainerHome">
      <div className="leftsideContainerHome">
        <div className="LogoIconContainerHome">
          <img className="LogoHome" src={require('../assets/ReReReDesign.png')} alt="Logo" />
        </div>
        <Footer setActiveIcon={setActiveIcon} activeIcon={activeIcon} />
      </div>
      <div className="mainContainer">
        <div className='HomeScreenContentContainer'>
          <div className='FirstLayerHomeScreenContentContainer'>
            <div className='TitleHomeScreenContentContainer'>
              Accueil
            </div>
            <div className="chatIconContainer">
              <img className="Chat" src={require('../assets/ChatLetter.png')} alt="Chat" onClick={navigateToChatPage} />
            </div>
          </div>
          {isLoading ? (
            <div className="ProfilePageLoadingContainer">
              <div className="spinner"></div>
            </div>
          ) : (
            <div>
              <div className='HomeScreenSearchBarContainer'>
                <Search />
              </div>
              <div className="listContainer">
                {coaches.map((coach) => (
                  <div key={coach.id} className="coachCard" onClick={() => handleCoachSelect(coach)}>
                    <div className="bannerContainer">
                      {/* Updated to use bannerUri from coachDetails */}
                      <img
                        src={coach.coachDetails?.bannerUri || require('../assets/default_banner.png')}
                        className="banner"
                        alt="Banner"
                      />
                    </div>
                    <div className="coachInfoContainer">
                      <span className="coachName">
                        {capitalizeName(coach.Name)}
                      </span>
                      {coach.coachDetails && (
                        <div className="TitleAndDescriptionContainer">
                          <span className="coachTitle">
                            {coach.coachDetails.title.length > 35
                              ? `${coach.coachDetails.title.substring(0, 35)}...`
                              : coach.coachDetails.title}
                          </span>
                          <p className="coachDescription">
                            {coach.coachDetails.description.length > 40
                              ? `${coach.coachDetails.description.substring(0, 40)}...`
                              : coach.coachDetails.description}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomeScreen;
