import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { collection, getDocs, doc, getDoc, setDoc, } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { firestore } from '../firebase.js';
import { useLocation } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';
import Footer from '../components/Footer.tsx';
import CancelSubscriptionModal from '../components/CancelSubscriptionModal.tsx';
import { UserContext } from '../components/UserContext.tsx';
import './ManageSubscriptionsStyles.css';

interface Coach {
  id: string;
  Name: string;
  profilePicUri?: string;
  subscriptionId: string;
  isCancelled?: boolean;
  cancellationDate?: Date | string | null; // Allow null as a valid type
  engagementPeriod?: string;
  // Add other properties of a coach here if needed
}

interface Student {
  id: string;
  Name: string;
  profilePicUri?: string;
}

const ManageSubscriptions = () => {
  const history = useHistory();
  const location = useLocation();
  const coachName = location.state?.coachName; // Access coachName from the state
  const { userRole } = useContext(UserContext);
  const [coaches, setCoaches] = useState<Coach[]>([]);
  const [students, setStudents] = useState<Student[]>([]); // For coach's students
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const [activeIcon, setActiveIcon] = useState<'personne' | 'calendrier' | 'stats' | 'maison' | 'invitation' | 'eleves' | 'parametres' | null>(null);
  const [selectedCoachId, setSelectedCoachId] = useState<string | null>(null);
  const [selectedStudentId, setSelectedStudentId] = useState<string | null>(null);

  const auth = getAuth();
  const currentUser = auth.currentUser; // Define currentUser here

  const navigateToInviteStudents = () => {
    history.push({
      pathname: '/InviteStudents',
      state: { coachName } // Pass coachName in the state
    });
  };

  const fetchData = async () => {
    if (!currentUser) {
      console.log('User not logged in');
      return;
    }
    setIsLoading(true);
    try {
      let studentsList: Student[] = [];
      let coachesList: Coach[] = [];

      if (userRole === 'coach') {
        const myStudentsRef = collection(firestore, 'users', currentUser.uid, 'myStudents');
        const studentsSnapshot = await getDocs(myStudentsRef);

        for (const docSnapshot of studentsSnapshot.docs) {
          const studentId = docSnapshot.id;
          let profilePicUri = '';

          // Try to fetch studentProfileInfo first
          const studentProfileInfoRef = doc(firestore, 'users', studentId, 'profileInfo', 'studentProfileInfo');
          const studentProfileInfoDoc = await getDoc(studentProfileInfoRef);

          if (studentProfileInfoDoc.exists()) {
            profilePicUri = studentProfileInfoDoc.data().profilePicUri || '';
          } else {
            // If not found, then try coachProfileInfo
            const coachProfileInfoRef = doc(firestore, 'users', studentId, 'profileInfo', 'coachProfileInfo');
            const coachProfileInfoDoc = await getDoc(coachProfileInfoRef);
            if (coachProfileInfoDoc.exists()) {
              profilePicUri = coachProfileInfoDoc.data().profilePicUri || '';
            }
          }

          studentsList.push({
            id: studentId,
            Name: docSnapshot.data().Name || 'Unknown Name',
            profilePicUri: profilePicUri
          });
        }
      }

      const currentUserSubscriptionsRef = collection(firestore, 'users', currentUser.uid, 'mySubscriptions');
      const subscriptionsSnapshot = await getDocs(currentUserSubscriptionsRef);

      for (const docSnapshot of subscriptionsSnapshot.docs) {
        const coachId = docSnapshot.id;
        const subscriptionData = docSnapshot.data();

        const coachProfileInfoRef = doc(firestore, 'users', coachId, 'profileInfo', 'coachProfileInfo');
        const coachProfileInfoDoc = await getDoc(coachProfileInfoRef);

        // Convert Firestore Timestamp to JavaScript Date object
        const cancellationDate = subscriptionData.cancellationDate
          ? new Date(subscriptionData.cancellationDate.seconds * 1000)
          : null;

        let coachData = {
          id: coachId,
          Name: docSnapshot.data().Name || 'Unknown Name',
          profilePicUri: '',
          subscriptionId: docSnapshot.data().subscriptionId || '',
          isCancelled: subscriptionData.isCancelled || false,
          cancellationDate: cancellationDate,
          engagementPeriod: subscriptionData.engagementPeriod || null, // Fetch engagementPeriod, default to null if not present
        };

        if (coachProfileInfoDoc.exists()) {
          coachData.profilePicUri = coachProfileInfoDoc.data().profilePicUri || '';
        }

        coachesList.push(coachData);
      }

      setStudents(studentsList);
      setCoaches(coachesList);
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Update the useEffect hook
  useEffect(() => {
    fetchData();
  }, [currentUser, userRole]);

  const handleCancelClick = (coachId) => {
    setSelectedCoachId(coachId);
    setIsCancelModalVisible(true);
  };

  const handleResignClick = (studentId: string) => {
    setSelectedStudentId(studentId);
    setIsCancelModalVisible(true);
  };

  const handleConfirmCancel = async () => {
    if (!selectedCoachId || !currentUser) {
      console.log('No coach selected for cancellation or user not logged in');
      return;
    }

    try {
      setIsProcessing(true);

      // Fetch the subscription ID directly from Firestore
      const subscriptionDocRef = doc(firestore, 'users', currentUser.uid, 'mySubscriptions', selectedCoachId);
      const subscriptionDoc = await getDoc(subscriptionDocRef);

      if (!subscriptionDoc.exists()) {
        throw new Error('Subscription document not found in Firestore');
      }

      const subscriptionData = subscriptionDoc.data();
      const subscriptionId = subscriptionData.subscriptionId;
      const startDate = subscriptionData.startDate.toDate(); // Assuming startDate is stored as a Firestore Timestamp
      const engagementPeriod = subscriptionData.engagementPeriod || 0; // Use 0 if engagementPeriod is undefined

      // Calculate if the current date is before the end of the engagement period
      const currentDate = new Date();
      const endEngagementDate = new Date(startDate);
      if (engagementPeriod > 0) {
        endEngagementDate.setMonth(endEngagementDate.getMonth() + engagementPeriod);
      }
      const formattedEndDate = endEngagementDate.toLocaleDateString('fr-FR', { // Assuming you want the date in French format
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });

      // Update Firestore document with cancellation status and date
      await setDoc(subscriptionDocRef, {
        isCancelled: true,
        cancellationDate: endEngagementDate // Storing the actual Date object
      }, { merge: true }); // Use merge to update the document without overwriting other fields

      if (currentDate < endEngagementDate) {
        alert(`Votre demande d'annulation a été enregistrée. Votre abonnement restera actif et sera automatiquement renouvelé jusqu'au ${formattedEndDate}, date de fin de votre période d'engagement de ${engagementPeriod} mois. Après cette date, l'abonnement ne sera plus renouvelé et vous ne serez plus débité.`);
      } else {
        alert("Nous avons bien pris en compte votre demande d'annulation. Votre abonnement restera actif jusqu'à la fin de votre période de facturation actuelle, après quoi il ne sera pas renouvelé.");
      }

      if (!subscriptionId) {
        throw new Error('Subscription ID not found');
      }

      const cancelSubscription = httpsCallable(getFunctions(), 'cancelStripeSubscription');
      await cancelSubscription({ subscriptionId, coachId: selectedCoachId, engagementPeriod });

      setCoaches(coaches.filter(coach => coach.id !== selectedCoachId));
      setIsCancelModalVisible(false);
    } catch (error) {
      console.error('Error cancelling subscription:', error);
      // Handle error
    } finally {
      setIsProcessing(false);
    }
    await fetchData();
  };

  const handleConfirmResign = async () => {
    console.log('Confirming resignation for student ID:', selectedStudentId);
    if (!selectedStudentId) {
      console.log('No student selected for resignation');
      return;
    }

    if (!currentUser) {
      console.error('User not logged in');
      alert('Please log in to proceed.');
      return;
    }

    try {
      console.log('before processing');

      setIsProcessing(true);

      console.log('after processing');

      // Fetch the subscription ID from 'myStudents' for coaches
      const studentSubscriptionRef = doc(firestore, 'users', currentUser.uid, 'myStudents', selectedStudentId);
      const studentSubscriptionDoc = await getDoc(studentSubscriptionRef);
      console.log('fetch path firestore');

      if (!studentSubscriptionDoc.exists()) {
        throw new Error('Student subscription document not found in Firestore');
      }

      const subscriptionId = studentSubscriptionDoc.data().subscriptionId;
      console.log('subscriptionId fetch normalement');


      if (!subscriptionId) {
        throw new Error('Subscription ID not found');
      }

      const cancelSubscription = httpsCallable(getFunctions(), 'cancelStripeSubscription');
      await cancelSubscription({ subscriptionId, studentId: selectedStudentId });
      console.log('cancel tentative');

      // Update the students state
      setStudents(students.filter(student => student.id !== selectedStudentId));

      setIsCancelModalVisible(false);
    } catch (error) {
      console.error('Error resigning student:', error);
    } finally {
      setIsProcessing(false);
    }
    await fetchData();
  };

  return (
    <div className="SuprememainContainerProfile">
      <div className="leftsideContainerProfile">
        <div className="LogoIconContainerProfile">
          <img className="LogoProfile" src={require('../assets/ReReReDesign.png')} alt="Logo" />
        </div>
        <Footer setActiveIcon={setActiveIcon} activeIcon={activeIcon} />
      </div>
      <div className='ProfilePageContent'>
        <div className='ProfileScreenContentContainer'>
          <div className='FirstLayerManageSubscriptionsScreenContentContainer'>
            <div className='TitleProfileScreenContentContainer'>
              <span> {userRole === 'coach' ? 'Mes élèves' : 'Abonnement'}</span>
            </div>
          </div>
          {isLoading ? (
            <div className="ProfilePageLoadingContainer">
              <div className="spinner"></div>
            </div>
          ) : (
            <div className="MySubscriptionsContainer">
              {userRole === 'coach' && (
                <>
                  <div className='ManageSubscriptionsStylesTitle'>
                    <span>Mes élèves</span>
                    <button className="" onClick={() => navigateToInviteStudents()}>
                      <div className='ManagerSubscriptionsInviteTextContainer'>
                        <img className="ManageSubscriptionsInviteIcon" src={require('../assets/invite_icon.png')} alt="Add" />
                        <span className='ManageSubscriptionsInviteText'>Inviter des élèves</span>
                      </div>
                    </button>
                  </div>
                  {students.length > 0 ? (
                    students.map((student) => (
                      <div>
                        <div key={student.id} className="MySubscriptionsCard">
                          <div className='MySubscriptionsCardPhotoName'>
                            <img
                              src={student.profilePicUri || require('../assets/default_profile_picture.png')}
                              className="MySubscriptionsProfilePic"
                              alt="Student"
                            />
                            <span className="MySubscriptionsName">{student.Name}</span>
                          </div>
                          <button
                            className='MySubscriptionsCancelButton'
                            onClick={() => handleResignClick(student.id)}
                            disabled={isProcessing}
                          >
                            {isProcessing ? (
                              <div className="spinner ManageSubscriptionStylesSpinnerSmall"></div>
                            ) : (
                              "Résilier l'élève"
                            )}
                          </button>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className={userRole === 'coach' ? "MySubscriptionsCenteredContainer" : "MySubscriptionsCenteredContainerStudent"}>
                      <span className="MySubscriptionsNoSubscriptionsText">
                        Vous n'avez pas encore d'élèves.
                      </span>
                    </div>
                  )}
                </>
              )}

              {userRole === 'coach' && coaches.length > 0 && <div style={{ marginTop: '30px' }} className='ManageSubscriptionsStylesTitle'>Mes abonnements</div>}

              {coaches.length > 0 ? (
                coaches.map((coach) => (
                  <div key={coach.id} className="MySubscriptionsCard">
                    <div className='MySubscriptionsCardPhotoName'>
                      <img
                        src={coach.profilePicUri || require('../assets/default_profile_picture.png')}
                        className="MySubscriptionsProfilePic"
                        alt="Profile"
                      />
                      <span className="MySubscriptionsName">{coach.Name}</span>
                    </div>
                    {coach.isCancelled ? (
                      <div className="MySubscriptionsCancelledInfo">
                        {coach.engagementPeriod ? (
                          `Abonnement annulé, se termine le ${coach.cancellationDate
                            ? new Date(coach.cancellationDate).toLocaleDateString('fr-FR', {
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric'
                            })
                            : 'Date inconnue'
                          }`
                        ) : (
                          "Abonnement annulé, ne sera pas renouvelé"
                        )}
                      </div>
                    ) : (
                      <button
                        className='MySubscriptionsCancelButton'
                        onClick={() => handleCancelClick(coach.id)}
                        disabled={isProcessing}
                      >
                        {isProcessing ? (
                          <div className="spinner ManageSubscriptionStylesSpinnerSmall"></div>
                        ) : (
                          "Annuler l'abonnement"
                        )}
                      </button>
                    )}
                  </div>
                ))
              ) : (
                userRole === 'eleve' && (
                  <div className="MySubscriptionsCenteredContainerStudent">
                    <span className="MySubscriptionsNoSubscriptionsText">
                      Vous n'avez pas encore d'abonnement.<br />Allez découvrir des athlètes!
                    </span>
                    <button
                      className="ProgramCreationStylesGoToWorkoutButton"
                      onClick={() => history.push(`/home`)}
                    >
                      <span className="MySubscriptionsButtonText">Aller à l'accueil</span>
                    </button>
                  </div>
                )
              )}

            </div>
          )}
          <CancelSubscriptionModal
            visible={isCancelModalVisible}
            onConfirm={selectedCoachId ? handleConfirmCancel : handleConfirmResign}
            onCancel={() => {
              setIsCancelModalVisible(false);
              setSelectedCoachId(null); // Reset the selectedCoachId
              setSelectedStudentId(null); // Reset the selectedStudentId
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ManageSubscriptions;