import React from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation hook
import '../../screens/PaymentSuccessStyles.css'; // Make sure to create this CSS file

const PaymentSuccessPage = () => {
    const location = useLocation(); // Access location object
    const paymentType = location.state?.paymentType; // Access state passed to the route

    // Customize message based on payment type
    const isSubscription = paymentType === 'subscription';
    const successMessage = isSubscription ?
        "Félicitations, votre abonnement a été activé avec succès !" :
        "Félicitations, votre paiement a été effectué avec succès !";

    const explanation1 = isSubscription ?
        "Votre coach a été notifié de votre nouvel abonnement et est désormais prêt à élaborer votre programme personnalisé. 💪" :
        "Votre coach a reçu confirmation de votre paiement et peut maintenant commencer à préparer votre session exclusive. 💪";
    
        const explanation2 = isSubscription ?
        "Il est temps de commencer les échauffements et de se préparer pour une transformation épique ! 🔥" :
        "Vous pouvez dès à présent vous préparer mentalement et physiquement pour cette expérience unique et motivante ! 🔥";


    return (
        <div className="payment-success-container">
            <div className='PaymentSuccessContentContainer'>
                <div className='PaymentSuccessValidateContainer'>
                    <img className="payment-success-icon" src={require('../../assets/payment_success_icon_white.png')} alt="Validated" />
                    <span className='PaymentSuccessTitleH1'>Paiement réussi.</span>
                    <span className="subtitle">{successMessage}</span>
                </div>
                <div className='ContainerRight'>
                    <div className='PaymentSuccessValidateContainerSmallScreen'>
                        <img className="payment-success-icon" src={require('../../assets/payment_success_icon_black.png')} alt="Validated" />
                        <span className='PaymentSuccessTitleH1SmallScreen'>Paiement réussi.</span>
                        <span className="subtitleRight">{successMessage}</span>
                    </div>
                    <div className='PaymentSuccessSteps'>
                        <span className='PaymentSuccessNextTitle'>Quelle est la suite ?</span>
                        <span className="PaymentSuccessExplanation">1 - {explanation1}</span>
                        <span className="PaymentSuccessExplanation">2 - {explanation2}</span>
                    </div>
                    <div className='PaymentSuccessLastSentenceContainer'>
                        <span className="subtitleRight">Nous avons hâte de vous voir vous dépasser et accomplir vos objectifs.</span>
                        <button
                            className="buttontitle PaymentSuccessMarginButton"
                            onClick={() => window.location.href = 'https://www.google.com'}
                        >
                            <span className='buttonText'>Retourner sur le web</span>
                            <img className="ProgramCreationStylesConfigureIcon" src={require('../../assets/goWorkout_Icon.png')} alt="Add" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PaymentSuccessPage;