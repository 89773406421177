import React, { useState } from 'react';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import '../../components/PaymentComponentStyles.css';

if (!process.env.REACT_APP_STRIPE_PUBLIC_KEY) {
    throw new Error("Stripe public key is not defined in the environment variables.");
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const PaymentMembershipComponent = ({ pricingDetails, coachId, name, engagementPeriod, onPaymentSuccess, onBack, buttoncolor }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [isProcessing, setIsProcessing] = useState(false);
    const [email, setEmail] = useState('');
    const [isCardFocused, setIsCardFocused] = useState(false);
    const [studentName, setStudentName] = useState('');
    const allFieldsFilled = email && studentName;
    const [showDetails, setShowDetails] = useState(false);

    const toggleDetails = () => setShowDetails(!showDetails);


    const isValidEmail = email => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    function capitalizeName(name) {
        if (!name) return ""; // Return an empty string if name is falsy
        return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
    }

    const handleSubscribe = async (event) => {
        event.preventDefault();
        if (!stripe || !elements) {
            return;
        }

        setIsProcessing(true);

        try {
            const cardElement = elements.getElement(CardElement);
            if (!cardElement) {
                console.error('Card element not found');
                setIsProcessing(false);
                return;
            }

            // Create a payment method and associate it with the customer
            const paymentMethodResponse = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
            });

            if (paymentMethodResponse.error) {
                console.error('Error creating payment method:', paymentMethodResponse.error.message);
                setIsProcessing(false);
                return;
            }

            const paymentMethodId = paymentMethodResponse.paymentMethod.id;

            // Call your function to handle the subscription creation
            onPaymentSuccess(paymentMethodId, email, studentName);
        } catch (error) {
            console.error('Error during subscription process:', error);
            setIsProcessing(false);
        }
    };

    return (
        <div className="PayModalStylesContainer">
            <div className='PayModalStylesLeftContainer'>
                <div className='PayModalStylesBackButtonContainer'>
                    <button onClick={onBack} className="PayModalStylesBackButtonContainer">
                        <div className="PayModalStylesBackButtonCircle">
                            <img src={require('../../assets/back_arrow_black.png')} alt="Back" className="PayModalStylesBackButtonImage" />
                        </div>
                    </button>
                </div>
                <div className='PayModalStylesElementLeft'>
                    <span className='PayModalStylesDescriptionLeft'>Payer {capitalizeName(name)}</span>
                    <span className='PayModalStylesTitleLeft'>{pricingDetails.totalAmount.toFixed(2).replace('.', ',')} €</span>
                    <button className="PayModalStylesButtonDetails" onClick={toggleDetails}>
                        Afficher les détails
                        <img src={require('../../assets/DetailsArrow.png')} alt="Back" className="PayModalDetailsArrow" />
                    </button>
                    <div className={`MenuOverlay PayModalStylesAddLessDarkBackground ${showDetails ? 'Active' : ''}`} onClick={toggleDetails}></div>
                    <div className={`PayModalStylesPricingDetails ${showDetails ? 'PayModalStylesShowMobile active' : 'PayModalStylesShowMobile'}`}>
                        {showDetails && (
                            <div className='PayModalStylesCloseButtonDetailsContainer'>
                                <button className="PayModalStylesCloseButtonDetails" onClick={() => setShowDetails(false)}>
                                    Fermer
                                </button>
                            </div>
                        )}
                        <div className='PayModalStylesDetailLine'>
                            <div className='PayModalStylesAbonnementContainer'>
                                <div className='PayModalStylesContainerTextAndDescription'>
                                    <p className="PayModalStylesIntitule">Votre Abonnement :</p>
                                    <p className="PayModalStylesDescriptionSmallGrey">mensuel, {engagementPeriod} mois d'engagement</p>
                                </div>
                                <span className="PayModalStylesPrice">{pricingDetails.baseAmount.toFixed(2).replace('.', ',')}€</span>
                            </div>
                        </div>
                        <div className='PayModalStylesDetailLineSeparator'>
                        </div>
                        <div className='PayModalStylesDetailLine'>
                            <div className='PayModalStylesAbonnementContainer'>
                                <div className='PayModalStylesContainerTextAndDescription'>
                                    <p className="PayModalStylesIntitule">Frais services bancaires :</p>
                                    <p className="PayModalStylesDescriptionSmallGrey">2% du montant</p>
                                </div>
                                <span className="PayModalStylesPrice">{pricingDetails.serviceFee.toFixed(2).replace('.', ',')}€</span>
                            </div>
                        </div>
                        <div className='PayModalStylesDetailLineSeparator'>
                        </div>
                        <div className='PayModalStylesDetailLine'>
                            <div className='PayModalStylesAbonnementContainer'>
                                <p className="PayModalStylesIntituleBold">Montant total dû :</p>
                                <span className="PayModalStylesPriceBold">{pricingDetails.totalAmount.toFixed(2).replace('.', ',')}€</span>
                            </div>
                        </div>
                        <span className='PayModalStylesSmallLeft'>Conçu avec ❤️</span>
                    </div>
                </div>
            </div>
            <div className='PayModalStylesRightContainer'>
                <div className='PayModalStylesElementRight'>
                    <span className='PayModalStylesTitle'>Coordonnées</span>
                    <div className='PayModalStylesInfoAndCardContainer'>
                        <form onSubmit={handleSubscribe} className='PayModalAddFullWidth'>
                            <div className="fieldContainer">
                                <label className="PaymentLabel PayModalStylesAddGrey">E-mail</label>
                                <input
                                    type="email"
                                    className='input'
                                    placeholder="Adresse e-mail"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value.toLowerCase())} // Convert email to lowercase
                                    required
                                />
                                <label className="PaymentLabel PayModalStylesAddGrey PayModalStylesAddSmallMarginTop">Nom</label>
                                <input
                                    type="text"
                                    className='input'
                                    placeholder="Nom"
                                    value={studentName}
                                    onChange={(e) => setStudentName(e.target.value)}
                                    required
                                />
                            </div>
                            <span className='PayModalStylesTitle PayModalStylesAddMarginTop'>Moyen de paiement</span>
                            <div className={`PayModalStylesInputDiv ${isCardFocused ? 'card-focused' : ''}`}>
                                <CardElement
                                    onFocus={() => setIsCardFocused(true)}
                                    onBlur={() => setIsCardFocused(false)}
                                />
                            </div>
                            <button style={{ backgroundColor: buttoncolor || '#005B96' }} className={`buttontitle PayModalStylesMarginButton ${isProcessing || !stripe || !elements || !allFieldsFilled ? 'PayModalButtonDisabled' : ''}`}>
                                <span className={`buttonText ${isProcessing || !stripe || !elements || !allFieldsFilled || !isValidEmail(email) ? 'PayModalTextDisabled' : ''}`}>
                                    {isProcessing ? "Traitement…" : "S'abonner"}
                                </span>
                            </button>
                        </form>
                    </div>
                    <div className='PaymentModalSmallScreenStripeContainer'>
                        <span className='PaymentModalSmallLeftSmallScreen'>Conçu avec ❤️</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const StripeWrapperMembership = ({ pricingDetails, coachId, name, engagementPeriod, onPaymentSuccess, onBack, buttoncolor }) => {
    return (
        <Elements stripe={stripePromise}>
            <PaymentMembershipComponent pricingDetails={pricingDetails} coachId={coachId} name={name} engagementPeriod={engagementPeriod} onPaymentSuccess={onPaymentSuccess} onBack={onBack} buttoncolor={buttoncolor} />
        </Elements>
    );
};

export default StripeWrapperMembership;