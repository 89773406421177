import React from 'react';
import { useHistory } from 'react-router-dom';
import './PaymentSuccessStyles.css'; // Make sure to create this CSS file

const PaymentSuccess = () => {

    const history = useHistory();

    const goToProgramCreation = () => {
        history.push('/ProgramCreation');
    };

    return (
        <div className="payment-success-container">
            <div className='PaymentSuccessContentContainer'>
                <div className='PaymentSuccessValidateContainer'>
                    <img className="payment-success-icon" src={require('../assets/payment_success_icon_white.png')} alt="Validated"/>
                    <span className='PaymentSuccessTitleH1'>Paiement réussi.</span>
                    <span className="subtitle">Félicitations, votre nouveau programme démarre officiellement !</span>
                </div>
                <div className='ContainerRight'>
                    <div className='PaymentSuccessValidateContainerSmallScreen'>
                        <img className="payment-success-icon" src={require('../assets/payment_success_icon_black.png')} alt="Validated"/>
                        <span className='PaymentSuccessTitleH1SmallScreen'>Paiement réussi.</span>
                        <span className="subtitleRight">Félicitations, votre nouveau programme démarre officiellement !</span>
                    </div>
                    <div className='PaymentSuccessSteps'>
                        <span className='PaymentSuccessNextTitle'>Quelle est la suite ?</span>
                        <span className="PaymentSuccessExplanation">1 - Votre coach a été notifié de votre abonnement et peut donc démarrer la création de votre programme.</span>
                        <span className="PaymentSuccessExplanation">2 - Vous pouvez à tout moment le/la contacter via la messagerie de la plateforme.</span>
                        <span className="PaymentSuccessExplanation">3 - Chaque week-end, votre coach établit votre programme de la semaine suivante, que vous pouvez consulter et renseigner dans l'onglet Planning.</span>
                    </div>
                    <div className='PaymentSuccessLastSentenceContainer'>
                        <span className="subtitleRight">Nous avons hâte de vous voir vous dépasser et accomplir vos objectifs.</span>
                        <button 
                            className="buttontitle PaymentSuccessMarginButton" 
                            onClick={() => goToProgramCreation()}
                        >
                            <span className='buttonText'>Aller à mon planning</span>
                            <img className="ProgramCreationStylesConfigureIcon" src={require('../assets/goWorkout_Icon.png')} alt="Add"/>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PaymentSuccess;
