import { PRMap } from './types.ts';
import { getAuth } from 'firebase/auth';

const useUserId = () => {
  const auth = getAuth();
  const user = auth.currentUser;

  return user ? user.uid : null;
};

function mapExerciseToKey(exerciseName: string): keyof typeof prs {
    const prs = {
      muscleUp: 0,
      pullUp: 0,
      dips: 0,
      squat: 0,
    };

    switch (exerciseName) {
      case 'Muscle Up':
        return 'muscleUp';
      case 'Pull Up/Chin Up':
        return 'pullUp';
      case 'Dip':
        return 'dips';
      case 'Squat':
        return 'squat';
      default:
        throw new Error('Invalid exercise name');
    }
}

// Exporting the functions for external use
export { useUserId, mapExerciseToKey };

