import React, { useEffect, useRef, useState } from 'react';

// Helper function to validate email

const UserInformationModal = ({ visible, onSubmit, onClose }) => {
    const [name, setName] = useState('');
    const isFormValid = name.trim() !== '';
    const modalRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
                onClose(); // Call the onClose function to hide the modal            
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    if (!visible) return null;

    return (
        <div className="modalContainer" onClick={() => {}}>
            <div className="safeArea" ref={modalRef}>
                 <div className="fieldContainer">
                    <label className="PaymentLabel">Nom</label>
                    <input
                        className="input"
                        type="text"
                        placeholder="Entrer votre pseudo"
                        onChange={(e) => setName(e.target.value)}
                        />
                </div>
                <button
                    className={`buttontitle VioletPaymentButton ${ !isFormValid ? 'buttonDisabled' : ''}`}
                    onClick={() => isFormValid && onSubmit(name)}
                    disabled={!isFormValid}
                >
                    <span className={`buttonText ${!isFormValid ? 'buttonTextDisabled' : ''}`}>
                        Suivant
                    </span>
                </button>
            </div>
        </div>
    );
};

export default UserInformationModal;