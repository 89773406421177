import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { firestore } from '../firebase';
import { collection, query, where, getDocs, doc, getDoc, limit } from 'firebase/firestore';
import '../components/ParrainageSelectionStyles.css';
import debounce from 'lodash/debounce';

type User = {
  id: string;
  Name: string;
  role: string;
  coachStripeAccountId: string;
  profileInfo?: {
    title: string;
    description: string;
    profilePicUri: string;
    bannerUri: string
  };
};

const ParrainageSelection = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [users, setUsers] = useState<User[]>([]);
  const history = useHistory(); // Use history for navigation
  const [searchPerformed, setSearchPerformed] = useState(false); // New state variable
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  const searchUsers = async (searchQuery) => {
    const searchQueryNormalized = searchQuery.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

    // Check if the search query is empty and clear the users if it is
    if (!searchQueryNormalized.trim()) {
      setUsers([]);
      setSearchPerformed(false); // Reset search performed flag if search query is empty
      return;
    }
    try {
      setIsLoading(true); // Set loading to true at the beginning of the search
      const usersRef = collection(firestore, 'users');
      const q = query(usersRef, where("role", "==", "coach")); 
      const querySnapshot = await getDocs(q);
  
      const matchingUsers: User[] = [];
      for (let docSnapshot of querySnapshot.docs) {
        const docData = docSnapshot.data();
        if (docData) {
          const userData = { ...docData, id: docSnapshot.id } as Partial<User>;
          const userNameNormalized = userData.Name?.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
  
          // Check if normalized Name matches the normalized search query
          if (userNameNormalized && userNameNormalized.includes(searchQueryNormalized)) {
            // Fetch profileInfo subcollection
            const profileInfoRef = collection(firestore, 'users', docSnapshot.id, 'profileInfo');
            const profileInfoSnapshot = await getDocs(profileInfoRef);
  
            let profileInfo = { title: '', description: '', profilePicUri: '', bannerUri: '' }; // Default values for profileInfo
            profileInfoSnapshot.forEach(doc => {
              if (doc.exists()) {
                Object.assign(profileInfo, doc.data());
              }
            });
  
            matchingUsers.push({ ...userData, profileInfo } as User);
          }
        }
      }
  
      setUsers(matchingUsers);
      setIsLoading(false); // Set loading to false after the search is complete
      setSearchPerformed(true); // Set to true when search is performed
    } catch (error) {
      console.error('Error fetching users:', error);
      setIsLoading(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((searchQuery) => {
      searchUsers(searchQuery);
    }, 300), // 300 milliseconds delay
    [] // Dependencies array
  );

  const handleSearchChange = (searchQuery: string) => {
    setSearchTerm(searchQuery);
    debouncedSearch(searchQuery); // Use the debounced function
  
    // Clear selected user if the search term is cleared or doesn't match the selected user's name closely
    if (!searchQuery.trim() || (selectedUser && !selectedUser.Name.toLowerCase().includes(searchQuery.toLowerCase()))) {
      setSelectedUser(null); // Clear the selected user
      localStorage.removeItem('selectedCoach'); // Also clear from localStorage if you're storing the selection there
    }
  };

  const handleUserSelect = async (userId: string) => {
    const user = users.find(u => u.id === userId);
    if (user && user.coachStripeAccountId) {
        // Store the selected coach's information
        // For example, using local storage (or any other state management solution)
        localStorage.setItem('selectedCoach', JSON.stringify({ 
          coachStripeAccountId: user.coachStripeAccountId,
          coachUserId: user.id
        }));
        console.log(user.id);
      setSelectedUser(user);   // Set the selected user
      setSearchTerm(user.Name); // Update the search term to display the selected user's name
      setSearchPerformed(false); // Reset search performed flag
      setUsers([]);         // Optional: Update the users array to only include the selected user
    }
  };
  
  return (
    <div className='ParrainageContainerMain'>
        <div className="ParrainageSearchContainer">
          <input
            className="input"
            placeholder="Rechercher"
            value={searchTerm}
            onChange={(e) => handleSearchChange(e.target.value)}
          />
        </div>
      <div className="ParrainageResultsContainer">
      {isLoading ? (
        <div className="ParrainageSearchSpinner"></div>
      ) : searchPerformed && users.length === 0 ? (
        <div className="ParrainageNoUserFoundMessage">
          Aucun utilisateur trouvé
        </div>
      ) : (
        users.map((user) => (
          <div onClick={() => handleUserSelect(user.id)} className="ParrainageCard" key={user.id}>
            <img
              src={user.profileInfo?.profilePicUri || require('../assets/default_profile_picture.png')}
              className="ParrainageProfilePic"
              alt="Profile"
            />
            <span className="ParrainageName">{user.Name}</span>
          </div>
        ))
      )}
      </div>
    </div>
  );
};

export default ParrainageSelection;
