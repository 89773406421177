// src/App.tsx (Web Version)
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect, useLocation } from 'react-router-dom';
import LoginScreen from './screens/LoginScreen.tsx';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { firestore } from './firebase.js';
import { doc, getDoc } from 'firebase/firestore';
import ProfileCreation from './screens/ScreensNotUsed/ProfileCreation.tsx';
import HomeScreen from './screens/HomeScreen.tsx';
import ProfilePageSearch from './screens/ProfilePageSearch.tsx';
import ProfilePage from './screens/ProfilePage.tsx';
import ManageSubscriptions from './screens/ManageSubscriptions.tsx';
import { DateProvider } from './components/DateContext.tsx';
import ProgramCreation from './screens/StudentScreens/ProgramCreation.tsx';
import Workout from './screens/StudentScreens/Workout.tsx';
import WorkoutSubscribe from './screens/CoachScreens/WorkoutSubscribe.tsx';
import ChatPage from './screens/ChatPage.tsx';
import { UserProvider } from './components/UserContext.tsx';
import { SelectedUserProvider } from './components/SelectedUserContext.tsx';
import WeekWorkout from './screens/CoachScreens/WeekWorkout.tsx';
import Statistics from './screens/Statistics.tsx';
import PaymentSuccess from './screens/PaymentSuccess.tsx';
import ScrollToTop from './components/ScrollToTop.tsx';
import { ToastContainer } from 'react-toastify';
import LoadingAnimation from './components/LoadingAnimation.tsx';
import Calculator from './components/Calculator.tsx';
import FAQ from './components/FAQ.tsx';
import FAQPayment from './screens/PaymentProject/FAQPayment.tsx';
import InviteStudents from './screens/InviteStudents.tsx';
import ParametersPage from './screens/ParametersPage.tsx';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import LandingPage from './screens/LandingPage.tsx';
import LandingPayment from './screens/PaymentProject/LandingPayment.tsx';
import AboutUs from './screens/AboutUs.tsx';
import AboutUsPayment from './screens/PaymentProject/AboutUsPayment.tsx';
import DemoLogin from './components/DemoLogin.tsx';
import PaymentLoginScreen from './screens/PaymentProject/PaymentLoginScreen.tsx';
import PaymentProfilePage from './screens/PaymentProject/PaymentProfilePage.tsx';
import PaymentManageSubscriptions from './screens/PaymentProject/PaymentManageSubscriptions.tsx';
import { BlockConfigProvider } from './components/BlockConfigContext.tsx';
import PaymentPublicProfile from './screens/PaymentProject/PaymentPublicProfile.tsx';
import PaymentSuccessPage from './screens/PaymentProject/PaymentSuccessPage.tsx';
import AppPayment from './screens/PaymentProject/AppPayment.tsx';
import LandingPayment2 from './screens/PaymentProject/LandingPayment2.tsx';
import DemoPublicProfile from './screens/PaymentProject/DemoPublicProfile.tsx';
import CreateAccountPayment from './screens/PaymentProject/CreateAccountPayment.tsx';
import BeforeCheckout from './screens/PaymentProject/BeforeCheckout.tsx';

const App = () => {
  const [loading, setLoading] = useState(true);
  const [userRole, setUserRole] = useState(null);
  const auth = getAuth();

  useEffect(() => {
    const subscriber = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userDocRef = doc(firestore, "users", user.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists() && userDoc.data().role) {
          setUserRole(userDoc.data().role); // Set user role directly here
        } else {
          setUserRole(null); // No role found
        }
        // Redirect if a redirect URL is present in the query params
        const params = new URLSearchParams(window.location.search);
        const redirectUrl = params.get('redirect');
        if (redirectUrl) {
          window.location.href = decodeURIComponent(redirectUrl);
        }
      } else {
        setUserRole(null); // User not signed in
      }
      setLoading(false);
    });

    return () => subscriber();
  }, []);

  if (loading) {
    return <LoadingAnimation />; // Show the loading animation when the app is loading
  }

  return (
    <UserProvider>
      <SelectedUserProvider>
        <BlockConfigProvider>
          <DateProvider>
            <DndProvider backend={HTML5Backend}> {/* Wrap Router with DndProvider */}
              <Router>
                <ScrollToTop />
                <ToastContainer position="bottom-right" autoClose={5000} /> {/* ToastContainer added here */}
                <Switch>
                  {/* Define your routes here */}
                  <Route path="/inscription" component={ProfileCreation} />
                  <Route path="/home" component={HomeScreen} />
                  <Route path="/ProfilePageSearch/:name" component={ProfilePageSearch} />
                  <Route path="/ProfilePage" component={ProfilePage} />
                  <Route path="/statistiques" component={Statistics} />
                  <Route path="/ProgramCreation" component={ProgramCreation} />
                  <Route path="/Workout" component={Workout} />
                  <Route path="/WeekWorkout" component={WeekWorkout} />
                  <Route path="/WorkoutSubscribe" component={WorkoutSubscribe} />
                  <Route path="/chat" component={ChatPage} />
                  <Route path="/paymentSuccess" component={PaymentSuccess} />
                  <Route path="/ManageSubscriptions" component={ManageSubscriptions} />
                  <Route path="/CalculAffiliation" component={Calculator} />
                  <Route path="/FAQ" component={FAQ} />
                  <Route path="/FAQPayment" component={FAQPayment} />
                  <Route path="/InviteStudents" component={InviteStudents} />
                  <Route path="/Ressources" component={ParametersPage} />
                  <Route path="/login" component={LoginScreen} />
                  <Route path="/Presentation" component={LandingPage} />
                  <Route path="/PresentationPayment" component={LandingPayment} />
                  <Route path="/ModulePaiement" component={LandingPayment2} />
                  <Route path="/AboutUs" component={AboutUs} />
                  <Route path="/AboutUsPayment" component={AboutUsPayment} />
                  <Route path="/Demo" component={DemoLogin} />
                  <Route path="/PaymentLogin" component={PaymentLoginScreen} />
                  <Route path="/PaymentProfile" component={PaymentProfilePage} />
                  <Route path="/PaymentManageStudents" component={PaymentManageSubscriptions} />
                  <Route path="/coach-profile" component={PaymentPublicProfile} />
                  <Route path="/paymentSuccessPage" component={PaymentSuccessPage} />
                  <Route path="/ProfileDemo" component={DemoPublicProfile} />
                  <Route path="/CreationCompte" component={CreateAccountPayment} />
                  <Route path="/Finalisation" component={BeforeCheckout} />
                  <Route path="/checkout" component={AppPayment} />
                  <Route exact path="/">
                    {auth.currentUser && userRole ? <Redirect to="/home" /> : <LandingPage />}
                  </Route>
                </Switch>
              </Router>
            </DndProvider> {/* Closing tag for DndProvider */}
          </DateProvider>
        </BlockConfigProvider>
      </SelectedUserProvider>
    </UserProvider>
  );
};

export default App;