import React, { useEffect, useRef, useState } from 'react';
import { useDrop } from 'react-dnd';
import DraggableExercise from './DraggableExercise.tsx';

interface DraggableExerciseItem {
    id: string;
    exerciseIndex: number;
    date: string;
}

const DayContainer = ({
    date, exercises, onDropExercise, onReorderExercise,
    handleExerciseNameChange, handleTextChange,
    handleCoachNoteChange, handleNoteChange,
    updateNoteInFirestore, deleteExercise,
    isEditMode, videoUrls, allExercises,
    showContextMenu, formatDate, stopPropagation, userRole,
    handleOpenModal, setCurrentAddingDate, weekWorkouts // Added these as props
}) => {

    /**
     * Determines the target index for a dropped exercise based on the drop position.
     * @param {Object} dropPosition - The drop position from the monitor object (monitor.getClientOffset()).
     * @param {Array} exercises - The current array of exercises for the day.
     * @param {HTMLElement} containerRef - Ref to the container element that holds all draggable items.
     * @returns {number} The target index where the dropped item should be moved.
     */
    function determineTargetIndexBasedOnDropPosition(dropPosition, exercises, containerRef) {

        if (!dropPosition || !containerRef.current) {
            return -1; // Or handle this case as appropriate
        }

        const containerBounds = containerRef.current.getBoundingClientRect();
        const relativeY = dropPosition.y - containerBounds.top;
        const itemHeight = containerBounds.height / exercises.length;

        // Extend the target area to improve dropping at the end
        const totalHeight = itemHeight * exercises.length;
        let targetIndex = Math.floor((relativeY + itemHeight / 2) / itemHeight);

        // Adjust for dropping beyond the last item
        if (relativeY > totalHeight) {
            targetIndex = exercises.length; // Append to the end
        } else {
            targetIndex = Math.min(exercises.length, Math.max(0, targetIndex));
        }

        return targetIndex;
    }

    const containerRef = useRef(null); // Add this near your component's state/refs

    const [{ isOver, canDrop }, drop] = useDrop(() => ({
        accept: "exercise",
        drop: (item: DraggableExerciseItem, monitor) => {
            const clientOffset = monitor.getClientOffset();
            if (!clientOffset) return;

            const targetIndex = determineTargetIndexBasedOnDropPosition(clientOffset, exercises, containerRef);
            if (targetIndex !== undefined && targetIndex !== -1 && item.exerciseIndex !== targetIndex) {
                onReorderExercise(item.exerciseIndex, targetIndex);
            }
        },
        canDrop: (item, monitor) => {
            return item.date === date; // Only allow drop if the item's date matches the container's date
        },
        collect: monitor => ({
            isOver: !!monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    }), [date, exercises]);

    const [expandedDates, setExpandedDates] = useState({});

    useEffect(() => {
        if (!isEditMode || isEditMode) {
            const newExpandedDates = {};
            Object.keys(weekWorkouts).forEach(date => {
                newExpandedDates[date] = true; // Assuming you want all dates expanded by default
            });
            setExpandedDates(newExpandedDates);
        }
    }, [weekWorkouts, isEditMode]);

    const toggleDateExpansion = (date) => {
        setExpandedDates(prevExpandedDates => ({
            ...prevExpandedDates,
            [date]: !prevExpandedDates[date]
        }));
    };

    const containerClassName = `day-container ${isOver && !canDrop ? 'invalid-drop-target' : ''}`;

    return (
        ((exercises.length > 0 || isEditMode) && (
            <div ref={drop} className={containerClassName}>
                <div key={date} onClick={() => toggleDateExpansion(date)}>
                    <div className="ProgramCreationStylesExerciseNameContainer">
                        <span className="ProgramCreationStylesExerciseName">{formatDate(date).dayName} {formatDate(date).monthAndDay}</span>
                        {isEditMode && (
                            <button onClick={(e) => { e.stopPropagation(); showContextMenu(date); }}>
                                <span style={{ color: 'white' }}>...</span>
                            </button>
                        )}
                    </div>
                    {expandedDates[date] && (
                        <div onClick={stopPropagation}>
                            {exercises.length > 0 && ( // Only render this section if there are exercises
                                <div className="WeekWorkoutStylesHeaderContainerWorkout">
                                    <div className="WeekWorkoutStylesExerciseColumnContainer">
                                        <div className="WeekWorkoutStylesMainExerciseContainer">
                                            <span className="ProgramCreationStylesHeaderText">Exercice</span>
                                        </div>
                                    </div>
                                    <div className="WeekWorkoutStylesSetColumnContainer">
                                        <div className="WeekWorkoutStylesHeaderSetContainer">
                                            <span className="ProgramCreationStylesHeaderText">Série</span>
                                        </div>
                                    </div>
                                    <div className="WeekWorkoutStylesFakeXContainer">
                                        <span>x</span>
                                    </div>
                                    <div className="WeekWorkoutStylesRepsColumnContainer">
                                        <div className="WeekWorkoutStylesHeaderSetContainer">
                                            <span className="ProgramCreationStylesHeaderText">Reps</span>
                                        </div>
                                    </div>
                                    <div className="WeekWorkoutStylesWeightColumnContainer">
                                        <div className="WeekWorkoutStylesHeaderSetContainer">
                                            <span className="ProgramCreationStylesHeaderText">Poids</span>
                                        </div>
                                    </div>
                                    <div className="WeekWorkoutStylesRPEColumnContainerWorkout">
                                        <div className="WeekWorkoutStylesHeaderSetContainer">
                                            <span className="ProgramCreationStylesHeaderText">RPE</span>
                                        </div>
                                    </div>
                                    <div className="WeekWorkoutStylesReposColumnContainer">
                                        <div className="WeekWorkoutStylesHeaderSetContainer">
                                            <span className="ProgramCreationStylesHeaderText">Repos</span>
                                        </div>
                                    </div>
                                    <div className="WeekWorkoutStylesFakeLottieContainer WeekWorkoutHideVeryTinyMobile">
                                        <span className="WeekWorkoutStylesFakeLottie">État</span>
                                    </div>
                                    {userRole === 'coach' && (
                                        <>
                                            <div className="WeekWorkoutStylesNotesContainer">
                                                <div className='WeekWorkoutStylesHearderNotesContainer'>
                                                    <span className="WeekWorkoutStylesFakeLottie">Notes élève</span>
                                                </div>
                                            </div>
                                            <div className="WeekWorkoutStylesNotesContainer">
                                                <div className='WeekWorkoutStylesHearderNotesContainer'>
                                                    <span className="WeekWorkoutStylesFakeLottie">Notes coach</span>
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    {userRole === 'eleve' && (
                                        <>
                                            <div className="WeekWorkoutStylesNotesContainer">
                                                <div className='WeekWorkoutStylesHearderNotesContainer'>
                                                    <span className="WeekWorkoutStylesFakeLottie">Notes coach</span>
                                                </div>
                                            </div>
                                            <div className="WeekWorkoutStylesNotesContainer">
                                                <div className='WeekWorkoutStylesHearderNotesContainer'>
                                                    <span className="WeekWorkoutStylesFakeLottie">Notes élève</span>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    <div className='WeekWorkoutStylesVideoContainer'>
                                        <img className="WeekWorkoutStylesFakeVideoIcon" src={require('../assets/PlayVid.png')} alt="Play Video" />
                                    </div>
                                    {isEditMode && (
                                        <div className='WorkoutStylesDeleteIconContainer'>
                                        </div>
                                    )}
                                </div>
                            )}
                            {exercises.map((exercise, exerciseIndex) => (
                                <DraggableExercise
                                    key={`${date}-${exercise.id}-${exerciseIndex}`}
                                    exercise={exercise}
                                    exerciseIndex={exerciseIndex}
                                    date={date}
                                    onReorderExercise={onReorderExercise}
                                    handleExerciseNameChange={handleExerciseNameChange}
                                    handleTextChange={handleTextChange}
                                    handleCoachNoteChange={handleCoachNoteChange}
                                    handleNoteChange={handleNoteChange}
                                    updateNoteInFirestore={updateNoteInFirestore}
                                    deleteExercise={deleteExercise}
                                    videoUrls={videoUrls}
                                    allExercises={allExercises}
                                    isEditMode={isEditMode}
                                    weekWorkouts={weekWorkouts}
                                />
                            ))}
                            {isEditMode && (
                                <div className='WorkoutStylesAddExerciseButtonContainer'>
                                    <button
                                        className="WorkoutStylesAddExerciseButton"
                                        onClick={() => {
                                            setCurrentAddingDate(date); // Set the current date
                                            handleOpenModal(); // Open the modal
                                        }}
                                    >
                                        Ajouter Exercice
                                        <img className="ProgramCreationStylesSelectExercisePlusIcon" src={require('../assets/plus_icon.png')} alt="Add" />
                                    </button>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        ))
    );
};

export default DayContainer;