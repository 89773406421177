import React, { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { toast } from 'react-toastify';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { collection, doc, getDoc, serverTimestamp, setDoc } from 'firebase/firestore';
import { firestore } from '../firebase';
import 'react-toastify/dist/ReactToastify.css';
import './InviteStudentsStyles.css'; // Make sure to create and import your CSS styles
import { useLocation } from 'react-router-dom';
import Footer from '../components/Footer.tsx';

interface EmailDatePair {
    email: string;
    date: string;
    name: string;
    gender: 'Homme' | 'Femme' | '';
}
interface CreateStudentResponse {
    success: boolean;
    message: string;
}
interface HoveredIndexState {
    male: number | null;
    female: number | null;
}

const InviteStudents = () => {
    const auth = getAuth();
    const currentUser = auth.currentUser; // Define currentUser here
    const location = useLocation();
    const [coachName, setCoachName] = useState(''); // Initialized to empty and will be updated either from location.state or Firestore
    const [activeIcon, setActiveIcon] = useState<'personne' | 'calendrier' | 'stats' | 'maison' | 'invitation' | 'eleves' | 'parametres' | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        // Function to fetch coach's name from Firestore
        const fetchCoachName = async (userId) => {
            const userDocRef = doc(firestore, 'users', userId);
            const userDoc = await getDoc(userDocRef);
            if (userDoc.exists()) {
                // Assuming 'Name' field holds the coach's name
                setCoachName(userDoc.data().Name || '');
            } else {
                console.error("No such document!");
            }
        };

        // Check if coachName is already provided through navigation state
        if (location.state?.coachName) {
            setCoachName(location.state.coachName);
        } else {
            // Listen for auth state changes to get the current user
            const unsubscribe = onAuthStateChanged(auth, (user) => {
                if (user) {
                    fetchCoachName(user.uid);
                } else {
                    console.log("User is signed out");
                }
            });
            // Cleanup subscription
            return () => unsubscribe();
        }
    }, [location.state]); // Depend on location.state to re-run if it changes

    const handleFieldChange = (
        index: number,
        field: 'email' | 'date' | 'name' | 'gender',
        value: string
    ) => {
        const updatedPairs = [...emailDatePairs];
        updatedPairs[index][field] = value as 'Homme' | 'Femme' | '';
        setEmailDatePairs(updatedPairs);
    };

    const handleAddEmailDatePair = () => {
        setEmailDatePairs([...emailDatePairs, { email: '', date: formatDateToInputValue(), name: '', gender: '' }]);
    };

    const handleRemoveEmailDatePair = (index: number) => {
        const filteredPairs = emailDatePairs.filter((_, i) => i !== index);
        setEmailDatePairs(filteredPairs);
    };

    const handleSubmit = () => {
        const validPairs = emailDatePairs.filter(pair => pair.email.trim() !== '' && pair.date.trim() !== '' && pair.name.trim() !== '' && pair.gender.trim() !== '');
        // Implement your onInvite logic here
        console.log(validPairs); // Example action
        toast.success("Les invitations ont été envoyées et les comptes sont en cours de création !");
        handleInviteStudents(emailDatePairs);
        setEmailDatePairs([{ email: '', date: '', name: '', gender: '' }]);
    };

    const sendInvitationEmail = async (email, userName, coachName) => {
        const functions = getFunctions();
        const sendEmailFunc = httpsCallable(functions, 'sendInvitationEmail');
        try {
            await sendEmailFunc({ userEmail: email, userName: userName, coachName: coachName });
            console.log(`Invitation email sent to ${email}`);
        } catch (error) {
            console.error('Error sending invitation email:', error);
            // Handle error appropriately
        }
    };

    const handleInviteStudents = async (emailDatePairs) => {
        if (!currentUser) {
            console.error('User not logged in');
            alert('Please log in to send invitations.');
            return;
        }

        setIsLoading(true); // Ensure loading starts here if it doesn't start in handleSubmit

        const functions = getFunctions();
        const createStudentAccountAndEstablishRelationshipCloud = httpsCallable(functions, 'createStudentAccountAndEstablishRelationship');

        const promises = emailDatePairs.map((pair) => {
            return new Promise(async (resolve, reject) => {
                // Validate the date here if necessary
                const validDate = new Date(pair.date);
                if (isNaN(validDate.getTime())) {
                    console.error('Invalid date for:', pair.email);
                    reject('Invalid date'); // Reject the promise if date is invalid
                    return; // Skip this iteration if the date is invalid
                }

                try {
                    const invitationRef = doc(collection(firestore, 'invitations'));
                    await setDoc(invitationRef, {
                        invitedEmail: pair.email,
                        coachId: currentUser.uid,
                        coachName: coachName, // Include the coach's name
                        invitationStatus: 'pending',
                        startingPaymentDate: validDate, // Using the date from the pair
                        createdAt: serverTimestamp()
                    });

                    // Call your Cloud Function to create the account and establish the relationship
                    const result = await createStudentAccountAndEstablishRelationshipCloud({
                        studentEmail: pair.email,
                        coachId: currentUser.uid,
                        coachName: coachName,
                        studentName: pair.name,
                        gender: pair.gender
                    });
                    const responseData = result.data as CreateStudentResponse;
                    console.log(responseData.message); // Now TypeScript knows the shape of responseData

                    // Optionally, call function to send email if you have a separate function for this
                    await sendInvitationEmail(pair.email, pair.name, coachName);

                    resolve(responseData); // Resolve the promise upon successful completion
                } catch (error) {
                    console.error('Error inviting student:', error);
                    reject(error); // Reject the promise if there's an error
                }
            });
        });

        try {
            await Promise.all(promises);
        } catch (error) {
            console.error('Error processing invitations:', error);
            // Handle errors from the promises here
        } finally {
            setIsLoading(false); // Stop loading when all operations are complete
            window.location.reload(); // Refresh the page after the process is complete
        }
    };

    const formatDateToInputValue = (date = new Date()) => {
        const year = date.getFullYear();
        const month = (`0${date.getMonth() + 1}`).slice(-2); // Add leading 0 and ensure it's 2 digits
        const day = (`0${date.getDate()}`).slice(-2); // Add leading 0 and ensure it's 2 digits
        return `${year}-${month}-${day}`;
    };
    
    // Update your initial state for emailDatePairs to include today's date
    const [emailDatePairs, setEmailDatePairs] = useState<EmailDatePair[]>([
        { email: '', date: formatDateToInputValue(), name: '', gender: '' },
    ]);

    const allFieldsFilled = emailDatePairs.every(pair => pair.email.trim() !== '' && pair.date.trim() !== '' && pair.name.trim() !== '' && (pair.gender === 'Homme' || pair.gender === 'Femme'));

    return (
        <div className="SuprememainContainerStats">
            <div className="leftsideContainerStats">
                <div className="LogoIconContainerStats">
                    <img className="LogoStats" src={require('../assets/ReReReDesign.png')} alt="Logo" />
                </div>
                <Footer setActiveIcon={setActiveIcon} activeIcon={activeIcon} />
            </div>
            <div className="statistics-container">
                <div className='StatsScreenContentContainer'>
                    <div className='FirstLayerStatsScreenContentContainer'>
                        <div className='TitleStatsScreenContentContainer'>
                            Inviter des élèves
                        </div>
                    </div>
                    <div className='InviteStudentsMainContainer'>
                        <div className='InviteStudentsMainButtonContainer'>
                            {/* Here we use the areAllFieldsFilled directly in the disabled attribute */}
                            <div className='InviteStudentsMainButtonSecondContainer'>
                                <button
                                    onClick={handleSubmit}
                                    disabled={!allFieldsFilled}
                                    className={` ${!allFieldsFilled ? 'buttonDisabled BlueButton' : 'BlueButton'}`}
                                >
                                    <div className='InviteStudentsMainButtonContainerTextAndImage'>
                                        Envoyer les invitations
                                        <img className="ProgramCreationStylesConfigureIcon" src={require('../assets/send_icon.png')} alt="Add" />
                                    </div>
                                </button>
                            </div>
                        </div>
                        {isLoading ? (
                            <div className="InviteStudentsLoadingContainer">
                                <div className="spinner"></div>
                            </div>
                        ) : (
                            <>
                                <div className='InviteStudentsMainLineContainer'>
                                    <div className='InviteStudentsMainLineNameContainer'>
                                        <span className='InviteStudentsMainLineTitleName InviteStudentsAddPaddingLeft'>Nom</span>
                                    </div>
                                    <div className='InviteStudentsMainLineGenderContainer'>
                                        <span className='InviteStudentsMainLineTitleGender InviteStudentsAddPaddingLeft'>Genre</span>
                                    </div>
                                    <div className='InviteStudentsMainLineEmailContainer'>
                                        <span className='InviteStudentsMainLineTitleEmail InviteStudentsAddPaddingLeft'>Email</span>
                                    </div>
                                    <div className='InviteStudentsMainLineDateContainer'>
                                        <span className='InviteStudentsMainLineTitleDate InviteStudentsAddPaddingLeft'>Date de paiement</span>
                                        <div className="InviteStudentsInfoIconContainer">
                                            <img className="InviteStudentsInfoIcon" src={require('../assets/info_icon.png')} alt="Info" />
                                            <span className="InviteStudentsTooltipText">Cette date détermine le commencement du prochain cycle de paiement.<br></br>Jusqu'à cette date, l'élève bénéficie de l'accès complet aux fonctionnalités, comme s'il était pleinement abonné.</span>
                                        </div>
                                    </div>
                                    <button className="InviteStudentFakeRemoveIcon" disabled>×</button>
                                </div>
                                <div className='InviteStudentsContactLineContainer'>
                                    {emailDatePairs.map((pair, index) => (
                                        <div key={index} className="InviteStudentsContactLine">
                                            <div className='InviteStudentsMainLineNameContainer'>
                                                <input type="text" placeholder="Nom de l'élève" className='InviteStudentsInput' value={pair.name}
                                                    onChange={(e) => handleFieldChange(index, 'name', e.target.value)} />
                                            </div>
                                            <div className='InviteStudentsMainLineGenderContainer'>
                                                <div className='InviteStudentsContainerGenderIcon'>
                                                    <button className={`gender-btn ${pair.gender === 'Homme' ? 'selected' : ''}`} onClick={() => handleFieldChange(index, 'gender', 'Homme')}>
                                                        <img className="InviteStudentsMaleIcon normal" src={require('../assets/male.png')} alt="Male" />
                                                        <img className="InviteStudentsMaleIcon selected" src={require('../assets/male_selected.png')} alt="Male Selected" />
                                                    </button>
                                                    <button className={`gender-btn ${pair.gender === 'Femme' ? 'selected' : ''}`} onClick={() => handleFieldChange(index, 'gender', 'Femme')}>
                                                        <img className="InviteStudentsFemaleIcon normal" src={require('../assets/female.png')} alt="Female" />
                                                        <img className="InviteStudentsFemaleIcon selected" src={require('../assets/female_selected.png')} alt="Female Selected" />
                                                    </button>
                                                </div>
                                            </div>
                                            <div className='InviteStudentsMainLineEmailContainer'>
                                                <input type="text" placeholder="Email de l'élève" className='InviteStudentsInput' value={pair.email}
                                                    onChange={(e) => handleFieldChange(index, 'email', e.target.value)}
                                                />
                                            </div>
                                            <div className='InviteStudentsMainLineDateContainer'>
                                                <input
                                                    type="date"
                                                    className={'InviteStudentsInput InviteStudentsInputDate'}
                                                    value={pair.date}
                                                    onChange={(e) => handleFieldChange(index, 'date', e.target.value)}
                                                />
                                                <div className="InviteStudentsInfoIconContainerMobile">
                                                    <img className="InviteStudentsInfoIcon" src={require('../assets/info_icon.png')} alt="Info" />
                                                    <span className="InviteStudentsTooltipText">Cette date détermine le commencement du prochain cycle de paiement.<br></br>Jusqu'à cette date, l'élève bénéficie de l'accès complet aux fonctionnalités, comme s'il était pleinement abonné.</span>
                                                </div>
                                            </div>
                                            <button
                                                className="InviteStudentRemoveButton"
                                                onClick={() => handleRemoveEmailDatePair(index)}
                                                style={{
                                                    opacity: index === 0 ? 0 : 1, // Set opacity to 0 for the first item
                                                    cursor: index === 0 ? 'default' : 'pointer' // Set cursor to default for the first item
                                                }}
                                                disabled={index === 0} // Disable the button for the first item
                                            >
                                                <img className="InviteStudentsStylesTrashIcon" src={require('../assets/trash.png')} alt="Add" />
                                            </button>
                                        </div>
                                    ))}
                                    <button className="AddEmailDatePairButton" onClick={handleAddEmailDatePair}>
                                        <span className=''>Ajouter une ligne</span>
                                        <img className="ProgramCreationStylesSelectExercisePlusIcon" src={require('../assets/plus_icon.png')} alt="Add" />
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InviteStudents;
