import React, { useEffect, useState } from 'react';
import '../LandingPageStyles.css';
import { Link } from 'react-router-dom';
import { getAnalytics, logEvent } from 'firebase/analytics'; // Import Firebase Analytics functions
import { Helmet } from 'react-helmet-async';
import PaymentNavigationLanding from './PaymentNavigationLanding.tsx';

const LandingPayment = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
    const analytics = getAnalytics(); // Initialize Firebase Analytics
    const [isNotMobile, setIsNotMobile] = useState(window.innerWidth > 950);
    const { navigateToFAQPayment, navigateToAboutUsPayment, navigateToCheckout, navigateToPres2, openEmail } = PaymentNavigationLanding();

    useEffect(() => {
        const handleResize = () => {
          setIsNotMobile(window.innerWidth > 950);
        };
    
        // Add event listener for window resize
        window.addEventListener('resize', handleResize);
    
        // Cleanup event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
      }, []);

      const imageSrc = isNotMobile
    ? require('../../assets/ScreenAppliPayment-7.png') // Desktop image
    : require('../../assets/ScreenAppliPayment-8.png'); // Mobile image

    useEffect(() => {
        logEvent(analytics, 'payment_landing_page_visit');
    }, []);

    return (
        <>
            <Helmet>
                <title>Plateforme de Coaching en Ligne - Musculation et Sports de Force</title>
                <meta name="description" content="Plateforme dédiée aux coachs de salle de sport en ligne, permettant de gérer et suivre facilement les programmes d'entraînement personnalisés de leurs élèves, tout en assurant une gestion fluide des paiements." />
                <meta name="keywords" content="coaching en ligne, musculation, sports de force, gestion entraînement, programme d'entraînement, suivi sportif, paiement pour coachs, entraînement personnalisé, plateforme pour coachs, coaching fitness" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <link rel="canonical" href="https://www.reppwebapp.fr/" />

                {/* Open Graph / Facebook */}
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.reppwebapp.fr/" />
                <meta property="og:title" content="Plateforme de Coaching en Ligne - Musculation et Sports de Force" />
                <meta property="og:description" content="Optimisez votre coaching avec notre plateforme spécialement conçue pour les entraîneurs de musculation et de sports de force, facilitant la création, le suivi des programmes d'entraînement et la gestion des paiements." />
                <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/repp-bf58c.appspot.com/o/Banner_fb.png?alt=media&token=64049cd3-3a06-40de-a4f8-44356fceb318" />

                {/* Twitter */}
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://www.reppwebapp.fr/" />
                <meta property="twitter:title" content="Plateforme de Coaching en Ligne - Musculation et Sports de Force" />
                <meta property="twitter:description" content="Optimisez votre coaching avec notre plateforme spécialement conçue pour les entraîneurs de musculation et de sports de force, facilitant la création, le suivi des programmes d'entraînement et la gestion des paiements." />
                <meta property="twitter:image" content="https://firebasestorage.googleapis.com/v0/b/repp-bf58c.appspot.com/o/Banner_twitter.png?alt=media&token=58bd519a-f0ed-407e-a5df-d0be83a03e94" />

                {/* Structured Data */}
                <script type="application/ld+json">
                    {`
            {
              "@context": "http://schema.org",
              "@type": "Organization",
              "url": "https://www.reppwebapp.fr/",
              "logo": "https://firebasestorage.googleapis.com/v0/b/repp-bf58c.appspot.com/o/Banner_twitter.png?alt=media&token=58bd519a-f0ed-407e-a5df-d0be83a03e94"
            }
        `}
                </script>
            </Helmet>
            <div className="LandingPageStylesMainContainerPayment">
                <header className="LandingPageStylesHeaderContainer">
                    <div onClick={navigateToPres2}>
                        <img src={require('../../assets/ReReReDesign.png')} alt="StreetLifting Logo" className="LandingPageStylesLogoPayment" />
                    </div>
                    <img src={require('../../assets/hamburger_menu.png')} alt="Menu" className="HamburgerMenuIcon" onClick={toggleMenu} />
                    <div className={`MenuOverlay ${isMenuOpen ? 'Active' : ''}`} onClick={toggleMenu}></div>
                    <nav className={`LandingPageStylesNavigationContainer ${isMenuOpen ? 'Active' : ''}`}>
                        <img src={require('../../assets/cross_modal.png')} alt="Close" className="CloseMenuIcon" onClick={toggleMenu} />
                        <Link to="/FAQPayment" className='LandingPageStylesContainerMenuMobileTextButton' onClick={() => { setIsMenuOpen(false); }}>
                            <span className='LandingPageStylesButtonNavigation'>FAQ</span>
                            <img className='LandingPageStylesMenuMobileIcon' src={require('../../assets/next_icon_gris.png')} alt="Next" />
                        </Link>
                        <Link to="/AboutUsPayment" className='LandingPageStylesContainerMenuMobileTextButton' onClick={() => { setIsMenuOpen(false); }}>
                            <span className='LandingPageStylesButtonNavigation'>Qui sommes-nous ?</span>
                            <img className='LandingPageStylesMenuMobileIcon' src={require('../../assets/next_icon_gris.png')} alt="Next" />
                        </Link>
                        <a href="mailto:contactinfo.repp@gmail.com" className='LandingPageStylesContainerMenuMobileTextButton'>
                            <span className='LandingPageStylesButtonNavigation'>Nous contacter</span>
                            <img className='LandingPageStylesMenuMobileIcon' src={require('../../assets/next_icon_gris.png')} alt="Compose email" />
                        </a>
                        <button onClick={navigateToCheckout} className="VioletButton LandingPageStylesAddWidth LandingPageStylesAddMargingTop">Je démarre !</button>
                        <div className="LandingPageFooterMessage">
                            <span>Fait avec ❤️ par l'équipe Repp</span>
                        </div>
                    </nav>
                </header>
                <main>
                    <section className="LandingPageStylesFirstSectionContainer">
                        <div className="LandingPageStylesTextContainerPayment">
                            {/* Use h1 for the main title of the page, ensuring it's descriptive of the page's content */}
                            <h1 className='LandingPageStylesTitlePayment'>
                                Vous avez des clients ?<br className="LandingPageGoToLine" />
                                <span className='LandingPageStylesTitlePayment LandingPageStylesTitleAddColorPayment'>Facilitez vos paiements.</span>
                            </h1>
                            {/* Use paragraph tag for descriptions for better semantics */}
                            <p className='LandingPageStylesDescription'>
                                Partagez votre profil. Soyez payés.
                            </p>
                            <div className="LandingPageButtonsContainerPayment">
                                {/* Add aria-label if needed for more descriptive accessibility */}
                                <button onClick={() => navigateToCheckout()} className="VioletButton LandingPageStylesContainerButtonArrow LandingPageStylesAddHeight">
                                    Créer mon profil
                                    <img className='LandingPageStylesArrow' src={require('../../assets/Arrow-rightPayment.png')} alt="Commencez avec StreetLifting" />
                                </button>
                                <button onClick={navigateToFAQPayment} className="BlueButton LandingPageStylesAddColorPayment LandingPageStylesAddHeight">
                                    En savoir plus
                                    <img className='LandingPageStylesArrow' src={require('../../assets/Arrow-rightPayment.png')} alt="Commencez avec StreetLifting" />
                                </button>
                            </div>
                        </div>
                        {/* Ensure the alt text is descriptive */}
                        <div className='LandingPaymentStylesVideoContainer'>
                            <video className="LandingPageStylesVideoCoach" autoPlay loop muted playsInline>
                                <source src={require('../../assets/VideoMarc.mp4')} type="video/mp4" />
                                Votre navigateur ne supporte pas la vidéo.
                            </video>
                        </div>
                    </section>
                    <div className='LandingPageStylesAddMaringPayment'>
                        <h3 className='LandingPageStylesContainerTextPayment'>Vous êtes pro, alors soyez payés comme un pro.</h3>
                        <section className="LandingPageStylesThirdSectionContainerPayment">
                            <article className='LandingPageStylesContainerIconAndTextDescriptionTitlePayment'>
                                <img src={require('../../assets/Features Overview-pana.png')} alt="Liberté d'esprit" className="LandingPageStylesFirstIconPayment2" />
                                <div className='LandingPageStylesContainerIconAndTextPayment'>
                                    <h3 className='LandingPageStylesContainerTextPayment2'>CONFIGUREZ VOTRE OFFRE SUR VOTRE PROFIL</h3>
                                </div>
                            </article>
                            <article className='LandingPageStylesContainerIconAndTextDescriptionTitlePayment'>
                                <img src={require('../../assets/Share link-pana.png')} alt="Liberté d'esprit" className="LandingPageStylesFirstIconPayment2" />
                                <div className='LandingPageStylesContainerIconAndTextPayment'>
                                    <h3 className='LandingPageStylesContainerTextPayment2'>ENVOYEZ LE LIEN À VOS CLIENTS</h3>
                                </div>
                            </article>
                            <article className='LandingPageStylesContainerIconAndTextDescriptionTitlePayment'>
                                <img src={require('../../assets/Revenue-pana.png')} alt="Liberté d'esprit" className="LandingPageStylesFirstIconPayment2" />
                                <div className='LandingPageStylesContainerIconAndTextPayment'>
                                    <h3 className='LandingPageStylesContainerTextPayment2'>RECEVEZ L'ARGENT SUR VOTRE COMPTE BANCAIRE</h3>
                                </div>
                            </article>
                        </section>
                    </div>
                    <section className="LandingPageStylesFourthSectionContainer LandingPageStylesAddMaringBottom">
                        <img
                            src={require('../../assets/Suivi2.png')}
                            alt="Coaching demonstration"
                            className="LandingPageStylesImageCoach"
                        />
                        <div className="LandingPageStylesTextContainer LandingPageStylesAddMarginLeft">
                            <h2 className='LandingPageStylesTitleLittle'>Des fonctionnalités pensées pour vous</h2>
                            <p className='LandingPageStylesDescription'>
                                Fixez un tarif, une période d'engagement, un abonnement mensuel... C'est vous le maître à bord !
                            </p>
                            <div className="LandingPageButtonsContainerFourthSection">
                                <button onClick={() => navigateToCheckout()} className="VioletButton LandingPageStylesAddWidthLastButton">
                                    Je mets en place mon offre !
                                    <img className="ProgramCreationStylesConfigureIcon" src={require('../../assets/goWorkout_Icon.png')} alt="Je passe" />
                                </button>
                            </div>
                        </div>
                    </section>
                    <section className="LandingPageStylesFirstSectionContainer">
                        <div className="LandingPageStylesTextContainer">
                            <h2 className='LandingPageStylesTitleLittle'>Gardez le contrôle</h2>
                            <p className='LandingPageStylesDescription'>
                                Visualisez en un clin d'oeil vos paiements reçus, et à venir. Gagnez en sérénité !
                            </p>
                            <div className="LandingPageButtonsContainerFourthSection">
                                <button onClick={() => navigateToCheckout()} className="VioletButton LandingPageStylesAddWidthLastButton">
                                    Je prends la main !
                                    <img className="ProgramCreationStylesConfigureIcon" src={require('../../assets/goWorkout_Icon.png')} alt="Inviter" />
                                </button>
                            </div>
                        </div>
                        <img src={imageSrc} alt="Des élèves participant à une séance de formation" className="LandingPageStylesImageCoach" />
                    </section>
                    <section className="LandingPageStylesFirstSectionContainerLastRow LandingPageStylesAddMaringBottom">
                        <img src={require('../../assets/Ok-bro.png')} alt="Des élèves participant à une séance de formation" className="LandingPageStylesImageCoachPayment2" />
                        <div className="LandingPageStylesTextContainerLastRow">
                            <h2 className='LandingPageStylesTitleLittle'>Vos clients vous disent merci</h2>
                            <p className='LandingPageStylesDescription'>
                                Pas besoin d'application ou de virement bancaire, il leur suffit d'un clic pour vous régler !
                            </p>
                            <div className="LandingPageButtonsContainerFourthSection">
                                <button onClick={() => navigateToCheckout()} className="VioletButton LandingPageStylesAddWidthLastButton">
                                    Je simplifie mes paiements !
                                    <img className="ProgramCreationStylesConfigureIcon" src={require('../../assets/goWorkout_Icon.png')} alt="Inviter" />
                                </button>
                            </div>
                        </div>
                    </section>
                    <section className="LandingPageStylesFifthSectionContainer LandingPageStylesAddMaringBottom">
                        <img src={require('../../assets/Men talking-pana.png')} alt="Coaching demonstration" className="LandingPageStylesImageCoachPayment3" />
                        <div className="LandingPageStylesTextContainer">
                            <div className='LandingPageStylesAddMaringPayment2'>
                                <img src={require('../../assets/Quotes.png')} alt="Liberté d'esprit" className="LandingPageStylesFirstIconPayment3" />
                                <h3 className='LandingPageStylesContainerTextPaymentQuote'>Je t'envoie mon Repp</h3>
                            </div>
                        </div>
                    </section>
                </main>
            </div>
            <footer className="LandingPageStylesFooterContainer">
                <div className="LandingFooter-section">
                    <img src={require('../../assets/repp_logo_white.png')} alt="Repp Company Logo" className="LandingPageStylesLogoFooter" />
                    <p style={{ marginTop: '20px' }}>© 2024 Repp Company.</p>
                </div>
                <nav className="LandingFooter-section">
                    <h5>Entreprise</h5>
                    <ul>
                        <li><button onClick={navigateToAboutUsPayment} className='LandingFooter-button'>À propos</button></li>
                        <li><button onClick={navigateToFAQPayment} className='LandingFooter-button'>FAQ</button></li>
                        <li><button onClick={openEmail} className='LandingFooter-button'>Nous contacter</button></li>
                    </ul>
                </nav>
                <nav className="LandingFooter-section">
                    <h5>Plateforme</h5>
                    <ul>
                        <li><button onClick={() => navigateToCheckout()} className='LandingFooter-button'>Créer un compte</button></li>
                    </ul>
                </nav>
            </footer>
        </>
    );
};

export default LandingPayment;